import { Dimensions, Platform } from 'react-native'

import { AxiosError } from 'axios'
import * as Device from 'expo-device'

import { TrackingCategory } from '@/components/Analytics'

import { axios } from '@/lib/httpClient'

import { getBrowser } from '@/utils/getBrowser'
import storage from '@/utils/storage'

export type DeviceIdResponse = {
    device_id: string
}

export interface MixpanelEventRequest {
    event: string
    device_id: string | null
    properties: {
        category: TrackingCategory
        // browser?: string
        [key: string]: string | number
    }
    project_name: 'Popchew App'
    platform?: string | number
    os_version?: string | number
}

export const postMixpanelEvent = async (
    event: string,
    category: TrackingCategory,
    props?: Record<string, unknown> | undefined
) => {
    // Get device id from storage or new one from api
    let device_id = await storage.getDeviceId()

    if (device_id === null) {
        const response = await axios.get<DeviceIdResponse>('/meta/device')
        device_id = response?.data?.device_id
        storage.setDeviceId(device_id)
    }

    const screenWidth = Dimensions.get('window').width
    const screenHeight = Dimensions.get('window').height

    // Build request
    const request: MixpanelEventRequest = {
        event,
        device_id,
        project_name: 'Popchew App',
        properties: {
            category,
            $screen_width: screenWidth,
            $screen_height: screenHeight,
            ...props,
        },
    }

    // Add platform, mapping to types accepted by backend
    if (Platform.OS === 'android') request.platform = 'Android'
    else if (Platform.OS === 'ios') request.platform = 'iOS'
    else if (Platform.OS === 'web') request.platform = 'web'
    else request.platform = 'Unknown'

    // Add OS version
    request.os_version = Device.osName + ' ' + Device.osVersion

    // Add browser if on web
    if (Platform.OS === 'web') request.properties['browser'] = getBrowser()

    try {
        const response = await axios.post<void>('/ingest/mixpanel', request)
        return response.data
    } catch (error) {
        const { response } = error as AxiosError
        if (
            response?.status === 400 &&
            response.data.message.includes('400 Invalid device id')
        ) {
            storage.removeDeviceId()
            postMixpanelEvent(event, category, props)
        }
    }
}

export interface MixpanelUserPropertiesRequest {
    properties: Record<string, string>
    project: 'Popchew App'
}

export const postMixpanelUserProperties = async (
    user_properties: Record<string, string>
) => {
    const request: MixpanelUserPropertiesRequest = {
        project: 'Popchew App',
        properties: user_properties,
    }
    const response = await axios.post<void>(
        '/ingest/mixpanel/user_properties',
        request
    )
    return response
}
