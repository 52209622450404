import { QueryKey, useQuery } from 'react-query'

import { axios } from '@/lib/httpClient'

import { LootboxInfoResponse } from '../types'

const lootboxInfoQueryFn = async (id: GetLootboxId) => {
    const queryParams =
        'orderId' in id ? `order_id=${id.orderId}` : `reward_id=${id.rewardId}`

    const response = await axios.get<LootboxInfoResponse>(
        `/order/lootbox?` + queryParams
    )
    return response.data
}

type GetLootboxId = { orderId: string } | { rewardId: string }

export const useLootboxInfoQueryKey = (
    orderId: string | undefined | null
): QueryKey => ['lootboxInfo', orderId]

export const useLootboxInfo = (id: GetLootboxId) => {
    const IdForLootbox = 'orderId' in id ? id.orderId : id.rewardId

    return useQuery(
        useLootboxInfoQueryKey(IdForLootbox),
        () => lootboxInfoQueryFn(id),
        {
            staleTime: Infinity,
        }
    )
}
