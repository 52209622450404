import React from 'react'

import { Image, Text, View } from 'react-native'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import * as WebBrowser from 'expo-web-browser'

import { Support } from '@/features/Account/screens/Support'

import { OrderDetailResponse } from '@/types/Order'

import { Button } from '@/components/Button'

import tw from '@/tailwind/tailwind'

import { isOrderDeliveryInfoRelevant } from '../utils/isOrderDeliveryInfoRelevant'

export const OrderPlacedBottom = ({
    order,
    setBottomHeight,
    isOrderFailed,
    trackingUrl,
}: {
    order: OrderDetailResponse
    setBottomHeight: React.Dispatch<React.SetStateAction<number>>
    isOrderFailed: boolean | undefined
    trackingUrl?: string
}) => {
    const supportModalRef = React.useRef<BottomSheetModal>(null)

    const isDeliveryInfoRelevant = isOrderDeliveryInfoRelevant(order)

    return (
        <>
            <View
                style={tw`border-t border-pc-shade-60 absolute bottom-0 left-0 right-0 bg-white px-container pb-container pt-[${
                    isDeliveryInfoRelevant ? 8 : 16
                }px]`}
                onLayout={(event) =>
                    setBottomHeight(event.nativeEvent.layout.height)
                }
            >
                {isDeliveryInfoRelevant && (
                    <>
                        <View
                            style={tw`mb-2 flex-row justify-center items-center`}
                        >
                            {isOrderFailed ? (
                                <Text
                                    style={tw`font-ppa-b text-center text-pc-warning`}
                                >
                                    There was an error with your delivery
                                </Text>
                            ) : trackingUrl ? (
                                <>
                                    <Text
                                        style={tw`font-ppa-b text-pc-shade-60`}
                                    >
                                        Delivery by
                                    </Text>
                                    <View style={tw`w-24 h-10 ml-2`}>
                                        <Image
                                            source={require('@/assets/doordash.png')}
                                            resizeMode="contain"
                                            style={tw`h-full w-full`}
                                        />
                                    </View>
                                </>
                            ) : (
                                <Text style={tw`font-ppa-b text-center`}>
                                    You’ll be notified when a driver is assigned
                                </Text>
                            )}
                        </View>
                        <View style={tw`mb-2`}>
                            <Button
                                variant="primary"
                                text={'Track Order'}
                                onPress={() => {
                                    if (trackingUrl)
                                        WebBrowser.openBrowserAsync(trackingUrl)
                                }}
                                disabled={!trackingUrl}
                                trackableCategory="Order"
                                trackableName="Tapped Track Order"
                            />
                        </View>
                    </>
                )}
                <Button
                    variant={isOrderFailed ? 'warning' : 'secondary'}
                    text={'Get Help'}
                    onPress={() => supportModalRef.current?.present()}
                    trackableCategory="Order"
                    trackableName="Tapped Get Help"
                />
            </View>
            <Support ref={supportModalRef} />
        </>
    )
}
