import { Text, TouchableOpacity, View } from 'react-native'

import { TrackableClick } from '@/components/Analytics'

import { useStore } from '@/lib/store'

import tw from '@/tailwind/tailwind'

import { HiddenPaymentMethod, PaymentMethodResponse } from '../types'
import { CardDetails } from './CardDetails'

type PaymentMethodSectionProps = {
    paymentMethod: PaymentMethodResponse | HiddenPaymentMethod | null
    showSelectPaymentMethodScreen: () => void | undefined
}
export const PaymentMethodSection = ({
    paymentMethod,
    showSelectPaymentMethodScreen,
}: PaymentMethodSectionProps) => {
    const useCash = useStore((state) => state.devTools.useCash)

    return (
        <View style={tw`py-8`}>
            <>
                <Text style={tw`font-ppp-b text-white text-base mb-2`}>
                    {`Payment Method${useCash ? ' CASH - TEST' : ''}`}
                </Text>
                <TrackableClick
                    name="Tapped Select Payment Method in checkout"
                    category="Checkout"
                >
                    <TouchableOpacity
                        onPress={() => showSelectPaymentMethodScreen()}
                        style={tw`py-2.5`}
                    >
                        <CardDetails
                            paymentMethod={paymentMethod}
                            showChevron
                        />
                    </TouchableOpacity>
                </TrackableClick>
            </>
        </View>
    )
}
