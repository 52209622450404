import { useEffect } from 'react'

import { Platform, Text, View } from 'react-native'

import { ReviewOrderBounceScreenProps } from '@/navigation/types/ScreenProps'
import { SafeAreaView } from 'react-native-safe-area-context'

import { PointsEarned } from '@/features/OrderPlaced/components/PointsEarned'

import { Button } from '@/components/Button'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { NoOrders } from '@/components/NoOrders'

import { useOrderDetails } from '@/hooks/query/useOrder'

import { SUPPORT_EMAIL } from '@/utils/constants'
import { openLink } from '@/utils/linking'

import tw from '@/tailwind/tailwind'

import { ReviewOrderHeader } from '../components/ReviewOrderHeader'

export const ReviewOrderBounce = ({
    navigation,
    route,
}: ReviewOrderBounceScreenProps) => {
    const { orderId } = route.params || {}

    const { data: order, isLoading: isOrderLoading } = useOrderDetails(orderId)

    useEffect(() => {
        if (!orderId) navigation.navigate('HomeTabs')
    }, [orderId])
    if (!orderId) return null

    if (isOrderLoading) return <FullScreenLoader />
    if (!isOrderLoading && !order) return <NoOrders />

    const { flipdish_order_id } = order

    return (
        <SafeAreaView style={tw`flex-1`}>
            <ReviewOrderHeader title={''} />
            <View style={tw`container flex-1`}>
                <Text style={tw`font-ppa-b text-3xl mb-9`}>
                    Enjoy your order
                </Text>
                <Text style={tw`font-ppa-b text-pc-shade-80 text-base mb-5`}>
                    Your order #{flipdish_order_id} has been delivered, take a
                    minitue to rate your order &{' '}
                    <Text style={tw`text-pc-secondary`}>earn 100 points</Text>
                </Text>
                <PointsEarned order={order} />
            </View>
            <View style={tw`container`}>
                <Button
                    variant="primary"
                    text="Review Order"
                    onPress={() =>
                        navigation.navigate('Review Order', { orderId })
                    }
                    trackableCategory="Review"
                    trackableName="Clicked review order button on review order screen"
                />
                <View style={tw`mt-2`}>
                    <Button
                        variant="secondary"
                        text="Get Help"
                        onPress={() =>
                            openLink(
                                `mailto:${SUPPORT_EMAIL}?subject=Popchew: Help with order #${flipdish_order_id}&body=WRITE HERE <br><br> -----------<br>Platform: ${Platform.OS}`
                            )
                        }
                        trackableCategory="Review"
                        trackableName="Clicked get help button on review order screen"
                    />
                </View>
            </View>
        </SafeAreaView>
    )
}
