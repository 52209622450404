import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const StarGroupingSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width="41"
        height="43"
        viewBox="0 0 41 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M9.73542 24.5033C9.99068 23.8135 10.9664 23.8135 11.2216 24.5033L13.0271 29.3827C13.1074 29.5995 13.2784 29.7705 13.4953 29.8508L18.3746 31.6563C19.0645 31.9116 19.0645 32.8873 18.3746 33.1425L13.4953 34.948C13.2784 35.0283 13.1074 35.1993 13.0271 35.4162L11.2216 40.2955C10.9664 40.9854 9.99068 40.9854 9.73542 40.2955L7.9299 35.4162C7.84965 35.1993 7.67865 35.0283 7.46177 34.948L2.58242 33.1425C1.89259 32.8873 1.89259 31.9116 2.58242 31.6563L7.46177 29.8508C7.67865 29.7705 7.84965 29.5995 7.9299 29.3827L9.73542 24.5033Z"
            fill="#FFFC00"
        />
        <Path
            d="M29.544 14.599C29.7993 13.9092 30.775 13.9092 31.0302 14.599L32.8357 19.4784C32.916 19.6953 33.087 19.8662 33.3039 19.9465L38.1832 21.752C38.8731 22.0073 38.8731 22.983 38.1832 23.2382L33.3039 25.0438C33.087 25.124 32.916 25.295 32.8357 25.5119L31.0302 30.3912C30.775 31.0811 29.7993 31.0811 29.544 30.3912L27.7385 25.5119C27.6582 25.295 27.4872 25.124 27.2704 25.0438L22.391 23.2382C21.7012 22.983 21.7012 22.0073 22.391 21.752L27.2704 19.9465C27.4872 19.8662 27.6582 19.6953 27.7385 19.4784L29.544 14.599Z"
            fill="#FFFC00"
        />
        <Path
            d="M16.0753 2.71328C16.3305 2.02345 17.3062 2.02345 17.5615 2.71328L19.367 7.59263C19.4472 7.80951 19.6182 7.98051 19.8351 8.06076L24.7145 9.86628C25.4043 10.1215 25.4043 11.0972 24.7145 11.3525L19.8351 13.158C19.6182 13.2383 19.4472 13.4093 19.367 13.6261L17.5615 18.5055C17.3062 19.1953 16.3305 19.1953 16.0753 18.5055L14.2697 13.6261C14.1895 13.4093 14.0185 13.2383 13.8016 13.158L8.92227 11.3525C8.23244 11.0972 8.23243 10.1215 8.92226 9.86628L13.8016 8.06076C14.0185 7.98051 14.1895 7.80951 14.2697 7.59263L16.0753 2.71328Z"
            fill="#FFFC00"
        />
    </Svg>
)

export default StarGroupingSvg
