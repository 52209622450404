import { RootStackParamList } from '@/navigation/types/RootStackParamList'
import { Route } from '@react-navigation/native'

import { OrderDetailResponse, OrderStatusResponse } from '@/types/Order'

import { getOrderStatusText } from '@/utils/getOrderStatusText'

const ONE_MINUTE = 60 * 1000
const ONE_HOUR = 60 * 60 * 1000

export const shouldTopbarShowOnCurrentRoute = (
    routesToHideIn: Array<keyof RootStackParamList>,
    currentRoute: Route<string, object | undefined> | undefined
) => {
    if (!currentRoute) return true
    return !routesToHideIn.includes(
        currentRoute.name as keyof RootStackParamList
    )
}

const hasXTimePassedSincePickup = (
    order: OrderDetailResponse,
    timeInMS: number
) => {
    const currentTime = new Date().getTime()
    return (
        currentTime - new Date(order.pickup_time || new Date()).getTime() >
        timeInMS
    )
}

const hasXTimePassedSinceCreated = (
    order: OrderDetailResponse,
    timeInMS: number
) => {
    const currentTime = new Date().getTime()
    return currentTime - new Date(order.created_on).getTime() > timeInMS
}

// 1. The order is DELIVERY, and it IS NOT yet delivered
// 2. The order is DELIVERY, and it IS delivered, and has been less than one hour since order_placed (we don't yet have delivered_at)
// 3. The order is DEILVERY, and it is failed and current time is between placed and (order placed + 5 min)
const shouldShowTopBarDelivery = (
    deliveryOrder: OrderDetailResponse,
    orderStatus: OrderStatusResponse
) => {
    const statusObj = getOrderStatusText(deliveryOrder, orderStatus)

    if (!statusObj.isFailed) {
        // 1. not yet delivered
        if (
            orderStatus.order_state === 'Dispatched' &&
            orderStatus.status !== 'Delivered'
        )
            return true

        // 2. Delivered
        // TODO once delivered_at has been added to backend (not this will always be optional cause old order won't have)

        if (
            orderStatus.status === 'Delivered' &&
            !hasXTimePassedSinceCreated(deliveryOrder, ONE_HOUR)
        )
            return true

        return false
    } else {
        // 3. Delivery is failed :(
        return !hasXTimePassedSinceCreated(deliveryOrder, 5 * ONE_MINUTE)
    }
}

// 1. The order is PICKUP and current time is between order placed and (pickup_time + 10 min)
// 2. The order is PICKUP has failed, and current time is between order placed and (order placed + 5 min)
const shouldShowTopBarPickup = (
    pickupOrder: OrderDetailResponse,
    orderStatus: OrderStatusResponse
) => {
    const statusObj = getOrderStatusText(pickupOrder, orderStatus)

    // 1. Pickup and not failed
    if (!statusObj.isFailed) {
        // check if current time is between order placed and pickup + 10
        return !hasXTimePassedSincePickup(pickupOrder, 10 * ONE_MINUTE)
    } else {
        // 2. Pickup and failed
        return !hasXTimePassedSinceCreated(pickupOrder, 5 * ONE_MINUTE)
    }
}

// Safety net in case an order status doesn't update automatically
// Don't show status bar for any order older than 2.5 hours
const isOrderTooOld = (order: OrderDetailResponse) =>
    hasXTimePassedSinceCreated(order, 2.5 * ONE_HOUR)

/**
 * We should show the order status bar if:
 *
 *      1. The order is PICKUP and current time is between order placed and (pickup_time + 10 min)
 *      2. The order is PICKUP has failed, and current time is between order placed and (order placed + 5 min)
 *      3. The order is DELIVERY, and it IS NOT yet delivered
 *      4. The order is DELIVERY, and it IS delivered, and has been less than one hour since order_placed (we don't yet have delivered_at)
 *      5. The order is DEILVERY, and it is failed and current time is between placed and (order placed + 5 min)
 *
 *      ADDITIONAL: As a safety net, incase status doesn't update correctly from webhook, don't show the order status bar
 *      if it has been > 2.5 hours since order placed
 */

export const shouldShowTopBar = (
    order: OrderDetailResponse,
    orderStatus: OrderStatusResponse
) => {
    // Safety net in case an old order (2.5 hr) doesn't have its status updated correctly
    if (isOrderTooOld(order)) return false

    const isDelivery = order.fulfillment_type === 'delivery'
    const shouldShow = isDelivery
        ? shouldShowTopBarDelivery
        : shouldShowTopBarPickup
    return shouldShow(order, orderStatus)
}
