import { Text } from 'react-native'

import tw from '@/tailwind/tailwind'

export const HowWasText = ({
    instanceName,
}: {
    instanceName: string | null
}) => {
    return (
        <Text style={tw`text-center text-lg font-ppa-b mb-9`}>
            {instanceName ? `How was ${instanceName}?` : 'How was your order?'}
        </Text>
    )
}
