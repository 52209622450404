import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const CheckCircleSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm5.374-14.626A1 1 0 0 0 15.96 7.96L10 13.92l-1.96-1.96a1 1 0 1 0-1.414 1.414l2.667 2.666a1 1 0 0 0 1.414 0l6.667-6.666Z"
            fill="currentColor"
        />
    </Svg>
)

export default CheckCircleSvg
