import { OrderDetailResponse } from '@/types/Order'

export const isOrderDeliveryInfoRelevant = (order: OrderDetailResponse) => {
    const isDelivery = order.fulfillment_type === 'delivery'
    const orderCreatedOn = new Date(order.created_on)
    const ONE_HOUR = 60 * 60 * 1000
    const isOrderPlacedWithinPast12Hours =
        new Date().getTime() - orderCreatedOn.getTime() < 12 * ONE_HOUR

    return isDelivery && isOrderPlacedWithinPast12Hours
}
