import { axios } from '@/lib/httpClient'

import { SmsVerify } from '../types'

export const sendSMSVerification = async (data: SmsVerify) => {
    const path = '/order/auth/sms'

    const response = await axios.post(path, data)
    return response.data
}

export const sendSMS = async (phoneNumber: string) => {
    const data: SmsVerify = { phone: phoneNumber }
    return await sendSMSVerification(data)
}
