import { parseAxiosError } from '@/lib/httpClient'

export interface ApiError {
    statusCode: number
    backendMessage: string
    frontendMessage: string | null
}

export interface ApiErrors {
    genericMessage: string
    specificErrors: ApiError[]
}

// How to use:
// 1. For a given endpoint, build an ApiErrors object that contains
//    - a generic message to be shown if error is not in the list
//    - a list of specific errors in ApiError format
//          * statusCode: the HTTP status code
//          * backendMessage: the message sent by the backend
//          * frontendMessage: the message to be shown to the user
// 2. Pass an axios error to getApiErrorMessage along with the ApiErrors object
export const getApiErrorMessage = (
    error: any,
    apiErrors: ApiErrors
): string => {
    const { status, message } = parseAxiosError(error)
    const msg = message?.toLowerCase() || ''

    for (const apiError of apiErrors.specificErrors) {
        if (
            // status === apiError.statusCode && // Checking status code has not been necessary so far
            msg.includes(apiError.backendMessage.toLowerCase())
        )
            return apiError.frontendMessage || apiError.backendMessage
    }

    return apiErrors.genericMessage
}
