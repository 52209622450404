export interface Address {
    address_id?: string
    name?: string
    address1: string
    address2?: string
    city: string
    zip: string
    state: StateAbbreviation
    lat: string
    lon: string
    delivery_instructions?: string
    building_name?: string
    work_at?: number | null // When this address was last selected as work address
    home_at?: number | null // When this address was last selected as home address
}

export type StateAbbreviation =
    | 'AL'
    | 'AK'
    | 'AS'
    | 'AZ'
    | 'AR'
    | 'CA'
    | 'CO'
    | 'CT'
    | 'DE'
    | 'DC'
    | 'FM'
    | 'FL'
    | 'GA'
    | 'GU'
    | 'HI'
    | 'ID'
    | 'IL'
    | 'IN'
    | 'IA'
    | 'KS'
    | 'KY'
    | 'LA'
    | 'ME'
    | 'MH'
    | 'MD'
    | 'MA'
    | 'MI'
    | 'MN'
    | 'MS'
    | 'MO'
    | 'MT'
    | 'NE'
    | 'NV'
    | 'NH'
    | 'NJ'
    | 'NM'
    | 'NY'
    | 'NC'
    | 'ND'
    | 'MP'
    | 'OH'
    | 'OK'
    | 'OR'
    | 'PW'
    | 'PA'
    | 'PR'
    | 'RI'
    | 'SC'
    | 'SD'
    | 'TN'
    | 'TX'
    | 'UT'
    | 'VT'
    | 'VI'
    | 'VA'
    | 'WA'
    | 'WV'
    | 'WI'
    | 'WY'

export enum DeliveryType {
    pickup = 'pickup',
    delivery = 'delivery',
}
