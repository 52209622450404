import { Linking, Platform } from 'react-native'

import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'

import { showAlert } from '@/utils/showAlert'

import tw from '@/tailwind/tailwind'

import { axios, axiosPost } from '../lib/httpClient'
import storage from '../utils/storage'

// NOTIFICATION HANDLER, PERMISSIONS, TOKENS

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
})

// Android notification settings
if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: tw.color('bg-pc-warning'),
    })
}

export const clearPermissionCheck = async () => {
    storage.clearNotificationPermissionChecked()
}

export const clearToken = async () => {
    storage.clearNotificationToken()
}

// Add a token for current user via api
export const setNotificationToken = async (token: string) => {
    const response = await axios.post('/notification/token', {
        token: token,
        type: 'expo',
    })
    return response
}
// Add a token for current user via api
export const removeNotificationToken = async (token: string) => {
    const response = await axios.delete(`/notification/token?token=${token}/`)
    return response
}

export const checkPermissions = async (
    overwritePermissions: boolean = false
) => {
    // Check current os notification permissions of app and return true if already granted
    const { status: existingStatus, canAskAgain } =
        await Notifications.getPermissionsAsync()
    if (existingStatus === 'granted') return true

    // If permissions aren't already granted and have already been requested with no overwrite
    const permissionsChecked =
        !!(await storage.getNotificationPermissionChecked())
    if (!overwritePermissions && permissionsChecked) return false

    // If can't request again, open the settings!
    if (!canAskAgain) {
        showAlert({
            title: 'Launch your settings',
            description: 'Open your settings to allow notification permissions',
            buttonTexts: ['Yes', 'No'],
            buttonHandlers: {
                Yes: async () => {
                    Linking.openSettings()
                },
                No: async () => {},
            },
        })

        return false
    }

    // Otherwise, request
    const { status } = await Notifications.requestPermissionsAsync({
        android: {},
        ios: {
            allowAlert: true,
            allowBadge: true,
            allowAnnouncements: true,
            // allowProvisional: true,
            allowDisplayInCarPlay: true,
            allowCriticalAlerts: true,
            allowSound: true,
            provideAppNotificationSettings: true,
        },
    })

    await storage.setNotificationPermissionChecked()

    if (status === 'granted') return true

    return false
}

export const getAndSetToken = async (overwritePermissions: boolean = false) => {
    // If using a simulator, return. Expo push notifications are not supported by simulators
    if (!Device.isDevice || Platform.OS === 'web') {
        console.error(
            'Must use ios or android physical device for Push Notifications'
        )
        return null
    }

    const storage_token = await storage.getNotificationToken()

    // Check permissions
    const permissions = await checkPermissions(overwritePermissions)
    if (!permissions) {
        if (storage_token) {
            removeNotificationToken(storage_token)
            storage.clearNotificationToken()
        }

        console.error('Push notifications not permitted for this app')
        return null
    }
    if (storage_token) return storage_token

    // If not token in storage retrieve new one from expo
    const new_token = (
        await Notifications.getExpoPushTokenAsync({
            experienceId: '@popchew/popchew-app',
        })
    ).data
    if (new_token) {
        const response = await setNotificationToken(new_token)
        // If successfully sent to backend, save to storage
        if (response.status === 200) {
            await storage.setNotificationToken(new_token)
        } else {
            console.error('Failed to set push notification token')
        }
    }
    return new_token
}

// NOTIFICATION PREFERENCES
export interface NotificationPreferences {
    [categoryName: string]: {
        title: string
        value: boolean
        notifications: {
            [notificationName: string]: {
                title: string
                subtitle: string
                value: boolean
            }
        }
    }
}

// Get preferences for current user from api
export const getNotificationPreferences =
    async (): Promise<NotificationPreferences> => {
        const preferences = await axios.get('/notification/preferences')
        return preferences.data as NotificationPreferences
    }

export type ChangeNotificationPrefsRequest = {
    [category: string]: {
        value?: boolean
        notifications?: {
            [name: string]: { value: boolean }
        }
    }
}
// Set preferences for current user via api
export const setNotificationPreferences = async (
    prefsToChange: ChangeNotificationPrefsRequest
) => {
    const response = await axios.patch('/notification/preferences', {
        notification_preferences: prefsToChange,
    })
    return response.data
}

// SENDING NOTIFICATIONS FROM FRONT END
interface ExpoPushNotification {
    token?: string
    title?: string
    subtitle?: string
    body?: string
    data?: JSON
}

export const sendCustomPushNotification2 = axiosPost<
    ExpoPushNotification,
    undefined
>('/notification')
