import {
    Text,
    TouchableOpacity,
    TouchableOpacityProps,
    View,
} from 'react-native'

import { TrackableClick, TrackableElementProps } from '@/components/Analytics'

import tw from '@/tailwind/tailwind'

import XSvg from '@/svgs/XSvg'

interface ModalXProps extends TrackableElementProps {
    onPress?: TouchableOpacityProps['onPress']
    title?: string
    color?: string
}

export const ModalX = (props: ModalXProps) => {
    const {
        category,
        name,
        onPress,
        testID,
        onesignal,
        properties,
        title,
        color = 'black',
    } = props

    return (
        <View style={tw`flex-row items-center`}>
            <TrackableClick
                testID={testID}
                onesignal={onesignal}
                properties={{ ...properties, isX: true, title }}
                name={name}
                category={category}
            >
                <TouchableOpacity
                    onPress={onPress}
                    hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
                >
                    <XSvg strokeWidth={3} color={color} />
                </TouchableOpacity>
            </TrackableClick>
            {title && (
                <Text
                    style={[tw`ml-2.5 font-ppp-b text-xl mt-1`, { color }]}
                    numberOfLines={1}
                >
                    {title}
                </Text>
            )}
        </View>
    )
}
