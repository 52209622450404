import { Platform, Text, View } from 'react-native'

import { BackButton } from '@/components/Titles/BackButton'

import tw from '@/tailwind/tailwind'

type ReviewOrderHeaderProps = {
    title: string
}

export const ReviewOrderHeader = (props: ReviewOrderHeaderProps) => {
    const { title } = props

    return (
        <View
            style={tw`relative justify-center items-center px-container mb-14 ${
                Platform.OS === 'web' ? 'pt-container' : ''
            }`}
        >
            <View style={tw`self-start`}>
                <BackButton fallback="HomeTabs" isX />
            </View>

            <Text style={tw`absolute font-ppa-b text-base my-auto`}>
                {title}
            </Text>
        </View>
    )
}
