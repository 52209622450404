import { useRef } from 'react'

import { ListRenderItemInfo, Text, View } from 'react-native'

import Animated from 'react-native-reanimated'

import tw from '@/tailwind/tailwind'

import { HandleContentChangeType, HandleScrollEventType } from '../..'
import { useComments } from '../../api/getComments'
import { SectionIsLoading } from '../../components/SectionIsLoading'
import { AnimatedFlatList } from '../../components/wrappers/AnimatedFlatList'
import { BrandPageSectionWrapper } from '../../components/wrappers/BrandPageSectionWrapper'
import { FullPageView } from '../../components/wrappers/FullPageView'
import { Comment } from '../../types'

const NoComments = ({ tabBarHeight }: { tabBarHeight: number }) => {
    return (
        <FullPageView tabBarHeight={tabBarHeight}>
            <View style={tw`flex-1 justify-center items-center`}>
                <Text style={tw`font-ppa-b`}>No comments yet.</Text>
            </View>
        </FullPageView>
    )
}

const CommentCard = ({ item }: { item: ListRenderItemInfo<Comment> }) => {
    const { comment, name } = item.item
    const angle = useRef<number>(Math.round((Math.random() - 0.5) * 6))
    return (
        <Animated.View
            style={[
                tw`w-full h-25 bg-white rounded overflow-hidden flex-1 p-2 m-[6px]`,
                {
                    transform: [
                        {
                            rotate: `${angle.current}deg`,
                        },
                    ],
                },
            ]}
        >
            <Text numberOfLines={3} style={tw`font-ppa-reg text-sm`}>
                {comment}
            </Text>
            <Text style={tw`font-ppa-reg text-xs absolute bottom-2 right-3`}>
                {name}
            </Text>
        </Animated.View>
    )
}

export const Comments = ({
    brandId,
    pageHeight,
    viewWidth,
    top,
    handleScroll,
    handleContentSizeChange,
    tabBarHeight,
}: {
    brandId: string
    pageHeight: number
    viewWidth: number
    top: number
    handleScroll: HandleScrollEventType
    handleContentSizeChange: HandleContentChangeType
    tabBarHeight: number
}) => {
    const { data: comments, isLoading: isCommentsLoading } = useComments(
        brandId,
        50 // default is 50 comments anyways
    )

    const isComments = comments && comments.length > 0

    return (
        <BrandPageSectionWrapper
            backgroundColor={tw.color('bg-pc-shade-20')}
            pageHeight={pageHeight}
            viewWidth={viewWidth}
        >
            {isCommentsLoading ? (
                <SectionIsLoading
                    tabBarHeight={tabBarHeight}
                    color={'black'}
                    sectionName="comments"
                />
            ) : !isComments ? (
                <NoComments tabBarHeight={tabBarHeight} />
            ) : (
                <AnimatedFlatList
                    flatlistProps={{
                        data: comments,
                        numColumns: 2,
                        keyExtractor: (_, idx) => idx.toString(),
                        renderItem: (itemComment) => (
                            <CommentCard item={itemComment} />
                        ),
                    }}
                    pageHeight={pageHeight}
                    viewWidth={viewWidth}
                    top={top}
                    handleScroll={handleScroll}
                    handleContentSizeChange={handleContentSizeChange}
                    additionalTopSpacing={5}
                    tabBarHeight={tabBarHeight}
                />
            )}
        </BrandPageSectionWrapper>
    )
}
