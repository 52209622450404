import { useQuery } from 'react-query'

import { Brand } from '@/types/Brand'

import { axios } from '@/lib/httpClient'

type AllBrands = {
    brands: Brand[]
}

export const allBrandsQueryFn = async () => {
    const response = await axios.get<AllBrands>('/brand/all', {
        params: {
            visible_only: true,
        },
    })
    return response.data?.brands
}

export const allBrandsQueryKey = ['brands']
export const useAllBrands = () => {
    return useQuery(allBrandsQueryKey, allBrandsQueryFn, {
        staleTime: 60 * 1000,
    })
}
