import { Cart, CartResponse } from '@/types/Cart'

import { axios, parseAxiosError } from '@/lib/httpClient'

import storage from '@/utils/storage'

import { emptyCart } from '../utils/emptyCart'

export const cartQueryFn = async () => {
    const cartId = await storage.getCartId()
    if (!cartId) return emptyCart

    try {
        const response = await axios.get<CartResponse>('/order/cart', {
            params: { cart_id: cartId },
        })
        return response.data as Cart
    } catch (error) {
        const { status } = parseAxiosError(error)
        if (status === 404) await storage.removeCartId()
        throw error
    }
}
