import { useMutation, useQueryClient } from 'react-query'

import { axios } from '@/lib/httpClient'

const buyItemWithPoints = async (itemId: string) => {
    const response = await axios.post('/prize/buy', { id: itemId })
    return response.data
}

export const useBuyItemWithPoints = () => {
    const queryClient = useQueryClient()
    return useMutation(
        async (itemId: string) => {
            return await buyItemWithPoints(itemId)
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(['points'])
            },
        }
    )
}
