import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const BouySvg = (props: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M20 12a8 8 0 0 1-8 8v2c5.523 0 10-4.477 10-10h-2Zm-8 8a8 8 0 0 1-8-8H2c0 5.523 4.477 10 10 10v-2Zm-8-8a8 8 0 0 1 8-8V2C6.477 2 2 6.477 2 12h2Zm8-8a8 8 0 0 1 8 8h2c0-5.523-4.477-10-10-10v2Zm3 8a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5h-2Zm-3 3a3 3 0 0 1-3-3H7a5 5 0 0 0 5 5v-2Zm-3-3a3 3 0 0 1 3-3V7a5 5 0 0 0-5 5h2Zm3-3a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2Zm5.657-4.071L14.12 8.464l1.415 1.415 3.535-3.536-1.414-1.414ZM14.12 15.536l3.536 3.535 1.414-1.414-3.536-3.536-1.414 1.415ZM9.88 8.464 6.343 4.93 4.93 6.343 8.464 9.88 9.88 8.464Zm-1.415 5.657L4.93 17.657l1.414 1.414 3.536-3.536-1.415-1.414Z"
            fill="#FE5C00"
        />
    </Svg>
)

export default BouySvg
