import { useMemo } from 'react'

import { Text, View } from 'react-native'

import { CartItem } from '@/types/Cart'

import { listOfModifiers } from '@/utils/listOfModifiers'
import { toPrice } from '@/utils/normalizers'
import { totalItemPrice } from '@/utils/totalItemPrice'

import tw from '@/tailwind/tailwind'

const OrderItem = (cartItem: CartItem) => {
    const { item, count } = cartItem
    const { name } = item

    const totalPrice = useMemo(
        () => totalItemPrice(item) * count,
        [item, count]
    )

    const modifiersString = useMemo(() => listOfModifiers(item), [item])

    return (
        <View style={tw`border-b border-grey-700 py-3`}>
            <View style={tw`px-container`}>
                <View style={tw`flex-row`}>
                    <Text style={tw`font-ppp-m text-sm text-white mr-5`}>
                        {count}
                    </Text>
                    <View style={tw`shrink mr-4`}>
                        <Text
                            style={tw`flex-shrink text-base font-ppp-b text-white`}
                        >
                            {name}
                        </Text>
                        {modifiersString ? (
                            <Text style={tw`text-sm font-ppp-m text-white`}>
                                {modifiersString}
                            </Text>
                        ) : null}
                    </View>
                    <Text style={tw`ml-auto text-sm font-ppp-m text-white`}>
                        {toPrice(totalPrice)}
                    </Text>
                </View>
            </View>
        </View>
    )
}

export const OrderItems = ({ cartItems }: { cartItems: CartItem[] }) => {
    return (
        <View style={tw`mt-8`}>
            <Text
                style={tw`font-ppp-b text-base text-white mb-3 px-container `}
            >
                Your Order
            </Text>
            {cartItems.map((cartItem) => (
                <OrderItem key={cartItem.cart_item_id} {...cartItem} />
            ))}
        </View>
    )
}
