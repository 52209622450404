import { useEffect, useState } from 'react'

import { DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { getInstanceAvailability } from '@/utils/instance/getInstanceAvailability'

export const useInstanceAvailability = (
    instance: Instance | undefined,
    deliveryType: DeliveryType | undefined | null
) => {
    const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined)
    const [opensSoon, setOpensSoon] = useState<boolean | undefined>(undefined)
    const [message, setMessage] = useState<string | undefined>('')

    useEffect(() => {
        if (deliveryType && instance?.hours && instance?.timezone) {
            const {
                isOpen: _isOpen,
                message: _message,
                opensSoon: _opensSoon,
            } = getInstanceAvailability(
                deliveryType,
                instance.hours,
                instance.timezone,
                instance.hours_overrides
            )
            setIsOpen(_isOpen)
            setMessage(_message)
            setOpensSoon(_opensSoon)
        } else {
            setIsOpen(undefined)
            setMessage('')
            setOpensSoon(undefined)
        }
    }, [instance, deliveryType])

    return {
        isOpen,
        opensSoon,
        message,
    }
}
