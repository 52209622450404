import { MenuResponse } from '@/types/Menu'

import { axios } from '@/lib/httpClient'

export const useDefaultMenuQueryFn = async (
    brandId: string | undefined | null
) => {
    if (!brandId) return Promise.reject(new Error('Invalid brand id'))
    const response = await axios.get<MenuResponse>('/brand/default_menu', {
        params: { brand_id: brandId },
    })
    return response.data
}
