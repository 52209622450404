import { useState } from 'react'

import { Platform } from 'react-native'

import * as Device from 'expo-device'

export const useIsMobile = () => {
    const [isMobile, _setIsMobile] = useState(
        !(
            Platform.OS === 'web' &&
            Device.osName !== 'iOS' &&
            Device.osName !== 'Android'
        )
    )

    return isMobile
}
