import { useQuery } from 'react-query'

import { axios } from '@/lib/httpClient'

import { useIsAuth } from '../utils/isAuthenticated'

type pointsResponse = {
    points: number
    message: string
}
const getPoints = async (isAnon: boolean) => {
    if (isAnon) return { points: 0 }

    try {
        const response = await axios.get<pointsResponse>('/user/points')
        return response.data
    } catch (e) {
        console.error(e)
    }
}

export const useUserPoints = () => {
    const { isAnon } = useIsAuth()

    return useQuery(['points', isAnon], () => getPoints(Boolean(isAnon)), {
        staleTime: 1000 * 60, // one min
    })
}
