import { Address } from '@/types/Address'

import { axios } from '@/lib/httpClient'

export const getAddress = async ({ addressId }: { addressId: string }) => {
    const response = await axios.get<Address>('/order/address', {
        params: { id: addressId },
    })
    return response.data
}
