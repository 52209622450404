import { useQuery } from 'react-query'

import { useValidateCart } from '@/features/Cart/hooks/useValidateCart'

import { Brand } from '@/types/Brand'

import { useStore } from '@/lib/store'

import { useDefaultMenuQueryFn } from './fetchDefaultMenu'
import { fetchMenu } from './fetchMenu'

export const useMenuQueryKey = (instanceId: string | undefined) => [
    'menu',
    instanceId,
]
export const useMenuQueryFn = (instanceId: string | undefined) =>
    fetchMenu(instanceId)

export const useMenu = (instanceId: string | undefined) => {
    const [validateCart] = useValidateCart()

    const selectedLocation = useStore(
        (state) => state.location.selectedLocation
    )
    return useQuery(
        useMenuQueryKey(instanceId),
        () => {
            return useMenuQueryFn(instanceId)
        },
        {
            enabled: Boolean(selectedLocation && instanceId),
            onSuccess: (menu) => {
                if (!(instanceId && selectedLocation && menu)) return
                validateCart(instanceId, menu)
            },
        }
    )
}

export const useDefaultMenuQueryKey = (brandId: string) => [
    'defaultMenu',
    brandId,
]

export const useDefaultMenu = (brandId: Brand['id']) => {
    return useQuery(
        useDefaultMenuQueryKey(brandId),
        () => useDefaultMenuQueryFn(brandId),
        {
            staleTime: 60 * 1000, // 60 seconds
            enabled: !!brandId,
        }
    )
}
