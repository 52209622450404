import { useRef } from 'react'

import clone from 'clone'
import { useMutation, useQueryClient } from 'react-query'

import { GetProfileResponse, User } from '@/types/User'

import { axios } from '@/lib/httpClient'

import { ApiErrors, getApiErrorMessage } from '@/utils/handleApiError'
import { showMessage } from '@/utils/showMessage'

// Had to do this one uniquely (couldn't use useOptimisticMutation) because api doesn't take same type that it returns
export const useUpdateUser = () => {
    const currentChanges = useRef(0)
    const queryClient = useQueryClient()

    return useMutation(
        async (profileUpdates: Partial<User>) => {
            const response = await axios.patch<
                Omit<User, 'access_token' | 'refresh_token'>
            >('/user', profileUpdates)
            return response.data
        },
        {
            onMutate: async (profileUpdates: Partial<User>) => {
                currentChanges.current++
                await queryClient.cancelQueries('profile')
                const previousProfile =
                    queryClient.getQueryData<GetProfileResponse>('profile')
                const previousProfileClone = clone(previousProfile)

                queryClient.setQueryData('profile', {
                    ...previousProfileClone,
                    ...profileUpdates,
                })

                return { previousProfile }
            },
            onError: (error, _variables, context) => {
                const errorMessage = getApiErrorMessage(
                    error,
                    patchUserApiErrors
                )
                showMessage({
                    type: 'warning',
                    message: errorMessage,
                })

                if (!context) return
                queryClient.setQueryData('profile', context.previousProfile)
            },
            onSuccess: () => {
                if (currentChanges.current === 1) {
                    queryClient.invalidateQueries('profile')
                }
            },
            onSettled: () => {
                currentChanges.current--
            },
        }
    )
}

export const patchUserApiErrors: ApiErrors = {
    // Handled with generic error message (Could handle specifically but should never happen)
    // 404 User not found
    // 400 Missing user id
    // 400 Invalid or missing fields
    genericMessage: 'Something went wrong while trying to update user',
    specificErrors: [
        {
            statusCode: 400,
            backendMessage: 'Referral code already exists',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Phone already exists',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Email already exists',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid email',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid phone',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid zip',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid name',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid referral code',
            frontendMessage: null,
        },
    ],
}

// POST /user endpoint is not currently used
const postUserErrors: ApiErrors = {
    // Handled with generic error message:
    // 400 Invalid or missing fields
    genericMessage: 'Failed to create user',
    specificErrors: [
        {
            statusCode: 400,
            backendMessage: 'Phone already exists',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Email already exists',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid email',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid phone',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid zip',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid name',
            frontendMessage: null,
        },
    ],
}
