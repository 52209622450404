import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
    ActivityIndicator,
    Image,
    Platform,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'

import { useNavigateBack } from '@/navigation/hooks/useNavigateBack'
import { LootBoxProps } from '@/navigation/types/ScreenProps'
import { AxiosError } from 'axios'
import {
    AVPlaybackStatus,
    AVPlaybackStatusSuccess,
    ResizeMode,
    Video,
} from 'expo-av'
import * as Haptics from 'expo-haptics'
import Animated, {
    Easing,
    runOnJS,
    useAnimatedStyle,
    useDerivedValue,
    useSharedValue,
    withRepeat,
    withSequence,
    withTiming,
} from 'react-native-reanimated'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Prize } from '@/types/Prize'

import { TrackableClick } from '@/components/Analytics'
import { Button } from '@/components/Button'

import { useEligibleRewardsQueryKey } from '@/hooks/query/useEligibleRewards'

import { postMixpanelEvent } from '@/lib/mixpanel'
import { queryClient, useAxiosPostMutation } from '@/lib/queryClient'

import { LOOTBOX_CLAIM_FORM, WELCOME_LOOTBOX_ID } from '@/utils/constants'
import { detectAnOrA } from '@/utils/detectAnOrA'
import { openLink } from '@/utils/linking'
import { showMessage } from '@/utils/showMessage'

import tw from '@/tailwind/tailwind'

import { useLootboxInfo } from '../api/useLootboxInfo'
import LootboxTerms from '../components/LootboxTerms'
import { LootboxClaimRequest, LootboxClaimResponse } from '../types'

// ANIMATION PARAMS
const MIN_PRIZES = 3 // this needs to be at least 3
const MAX_PRIZES = 8

const INITIAL_PRIZE_IMAGE_SIZE = 5
const INITIAL_PRIZE_SPREAD = 0.1
const FADE_IN_TRIGGER_TIME_BEFORE_VIDEO_END_MS = 5000
const FADE_IN_DURATION = 250

const RAISE_ANIMATION_DURATION = 800
const RAISE_HEIGHT = 170
const SPIN_HEIGHT = 100
const SPIN_WIDTH = 400
const FULL_PRIZE_IMAGE_SIZE = 30
const MIN_NUM_SPINS = 3
const MAX_NUM_SPINS = 5
const FULL_SPIN_SPEED_RPS = 0.8

const OVERSHOOT_SPIN_SPEED_RPS = 0.2
const TO_WINNER_BOUNCE_DURATION_MS = 1000

const EXTRA_SCALE_WHEN_WON = 0.4
const GROW_ANIMATION_DURATION = 1000

const runHaptics = (heavy: boolean = false) => {
    if (Platform.OS !== 'web') {
        Haptics.impactAsync(
            heavy
                ? Haptics.ImpactFeedbackStyle.Heavy
                : Haptics.ImpactFeedbackStyle.Light
        )
    }
}

export const LootboxAnimation = ({ navigation, route }: LootBoxProps) => {
    const { orderId, rewardId } = route.params || {}

    // LOOTBOX INFO API INTEGRATION AND ERROR HANDLING
    const {
        data: lootbox,
        isLoading: isLootboxLoading,
        isError: isLootboxError,
        error: lootboxInfoError,
    } = useLootboxInfo(route.params)

    const isWelcomeLootbox = lootbox?.reward?.id === WELCOME_LOOTBOX_ID

    const goToAuth = useCallback(() => {
        navigation.navigate('Login', {
            screen: 'PhoneNumberCapture',
            params: {
                returnToOnFinish: 'LootboxAnimation',
                returnToOnFinishParams: route.params,
            },
        })
    }, [])

    const getLootboxError = useCallback((message?: string, auth?: boolean) => {
        if (message) console.error(message)
        console.error(lootboxInfoError)
        showMessage({
            message: 'There was a problem with your lootbox',
            type: 'warning',
        })
        if (auth) goToAuth()
        else navigateBack('HomeTabs')
    }, [])
    const handleVideoError = (error: string) => {
        console.error(error)
        // getLootboxError('Lootbox video failed to load')
    }

    const [lootboxId, setLootboxId] = useState<string>('')
    const [videoUrl, setVideoUrl] = useState<string>('')
    const [fontColor, setFontColor] = useState<string>('#000000')
    const [posterUrl, setPosterUrl] = useState<string>('')
    useEffect(() => {
        if (isLootboxLoading) return
        if (isLootboxError) {
            const error = lootboxInfoError as AxiosError
            if (!error.isAxiosError) return console.error(error)
            if (error.response?.status === 400)
                return getLootboxError('Order not dispatched')
            if (error.response?.status === 404)
                return getLootboxError('Lootbox/order not found')
            if (error.response?.status === 405)
                return getLootboxError('Order not associated with user', true)
            return getLootboxError('Unknown lootbox info error', false)
        }

        if (!lootbox) return getLootboxError('Invalid Lootbox', true)

        // Get lootbox id
        const reward = lootbox.reward
        if (!reward?.id) return getLootboxError('Invalid reward struture')

        // Get lootbox animation video url
        const custom_data = reward.custom_data
        if (!custom_data) return getLootboxError('No custom data on reward')

        const {
            animation_video_url: animationVideoUrl,
            animation_poster_url: animationPosterUrl = 'blah',
            font_color: fontColor = '#000000',
        } = custom_data
        if (!animationVideoUrl || !animationPosterUrl)
            return getLootboxError('Invalid reward custom data')

        // Check prizes and min and max # of prizes
        const lootboxPrizes = lootbox.prizes
        if (!lootboxPrizes) return getLootboxError('Invalid lootbox prizes')

        if (lootboxPrizes.length < MIN_PRIZES)
            return getLootboxError(
                `Number of lootbox prizes must be at least ${MIN_PRIZES}`
            )

        // Prefetch images
        const asyncPrizeImagePrefetch = async () => {
            await Promise.all(
                lootboxPrizes.map((prize) => Image.prefetch(prize.image_url))
            )
        }
        asyncPrizeImagePrefetch()

        setLootboxId(reward.id)
        setVideoUrl(animationVideoUrl)
        setFontColor(fontColor)
        setPosterUrl(animationPosterUrl)
        prizes.current = lootboxPrizes
        winningIndex.current = Math.floor(lootboxPrizes.length * 0.6)
        setAnimationState(0)
    }, [isLootboxLoading])

    const navigateBack = useNavigateBack()

    const video = useRef<Video>(null)
    const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false)
    const prizes = useRef<Prize[]>([])
    const winningPrize = useRef<Prize>()
    const winningIndex = useRef<number>(0)
    const claimResponse = useRef<LootboxClaimResponse>()

    // THIS ANIMATION HAS 4 STATES:
    // 0: Initial state, no animation
    // 1: Lootbox data has been fetched, video starts
    // 2: Fade in phase: a certain time from the end of the video, the prizes fade into the open box
    // 3: Raise phase: the prizes raise into a ring with a bounce effect
    // 4: Spin phase: the ring starts spinning, eventually settling on the winning prize
    // 5: Winning prize grows, other prizes fade off, user can navigate to redemption instructions
    const [animationState, setAnimationState] = useState<number>(0)
    const [isAnimating, setIsAnimating] = useState<boolean>(false)

    const fadeAnim = useSharedValue<number>(0)
    const raiseAnim = useSharedValue<number>(0)
    const spinAnim = useSharedValue<number>(0)
    const winGrowAnim = useSharedValue<number>(0)

    // THE ANIMATION FUNCTIONS
    // THIS IS WHERE THE ANIMATION VALUES ARE CONTROLLED

    // Initial animation state: attempt to claim lootbox and then play video
    const claimLootboxError = useCallback(
        (message?: string, error?: AxiosError | string, auth?: boolean) => {
            setIsAnimating(false)

            if (message) console.error(message)
            if (error) console.error(error)
            showMessage({
                message: 'There was a problem claiming your lootbox',
                type: 'warning',
            })
            if (auth) goToAuth()
        },
        []
    )

    const ctaButtonText = isWelcomeLootbox ? 'VIEW FOOD' : 'CLAIM'

    const [claimButtonText, setClaimButtonText] =
        useState<string>(ctaButtonText)
    const { mutateAsync: claimLootbox } = useAxiosPostMutation<
        LootboxClaimRequest,
        LootboxClaimResponse
    >(
        '/order/lootbox/claim',
        (error) => {
            if (!error.isAxiosError) throw error
            if (error.response?.status === 405)
                return claimLootboxError(
                    'Requested order is not associated with the current user',
                    error,
                    true
                )
            setClaimButtonText('TRY AGAIN')
            if (error.response?.status === 404)
                claimLootboxError('Order or lootbox not found', error)
            else if (error.response?.status === 400)
                claimLootboxError(error.response.data.message, error)
            else if (error.response?.status !== 200)
                claimLootboxError('Unknown lootbox claim error', error)
        },
        (data) => {
            queryClient.invalidateQueries(useEligibleRewardsQueryKey)
            const claim = data
            if (!claim || !claim.prize_details)
                return claimLootboxError('Invalid lootbox claim')
            if (claim.prize_details.length !== 1)
                return claimLootboxError('Can only show one winning prize')

            const winner = claim.prize_details[0]?.prize
            // winner.image_url = `https://avatars.dicebear.com/api/adventurer/${winner.id}.png`
            if (!winner || !winner.image_url || !winner.name || !winner.id)
                return claimLootboxError('Invalid winning prize')

            // Move winner to previously determined winning index
            prizes.current.sort((prize) => (prize.id == winner.id ? -1 : 1))
            prizes.current.splice(0, 1)

            // Truncate prizes to max supported by animation
            // make sure to sort important prizes to front first in future
            if (prizes.current.length > MAX_PRIZES)
                prizes.current.length = MAX_PRIZES - 1
            prizes.current.splice(winningIndex.current, 0, winner)

            // TODO - remove rarest prize and re-insert next to winner
            // Unless winner is the rarest

            postMixpanelEvent('Lootbox Claimed', 'Lootbox', {
                'lootbox id': claim.reward.id,
                'lootbox name': claim.reward.name,
            })

            winningPrize.current = winner
            claimResponse.current = claim
            setAnimationState(1)
            setClaimButtonText(ctaButtonText)
        }
    )()

    const startAnimation = async () => {
        setIsAnimating(true)

        await claimLootbox({
            ...(orderId && { order_id: orderId }),
            reward_id: lootboxId,
        })
    }

    // Trigger the next stage a certain time from the end of the video
    // If we wait till the box is open it feels slow
    // This only triggers if we haven't made it to animation state 2 yet
    const handleVideoStatus = (status: AVPlaybackStatus) => {
        if (animationState < 2) {
            const success = status as AVPlaybackStatusSuccess
            if (
                success.didJustFinish || // This is a backup to make sure the next state is triggered if the video ends and the trigger time is somehow missed
                (success.durationMillis &&
                    success.durationMillis <
                        success.positionMillis +
                            FADE_IN_TRIGGER_TIME_BEFORE_VIDEO_END_MS)
            )
                setAnimationState(2)
        }
    }

    // Run the fade prize images in animation and then update the animation state
    const runFadeAnimation = async () => {
        fadeAnim.value = withTiming(
            1,
            {
                duration: FADE_IN_DURATION,
                easing: Easing.linear,
            },
            () => {
                runOnJS(setAnimationState)(3)
            }
        )
    }

    // Run the raise prize images animation and then update the animation state
    const runRaiseAnimation = async () => {
        raiseAnim.value = withTiming(
            1,
            {
                duration: RAISE_ANIMATION_DURATION,
                easing: Easing.bounce,
            },
            () => {
                runOnJS(setAnimationState)(4)
            }
        )
    }

    // Run the spin prize images animation and then update the animation state
    const runSpinAnimation = async () => {
        // We'll do a random number of full spins, between 3 and 6
        const numberOfFullSpins =
            MIN_NUM_SPINS +
            Math.round(Math.random() * (MAX_NUM_SPINS - MIN_NUM_SPINS))

        // The winning prize's animation position is the ratio of its index to the number prizes
        const winningPosition = winningIndex.current / prizes.current.length

        // We will overshoot a bit
        const overshootPosition =
            (winningIndex.current + 0.25) / prizes.current.length

        spinAnim.value = withSequence(
            // First ease in with one slow rotation
            // 1.4 is roughly the slope of the end of a sin ease in vs linear, to avoid a jump in speed
            withTiming(1, {
                duration: (1000 / FULL_SPIN_SPEED_RPS) * 1.4,
                easing: Easing.in(Easing.sin),
            }),
            withTiming(0, {
                duration: 0,
            }),
            // Then, do the remaining full rotations at full speed
            withRepeat(
                withTiming(1, {
                    duration: 1000 / FULL_SPIN_SPEED_RPS,
                    easing: Easing.linear,
                }),
                numberOfFullSpins - 1
            ),
            withTiming(0, {
                duration: 0,
            }),
            // Then, do a partial spin to the point of overshoot, slowing down as we approach
            withTiming(overshootPosition, {
                duration: (1000 / OVERSHOOT_SPIN_SPEED_RPS) * overshootPosition,
                easing: Easing.out(Easing.ease),
            }),
            // Finally, bounce back to the winner
            withTiming(
                winningPosition,
                {
                    duration: TO_WINNER_BOUNCE_DURATION_MS,
                    easing: Easing.bounce,
                },
                () => {
                    runOnJS(setAnimationState)(5)
                }
            )
        )
    }

    // Last step: grow the winning prize image and fade the others off
    const runGrowAnimation = async () => {
        winGrowAnim.value = withTiming(
            1,
            {
                duration: GROW_ANIMATION_DURATION,
                easing: Easing.out(Easing.ease),
            },
            () => {
                runOnJS(setAnimationState)(6)
            }
        )
    }

    // THE ACTUAL ANIMATION STYLES
    // Maintain an array of derived style values for each prize
    const prizeStyleVals = useDerivedValue(() => {
        if (spinAnim.value > 0 && spinAnim.value < 0.2) runOnJS(runHaptics)()

        const out = prizes.current.map((_, index) => {
            const isWinner = index === winningIndex.current
            const spinWithOffset =
                (1 + spinAnim.value - index / prizes.current.length) % 1

            // 0 -> pi/2
            // 0.25 -> pi/4
            // 0.4999... -> 0
            // 0.50...01 -> pi
            // 0.75 -> 3pi/4
            // 1 -> pi/2

            const compressFactor = prizes.current.length / MAX_PRIZES
            const radialPosition =
                Math.PI *
                (1 / 2 +
                    compressFactor *
                        (spinWithOffset < 0.5
                            ? -spinWithOffset
                            : 1 - spinWithOffset))

            const cos = Math.cos(radialPosition)
            const sin = Math.sin(radialPosition)

            const imageSizeRatio =
                INITIAL_PRIZE_IMAGE_SIZE / FULL_PRIZE_IMAGE_SIZE

            const spinWidth =
                SPIN_WIDTH *
                (INITIAL_PRIZE_SPREAD +
                    (1 - INITIAL_PRIZE_SPREAD) * raiseAnim.value)

            const spinHeight =
                SPIN_HEIGHT *
                (INITIAL_PRIZE_SPREAD +
                    (1 - INITIAL_PRIZE_SPREAD) * raiseAnim.value)

            return {
                opacity: fadeAnim.value - (isWinner ? 0 : winGrowAnim.value),
                transform: [
                    {
                        translateX: spinWidth * cos,
                    },
                    {
                        translateY: -(
                            RAISE_HEIGHT * raiseAnim.value +
                            spinHeight * sin
                        ),
                    },
                    {
                        scale:
                            raiseAnim.value * (1 - imageSizeRatio) +
                            imageSizeRatio +
                            (isWinner
                                ? EXTRA_SCALE_WHEN_WON * winGrowAnim.value
                                : 0),
                    },
                ],
            }
        })
        return out
    })

    useDerivedValue(() => {
        if (spinAnim.value > 0 && spinAnim.value < 0.2) runOnJS(runHaptics)()
    }, [spinAnim])

    // // It seems that each element requires its own useAnimatedStyle hook. This is the solution to that.
    const prizeStyle0 = useAnimatedStyle(() => {
        return prizeStyleVals.value[0] || {}
    }, [fadeAnim, raiseAnim, spinAnim, winGrowAnim])
    const prizeStyle1 = useAnimatedStyle(() => {
        return prizeStyleVals.value[1] || {}
    }, [fadeAnim, raiseAnim, spinAnim, winGrowAnim])
    const prizeStyle2 = useAnimatedStyle(() => {
        return prizeStyleVals.value[2] || {}
    }, [fadeAnim, raiseAnim, spinAnim, winGrowAnim])
    const prizeStyle3 = useAnimatedStyle(() => {
        return prizeStyleVals.value[3] || {}
    }, [fadeAnim, raiseAnim, spinAnim, winGrowAnim])
    const prizeStyle4 = useAnimatedStyle(() => {
        return prizeStyleVals.value[4] || {}
    }, [fadeAnim, raiseAnim, spinAnim, winGrowAnim])
    const prizeStyle5 = useAnimatedStyle(() => {
        return prizeStyleVals.value[5] || {}
    }, [fadeAnim, raiseAnim, spinAnim, winGrowAnim])
    const prizeStyle6 = useAnimatedStyle(() => {
        return prizeStyleVals.value[6] || {}
    }, [fadeAnim, raiseAnim, spinAnim, winGrowAnim])
    const prizeStyle7 = useAnimatedStyle(() => {
        return prizeStyleVals.value[7] || {}
    }, [fadeAnim, raiseAnim, spinAnim, winGrowAnim])

    const prizeStyles = [
        prizeStyle0,
        prizeStyle1,
        prizeStyle2,
        prizeStyle3,
        prizeStyle4,
        prizeStyle5,
        prizeStyle6,
        prizeStyle7,
    ]

    // COMMAND CENTER:
    // Handle animation state changes/triggers
    // This is where the animation functions are officially triggered based on animation state
    // and animation values are also properly reset based on the animation state
    useEffect(() => {
        if (animationState === 0 || animationState === 6) setIsAnimating(false)
        else setIsAnimating(true)

        if (animationState === 0) {
            const startVideoOver = async () => {
                await video.current?.playFromPositionAsync(0)
                await video.current?.pauseAsync()
            }
            if (video) startVideoOver()
        }
        if (animationState === 1)
            // video.current?.playFromPositionAsync(0)
            video.current?.playAsync()

        if (animationState < 2) fadeAnim.value = 0
        if (animationState === 2) runFadeAnimation()
        if (animationState > 2) fadeAnim.value = 1

        if (animationState < 3) raiseAnim.value = 0
        if (animationState === 3) runRaiseAnimation()
        if (animationState > 3) raiseAnim.value = 1

        if (animationState < 4) spinAnim.value = 0
        if (animationState === 4) runSpinAnimation()
        if (animationState > 4)
            spinAnim.value = winningIndex.current / prizes.current.length

        if (animationState < 5) winGrowAnim.value = 0
        if (animationState === 5) runGrowAnimation()
        if (animationState > 5) winGrowAnim.value = 1

        runHaptics(true)
    }, [animationState])

    // Handle missing/changed rewardId
    useEffect(() => {
        setAnimationState(0)
        if (!rewardId || (lootbox && !lootbox.reward)) {
            showMessage({ message: 'No reward found', type: 'warning' })
            navigation.navigate('HomeTabs')
        }
    }, [lootbox, navigation, rewardId])

    const isLoading = isLootboxLoading // Boolean(isLootboxLoading || !isVideoLoaded)
    const prizeAorAn = useMemo(() => {
        const aOrAn = detectAnOrA(winningPrize.current?.name ?? '')
        return aOrAn ? aOrAn + ' ' : ''
    }, [winningPrize.current])

    if (!rewardId || !lootbox?.reward) return null

    return (
        <View style={tw`h-full w-full bg-transparent`}>
            {isLoading && (
                <View
                    style={tw`h-full w-full justify-center items-center z-10`}
                >
                    <ActivityIndicator />
                </View>
            )}
            {/* This is the background animation video. It should have a height-width ratio of at least 1.9 to fill all normal phones properly */}
            {/* I am using a poster image to reduce loading time but could also use a loading screen */}
            <Video
                videoStyle={tw`w-full h-full`}
                ref={video}
                style={tw`absolute top-0 left-0 w-full h-full`}
                resizeMode={ResizeMode.COVER}
                progressUpdateIntervalMillis={100}
                source={{
                    uri: videoUrl,
                }}
                onLoad={() => {
                    setIsVideoLoaded(true)
                }}
                onError={handleVideoError}
                usePoster
                posterSource={{
                    uri: posterUrl,
                }}
                posterStyle={[
                    { resizeMode: 'cover' },
                    tw`absolute top-0 left-0 w-full h-full`,
                ]}
                onPlaybackStatusUpdate={handleVideoStatus}
            />
            {/* Supporting text and buttons */}

            <SafeAreaView style={tw`flex-1 py-4 px-4 items-center`}>
                {!isAnimating && (
                    <TouchableOpacity
                        style={tw`self-end z-10`}
                        hitSlop={{
                            top: 20,
                            left: 20,
                            bottom: 20,
                            right: 20,
                        }}
                    >
                        <Button
                            variant="primary"
                            onPress={() => {
                                navigateBack('HomeTabs')
                            }}
                            size="sm"
                            text="Close"
                            trackableName="Tapped Close in Lootbox Screen"
                            trackableCategory="Order"
                        />
                    </TouchableOpacity>
                )}
                {!isAnimating && animationState === 0 && (
                    <>
                        <Text
                            style={tw`text-2xl font-ppa-b pt-18 text-[${fontColor}]`}
                        >
                            You got a loot box!
                        </Text>
                        <Text
                            style={tw`pt-2 font-ppa-b text-sm text-[${fontColor}]`}
                        >{`${'Tap'} to open the lid`}</Text>
                        <Image
                            source={require('@/assets/images/lootbox/open-lootbox-arrow.png')}
                            style={tw`h-20 mt-4`}
                            resizeMode="contain"
                        />
                        <TrackableClick
                            name={'Tapped lootbox to start animation'}
                            category={'Lootbox'}
                            properties={
                                lootbox
                                    ? {
                                          'lootbox id': lootbox.reward.id,
                                          'lootbox name': lootbox.reward.name,
                                      }
                                    : {}
                            }
                        >
                            <TouchableOpacity
                                style={tw`flex-1 w-full h-full mb-20`}
                                onPress={() => {
                                    startAnimation()
                                }}
                            />
                        </TrackableClick>
                    </>
                )}

                {animationState > 1 &&
                    prizes.current.map((prize, index) => (
                        <Animated.Image
                            key={prize.id}
                            source={{ uri: prize.image_url }}
                            style={[
                                tw`h-${FULL_PRIZE_IMAGE_SIZE} w-${FULL_PRIZE_IMAGE_SIZE} absolute top-[50%] m-0 p-0`,
                                prizeStyles[index],
                            ]}
                        />
                    ))}
                {animationState >= 5 && (
                    <View style={tw`h-50 w-full absolute bottom-0`}>
                        <Text
                            style={tw`text-2xl font-ppa-b w-full text-center text-[${fontColor}] px-10`}
                        >
                            {`You won ${prizeAorAn}${winningPrize.current?.name}!`}
                        </Text>
                        <View style={tw`px-10 mt-5`}>
                            <Button
                                variant="primaryStrong"
                                onPress={() => {
                                    if (!isWelcomeLootbox)
                                        openLink(LOOTBOX_CLAIM_FORM)
                                    if (isWelcomeLootbox)
                                        showMessage({
                                            type: 'success',
                                            message: '$10 off applied',
                                        })
                                    navigateBack('HomeTabs')
                                }}
                                text={claimButtonText}
                                trackableName="Tapped Claim in Lootbox Screen"
                                trackableCategory="Order"
                                full
                                size="default"
                                trackableProperties={{
                                    'lootbox id': rewardId,
                                    'lootbox name': winningPrize.current?.name,
                                }}
                            />
                        </View>
                        <View style={tw`px-10`}>
                            {!isWelcomeLootbox && <LootboxTerms />}
                        </View>
                    </View>
                )}
            </SafeAreaView>
        </View>
    )
}
