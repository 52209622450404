import { Address, DeliveryType } from '@/types/Address'

import { StoreSlice, StoreState } from '@/lib/store'

import storage from '@/utils/storage'

import { LocationState } from './types'

export const createLocationSlice: StoreSlice<LocationState> = (set) => ({
    location: {
        selectedLocation: null,
        tempLocation: null,
        deliveryType: DeliveryType.delivery,
    },
    setSelectedLocation: async (address: Address | null) => {
        if (address?.address_id) await storage.setAddressId(address.address_id)
        else await storage.removeAddressId()
        set((state: StoreState) => {
            state.location.selectedLocation = address
            return state
        })
    },
    removeSelectedLocation: () => {
        set((state: StoreState) => {
            state.location.selectedLocation = null
            return state
        })
    },
    setTempLocation: (address: Address | null) => {
        set((state: StoreState) => {
            state.location.tempLocation = address
            return state
        })
    },
    setDeliveryType: (deliveryType: DeliveryType) => {
        set((state: StoreState) => {
            state.location.deliveryType = deliveryType
            return state
        })
    },
})
