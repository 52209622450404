import { DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { getInstanceAvailability } from './getInstanceAvailability'

/**
 * Sort brands first based on open or close, and settle ties with distance from user
 */
export const sortInstances = (
    instances: Instance[],
    deliveryType: DeliveryType
) => {
    instances.sort((a, b) => {
        const { isOpen: aIsOpen } = getInstanceAvailability(
            deliveryType,
            a.hours,
            a.timezone,
            a.hours_overrides
        )
        const { isOpen: bIsOpen } = getInstanceAvailability(
            deliveryType,
            b.hours,
            b.timezone,
            a.hours_overrides
        )

        if (aIsOpen === bIsOpen) {
            return a.distance_from_address < b.distance_from_address ? -1 : 1
        }

        return aIsOpen ? -1 : 1
    })
}
