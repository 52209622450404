import * as React from 'react'

import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg'

const CardSvg = (props: SvgProps) => (
    <Svg
        width={16}
        height={17}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <G
            clipPath="url(#a)"
            stroke="#fff"
            strokeWidth={1.75}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <Path d="M14 3.167H2c-.737 0-1.333.597-1.333 1.333v8c0 .736.596 1.333 1.333 1.333h12c.736 0 1.333-.597 1.333-1.333v-8c0-.736-.597-1.333-1.333-1.333ZM.667 7.167h14.666" />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path
                    fill="#fff"
                    transform="translate(0 .5)"
                    d="M0 0h16v16H0z"
                />
            </ClipPath>
        </Defs>
    </Svg>
)
export default CardSvg
