import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
    ActivityIndicator,
    SafeAreaView,
    ScrollView,
    Text,
    View,
} from 'react-native'

import { RootStackScreenProps } from '@/navigation/types/ScreenProps'
import { BottomSheetModal as BottomSheetModalType } from '@gorhom/bottom-sheet'
import { useFocusEffect } from '@react-navigation/native'

import { useIsAuth } from '@/features/Auth/utils/isAuthenticated'
import { useCart } from '@/features/Cart/api/useCart'
import { useInstance } from '@/features/Cart/api/useInstance'
import { isAboveMinimumDeliveryValue } from '@/features/Cart/utils/isAboveMinimumDeliveryValue'

import { DeliveryType } from '@/types/Address'
import { CartResponse } from '@/types/Cart'
import { Order, OrderDetailResponse } from '@/types/Order'

import { postTrackingEvent } from '@/components/Analytics'
import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { LoadingView } from '@/components/Loading'

import { useEligibleRewards } from '@/hooks/query/useEligibleRewards'
import { useFeatureFlags } from '@/hooks/query/useFeatureFlags'
import { useProfile } from '@/hooks/query/useProfile'

import { useStore } from '@/lib/store'

import {
    DORITOS_BRAND_ID,
    WELCOME_COUPON_CODE,
    WELCOME_FEATURE_FLAG,
    WELCOME_LOOTBOX_ID,
} from '@/utils/constants'
import { useInstanceAvailability } from '@/utils/instance/useInstanceAvailability'
import { showMessage } from '@/utils/showMessage'
import { sortOrders } from '@/utils/sortOrders'

import tw from '@/tailwind/tailwind'

import { useApplyCouponToOrder } from '../../api/useApplyCouponToOrder'
import { useCreateOrder } from '../../api/useCreateOrder'
import { usePlaceOrder } from '../../api/usePlaceOrder'
import { useSignupForEmail } from '../../api/useSignupForEmail'
import { ApplyCouponTopbar } from '../../components/ApplyCouponTopbar'
import { CheckoutLocationInfo } from '../../components/CheckoutLocationInfo'
import { CheckoutTitle } from '../../components/CheckoutTitle'
import { CouponModal } from '../../components/CouponModal'
import { CouponSection } from '../../components/CouponSection'
import { DeliveryNoteModal } from '../../components/DeliveryNoteModal'
import { InstructionBlock } from '../../components/InstructionBlock'
import { LocationBlock } from '../../components/LocationBlock'
import { OrderDetails } from '../../components/OrderDetails'
import { OrderItems } from '../../components/OrderItems'
import { OrderTotal } from '../../components/OrderTotal'
import { PaymentMethodSection } from '../../components/PaymentMethodSection'
import { SelectPaymentMethod } from '../../components/SelectPaymentMethod'
import { SignupForEmailCheckbox } from '../../components/SignupForEmailCheckbox'
import { StoreStatus } from '../../components/StoreStatus'
import { TipSelector } from '../../components/TipSelector'
import { UserInfoModal } from '../../components/UserInfoModal'
import {
    CouponWithDetails,
    HiddenPaymentMethod,
    PaymentMethodResponse,
} from '../../types'
import { calcCheckoutTotals } from '../../utils/calcCheckoutTotals'

export const Checkout = ({
    navigation,
    route,
}: RootStackScreenProps<'Checkout'>) => {
    const { showModal } = route.params ?? {}
    /** STATES */

    const [first, setFirst] = useState<string>('')
    const [last, setLast] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [emailChanged, setEmailChanged] = useState<boolean>(true)
    const [coupon, setCoupon] = useState<CouponWithDetails | null>(null)
    const [tip, setTip] = useState<number>(0)
    const [paymentMethod, setPaymentMethod] = useState<
        PaymentMethodResponse | HiddenPaymentMethod | null
    >(null)
    const [deliveryNote, setDeliveryNote] = useState<string>('')

    const [couponErrorMessage, setCouponErrorMessage] = useState<string>('')
    const [cartCopy, setCartCopy] = useState<CartResponse>() // useful because we clear the cart after an order is placed
    const [signupForEmail, setSignupForEmail] = useState(false)
    const [showPrefillCoupon, setShowPrefillCoupon] = useState(true) // delete after welcome experiment
    /** STATES END */

    /** REACT QUERY CALLS */
    const { data: cart, isLoading: cartIsLoading } = useCart()
    const { data: instance, isLoading: instanceIsLoading } = useInstance(
        cart?.instance_id ?? cartCopy?.instance_id
    )
    const { isAnon, isLoading: isAuthLoading } = useIsAuth()
    const { data: profile } = useProfile()
    const { placeOrder, isLoading: isPlaceOrderLoading } = usePlaceOrder()
    const createOrder = useCreateOrder()
    const applyCouponToOrder = useApplyCouponToOrder(setCouponErrorMessage)
    const [couponIsLoading, setCouponIsLoading] = useState<boolean>(false)

    const { data: rewards } = useEligibleRewards()

    const { mutateAsync: signupForEmailFn } = useSignupForEmail()()
    const { featureFlags } = useFeatureFlags()

    /** REACT QUERY CALLS END */

    /** BOTTOM SHEET REFS */
    const tipBottomSheetRef = useRef<BottomSheetModalType>(null)
    const couponBottomSheetRef = useRef<BottomSheetModalType>(null)
    const userInfoBottomSheetRef = useRef<BottomSheetModalType>(null)
    const deliveryNoteBottomSheetRef = useRef<BottomSheetModalType>(null)
    const selectPaymentMethodRef = useRef<BottomSheetModalType>(null)
    /** END BOTTOM SHEET REFS */

    /** BOTTOM SHEET OPEN CLOSE FUNS */
    const showCouponScreen = () => couponBottomSheetRef.current?.present()
    const hideCouponScreen = () => couponBottomSheetRef.current?.close()
    const showDeliveryNoteScreen = () =>
        deliveryNoteBottomSheetRef.current?.present()

    const showSelectPaymentMethodScreen = () =>
        selectPaymentMethodRef.current?.present()
    const hideSelectPaymentMethodScreen = () =>
        selectPaymentMethodRef.current?.close()
    const showUserInfoScreen = () => userInfoBottomSheetRef.current?.present()
    const hideUserInfoScreen = () => userInfoBottomSheetRef.current?.close()
    const showTipScreen = () => tipBottomSheetRef.current?.present()

    useEffect(() => {
        if (showModal === 'selectPaymentMethod') showSelectPaymentMethodScreen()
        if (showModal === 'deliveryNote') showDeliveryNoteScreen()
        if (showModal === 'userInfo') showUserInfoScreen()
        if (showModal === 'coupon') showCouponScreen()
        if (showModal === 'tip') showTipScreen()
    }, [showModal])
    // const [isOrderProcessingModalOpen, setIsOrderProcessingModalOpen] =
    //     useState<boolean>(false)
    /** END BOTTOM SHEET OPEN CLOSE FUNS */

    const selectedLocation = useStore(
        (state) => state.location.selectedLocation
    )

    /** Update profile details */
    useEffect(() => {
        if (profile?.first) setFirst(profile.first)
        if (profile?.last) setLast(profile.last)
        if (profile?.email) setEmail(profile.email)
    }, [profile])

    const fulfillmentType = cart?.fulfillment_type
    const cartItems = cart?.items ?? []
    const isDelivery = !!(fulfillmentType === DeliveryType.delivery)
    const dataIsLoading = instanceIsLoading || cartIsLoading

    useEffect(() => {
        if (!cart?.instance_id) return

        setCartCopy(cart)
    }, [cart])

    /**  Set delivery note */
    useEffect(() => {
        if (selectedLocation?.delivery_instructions && isDelivery)
            setDeliveryNote(selectedLocation.delivery_instructions)
    }, [selectedLocation])

    /** Set default payment method */
    useEffect(() => {
        if (!profile?.payment_methods) return

        let sortedOrders
        let defaultMethod

        if (profile?.orders?.length > 0) {
            sortedOrders = sortOrders(profile.orders)

            const latestOrder = sortedOrders[0] as
                | OrderDetailResponse
                | undefined

            // Find the payment method that was used in the latest order
            defaultMethod = profile?.payment_methods.find(
                (paymentMethod) =>
                    paymentMethod.payment_method_id ===
                    latestOrder?.payment_method_id
            )
        }

        if (!defaultMethod) {
            // Find the payment method where default_at is greatest
            // ^ this was never implemented on backend
            defaultMethod =
                profile.payment_methods[profile.payment_methods.length - 1]
        }

        setPaymentMethod(defaultMethod)
    }, [profile?.payment_methods, profile?.orders])

    // navigate away if not authed
    useFocusEffect(
        useCallback(() => {
            if (!isAuthLoading && isAnon) {
                showMessage({
                    type: 'none',
                    message: 'Verify identity to check out',
                })

                navigation.navigate('Login', {
                    screen: 'PhoneNumberCapture',
                    params: {
                        returnToOnFinish: 'Checkout',
                    },
                })
            }
        }, [isAuthLoading, isAnon])
    )

    const checkoutTotals = useMemo(
        () => calcCheckoutTotals(cart, instance, coupon, tip),
        [cart, instance, coupon, tip]
    )

    const orderIsLoading = isPlaceOrderLoading || couponIsLoading

    const onPlaceOrderPress = () => {
        // will pop down message if not above minimum delivery value
        if (!isAboveMinimumDeliveryValue(cart, instance)) return

        if (hasUserInfo()) {
            buildAndPlaceOrder()
        }
    }

    const setUserInfo = (_first: string, _last: string, _email: string) => {
        setFirst(_first)
        setLast(_last)
        if (_email !== email) {
            setEmailChanged(true)
            setEmail(_email)
        }
        showTipScreen()
    }

    const hasUserInfo = () => {
        if (first && last && email) return true

        showUserInfoScreen()
        return false
    }

    const { isOpen } = useInstanceAvailability(instance, fulfillmentType)

    const buildOrder = (): Order => {
        if (!cart) throw new Error('Cart is missing')
        if (!cart?.fulfillment_type)
            throw new Error('Cart has no fulfillment type')
        if (!selectedLocation) throw new Error('Missing address') // return void?
        if (!instance) throw new Error('Missing instance') // return void?
        if (!paymentMethod?.payment_method_id)
            throw new Error('Missing payment method')

        const order: Order = {
            cart,
            address: selectedLocation,
            brand_name: instance.brand_name,
            brand_id: instance.brand_id,
            fulfillment_type: cart.fulfillment_type,
            payment_method_id: paymentMethod.payment_method_id,
            first,
            last,
            // previously only in submit order:
            ticket_amount: checkoutTotals.totalAfterTip,
            initial_tax_amount: checkoutTotals.totalTaxes,
            initial_items_amount: checkoutTotals.subtotal,
            // tax_discount,
            // final_tax_amount,
            // items_discount,
            // final_items_amount,
            // ^^ These are a bit tricky to parse because of how coupons work. Maybe in the future
        }
        if (coupon?.code) order.coupon = { code: coupon.code }
        if (tip) order.tip_amount = tip
        if (emailChanged) order.email = email
        if (deliveryNote && isDelivery) order.delivery_note = deliveryNote

        return order
    }

    const checkCoupon = async (code: string) => {
        if (!code) return setCouponErrorMessage('Enter a coupon code')
        if (!paymentMethod) {
            showMessage({
                type: 'info',
                message: `A payment method is required to add a coupon`,
                duration: 5000,
            })
            return showSelectPaymentMethodScreen()
        }
        try {
            setCouponIsLoading(true)
            let order = buildOrder()
            order = { ...order, coupon: { code } }

            postTrackingEvent({
                name: 'Tapped add coupon on coupon modal',
                category: 'Checkout',
                properties: {
                    'coupon code': code,
                },
            })

            const createdOrder = await createOrder(order)
            const orderWithCoupon = await applyCouponToOrder(createdOrder)

            const couponValue = (orderWithCoupon?.items_discount || 0) * 100

            const newCoupon: CouponWithDetails = {
                code: orderWithCoupon?.coupon?.code || code,
                value:
                    couponValue + checkoutTotals.subtotal < 0
                        ? -checkoutTotals.subtotal
                        : couponValue,
            }
            setCoupon(newCoupon)
            hideCouponScreen()
        } catch {
            setCoupon(null)
        } finally {
            setCouponIsLoading(false)
        }
    }

    const buildAndPlaceOrder = async () => {
        const order = buildOrder()
        if (!order) return

        if (signupForEmail) {
            const { phone, user_id } = profile ?? {}

            const { address1, address2, city, lat, lon, state, zip } =
                selectedLocation ?? {}

            await signupForEmailFn({
                first,
                last,
                email,
                phone,
                address1,
                address2,
                city,
                lat,
                lon,
                state,
                zip,
                user_id,
            })
        }

        const submittedOrder = await placeOrder(order)
        if (submittedOrder) {
            setEmailChanged(false)
            navigation.navigate('Order Processing', {
                orderId: submittedOrder.popchew_order_id,
            })
        }
    }

    const apply10Off = useCallback(() => {
        setShowPrefillCoupon(true)
        couponBottomSheetRef.current?.present()
        checkCoupon(WELCOME_COUPON_CODE)
    }, [paymentMethod])

    const userHasWelcomeLootbox = useMemo(() => {
        if (!rewards) return false
        return rewards.find((reward) => reward.id === WELCOME_LOOTBOX_ID)
    }, [rewards])

    const isFeatureEnabledForThisUser = useMemo(() => {
        return (
            featureFlags?.[WELCOME_FEATURE_FLAG] &&
            profile &&
            profile.orders.length === 0 &&
            !userHasWelcomeLootbox
        )
    }, [featureFlags, profile, userHasWelcomeLootbox])

    const shouldShowCouponTopbar = useMemo(() => {
        return isFeatureEnabledForThisUser && !coupon
    }, [coupon, isFeatureEnabledForThisUser])

    useEffect(() => {
        if (
            ((!cart && !cartCopy) || !instance || !selectedLocation) &&
            !cartIsLoading &&
            !instanceIsLoading
        ) {
            navigation.navigate('Cart')
        }
    }, [
        instance,
        selectedLocation,
        cart,
        cartIsLoading,
        instanceIsLoading,
        navigation,
        cartCopy,
    ])

    if (cartIsLoading || instanceIsLoading) return <FullScreenLoader />
    if (!instance || !selectedLocation || !cart || !cartCopy) return null

    const fulfillmentTypeReq = cart.fulfillment_type

    return (
        <>
            {shouldShowCouponTopbar && (
                <ApplyCouponTopbar applyCoupon={apply10Off} />
            )}
            <SafeAreaView style={tw`flex-1 bg-black`}>
                <View style={tw`pt-4`}>
                    <CheckoutTitle />
                </View>

                {dataIsLoading ? (
                    <LoadingView />
                ) : (
                    <ScrollView
                        contentContainerStyle={tw`flex-grow`}
                        showsVerticalScrollIndicator={false}
                    >
                        <CheckoutLocationInfo
                            isDelivery={isDelivery}
                            instance={instance}
                        />

                        {/* come back here and update this */}
                        <StoreStatus
                            instance={instance}
                            fulfillmentType={fulfillmentTypeReq}
                        />

                        <LocationBlock
                            instance={instance}
                            selectedLocation={selectedLocation}
                            isDelivery={isDelivery}
                        />

                        <InstructionBlock
                            isDelivery={isDelivery}
                            instance={instance}
                            deliveryNote={deliveryNote}
                            setDeliveryNote={setDeliveryNote}
                            showDeliveryNoteScreen={showDeliveryNoteScreen}
                        />

                        <OrderItems cartItems={cartItems} />

                        <Text
                            style={tw`font-ppp-b text-base text-white px-container mt-8 mb-4`}
                        >
                            Summary
                        </Text>

                        <CouponSection
                            setShowPrefillCoupon={setShowPrefillCoupon}
                            coupon={coupon}
                            setCoupon={setCoupon}
                            showCouponScreen={showCouponScreen}
                            instance={instance}
                            fulfillmentType={fulfillmentTypeReq}
                        />

                        <OrderDetails
                            checkoutTotals={checkoutTotals}
                            fulfillmentType={fulfillmentTypeReq}
                        />

                        <TipSelector
                            subtotal={checkoutTotals.subtotal}
                            setTip={setTip}
                            couponValue={checkoutTotals.couponValue}
                        />

                        <OrderTotal checkoutTotals={checkoutTotals} />

                        <View style={tw`px-container`}>
                            <PaymentMethodSection
                                paymentMethod={paymentMethod}
                                showSelectPaymentMethodScreen={
                                    showSelectPaymentMethodScreen
                                }
                            />

                            {instance.brand_id === DORITOS_BRAND_ID && (
                                <SignupForEmailCheckbox
                                    signupForEmail={signupForEmail}
                                    setSignupForEmail={setSignupForEmail}
                                />
                            )}
                        </View>
                    </ScrollView>
                )}
                <View style={tw`container border-t border-grey-700`}>
                    {!isPlaceOrderLoading ? (
                        <Button
                            variant="action"
                            onPress={onPlaceOrderPress}
                            text="Place Order"
                            disabled={
                                isPlaceOrderLoading ||
                                !paymentMethod ||
                                !isOpen ||
                                dataIsLoading ||
                                orderIsLoading
                            }
                            trackableName="Tapped Next in checkout"
                            trackableCategory="Checkout"
                            trackableProperties={{
                                subtotal: checkoutTotals.subtotal,
                                'service fee': checkoutTotals.serviceFee,
                                'delivery fee': checkoutTotals.deliveryFee,
                                'coupon discount': checkoutTotals.couponValue,
                                tax: checkoutTotals.totalTaxes,
                                'total before tip':
                                    checkoutTotals.totalBeforeTip,
                                tip,
                            }}
                            onesignal
                        />
                    ) : (
                        <View style={tw`h-14 flex items-center justify-center`}>
                            <ActivityIndicator size="small" color="#fff" />
                        </View>
                    )}
                </View>
            </SafeAreaView>

            {/* Coupon Modal */}

            <CouponModal
                bsmRef={couponBottomSheetRef}
                initialCode={
                    isFeatureEnabledForThisUser && showPrefillCoupon
                        ? WELCOME_COUPON_CODE
                        : ''
                } // delete after welcome box experiment
                closeModal={hideCouponScreen}
                checkCoupon={checkCoupon}
                errorMessage={couponErrorMessage}
                setCouponErrorMessage={setCouponErrorMessage}
                isLoading={orderIsLoading}
            />

            {/* Delivery Note Modal */}
            <DeliveryNoteModal
                setDeliveryNote={setDeliveryNote}
                deliveryNote={deliveryNote}
                bsmRef={deliveryNoteBottomSheetRef}
            />

            {/* Payment Method Modal */}

            <SelectPaymentMethod
                setPaymentMethod={setPaymentMethod}
                closeModal={hideSelectPaymentMethodScreen}
                selectedPaymentMethod={paymentMethod}
                bsmRef={selectPaymentMethodRef}
            />

            <BottomSheetModal
                ref={userInfoBottomSheetRef}
                snapPoints={['90%']}
                handleComponent={null}
                keyboardBehavior="interactive"
                trackableName="user name and email"
                trackableCategory="Checkout"
            >
                <UserInfoModal
                    closeModal={hideUserInfoScreen}
                    setUserInfo={setUserInfo}
                    currentFirst={first}
                    currentLast={last}
                    currentEmail={email}
                    isLoading={false}
                />
            </BottomSheetModal>
        </>
    )
}
