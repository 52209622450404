export const PositiveTags = [
    'Flavor',
    'Great value',
    'Craveable',
    'Cool packaging',
    'Correct temperature',
    'Good portions',
]

export const NegativeTags = [
    'Too slow',
    'Cold',
    'Missing items',
    'Bad taste',
    'Messy presentation',
    'Not worth price',
]
