import { useCallback } from 'react'

import { axios } from '@/lib/httpClient'

import { showAlert } from '@/utils/showAlert'
import { showMessage } from '@/utils/showMessage'

import { useLogout } from '../utils/logout'

export const useDeleteUser = () => {
    const [logout] = useLogout()
    const deleteUser = useCallback(() => {
        showAlert({
            title: 'Delete your account',
            description:
                'Are you sure you want to delete your account? This action is irreversible.',
            buttonTexts: ['Delete', 'Cancel'],
            buttonHandlers: {
                Delete: () => {
                    try {
                        axios.delete('/user')
                        logout()
                        showMessage({
                            type: 'info',
                            message:
                                'Your account is being processed for deletion. Please allow up to 72 hours.',
                            duration: 5000,
                        })
                    } catch (e) {
                        showMessage({
                            type: 'warning',
                            message:
                                'Unable to delete user. Please contact support.',
                        })
                    }
                },
                Cancel: () => {},
            },
        })
    }, [])
    return [deleteUser]
}
