import { Switch, Text, View } from 'react-native'

import { RootStackScreenProps } from '@/navigation/types/ScreenProps'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@/components/Button'
import { BackButton } from '@/components/Titles/BackButton'

import { useStore } from '@/lib/store'

import { WING_SZN_BRAND_ID } from '@/utils/constants'
import { clearAllStorage } from '@/utils/storage'

import tw from '@/tailwind/tailwind'

import { useSignOutFlipdish } from './api/useSignOutFlipdish'
import { DeveloperOrderDeliveryStatus } from './components/DeveloperOrderDeliveryStatus'
import { DeveloperOrderPages } from './components/DeveloperOrderPages'

export const DeveloperTools = ({
    navigation,
}: RootStackScreenProps<'Devtools'>) => {
    const signOutFlipdish = useSignOutFlipdish()
    const useCash = useStore((state) => state.devTools.useCash)
    const setUseCash = useStore((state) => state.devTools.setUseCash)

    return (
        <SafeAreaView style={tw`px-container`}>
            <View>
                <BackButton fallback="HomeTabs" />

                <Button
                    variant="link"
                    text="Wing SZN Streat Team"
                    onPress={() =>
                        navigation.navigate('Street Team', {
                            brandId: WING_SZN_BRAND_ID,
                        })
                    }
                    trackableCategory="Developer Tools"
                    trackableName="Tapped Clear all data in Developer Tools"
                />
                <Button
                    variant="link"
                    text="clear all data"
                    onPress={() => clearAllStorage()}
                    trackableCategory="Developer Tools"
                    trackableName="Tapped Clear all data in Developer Tools"
                />
                <Button
                    variant="link"
                    text="Signout of all flipdish brands"
                    onPress={() => signOutFlipdish()}
                    trackableCategory="Developer Tools"
                    trackableName="Tapped Signout of flipdish brands in Developer Tools"
                />
                <DeveloperOrderPages />
                <DeveloperOrderDeliveryStatus />
                <View style={tw`flex-row justify-center items-center`}>
                    <Text style={tw`mr-3`}>Use cash for payments</Text>
                    <Switch
                        value={useCash}
                        onValueChange={(value) => setUseCash(value)}
                    />
                </View>
            </View>
        </SafeAreaView>
    )
}
