import { capitalizeFirstLetter } from './text'

export type DayOfWeek =
    | 'sunday'
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'

export const days: DayOfWeek[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
]

export const dayIndexes: { [key: number]: DayOfWeek } = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
}

export type month_name =
    | 'january'
    | 'february'
    | 'march'
    | 'april'
    | 'may'
    | 'june'
    | 'july'
    | 'august'
    | 'september'
    | 'october'
    | 'november'
    | 'december'

export const months: { [key: number]: month_name } = {
    0: 'january',
    1: 'february',
    2: 'march',
    3: 'april',
    4: 'may',
    5: 'june',
    6: 'july',
    7: 'august',
    8: 'september',
    9: 'october',
    10: 'november',
    11: 'december',
}

export const getAmPmTime = (date: Date) => {
    const hourDifference = date.getHours() - date.getUTCHours()
    let h = date.getHours() + hourDifference
    if (h < 0) h = h + 24
    if (h > 24) h = h - 24
    const am_pm = h < 12 ? 'AM' : 'PM'
    const hoursRegular = h === 0 ? 12 : h > 12 ? h - 12 : h

    const m = date.getMinutes()
    const minsRegular = m < 10 ? '0' + m.toString() : m.toString()

    return `${hoursRegular}:${minsRegular} ${am_pm}`
}

export const getDateString = (date: Date | undefined) => {
    if (!date) return ''
    const weekday = capitalizeFirstLetter(
        dayIndexes[date.getDay()].substring(0, 3)
    )
    const day = date.getDate()
    const month = capitalizeFirstLetter(months[date.getMonth()])
    const time = getAmPmTime(date)
    return `${weekday}, ${month} ${day}, ${time}`
}
