import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const FlagSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            d="M5 3v18"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 2a1 1 0 0 0-1 1v8a3 3 0 0 0 3 3h3c.35 0 .687-.06 1-.17V15a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3h-5V5a3 3 0 0 0-3-3H5Zm8 6v7a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-5Zm-2-1V5a1 1 0 0 0-1-1H6v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7Z"
            fill="currentColor"
        />
    </Svg>
)

export default FlagSvg
