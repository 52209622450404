import { useEffect } from 'react'

import { Platform } from 'react-native'

import { NavigationState, PartialRoute, Route } from '@react-navigation/native'

import { sleep } from '@/utils/async'

// figure out current path + params based on nav state
const recurNavState = (
    route:
        | NavigationState['routes'][number]
        | PartialRoute<Route<string, object | undefined>>,
    current = ''
): string => {
    const params = { ...route.params }

    const stringifiedParams = new URLSearchParams(params).toString()
    const deeplinkPath = current + '?' + stringifiedParams

    const routeState = route.state

    if (routeState === undefined || routeState.index === undefined)
        return deeplinkPath

    const nextRoute = routeState.routes[routeState.index]

    if (!nextRoute) return deeplinkPath
    current += '/' + nextRoute.name

    return recurNavState(nextRoute, current)
}

export const getDLPath = (state: NavigationState) => {
    const route = state.routes[state.index]
    if (!state.routes || !route) return ''

    return recurNavState(route, route.name)
}

const onJourneyClosed = () => {
    sessionStorage.setItem(
        'clickedBranchJourneyCloseAt',
        new Date().getTime().toString()
    )
}

const shouldShowJourney = () => {
    // check if it was closed within past week
    // and if so don't show
    const weekMs = 604800000
    const clickedBranchJourneyCloseAt = sessionStorage.getItem(
        'clickedBranchJourneyCloseAt'
    )
    if (!clickedBranchJourneyCloseAt) return true
    const currentTime = new Date().getTime()
    return !(Number(clickedBranchJourneyCloseAt) > currentTime - weekMs)
}

export const useBranchJourney = () => {
    useEffect(() => {
        if (Platform.OS === 'web') {
            const branch = require('branch-sdk')
            branch.init(process.env.REACT_APP_BRANCH_KEY)
        }
    }, [])

    useEffect(() => {
        if (Platform.OS !== 'web') return
        const branch = require('branch-sdk')
        branch.addListener('didClickJourneyClose', onJourneyClosed)

        return () =>
            branch.removeListener('didClickJourneyClose', onJourneyClosed)
    }, [])

    const setDeeplinkPath = async (deeplinkPath: string) => {
        if (Platform.OS !== 'web') return
        const branch = require('branch-sdk')

        // Close journey is required to change data on branch journey
        // https://github.com/BranchMetrics/web-branch-deep-linking-attribution/issues/554
        branch.closeJourney()

        branch.setBranchViewData({
            data: {
                $deeplink_path: deeplinkPath,
                custom: deeplinkPath,
            },
        })

        // This is necessary due to some async issues I could not solve
        // it could be less, but it's kinda nice to add some time for visual reasons
        await sleep(6000)

        if (shouldShowJourney()) branch.track('pageview')
    }

    return { setDeeplinkPath }
}
