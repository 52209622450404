import { Text } from 'react-native'

import tw from '@/tailwind/tailwind'

export const SectionTitle = (props: {
    title: string
    isFeatured?: boolean
}) => {
    const { title, isFeatured } = props

    if (isFeatured) return null

    return (
        <Text
            style={tw` font-ppp-b pt-2 pb-6 text-xl px-container text-white ${
                isFeatured ? ' bg-pc-secondary' : 'text-center'
            } `}
        >
            {title}
        </Text>
    )
}
