import create, { StateCreator } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { createDevToolsSlice } from '@/features/DeveloperTools/slice'
import { DevToolsState } from '@/features/DeveloperTools/slice'
import { createLocationSlice } from '@/features/LocationPicker/slice'
import { LocationState } from '@/features/LocationPicker/types'

export type StoreState = LocationState & DevToolsState

export type StoreSlice<T> = StateCreator<
    StoreState,
    [['zustand/immer', never]],
    [],
    T
>

export const useStore = create<StoreState>()(
    immer((...a) => ({
        ...createLocationSlice(...a),
        ...createDevToolsSlice(...a),
    }))
)
