import React from 'react'

import {
    ActivityIndicator,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native'

import {
    BottomSheetModal as BSM,
    BottomSheetScrollView,
} from '@gorhom/bottom-sheet'

import { useDeleteUser } from '@/features/Auth/api/useDeleteUser'

import { TrackableClick } from '@/components/Analytics'
import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'

import { useProfile } from '@/hooks/query/useProfile'
import { useUpdateUser } from '@/hooks/query/useUpdateUser'

import { showMessage } from '@/utils/showMessage'
import { validateEmail, validateName } from '@/utils/validators'

import tw from '@/tailwind/tailwind'

import BinSvg from '@/svgs/BinSvg'

import ModalHeader from '../../components/ModalHeader'

export const EditAccount = React.forwardRef<BSM>((_, ref) => {
    const { data: profile } = useProfile()
    const { mutateAsync: updateUser, isLoading } = useUpdateUser()
    const [firstName, setFirstName] = React.useState(profile?.first || '')
    const [lastName, setLastName] = React.useState(profile?.last || '')
    const [email, setEmail] = React.useState(profile?.email || '')
    const [isValidEmail, setIsValidEmail] = React.useState(!!profile?.email)

    const isDisabled = !isValidEmail || !firstName || !lastName || !email

    const [deleteUser] = useDeleteUser()

    const onEmailChange = (value: string) => {
        setIsValidEmail(validateEmail(value))

        setEmail(value)
    }

    const onXPress = () => {
        setFirstName(profile?.first || '')
        setLastName(profile?.last || '')
        setEmail(profile?.email || '')
        ;(ref as React.MutableRefObject<BSM>)?.current?.dismiss()
    }

    const onSavePress = async () => {
        if (isDisabled) return

        if (!validateEmail(email)) {
            showMessage({ type: 'warning', message: 'Email is invalid' })
            return
        }

        if (!validateName(firstName)) {
            showMessage({
                type: 'warning',
                message:
                    'first name is invalid. Cannot contain special characters.',
            })
            return
        }

        if (!validateName(lastName)) {
            showMessage({
                type: 'warning',
                message:
                    'last name is invalid. Cannot contain special characters.',
            })
            return
        }

        await updateUser({
            email,
            first: firstName,
            last: lastName,
        })
        ;(ref as React.MutableRefObject<BSM>)?.current?.dismiss()
    }

    return (
        <BottomSheetModal
            trackableName={'edit account'}
            trackableCategory="Account"
            ref={ref}
            snapPoints={['90%']}
            handleComponent={null}
        >
            <ModalHeader
                trackableCloseButtonProps={{
                    name: 'Tapped X in edit account screen',
                    category: 'Account',
                    testID: 'close-edit-account',
                }}
                closeButtonProps={{
                    onPress: onXPress,
                }}
                title="Edit Account"
            />
            <BottomSheetScrollView
                showsVerticalScrollIndicator={false}
                style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    marginTop: 16,
                }}
            >
                <View style={tw`flex-row justify-between mb-4`}>
                    <View style={tw`flex-1 mr-2`}>
                        <Text style={tw`label`}>First Name</Text>
                        <TextInput
                            style={tw`input`}
                            value={firstName}
                            onChangeText={setFirstName}
                            testID="edit-account-first-name"
                            // autofocus doesn't work great on web
                            // and hides delete account button on ios
                            autoCompleteType="name"
                            textContentType="name"
                            onSubmitEditing={onSavePress}
                        />
                    </View>
                    <View style={tw`flex-1 ml-2`}>
                        <Text style={tw`label`}>Last Name</Text>
                        <TextInput
                            testID="edit-account-last-name"
                            style={tw`input`}
                            value={lastName}
                            onChangeText={setLastName}
                            autoCompleteType="name"
                            textContentType="name"
                            onSubmitEditing={onSavePress}
                        />
                    </View>
                </View>

                <View style={tw`mb-8`}>
                    <Text style={tw`label`}>Email</Text>
                    <TextInput
                        style={tw`input`}
                        value={email}
                        onChangeText={onEmailChange}
                        testID="edit-account-email"
                        autoCompleteType="email"
                        textContentType="emailAddress"
                        onSubmitEditing={onSavePress}
                        keyboardType={'email-address'}
                    />
                </View>

                <View style={tw`mb-10`}>
                    {isLoading ? (
                        <ActivityIndicator style={tw`py-4`} />
                    ) : (
                        <Button
                            variant="primary"
                            onPress={onSavePress}
                            text="Save"
                            disabled={isDisabled}
                            testID="edit-account-save-btn"
                            full
                            trackableName="Tapped save new account details"
                            trackableCategory="Account"
                        />
                    )}
                </View>
                <TrackableClick
                    name="Tapped request to delete account"
                    category="Account"
                >
                    <TouchableOpacity
                        onPress={() => {
                            deleteUser()
                        }}
                    >
                        <View
                            style={tw`flex-row justify-center items-center mt-7 pt-7 border-pc-shade-80`}
                        >
                            <BinSvg color={tw.color('pc-warning')} />
                            <Text
                                style={tw`font-ppa-b text-sm text-pc-warning text-center ml-1 mr-4`}
                            >
                                Request account deletion
                            </Text>
                        </View>
                    </TouchableOpacity>
                </TrackableClick>
            </BottomSheetScrollView>
        </BottomSheetModal>
    )
})
