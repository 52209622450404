import * as React from 'react'

import Svg, { Path, Rect, SvgProps } from 'react-native-svg'

const GiftBoxSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={30}
        height={30}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Rect
            x={3.75}
            y={10}
            width={22.5}
            height={5}
            rx={2.5}
            stroke="currentColor"
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M6.25 15h17.5v8.75a2.5 2.5 0 0 1-2.5 2.5H8.75a2.5 2.5 0 0 1-2.5-2.5V15ZM15 26.25v-10M8.08 8.323c-.788-1.967.922-4.023 3-3.607l.111.022a2.73 2.73 0 0 1 1.906 1.456L15 10h-4.444A2.668 2.668 0 0 1 8.08 8.323v0ZM21.92 8.323c.788-1.967-.922-4.023-3-3.607l-.111.022a2.73 2.73 0 0 0-1.906 1.456L15 10h4.444c1.09 0 2.072-.664 2.477-1.677v0Z"
            stroke="currentColor"
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default GiftBoxSvg
