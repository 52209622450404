import { Image, Text, View } from 'react-native'

import { ComingSoonPill } from '@/components/Elements/ComingSoonPill'

import tw from '@/tailwind/tailwind'

const DefaultRewards = () => {
    return (
        <View style={tw`justify-center items-center flex-1  relative`}>
            <View style={tw`items-center`}>
                <Text style={tw`text-center font-ppa-wbi text-3xl px-10 mb-3`}>
                    There’s more to ‘chew
                </Text>
                <ComingSoonPill />
            </View>

            <View
                style={tw`justify-center items-center px-16 absolute bottom-20`}
            >
                <View style={tw`h-12 w-12 justify-center items-center `}>
                    <Image
                        source={require('@/assets/capsule.png')}
                        resizeMode="contain"
                        style={tw`h-full w-full`}
                    />
                </View>
                <Text
                    style={tw`font-ppa-b text-base text-pc-shade-60 text-center mt-4`}
                >
                    Prizes and merch from your favorite creators
                </Text>
            </View>
        </View>
    )
}

export default DefaultRewards
