import { useState } from 'react'

import { Text, TextInput, TouchableOpacity, View } from 'react-native'

import tw from '@/tailwind/tailwind'

import { VoteButtonStatusType } from '../types'
import { getHighlightColorFromVote } from '../utils/getHighlightColorFromVote'

type AddCommentProps = {
    selectedVote: VoteButtonStatusType
    comment: string
    setComment: React.Dispatch<React.SetStateAction<string>>
}

export const AddComment = (props: AddCommentProps) => {
    const { selectedVote, comment, setComment } = props
    const [showCommentBox, setShowCommentBox] = useState(false)
    const color = getHighlightColorFromVote(selectedVote) || '#000000'

    return (
        <View style={tw`mt-20 px-container flex-1`}>
            {!showCommentBox ? (
                <TouchableOpacity>
                    <Text
                        onPress={() => setShowCommentBox(true)}
                        style={tw`text-center font-ppa-b text-[${color}] text-base`}
                    >
                        Anything to add?
                    </Text>
                </TouchableOpacity>
            ) : (
                <TextInput
                    placeholder="Tell us about your order"
                    placeholderTextColor={tw.color('pc-shade-80')}
                    style={tw`bg-pc-shade-20 flex-1 py-2 px-2 text-base max-h-[180px] font-ppa-reg`}
                    multiline
                    scrollEnabled={false}
                    blurOnSubmit={true}
                    value={comment}
                    onChangeText={setComment}
                />
            )}
        </View>
    )
}
