import { UseQueryResult } from 'react-query'

import { Instance } from '@/types/Instance'

export const getHashFromInstanceBoolArrayQuery = (
    arr: UseQueryResult<boolean | undefined, unknown>[]
) => {
    return arr.reduce((acc, curr) => {
        acc += curr.data ?? ''
        return acc
    }, '')
}

export const getHashFromInstanceArray = (arr: Instance[]) => {
    return arr.reduce((acc, curr) => {
        acc += curr.instance_id ?? ''
        return acc
    }, '')
}
