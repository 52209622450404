import { ViewStyle } from 'react-native'

import Animated, {
    Extrapolate,
    interpolate,
    useAnimatedStyle,
} from 'react-native-reanimated'

import tw from '@/tailwind/tailwind'

import {
    AMOUNT_TO_SCROLL_FOR_MIN_HEADER,
    INITIAL_HEADER_HEIGHT,
    MIN_HEADER_HEIGHT,
} from '../../utils/constants'

export const StickyHeader = ({
    scroll,
    top,
    children = <></>,
    styles,
    topSpacing = 0,
    tabBarHeight,
}: {
    scroll: Animated.SharedValue<number>
    top: number
    children?: JSX.Element
    styles?: ViewStyle
    topSpacing?: number
    tabBarHeight: number
}) => {
    const animatedStyle = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: interpolate(
                        scroll.value,
                        [0, AMOUNT_TO_SCROLL_FOR_MIN_HEADER],
                        [
                            INITIAL_HEADER_HEIGHT +
                                tabBarHeight +
                                topSpacing +
                                top,
                            MIN_HEADER_HEIGHT + tabBarHeight + top + topSpacing,
                        ],
                        { extrapolateRight: Extrapolate.CLAMP }
                    ),
                },
            ],
        }
    }, [scroll])

    return (
        <Animated.View
            style={[tw`w-full top-0 absolute`, animatedStyle, { ...styles }]}
        >
            {children}
        </Animated.View>
    )
}
