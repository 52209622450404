export function capitalizeFirstLetter(input: string) {
    if (!input) return input
    return input.charAt(0).toUpperCase() + input.slice(1)
}

// title case
export function titleCase(input: string) {
    if (!input) return input
    return input.split(' ').map(capitalizeFirstLetter).join(' ')
}
