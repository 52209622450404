import { Text, TouchableOpacity, View } from 'react-native'

import { LOOTBOX_TERMS_URL } from '@/utils/constants'
import { openLink } from '@/utils/linking'

import tw from '@/tailwind/tailwind'

const LootboxTerms = () => {
    return (
        <View>
            <TouchableOpacity
                style={tw`px-container py-2 flex-row justify-center items-center`}
                onPress={() => openLink(LOOTBOX_TERMS_URL)}
            >
                <Text style={tw`font-ppa-b text-white text-center text-xs`}>
                    No purchase necessary to win. Read the full terms and
                    conditions
                </Text>
            </TouchableOpacity>
        </View>
    )
}

export default LootboxTerms
