import React, { useMemo, useState } from 'react'

import {
    SafeAreaView,
    ScrollView,
    Text,
    TouchableWithoutFeedback,
    View,
} from 'react-native'

import { CartProps } from '@/navigation/types/ScreenProps'
import Checkbox from 'expo-checkbox'

import { TrackableClick } from '@/components/Analytics'
import { Button } from '@/components/Button'
import { LoadingView } from '@/components/Loading'
import { BackButton } from '@/components/Titles/BackButton'

import { toPrice } from '@/utils/normalizers'
import { showMessage } from '@/utils/showMessage'
import { totalCartPrice } from '@/utils/totalCartPrice'

import tw from '@/tailwind/tailwind'

import { useInstanceAvailability } from '../../utils/instance/useInstanceAvailability'
import { useIsAuth } from '../Auth/utils/isAuthenticated'
import { useCart } from './api/useCart'
import { useInstance } from './api/useInstance'
import { CartItemCard } from './components/CartItemCard'
import { NoCartItems } from './components/NoCartItems'
import { useDecreaseCount, useIncreaseCount } from './hooks/cartMutations'
import { useCountCartItems } from './hooks/useCountCartItems'
import { isAboveMinimumDeliveryValue } from './utils/isAboveMinimumDeliveryValue'

export const Cart = ({ navigation }: CartProps) => {
    const { data: cart, isLoading: cartIsLoading } = useCart()
    const { data: instance, isLoading: instanceIsLoading } = useInstance(
        cart?.instance_id
    )

    const { isLoading: isAuthLoading, isAnon } = useIsAuth()

    const isLoading = cartIsLoading || instanceIsLoading

    const instanceId = instance?.instance_id

    const cartItems = useMemo(() => cart?.items ?? [], [cart?.items])
    const instanceName = cart?.instance_name
    const menuId = cart?.menu_id

    const [isUtensilsSelected, setIsUtensilsSelected] = useState(false)
    const { mutate: decreaseCount } = useDecreaseCount()
    const { mutate: increaseCount } = useIncreaseCount()

    const { isOpen, opensSoon, message } = useInstanceAvailability(
        instance,
        cart?.fulfillment_type
    )

    // Handle counts
    const handleCountDecrement = (id: string) => {
        decreaseCount(id)
    }

    const handleCountIncrement = (id: string) => {
        increaseCount(id)
    }

    const handleUtensilsChange = () => {
        setIsUtensilsSelected(!isUtensilsSelected)
    }

    const totalPrice = useMemo(() => totalCartPrice(cartItems), [cartItems])
    const totalCount = useCountCartItems()

    const onGoToCheckout = () => {
        if (!cart) return
        if (!isAboveMinimumDeliveryValue(cart, instance)) return
        if (!isAnon) {
            navigation.navigate('Checkout')
        } else if (isAuthLoading) {
            showMessage({
                type: 'warning',
                message:
                    'Verifying authentication. Please try again in a moment.',
            })
        } else {
            showMessage({
                type: 'none',
                message: 'Verify identity to check out',
            })
            navigation.navigate('Login', {
                screen: 'PhoneNumberCapture',
                params: {
                    returnToOnFinish: 'Checkout',
                },
            })
        }
    }

    return (
        <SafeAreaView style={tw`bg-white flex-1`}>
            <View style={tw`p-container`}>
                <BackButton
                    title="Cart"
                    fallback="HomeTabs"
                    forceFallbackIfPreviousPageIn={['Checkout']}
                    trackableProperties={{
                        'cart quantity': totalCount,
                        'cart total price': totalPrice,
                    }}
                    legacyTrackableName="Tapped back arrow in cart"
                />
            </View>

            {isLoading ? (
                <View style={tw`flex-1 justify-center`}>
                    <LoadingView />
                </View>
            ) : !cart ||
              !instanceId ||
              !menuId ||
              !instanceName ||
              totalCount === 0 ? (
                <NoCartItems navigation={navigation} />
            ) : (
                <ScrollView
                    style={tw`px-container`}
                    contentContainerStyle={tw`flex-grow`}
                    showsVerticalScrollIndicator={false}
                >
                    {isOpen === false && (
                        <View style={tw`mt-3 bg-pc-shade-20 `}>
                            <View
                                style={tw`p-3 flex-row justify-between items-center`}
                            >
                                <Text style={tw`font-ppa-b text-pc-warning`}>
                                    Store is now closed.{' '}
                                    {opensSoon && ' ' + message}
                                </Text>
                            </View>
                        </View>
                    )}
                    {cartItems.map((cartItem) => (
                        <CartItemCard
                            key={cartItem.cart_item_id}
                            handleCountIncrement={handleCountIncrement}
                            handleCountdecrement={handleCountDecrement}
                            cartItem={cartItem}
                            navigation={navigation}
                            instanceId={instanceId}
                        />
                    ))}
                    <TrackableClick
                        name="Updated utensil preference"
                        category="Checkout"
                        properties={{
                            'old state': isUtensilsSelected,
                            'new state': !isUtensilsSelected,
                        }}
                    >
                        <TouchableWithoutFeedback
                            onPress={handleUtensilsChange}
                        >
                            <View
                                style={tw`py-4 border-b border-pc-shade-60/50`}
                            >
                                <View style={tw`flex-row p-3 bg-pc-shade-20`}>
                                    <Checkbox
                                        style={tw`w-6 h-6 rounded-full`}
                                        color="#000"
                                        value={isUtensilsSelected}
                                        onValueChange={setIsUtensilsSelected}
                                    />

                                    <Text style={tw`font-ppa-b text-base ml-4`}>
                                        Request utensils
                                    </Text>
                                </View>
                            </View>
                        </TouchableWithoutFeedback>
                    </TrackableClick>
                </ScrollView>
            )}
            {totalCount === 0 ? (
                <></>
            ) : (
                <View style={tw`container border-t border-pc-shade-60`}>
                    <Button
                        variant="primary"
                        onPress={() => onGoToCheckout()}
                        disabled={
                            !cartItems || cartItems.length === 0 || !isOpen
                        }
                        full
                        text={`Go to Checkout  •  ${toPrice(totalPrice)}`}
                        trackableName="Tapped Go to Checkout"
                        trackableCategory="Checkout"
                        trackableProperties={{
                            'total qty': totalCount,
                            'total price': totalPrice,
                        }}
                        onesignal
                    />
                </View>
            )}
        </SafeAreaView>
    )
}
