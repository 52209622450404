import { useEffect, useState } from 'react'

import {
    ActivityIndicator,
    KeyboardAvoidingView,
    Text,
    View,
} from 'react-native'

import { parsePhoneNumber } from 'libphonenumber-js'
import {
    CodeField,
    Cursor,
    useBlurOnFulfill,
    useClearByFocusCell,
} from 'react-native-confirmation-code-field'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useQueryClient } from 'react-query'

import { TrackableClick, postTrackingEvent } from '@/components/Analytics'
import { Button } from '@/components/Button'
import { Triangle } from '@/components/Elements/Triangle'
import { BackButton } from '@/components/Titles/BackButton'

import { profileQueryKey } from '@/hooks/query/useProfile'

import { parseAxiosError } from '@/lib/httpClient'

import tw from '@/tailwind/tailwind'

import { AuthorizeData, authorize } from '../../api/authorize'
import { sendSMS } from '../../api/sendSMSVerification'
import { VerifyCodeProps } from '../../types/AuthScreenProps'

const CELL_COUNT = 4

const VerifyCode = ({ route, navigation }: VerifyCodeProps) => {
    const { phone, returnToOnFinish, returnToOnFinishParams } =
        route.params || {}

    const queryClient = useQueryClient()
    // PHONE
    useEffect(() => {
        if (!phone) navigation.navigate('PhoneNumberCapture')
    })
    if (!phone) return null
    const phoneNumber = parsePhoneNumber(phone, 'US')

    // STATE
    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const ref = useBlurOnFulfill({ value: code, cellCount: CELL_COUNT })

    const submit = async () => {
        setLoading(true)

        postTrackingEvent({
            name: 'Entered verification code',
            category: 'Registration',
        })
        const data: AuthorizeData = {
            code,
            phone: phoneNumber.nationalNumber,
        }

        try {
            await authorize(data)

            try {
                await queryClient.invalidateQueries(profileQueryKey)
            } catch {
            } finally {
                if (returnToOnFinish) {
                    navigation.navigate(
                        returnToOnFinish,
                        returnToOnFinishParams
                    )
                } else navigation.navigate('HomeTabs')
            }
        } catch (error) {
            const { status, message } = parseAxiosError(error)

            if (status === 400 && message.includes('code'))
                setErrorMsg('Incorrect code')
            else setErrorMsg('Error verifying your number')
            ref.current?.focus()
        } finally {
            setLoading(false)
        }
    }

    const resendCode = async () => {
        if (loading) return
        setLoading(true)
        setCode('')
        try {
            await sendSMS(phoneNumber.nationalNumber)
        } catch {
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (code.length === CELL_COUNT) submit()
    }, [code])

    const handleTextChange = (codeInput: string) => {
        setErrorMsg('')

        // strip anything that's not a number
        codeInput = codeInput.replace(/[^0-9]/g, '')

        setCode(codeInput)
    }

    const [props, getCellOnLayoutHandler] = useClearByFocusCell({
        value: code,
        setValue: setCode,
    })

    return (
        <SafeAreaView style={tw`flex-1 `}>
            <KeyboardAvoidingView behavior="padding" style={tw`flex-1`}>
                <View style={tw`container flex-1`}>
                    <View style={tw`flex flex-row items-center`}>
                        <BackButton
                            fallback="PhoneNumberCapture"
                            title="Join"
                            legacyTrackableName="Tapped back arrow on verify code screen"
                        />
                    </View>
                    <Text style={tw`text-2xl font-ppa-wbi mt-10 text-center`}>
                        What’s the code?
                    </Text>
                    <Text
                        style={tw`text-base text-pc-shade-80 font-ppa-b text-center mt-1.5`}
                    >
                        We sent a code to {phone}
                    </Text>

                    <CodeField
                        ref={ref}
                        {...props}
                        autoFocus
                        RootProps={{
                            testID: 'auth-mobile-sms-input',
                        }}
                        value={code}
                        onChangeText={handleTextChange}
                        cellCount={CELL_COUNT}
                        rootStyle={tw`justify-center`}
                        keyboardType="number-pad"
                        textContentType="oneTimeCode"
                        textInputStyle={tw`bg-white`}
                        renderCell={({ index, symbol, isFocused }) => (
                            <View
                                key={index}
                                style={tw`h-10 w-5 flex justify-center items-center relative mt-6  ${
                                    errorMsg
                                        ? 'border-pc-warning'
                                        : 'border-pc-shade-20'
                                } `}
                            >
                                <View
                                    style={tw` flex-1 w-full justify-center items-center`}
                                >
                                    {/* 0's placeholder */}
                                    {!symbol && (
                                        <Text
                                            style={tw`absolute font-ppa-b text-2xl text-pc-shade-60 `}
                                        >
                                            0
                                        </Text>
                                    )}

                                    <Text
                                        style={tw`font-ppa-b text-2xl absolute left-0`}
                                        onLayout={getCellOnLayoutHandler(index)}
                                    >
                                        {symbol ||
                                            (isFocused ? <Cursor /> : null)}
                                    </Text>
                                </View>
                            </View>
                        )}
                    />
                    {!!errorMsg && (
                        <>
                            <View style={tw`self-center`}>
                                <Triangle
                                    color={tw.color('pc-warning') || 'black'}
                                />
                            </View>
                            <View
                                style={tw`bg-pc-warning rounded-full py-2 px-4 self-center`}
                            >
                                <Text style={tw`text-white`}>{errorMsg}</Text>
                            </View>
                        </>
                    )}

                    <View style={tw`flex-1  flex justify-end items-center `}>
                        {loading ? (
                            <ActivityIndicator />
                        ) : (
                            <>
                                <TrackableClick
                                    name="Tapped resend verification code"
                                    category="Registration"
                                >
                                    <Text
                                        style={tw`font-ppa-b text-center text-pc-shade-100 mb-6`}
                                        onPress={resendCode}
                                    >
                                        Resend Code
                                    </Text>
                                </TrackableClick>
                                <Button
                                    variant="primary"
                                    onPress={submit}
                                    text="Continue"
                                    trackableName="Tapped Continue in Verify Code Screen"
                                    trackableCategory="Registration"
                                    full
                                />
                            </>
                        )}
                    </View>
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    )
}
export { VerifyCode }
