import { Image } from 'react-native'

import { Brand } from '@/types/Brand'

// SOURCE: https://reactnative.dev/docs/image.html#source
const IMAGE_ENDINGS = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp']

const isImage = (assetUrl: string) =>
    IMAGE_ENDINGS.some((fileType) => assetUrl.endsWith(fileType))

export const prefetchBrandImages = (brands: Brand[]) => {
    for (const brand of brands) {
        const { background_image_url, main_image_url } = brand

        try {
            if (background_image_url && isImage(background_image_url))
                Image.prefetch(background_image_url)
            if (main_image_url && isImage(main_image_url))
                Image.prefetch(main_image_url)
        } catch (e) {
            console.error('Error prefetching images', e)
        }
    }
}
