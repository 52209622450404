import { Text, TextInput, TextInputProps, View } from 'react-native'

import tw from '@/tailwind/tailwind'

import { BottomSheetTextInput } from '../BottomSheetModal/BottomSheetTextInput'

const variants = {
    primary: { style: tw``, placeholderColor: tw.color('grey-400') },
}

const sizes = {
    default: tw``,
    paragraph: tw`h-32`,
}

interface InputProps extends Omit<TextInputProps, 'style'> {
    variant: keyof typeof variants
    errorMessage?: string
    isDisabled?: boolean
    isFocused?: boolean
    isBottomSheet?: boolean
    size?: keyof typeof sizes
    label?: string
}

export const Input = (props: InputProps) => {
    const {
        isBottomSheet,
        variant,
        errorMessage,
        size = 'default',
        label,
    } = props
    const InputComponent = isBottomSheet ? BottomSheetTextInput : TextInput

    const isError = Boolean(errorMessage)

    return (
        <View>
            {Boolean(label) && (
                <Text style={tw`ml-4 mb-1 text-white font-ppp-sb text-sm`}>
                    {label}
                </Text>
            )}
            <InputComponent
                style={[
                    tw`font-ppp-sb text-white bg-grey-700 rounded-xl px-4 py-4 border border-grey-700`,
                    variants[variant].style,
                    sizes[size],
                    tw`${isError ? 'border-pc-warning text-pc-warning' : ''}`,
                ]}
                {...props}
                placeholderTextColor={variants[variant].placeholderColor}
            />
            {isError && (
                <Text style={tw`mt-2 text-pc-warning px-container`}>
                    {errorMessage}
                </Text>
            )}
        </View>
    )
}
