import { Platform } from 'react-native'

const pushGtmTag = (
    name: string,
    props: Record<string, unknown> | undefined = undefined
) => {
    if (Platform.OS === 'web') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: name,
            ...props,
        })
    }
}

export { pushGtmTag }
