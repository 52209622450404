import { Text, TouchableOpacity, View } from 'react-native'

import { DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { TrackableClick } from '@/components/Analytics'

import { useInstanceAvailability } from '@/utils/instance/useInstanceAvailability'

import tw from '@/tailwind/tailwind'

import BinSvg from '@/svgs/BinSvg'
import PlusSvg from '@/svgs/PlusSvg'
import ShoppingTagSvg from '@/svgs/ShoppingTagSvg'

import { CouponWithDetails } from '../types'

type CouponSectionProps = {
    coupon: CouponWithDetails | null
    setCoupon: React.Dispatch<any>
    showCouponScreen: () => void | undefined
    instance: Instance
    fulfillmentType: DeliveryType
    setShowPrefillCoupon: React.Dispatch<React.SetStateAction<boolean>>
}
export const CouponSection = ({
    coupon,
    setCoupon,
    showCouponScreen,
    instance,
    fulfillmentType,
    setShowPrefillCoupon,
}: CouponSectionProps) => {
    const { isOpen } = useInstanceAvailability(instance, fulfillmentType)

    if (!isOpen) return null

    return (
        <View style={tw`mb-8 bg-grey-700 rounded-xl`}>
            {coupon ? (
                <View style={tw`flex-row justify-between items-center p-4`}>
                    <View style={tw`flex-row items-center`}>
                        <View style={tw`mr-2.5`}>
                            <ShoppingTagSvg />
                        </View>
                        <Text style={tw`text-pc-success font-ppp-sb`}>
                            {coupon.code}
                        </Text>
                    </View>
                    <TrackableClick
                        name="Cleared coupon"
                        category="Checkout"
                        properties={{
                            'coupon code': coupon.code,
                        }}
                    >
                        <TouchableOpacity
                            onPress={() => setCoupon(null)}
                            style={tw`flex-col justify-center`}
                        >
                            <BinSvg color="white" size="sm" />
                        </TouchableOpacity>
                    </TrackableClick>
                </View>
            ) : (
                <TrackableClick
                    name="Tapped add coupon code"
                    category="Checkout"
                >
                    <TouchableOpacity
                        style={tw`flex-row items-center p-4`}
                        onPress={() => {
                            setShowPrefillCoupon(false)
                            showCouponScreen()
                        }}
                    >
                        <View style={tw`mr-2.5`}>
                            <ShoppingTagSvg />
                        </View>
                        <Text style={tw`font-ppp-sb text-sm text-grey-400`}>
                            Coupon Code
                        </Text>
                    </TouchableOpacity>
                </TrackableClick>
            )}
        </View>
    )
}
