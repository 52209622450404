import * as React from 'react'

import Svg, { Circle, Path, Rect, SvgProps } from 'react-native-svg'

const NoInternetSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={216}
        height={416}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            d="M78.247 140.785 19.861 84.561c-1.07-1.03-1.23-2.695-.314-3.863 16.7-21.264 47.026-27.217 62.992-27.67 1.666-.047 2.988 1.34 2.945 3.006l-2.157 82.668c-.068 2.607-3.2 3.892-5.08 2.083Z"
            stroke="currentColor"
            strokeWidth={3}
        />
        <Path
            d="M85.563 61.856c-12.065-.804-41.342 3.218-61.933 25.739"
            stroke="currentColor"
            strokeWidth={3}
        />
        <Circle
            cx={71.889}
            cy={115.746}
            r={4.826}
            stroke="currentColor"
            strokeWidth={3}
        />
        <Circle
            cx={47.76}
            cy={88.399}
            r={8.043}
            stroke="currentColor"
            strokeWidth={3}
        />
        <Path
            d="m75.106 81.16 2.655 3.186a1 1 0 0 1-.768 1.64H71.17a1 1 0 0 0-.993.883l-1.401 11.907a1 1 0 0 1-.993.883h-7.96a1 1 0 0 0-.977.784l-1.157 5.205a1 1 0 0 1-1.423.678l-3.68-1.841M26.848 91.289v29.283a4.826 4.826 0 0 0 9.652 0v-1.609a3.217 3.217 0 1 1 6.434 0v17.695a4.826 4.826 0 1 0 9.652 0v-20.584L26.848 91.289ZM129.133 66.933v-5.771a2 2 0 0 1 .586-1.414L136.467 53"
            stroke="currentColor"
            strokeWidth={3}
        />
        <Rect
            x={102}
            y={66.2}
            width={52.8}
            height={8.8}
            rx={4.4}
            stroke="currentColor"
            strokeWidth={3}
        />
        <Path
            d="M106.4 75h44l-5.867 66h-30.8L106.4 75Z"
            stroke="currentColor"
            strokeWidth={3}
        />
        <Path
            d="m111.82 123.4-1.491.162.145 1.338h1.346v-1.5Zm-3.348-30.8v-1.5H106.8l.181 1.662 1.491-.162Zm40.27 0 1.494.13.142-1.63h-1.636v1.5Zm-2.679 30.8v1.5h1.376l.119-1.37-1.495-.13Zm-32.752-.162-3.348-30.8-2.982.324 3.348 30.8 2.982-.324ZM108.472 94.1h40.27v-3h-40.27v3Zm38.775-1.63-2.678 30.8 2.989.26 2.678-30.8-2.989-.26Zm-1.184 29.43H111.82v3h34.243v-3ZM198.315 43.34l-1.491-.164-.184 1.664h1.675v-1.5Zm-22.046.257.37 1.454 1.295-.33-.178-1.323-1.487.2Zm20.614-1.49c0 .361-.02.718-.059 1.069l2.982.33c.051-.46.077-.927.077-1.4h-3Zm-9.606-9.607c5.305 0 9.606 4.3 9.606 9.606h3c0-6.962-5.644-12.606-12.606-12.606v3Zm-9.607 9.606c0-5.305 4.301-9.606 9.607-9.606v-3c-6.963 0-12.607 5.644-12.607 12.606h3Zm.086 1.292a9.806 9.806 0 0 1-.086-1.292h-3c0 .573.038 1.137.113 1.69l2.973-.398ZM171.5 51.67a6.833 6.833 0 0 1 5.139-6.62l-.739-2.906c-4.253 1.082-7.4 4.934-7.4 9.526h3Zm6.83 6.83a6.83 6.83 0 0 1-6.83-6.83h-3c0 5.43 4.401 9.83 9.83 9.83v-3Zm27.766 0H178.33v3h27.766v-3Zm6.83-6.83a6.83 6.83 0 0 1-6.83 6.83v3c5.429 0 9.83-4.4 9.83-9.83h-3Zm-6.83-6.83a6.83 6.83 0 0 1 6.83 6.83h3c0-5.429-4.401-9.83-9.83-9.83v3Zm-7.781 0h7.781v-3h-7.781v3ZM14.221 11.362l1.49-.165.185 1.665h-1.675v-1.5Zm16.724.194-.37 1.454-1.294-.33.178-1.323 1.486.2Zm-15.275-1.13c0 .26.014.518.042.77l-2.982.33c-.04-.361-.06-.729-.06-1.1h3ZM22.595 3.5a6.926 6.926 0 0 0-6.925 6.925h-3C12.67 4.944 17.113.5 22.595.5v3Zm6.926 6.925c0-3.824-3.101-6.925-6.926-6.925v-3c5.482 0 9.926 4.444 9.926 9.925h-3Zm-.062.932a7 7 0 0 0 .062-.931h3c0 .45-.03.894-.089 1.33l-2.973-.399Zm4.743 6.324c0-2.248-1.541-4.14-3.626-4.67l.74-2.908a7.822 7.822 0 0 1 5.886 7.578h-3Zm-4.82 4.819a4.82 4.82 0 0 0 4.82-4.82h3a7.82 7.82 0 0 1-7.82 7.82v-3Zm-21.063 0h21.063v3H8.32v-3Zm-4.82-4.82a4.82 4.82 0 0 0 4.82 4.82v3a7.82 7.82 0 0 1-7.82-7.82h3Zm4.82-4.818a4.82 4.82 0 0 0-4.82 4.819h-3a7.82 7.82 0 0 1 7.82-7.82v3Zm5.902 0H8.319v-3h5.902v3Z"
            fill="currentColor"
        />
    </Svg>
)

export default NoInternetSvg
