import { Text, View } from 'react-native'

import { navigateUsingRef } from '@/navigation/navigateUsingRef'

import { OrderDetailResponse } from '@/types/Order'

import { Button } from '@/components/Button'

import { useOrderStatus } from '@/hooks/query/useOrder'

import tw from '@/tailwind/tailwind'

import StarGroupingSvg from '@/svgs/StarGroupingSvg'

type ReviewOrderPromptProps = {
    order: OrderDetailResponse
}

export const ReviewOrderPrompt = (props: ReviewOrderPromptProps) => {
    const { order } = props
    const { popchew_order_id, fulfillment_type, created_on } = order
    const { data: orderStatus } = useOrderStatus(popchew_order_id)
    const navigateToReviewOrder = () =>
        navigateUsingRef('Review Order', { orderId: popchew_order_id })

    if (!orderStatus) return null

    const orderCreatedOn = new Date(created_on)
    const ONE_HOUR = 60 * 60 * 1000
    const isOrderAtleastAnHourOld =
        new Date().getTime() - orderCreatedOn.getTime() > ONE_HOUR

    const { status: delivery_tracking_status } = orderStatus
    const isDelivery = fulfillment_type === 'delivery'
    const shouldBeVisible =
        (isDelivery && delivery_tracking_status === 'Delivered') ||
        (!isDelivery && isOrderAtleastAnHourOld)

    if (!shouldBeVisible) return null

    return (
        <View style={tw`py-4`}>
            <View
                style={tw`flex-row items-center px-6 py-4 bg-pc-secondary rounded-t-md`}
            >
                <View>
                    <StarGroupingSvg />
                </View>
                <View style={tw`flex-shrink ml-4`}>
                    <Text style={tw`text-white font-ppa-b text-base`}>
                        Earn 100 points when you leave a review for your order
                    </Text>
                </View>
            </View>
            <View
                style={tw`rounded-b-md border-b border-l border-r border-pc-shade-40 container`}
            >
                <View style={tw`self-start`}>
                    <Button
                        variant="primary"
                        text="Rate Order"
                        onPress={navigateToReviewOrder}
                        trackableCategory="Order"
                        trackableName="Tapped Rate Order"
                    />
                </View>
            </View>
        </View>
    )
}
