import jwtDecode, { InvalidTokenError, JwtPayload } from 'jwt-decode'

import { useProfile } from '@/hooks/query/useProfile'

import storage from '@/utils/storage'

export const decodeToken = (token: string) => {
    try {
        const decodedToken = jwtDecode<JwtPayload>(token)
        if (!decodedToken.exp || !decodedToken.sub) return null
        return decodedToken
    } catch (error) {
        if (error instanceof InvalidTokenError) {
            return null
        }
        throw error
    }
}

export const tokenIsExpired = (token: JwtPayload) => {
    if (!token.exp) return true
    return token.exp * 1000 <= new Date().getTime()
}

export const isAuthenticated = async () => {
    const accessToken = await storage.getAccessToken()
    const refreshToken = await storage.getRefreshToken()

    // If either token isn't there, clear all tokens

    if (!accessToken || !refreshToken) {
        await storage.removeAccessToken()
        await storage.removeRefreshToken()
        return false
    }

    // Check that tokens are decodable. If they are not they are bad
    const decodedAccess = decodeToken(accessToken)
    const decodedRefresh = decodeToken(refreshToken)

    if (!decodedAccess || !decodedRefresh) {
        await storage.removeAccessToken()
        await storage.removeRefreshToken()
        return false
    }

    // Check that refresh token isn't expired
    // Could also check access but axios interceptors handle expired access
    return !tokenIsExpired(decodedRefresh)
}

export const useIsAuth = () => {
    const { data: profile, isLoading } = useProfile()

    const isAnon = profile?.is_anon

    return {
        isAnon,
        isLoading,
    }
}
