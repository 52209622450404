import { useMemo, useState } from 'react'

import { Text, View } from 'react-native'

import { useBottomSheetDynamicSnapPoints } from '@gorhom/bottom-sheet'
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'

import { postTrackingEvent } from '@/components/Analytics'
import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { ModalX } from '@/components/Titles/ModalX'

import tw from '@/tailwind/tailwind'

export const DeliveryNoteModal = ({
    setDeliveryNote,
    deliveryNote,
    bsmRef,
}: {
    setDeliveryNote: (note: string) => void
    deliveryNote: string
    bsmRef: React.RefObject<BottomSheetModalMethods>
}) => {
    const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], [])
    const {
        animatedHandleHeight,
        animatedSnapPoints,
        animatedContentHeight,
        handleContentLayout,
    } = useBottomSheetDynamicSnapPoints(initialSnapPoints)
    const [dNote, setDNote] = useState(deliveryNote)

    const closeModal = () => {
        bsmRef.current?.close()
    }

    const onSubmit = () => {
        setDeliveryNote(dNote)
        postTrackingEvent({
            name: 'Added delivery note',
            category: 'Checkout',
            properties: {
                'delivery note': dNote,
            },
        })
        closeModal()
    }

    return (
        <BottomSheetModal
            ref={bsmRef}
            snapPoints={animatedSnapPoints}
            handleHeight={animatedHandleHeight}
            contentHeight={animatedContentHeight}
            handleComponent={null}
            keyboardBehavior="interactive"
            trackableName="delivery note"
            trackableCategory="Checkout"
            isDark
        >
            <View style={tw`py-container`} onLayout={handleContentLayout}>
                <View style={tw`px-container`}>
                    <ModalX
                        onPress={closeModal}
                        name="Tapped X on delivery note screen"
                        category="Checkout"
                        title="Delivery Note"
                        color="white"
                    />
                    <Text style={tw`text-sm text-white font-ppp-m py-4`}>
                        Delivery instructions
                    </Text>
                </View>

                <Input
                    variant="primary"
                    size="paragraph"
                    multiline
                    value={dNote}
                    onChangeText={(text) => setDNote(text)}
                    onSubmitEditing={onSubmit}
                    placeholder="Ex: Leave at front door"
                    isBottomSheet
                    // note, onSubmitEditing doesn't actually do anything right now
                    // since it's multiline and pressing enter just goes to next line
                    // but will leave in for now
                />
                <View style={tw`mt-8 px-container mb-2.5`}>
                    <Button
                        variant="action"
                        text="Add Note"
                        onPress={onSubmit}
                        trackableCategory="Checkout"
                        trackableName="Tapped Add Note"
                    />
                </View>
            </View>
        </BottomSheetModal>
    )
}
