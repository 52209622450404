import React, { Fragment } from 'react'

import { postTrackingEvent } from './postTrackingEvent'
import { TrackableElementProps, TrackableProps } from './types'

export const TrackableRender = (props: TrackableElementProps): JSX.Element => {
    const { children } = props

    React.useEffect(() => {
        postTrackingEvent(props as TrackableProps)
    }, [])

    return <Fragment>{children}</Fragment>
}
