import { useEffect, useState } from 'react'

export const useHasWindow = () => {
    const [hasWindow, setHasWindow] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        try {
            window.scroll() // arbitrary window fn to check if window exists
            setHasWindow(true)
        } catch (error) {
            setHasWindow(false)
            // Anything where window is not available SHOULD throw type error
            if (!(error instanceof TypeError)) console.error(error)
        }
    }, [])

    return hasWindow
}
