import React, { FC, ReactElement } from 'react'

import { postTrackingEvent } from './postTrackingEvent'
import { TrackableElementProps, TrackableProps } from './types'

// A component wrapper for sending mixpanel events.
export const TrackableClick: FC<TrackableElementProps> = (props) => {
    const { children, testID } = props
    const handleClick = async () => {
        postTrackingEvent(props as TrackableProps)
    }

    return (
        <>
            {React.Children.map(children, (child) =>
                React.cloneElement(child as ReactElement, {
                    testID,
                    onPress: () => {
                        handleClick()
                        {
                            ;(child as ReactElement).props?.onPress
                                ? (child as ReactElement).props.onPress()
                                : undefined
                        }
                    },
                })
            )}
        </>
    )
}

// Note, could switch order of handleClick and onPress call if e.g. don't want to send to mixpanel if onclick fails

// // Alternative method using TouchableOpacity - may have styling implications
//
// return (
//     <Fragment>
//         {React.Children.map(children, (child) => {
//             return (
//                 <TouchableOpacity onPress={handleClick}>
//                     {React.cloneElement(child as ReactElement, {
//                         nativeID,
//                     })}
//                 </TouchableOpacity>
//             )
//         })}
//     </Fragment>
// )
