import { Platform } from 'react-native'

import { deleteItemAsync, getItemAsync, setItemAsync } from 'expo-secure-store'

export const isomorphicStorage = {
    async removeItem(key: string) {
        if (Platform.OS === 'web') {
            return window.localStorage.removeItem(key)
        }

        await deleteItemAsync(key)
    },

    async getItem(key: string) {
        if (Platform.OS === 'web') {
            return window.localStorage.getItem(key)
        }

        return getItemAsync(key)
    },

    async setItem(key: string, value: string) {
        if (Platform.OS === 'web') {
            return window.localStorage.setItem(key, value)
        }

        await setItemAsync(key, value)
    },
}
