import { CouponWithDetails, PaymentMethod } from '@/features/Checkout/types'
import { BackendReview } from '@/features/ReviewOrder/types'

import { Address } from './Address'
import { CartResponse } from './Cart'
import { Coupon } from './Coupon'
import { FlipdishVirtualRestaurant } from './Instance'

export interface Order {
    order_id?: string
    popchew_order_id?: string
    flipdish_order_id?: string | null
    first?: string
    last?: string | null
    email?: string | null
    status?: OrderStatus
    created_on?: string
    fulfilled_on?: string | null
    fulfillment_type: 'pickup' | 'delivery'
    cart: CartResponse
    address: Address
    items_discount?: number | null
    ticket_amount?: number
    phone?: string | null
    created_on_local_time?: string
    created_on_timezone?: string
    dd_tracking_link?: string | null
    coupon?: Coupon | null
    coupon_with_details?: CouponWithDetails | null
    delivery_note?: string | null
    payment_method_id?: string
    flipdish_payment_account_id?: string | null
    tip_amount?: number | null
    user_id?: string
    restaurant?: FlipdishVirtualRestaurant | null
    payment_method?: PaymentMethod

    flipdish_app_id?: string | null
    brand_name?: string | null
    brand_id?: string
    final_items_amount?: number | null
    initial_items_amount?: number | null
    initial_tax_amount?: number | null
    tax_discount?: number | null
    final_tax_amount?: number | null

    // New additions for better post-order experience
    pickup_time?: string | null
    delivery_time?: string | null
    rejection_time?: string | null
}

type OrderStatus =
    | 'submitted'
    | 'out for delivery'
    | 'canceled'
    | 'picked up'
    | 'accepted'

export interface CreateOrderRequest extends Order {
    cash_payment?: boolean
}

export interface CreateOrderResponse extends Order {
    message?: string | null
}

export interface ApplyVoucherToOrderResponse extends Order {
    message?: string | null
}

export interface SubmitOrderResponse extends Order {
    message?: string | null
    points_earned: number | null
    popchew_order_id: string
    brand_id: string
    created_on: string
}

export interface OrderDetailResponse extends SubmitOrderResponse {
    reviews?: BackendReview[]
}

export type DeliveryTrackingStatus =
    | null
    | 'Unassigned'
    | 'Unaccepted'
    | 'Accepted'
    | 'Carrying'
    | 'OnTheWay'
    | 'ArrivedAtLocation'
    | 'Delivered'
    | 'CannotDeliver'

export type OrderState =
    | 'Created'
    | 'PlacedCanBeCancelled'
    | 20
    | 'ReadyToProcess'
    | 'AcceptedByRestaurant'
    | 'Dispatched'
    | 'Delivered'
    | 'Cancelled'
    | 'ManualReview'
    | 'RejectedByStore'
    | 'RejectedByFlipdish'
    | 'RejectedAutomatically'
    | 'RejectedAfterBeingAccepted'
    | 'AcceptedAndRefunded'

export const placedOrderStates: OrderState[] = [
    'Created',
    'PlacedCanBeCancelled',
    20,
    'ReadyToProcess',
    'AcceptedByRestaurant',
]
export const goodOrderStates: OrderState[] = ['Dispatched', 'Delivered']
export const badOrderStates: OrderState[] = [
    'Cancelled',
    'ManualReview',
    'RejectedByStore',
    'RejectedByFlipdish',
    'RejectedAutomatically',
    'RejectedAfterBeingAccepted',
    'AcceptedAndRefunded',
]

export type PaymentStatus =
    | 'Paid'
    | 'Unpaid'
    | 'Refunded'
    | 'PartiallyRefunded'
    | 'Disputed'

export type OrderStatusResponse = {
    delivery_status_notes: null | string
    error_message: null | string
    external_reference_id: null | string
    flipdish_order_id: number
    integration_code: null | string
    integration_name: null | string
    message: null | string
    order_id: null | string
    status: DeliveryTrackingStatus | null
    track_url: null | string
    delivery_tracking_status: DeliveryTrackingStatus | null
    order_state: OrderState
    payment_status: PaymentStatus
    rejection_reason: string | null
    popchew_order_id: string
    placed_time?: string
}
