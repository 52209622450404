import { Text, View } from 'react-native'

import { toPrice } from '@/utils/normalizers'

import tw from '@/tailwind/tailwind'

export const OrderNumberBlock = ({
    label,
    price,
    isLast,
}: {
    label: string
    price: number
    isLast?: boolean
}) => {
    return (
        <View
            style={tw`flex-row justify-between ${isLast ? 'mb-4' : 'mb-2.5'}`}
        >
            <Text
                style={tw`font-ppp-m text-base ${
                    price < 0 ? 'text-pc-success' : 'text-white'
                }
                `}
            >
                {label}
            </Text>
            <Text
                style={tw`font-ppp-m text-base ${
                    price < 0 ? 'text-pc-success' : 'text-white'
                }`}
            >
                {toPrice(price)}
            </Text>
        </View>
    )
}
