import { useMemo, useRef, useState } from 'react'

import {
    FlatList,
    KeyboardAvoidingView,
    Platform,
    Text,
    View,
} from 'react-native'

import { useNavigateBack } from '@/navigation/hooks/useNavigateBack'
import { LocationPickerProps } from '@/navigation/types/ScreenProps'
import { REACT_APP_API_URL } from '@env'
import {
    GooglePlacesAutocomplete,
    GooglePlacesAutocompleteRef,
} from 'react-native-google-places-autocomplete'
import { SafeAreaView } from 'react-native-safe-area-context'
import SwitchSelector from 'react-native-switch-selector'

import { useUpdateFulfillment } from '@/features/Cart/hooks/cartMutations'

import { DeliveryType } from '@/types/Address'

import { postTrackingEvent } from '@/components/Analytics'
import { Button } from '@/components/Button'
import { BackButton } from '@/components/Titles/BackButton'

import { useProfile } from '@/hooks/query/useProfile'

import { useStore } from '@/lib/store'

import { showMessage } from '@/utils/showMessage'
import storage from '@/utils/storage'

import tw from '@/tailwind/tailwind'

import { AddressItem } from '../../components/AddressItem'
import { DeliveryTypeSwitch } from '../../components/DeliveryTypeSwitch'
import { convertGooglePlaces } from '../../utils/googlePlacesConvert'
import { sortAddresses } from '../../utils/sortAddresses'
import { useClearCartConditionally } from '../../utils/useClearCartConditionally'
import { useSetLocationWithClearCart } from '../../utils/useSetLocationWithClearCart'

export const LocationPicker = ({ navigation }: LocationPickerProps) => {
    const { data: profile } = useProfile()
    const { addresses = [] } = profile || {}

    // STORE
    const selectedLocation = useStore(
        (state) => state.location.selectedLocation
    )
    const setTempLocation = useStore((state) => state.setTempLocation)
    const setDeliveryType = useStore((state) => state.setDeliveryType)

    const [addressLookupFocused, setAddressLookupFocused] = useState(false)

    const { mutateAsync: updateFulfillmentInCart } = useUpdateFulfillment()
    const setLocationWithClearCart = useSetLocationWithClearCart()
    const clearCartConditionally = useClearCartConditionally()

    const googlePlacesInputRef = useRef<GooglePlacesAutocompleteRef>(null)
    const deliveryTypeSwitchRef = useRef<SwitchSelector>(null)

    const autocompleteInputHeight = useMemo(() => {
        const inputStyleObject = tw.style('input') as {
            paddingTop: number
            paddingBottom: number
            fontSize: number
        }

        return (
            inputStyleObject.paddingTop +
            inputStyleObject.paddingBottom +
            inputStyleObject.fontSize +
            10 // arbitrary
        )
    }, [])

    // Note, currently this address sort only works when menu screen is reloaded
    //    this alone is worth it imo
    // Don't want to resort every time time selectedLocation is changed;
    //   feels clunky on UI for it to pop to top immediately, without scrolling
    //   could add a re-shuffle animation though
    const sortedAddresses = useMemo(
        () => sortAddresses(addresses, selectedLocation),
        [addresses]
    )

    const navigateBack = useNavigateBack()

    const onSwitchChange = async (value: DeliveryType) => {
        clearCartConditionally(
            true,
            `Are you sure you want to switch to ${value}? Your cart will be reset`,
            async () => {
                setDeliveryType(value)
                if (await storage.getCartId()) updateFulfillmentInCart(value)
            },
            () => {
                deliveryTypeSwitchRef.current?.toggleItem(
                    value === DeliveryType.delivery ? 1 : 0
                )
            }
        )
    }

    return (
        <SafeAreaView style={tw`flex-1 `}>
            <KeyboardAvoidingView
                style={tw`flex-1`}
                {...(Platform.OS === 'ios' ? { behavior: 'padding' } : {})}
            >
                <View style={tw`container`}>
                    <BackButton
                        fallback="HomeTabs"
                        forceFallbackIfPreviousPageIn={['Edit Location']}
                        title="Address"
                        legacyTrackableName="Tapped back arrow on Location Picker"
                    />
                </View>
                <View style={tw`px-container`}>
                    <DeliveryTypeSwitch
                        ref={deliveryTypeSwitchRef}
                        onSwitchChange={onSwitchChange}
                    />
                </View>
                <View
                    style={tw`container min-h-[80px] ${
                        addressLookupFocused ? 'flex-1' : ''
                    }`}
                    testID="google-places-input-container"
                >
                    <GooglePlacesAutocomplete
                        debounce={220}
                        ref={googlePlacesInputRef}
                        keyboardShouldPersistTaps="handled"
                        styles={{
                            textInput: [
                                tw`input rounded-none m-0`,
                                { height: autocompleteInputHeight },
                            ],
                        }}
                        onPress={(_data, details = null) => {
                            // 'details' is provided when fetchDetails = true
                            if (details) {
                                const address = convertGooglePlaces(details)

                                // Stops people from entering a location, like New York City as their address
                                if (!address.address1 || !address.zip) {
                                    showMessage({
                                        message:
                                            "Please make sure you've entered a valid address",
                                        type: 'warning',
                                    })

                                    return
                                }
                                setTempLocation(address)
                                setAddressLookupFocused(false)
                                googlePlacesInputRef.current?.clear()
                                postTrackingEvent({
                                    name: 'Tapped google places autocomplete item',
                                    category: 'Address',
                                })
                                navigation.navigate('Edit Location', {
                                    isEdit: false,
                                })
                            }
                        }}
                        placeholder="Enter a new address"
                        query={{
                            key: 'AIzaSyD5pPo7lbpeQs_q0PyY4dfFET-HcD7UC28',
                            language: 'en',
                            components: 'country:us',
                        }}
                        listViewDisplayed
                        enablePoweredByContainer={false}
                        textInputProps={{
                            onChangeText: (text) => {
                                setAddressLookupFocused(text.length > 0)
                            },
                            onBlur: async () => {
                                // this is a horrible hack that stops
                                // state change from cancelling out the onPress
                                // when an address is selected
                                if (Platform.OS === 'web') {
                                    setTimeout(() => {
                                        setAddressLookupFocused(false)
                                    }, 100)
                                } else {
                                    setAddressLookupFocused(false)
                                }
                            },
                        }}
                        fetchDetails
                        listEmptyComponent={() => (
                            <View>
                                <Text style={tw`py-5 px-4 text-pc-shade-100`}>
                                    No results
                                </Text>
                            </View>
                        )}
                        requestUrl={{
                            useOnPlatform: 'web',
                            url: REACT_APP_API_URL + '/redirect/google_maps',
                            // url: 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api', // or any proxy server that hits https://maps.googleapis.com/maps/api
                            headers: {
                                // TODO - AUTHORIZATION - Authorization: `an auth token`
                            },
                        }}
                    />
                </View>
                {!addressLookupFocused && (
                    <FlatList
                        keyboardShouldPersistTaps="handled"
                        style={tw`flex-1 mt-2`}
                        showsVerticalScrollIndicator={false}
                        testID="address-list-container"
                        data={sortedAddresses}
                        keyExtractor={(item) => item.address_id ?? ''}
                        renderItem={({ item: address }) => (
                            <AddressItem
                                navigation={navigation}
                                address={address}
                                isSelected={
                                    address.address_id ===
                                    selectedLocation?.address_id
                                }
                                setSelectedLocation={setLocationWithClearCart}
                                setTempLocation={setTempLocation}
                            />
                        )}
                    />
                )}

                <View
                    style={tw`px-container py-4 bg-white border-t border-pc-shade-60`}
                >
                    <Button
                        variant="primary"
                        onPress={() => navigateBack('HomeTabs')}
                        text={!selectedLocation ? 'Select an address' : 'Done'}
                        disabled={!selectedLocation}
                        trackableName="Tapped done on Location Picker"
                        trackableCategory="Address"
                        testID="address-done-button"
                        onesignal
                    />
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    )
}
