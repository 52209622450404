import { useMemo, useRef } from 'react'

import {
    Image,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    ScrollView,
    SectionList,
    SectionListData,
    Text,
    View,
} from 'react-native'

import { BrandScreenProps } from '@/navigation/types/ScreenProps'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce'

import {
    HandleContentChangeType,
    HandleScrollEventType,
} from '@/features/BrandPage'
import { MenuItem } from '@/features/BrandPage/screens/Menu/components/MenuItem'
import { INITIAL_HEADER_HEIGHT } from '@/features/BrandPage/utils/constants'
import { useCart } from '@/features/Cart/api/useCart'
import { countItem } from '@/features/Cart/utils/countItem'

import { DeliveryType } from '@/types/Address'
import { Cart } from '@/types/Cart'
import { ExtendedMenuSection, Item } from '@/types/Menu'

import { useStore } from '@/lib/store'

import tw from '@/tailwind/tailwind'

import { asyncMeasure } from '../utils/asyncMeasure'
import { getSectionX } from '../utils/getSectionX'
import { AddButton } from './AddButton'
import { SectionTitle } from './MenuSectionTitle'

export interface SectionItemProps {
    item: Item
    section: SectionListData<Item>
    index: number
}

const RenderItem = ({
    section,
    index,
    menuId,
    sectionContentArr,
    instanceId,
    instanceName,
    cart,
    deliveryType,
    isExampleMenu,
    navigation,
    showPickupDeliveryModal,
}: {
    section: ExtendedMenuSection
    index: number
    menuId: string
    sectionContentArr: React.MutableRefObject<(TouchableOpacity | null)[]>
    instanceId: string
    instanceName: string
    cart: Cart
    deliveryType: DeliveryType
    isExampleMenu: boolean
    navigation: BrandScreenProps['navigation']
    showPickupDeliveryModal: () => void
}) => {
    const item = section.data[index]

    const count = useMemo(
        () => countItem(cart.items, item.item_id),

        [cart, item]
    )

    if (section.is_bdot_featured && index === 0)
        return (
            <View
                key={section.section_id}
                style={tw`bg-[#FCE53E] pb-6 relative rounded-[48px] overflow-hidden mb-4`}
            >
                <View style={tw`px-1`}>
                    <Image
                        source={require('@/assets/bdot_featured_item.webp')}
                        style={tw`w-full h-56`}
                        resizeMode="contain"
                    />
                </View>
                <View style={tw`w-full h-14`}>
                    <Image
                        source={require('@/assets/bdot_title_white.webp')}
                        style={tw`w-full h-full`}
                        resizeMode="contain"
                        accessibilityLabel="The Bdot Meal"
                    />
                </View>

                <View style={tw`px-container`}>
                    <Text style={tw`font-ppp-m text-xs text-center mt-3`}>
                        Order BDOT’s favorite just in time for playoffs.{' '}
                        {item.description}
                    </Text>

                    <View style={tw`w-full flex-row justify-center`}>
                        <AddButton
                            disabled={!item.is_available}
                            quickAddCartItem={() =>
                                navigation.navigate('Menu Item', {
                                    instanceId,
                                    itemId: item.item_id,
                                })
                            }
                            count={count}
                            price={section.data[index].price}
                            item={section.data[index]}
                            isDark
                        />
                    </View>
                </View>
            </View>
        )
    else if (section.is_bdot_featured) return null

    return (
        <MenuItem
            isLast={index === section.data.length - 1}
            key={section.data[index].item_id}
            menuId={menuId}
            item={section.data[index]}
            section={section}
            index={index}
            sectionContentArr={sectionContentArr}
            navigation={navigation}
            instanceId={instanceId}
            instanceName={instanceName}
            cart={cart}
            deliveryType={deliveryType}
            isExampleMenu={isExampleMenu}
            showPickupDeliveryModal={showPickupDeliveryModal}
        />
    )
}

type MenuSectionGridProps = {
    navigation: BrandScreenProps['navigation']
    blockUpdateIndex: boolean
    setBlockUpdateIndex: React.Dispatch<React.SetStateAction<boolean>>
    focusedSectionIndex: number
    setFocusedSectionIndex: React.Dispatch<React.SetStateAction<number>>
    scrollHeader: React.RefObject<ScrollView>
    scrollHeaderParent: React.RefObject<View>
    scrollHeaderItems: React.MutableRefObject<(View | null)[]>
    sectionGridRef: React.RefObject<SectionList>
    menuSections: ExtendedMenuSection[]
    instanceId: string
    instanceName: string
    menuId: string
    additionalTopSpacing: number
    pageHeight: number
    viewWidth: number
    top: number
    bottom: number
    handleScroll: HandleScrollEventType
    isExampleMenu: boolean
    handleContentSizeChange: HandleContentChangeType
    showPickupDeliveryModal: () => void
    tabBarHeight: number
}

export const MenuSectionGrid = ({
    navigation,
    blockUpdateIndex,
    setBlockUpdateIndex,
    focusedSectionIndex,
    setFocusedSectionIndex,
    scrollHeader,
    scrollHeaderItems,
    sectionGridRef,
    menuSections,
    instanceId,
    instanceName,
    menuId,
    additionalTopSpacing,
    pageHeight,
    viewWidth,
    scrollHeaderParent,
    top,
    bottom,
    handleScroll,
    isExampleMenu,
    handleContentSizeChange,
    showPickupDeliveryModal,
    tabBarHeight,
}: MenuSectionGridProps) => {
    const sections = useRef<(View | null)[]>([])
    const sectionContentArr = useRef<(TouchableOpacity | null)[]>([])

    const { data: cart } = useCart()
    const deliveryType = useStore((state) => state.location.deliveryType)

    /**
     * Debounce callback
     * This is neccesary for web, as none of the scrollEnd methods word
     * https://github.com/necolas/react-native-web/issues/2249
     * Makes sure that after anyone has stopped scrolling for x ms
     * We mark the scroll as complete by unblocking update index
     */
    const debouncedSetBlockUpdateIndex = useDebouncedCallback(
        // function
        () => {
            setBlockUpdateIndex(false)
        },
        // delay in ms
        160
    )

    const throttledUpdateScrollHeaderOnScroll = useThrottledCallback(
        async () => {
            // get ypos of scrollheader
            const scrollHeaderEl = scrollHeaderParent.current
            if (!scrollHeaderEl) return
            const { py: scrollHeaderYPos } = await asyncMeasure(scrollHeaderEl)

            /**
             * Figure out which menu tab to scroll to based
             * on the ypos of the section and the scrollHeader
             */
            let idxToScrollTo = 0
            for (let i = 0; i < sections.current.length; i++) {
                if (!sections.current[i]) return
                const currentSection = sections.current[i] as View
                const { py: sectionYPos } = await asyncMeasure(currentSection)

                if (sectionYPos < scrollHeaderYPos) {
                    idxToScrollTo = i
                    continue
                }

                if (sectionYPos >= scrollHeaderYPos) {
                    const maxDistFromTop = 50
                    if (sectionYPos - scrollHeaderYPos < maxDistFromTop) {
                        idxToScrollTo = i
                    }
                    break
                }
            }

            if (idxToScrollTo !== focusedSectionIndex) {
                setFocusedSectionIndex(idxToScrollTo)
                const x = await getSectionX(idxToScrollTo, scrollHeaderItems)
                scrollHeader.current?.scrollTo({
                    x: x.fx - (tw.style('container').padding as number),
                    animated: true,
                })
            }
        },
        200
    )

    const handleMenuScroll = async (
        event: NativeSyntheticEvent<NativeScrollEvent>
    ) => {
        handleScroll(event)

        if (Platform.OS === 'web') {
            debouncedSetBlockUpdateIndex()
        }
        if (blockUpdateIndex) return

        // update the scroll view
        throttledUpdateScrollHeaderOnScroll()
    }

    return (
        <SectionList
            onScroll={handleMenuScroll}
            onContentSizeChange={handleContentSizeChange}
            scrollEventThrottle={16}
            testID="menu-grid-container"
            onMomentumScrollEnd={() => {
                setBlockUpdateIndex(false)
            }}
            onScrollEndDrag={() => {
                setBlockUpdateIndex(false)
            }}
            bounces={false}
            showsVerticalScrollIndicator={false}
            ref={sectionGridRef}
            stickySectionHeadersEnabled={false}
            sections={menuSections}
            contentContainerStyle={[
                tw`pt-[${
                    INITIAL_HEADER_HEIGHT +
                    tabBarHeight +
                    top +
                    additionalTopSpacing
                }px] pb-[${112 + bottom}px] bg-black`,
            ]}
            style={[tw`h-[${pageHeight}px] w-[${viewWidth}px]`]}
            keyExtractor={(item) => item.item_id}
            renderItem={(data) => {
                const { section, index } = data

                return (
                    <RenderItem
                        section={section}
                        index={index}
                        menuId={menuId}
                        sectionContentArr={sectionContentArr}
                        instanceId={instanceId}
                        instanceName={instanceName}
                        cart={cart}
                        deliveryType={deliveryType}
                        navigation={navigation}
                        isExampleMenu={isExampleMenu}
                        showPickupDeliveryModal={showPickupDeliveryModal}
                    />
                )
            }}
            renderSectionHeader={({ section }) => {
                return (
                    <View ref={(el) => (sections.current[section.index] = el)}>
                        <SectionTitle
                            title={section.title}
                            isFeatured={section.is_bdot_featured}
                        />
                    </View>
                )
            }}
        />
    )
}
