import React from 'react'

import { Platform, Text, View } from 'react-native'

import {
    BottomSheetModal as BSM,
    BottomSheetFlatList,
} from '@gorhom/bottom-sheet'

import { Order } from '@/types/Order'

import { BottomSheetModal } from '@/components/BottomSheetModal'

import { useProfile } from '@/hooks/query/useProfile'

import { sortOrders } from '@/utils/sortOrders'

import tw from '@/tailwind/tailwind'

import ModalHeader from '../../components/ModalHeader'
import { OrderScreen } from '../Order'
import { OrderCard } from './OrderCard'

const NUMBER_INITIAL_ORDERS = 5

export const OrderList = React.forwardRef<BSM>((_, ref) => {
    const orderModalRef = React.useRef<BSM>(null)
    const [orderId, setOrderId] = React.useState<string>()
    const { data: profile } = useProfile()
    const { orders = [] } = profile ?? {}
    const sortedOrders = sortOrders(orders)

    const onXPress = () => {
        ;(ref as React.MutableRefObject<BSM>).current?.dismiss()
    }

    const handleViewOrderPress = (order: Order) => {
        setOrderId(order.popchew_order_id)
        orderModalRef.current?.present()
    }

    return (
        <BottomSheetModal
            trackableName="orders"
            trackableCategory="Account"
            ref={ref}
            snapPoints={['90%']}
            handleComponent={null}
        >
            <ModalHeader
                trackableCloseButtonProps={{
                    name: 'Tapped X in past orders',
                    category: 'Account',
                    testID: 'close-orders-list-account',
                }}
                closeButtonProps={{
                    onPress: onXPress,
                }}
                title="Orders"
            />
            {orders.length === 0 ? (
                <View style={tw`flex justify-center items-center h-full`}>
                    <Text style={tw`font-ppa-b text-pc-shade-60 text-xl mb-32`}>
                        No orders yet
                    </Text>
                </View>
            ) : (
                <>
                    <BottomSheetFlatList
                        contentContainerStyle={{
                            paddingTop: 10,
                            paddingBottom: 50,
                            paddingLeft: 15,
                            paddingRight: 15,
                        }}
                        data={sortedOrders}
                        keyExtractor={(order) => order?.popchew_order_id!}
                        showsVerticalScrollIndicator={false}
                        // Eventually do this for web, but doesn't scroll well
                        {...(Platform.OS !== 'web' && {
                            initialNumToRender: NUMBER_INITIAL_ORDERS,
                            windowSize: 1.5,
                            maxToRenderPerBatch: 6,
                        })}
                        renderItem={({ item }: { item: Order }) => {
                            return (
                                <OrderCard
                                    item={item}
                                    onViewOrderPress={handleViewOrderPress}
                                />
                            )
                        }}
                    />
                    <OrderScreen orderId={orderId} ref={orderModalRef} />
                </>
            )}
        </BottomSheetModal>
    )
})
