import { axios } from '@/lib/httpClient'
import { sendOneSignalOutcome } from '@/lib/onesignal'
import { queryClient } from '@/lib/queryClient'

import storage from '@/utils/storage'

import { AuthorizeResponse } from '../types'

export interface AuthorizeData {
    phone: string
    code: string
    referral_code?: string
}

export const authorize = async (data: AuthorizeData) => {
    const path = '/order/auth/code'

    try {
        const response = await axios.post<AuthorizeResponse>(path, data)
        sendOneSignalOutcome('User logged in')
        // Set access/refresh tokens in storage
        await storage.setAccessToken(response.data.access_token)
        await storage.setRefreshToken(response.data.refresh_token)

        // clear all queries, ideally we just clear the ones that are related to the user
        // use resetQuries instead of clear so it triggers a rerender
        queryClient.resetQueries()

        return response.data
    } catch (e) {
        throw e
    }
}
