/**
 * apps with same bundle id share secure storage, this is
 * a problem because if you switch between adhoc, testflight, prod etc
 * and switch API endpoints some resources that we save
 * to secure storage might not exist in different api/db
 * also clears access/refresh from keychain
 */
import { REACT_APP_API_URL } from '@env'

import storage, { clearAllStorage } from './storage'

export const clearStorageIfEnvChange = async () => {
    const prevApi = await storage.getPreviousAPI()
    if (prevApi && prevApi !== REACT_APP_API_URL) {
        await clearAllStorage()
    }

    await storage.setPreviousAPI(REACT_APP_API_URL)
}
