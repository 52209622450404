import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const LightningSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={10}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color ?? '#000'}
        {...props}
    >
        <Path
            d="M9.087 4.805 3.407 11.5l1.136-4.304H0L5.68.5 4.542 4.804h4.544v.001Z"
            fill="#000"
        />
    </Svg>
)

export default LightningSvg
