import { Text, View } from 'react-native'

import { GeneralProps } from '@/navigation/types/ScreenProps'
import { useNavigation } from '@react-navigation/native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { TrackableClick } from '@/components/Analytics'
import { BrandLogoThumbnail } from '@/components/BrandLogoThumbnail'

import tw from '@/tailwind/tailwind'

export const SocialFeedItemIcon = ({
    brandId,
    brandName,
    first,
    last,
    profileImage,
}: {
    brandId: string
    brandName: string
    first: string
    last: string
    profileImage: string
}) => {
    const navigation = useNavigation<GeneralProps['navigation']>()

    return (
        <TrackableClick
            name={'Tapped brand image in order feed'}
            category={'Social Feed'}
            properties={{ brandId, brandName }}
        >
            <TouchableOpacity
                style={tw`relative h-8 w-16`}
                onPress={() => navigation.navigate('BrandPage', { brandId })}
            >
                <BrandLogoThumbnail brandId={brandId} />
                <View
                    style={tw`left-9 absolute -top-1 rounded-full w-7 h-7 bg-pc-secondary border-2 border-white overflow-hidden`}
                >
                    <Text
                        style={tw`m-auto text-white font-ppa-b uppercase text-xs`}
                    >
                        {first && first[0] && last ? first[0] + last : '??'}
                    </Text>
                </View>
            </TouchableOpacity>
        </TrackableClick>
    )
}
