import { View } from 'react-native'

import { CartNotification } from '@/components/CartNotification'
import { BackButton } from '@/components/Titles/BackButton'

import tw from '@/tailwind/tailwind'

export const Navbar = ({ brandName }: { brandName: string }) => {
    return (
        <View style={tw`flex flex-row items-center justify-between  container`}>
            <View style={tw` shrink`}>
                <BackButton
                    fallback="HomeTabs"
                    forceFallbackIfPreviousPageIn={['OrderPlaced']}
                    trackableProperties={{
                        'brand name': brandName,
                    }}
                    title={brandName}
                    legacyTrackableName="Tapped back arrow in menu navbar"
                />
            </View>

            <View style={tw`ml-4`}>
                <CartNotification />
            </View>
        </View>
    )
}
