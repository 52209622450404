import { Platform } from 'react-native'

import { CaptureConsole } from '@sentry/integrations'
import Ax, { AxiosError } from 'axios'
import * as Sentry from 'sentry-expo'

export const enableAppMonitoring = () => {
    Sentry.init({
        dsn: 'https://98cc4a2fb9294115a1635e62fbfaa481@o1277403.ingest.sentry.io/6474889',
        enableInExpoDevelopment: true,
        integrations: [new CaptureConsole({ levels: ['error'] }) as any],
        environment: process.env.NODE_ENV,
        debug: false,
    })
}

const redactSensitiveInfoFromError = (error: AxiosError) => {
    const requestBody = error.config.data
    if (!requestBody) return
    if (requestBody['password']) requestBody['password'] = 'REDACTED'
    if (requestBody['card_number']) requestBody['card_number'] = 'REDACTED'
    if (requestBody['cvv']) requestBody['cvv'] = 'REDACTED'
}

export const catchAndReportError = ({
    error,
    additionalInfo,
}: {
    error: Error
    additionalInfo?: string
}) => {
    if (additionalInfo) error.message = additionalInfo

    if (Ax.isAxiosError(error)) {
        const e = error as AxiosError
        redactSensitiveInfoFromError(e)

        const addBreadcrumb =
            Platform.OS === 'web'
                ? Sentry.Browser.addBreadcrumb
                : Sentry.Native.addBreadcrumb

        addBreadcrumb({
            data: { requestBody: e.config.data },
        })
    }

    const captureException =
        Platform.OS === 'web'
            ? Sentry.Browser.captureException
            : Sentry.Native.captureException

    captureException(error)
}
