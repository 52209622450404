import { Text, TouchableOpacity, View } from 'react-native'

import openMap from 'react-native-open-maps'

import { Instance } from '@/types/Instance'
import { OrderDetailResponse } from '@/types/Order'

import tw from '@/tailwind/tailwind'

export const PickupDetails = ({
    order,
    instance,
}: {
    order: OrderDetailResponse
    instance: Instance
}) => {
    const { created_on } = order

    const pickupISO = order.pickup_time! // will never be null on pickup

    const pickupTime = new Date(pickupISO).toLocaleString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    })

    const store = instance?.store
    const address = store?.address
    const storeAddress = `${address?.address1}, ${
        address?.address2 ? address.address2 + ', ' : ''
    }${address?.city}, ${address?.state} ${address?.zip}`

    const pickupInstructions = instance?.customer_pickup_instructions

    const orderCreatedOn = new Date(created_on)
    const ONE_HOUR = 60 * 60 * 1000
    const isOrderPlacedWithinPast12Hours =
        new Date().getTime() - orderCreatedOn.getTime() < 12 * ONE_HOUR

    const isPickupDetailsRelevant = isOrderPlacedWithinPast12Hours

    if (!isPickupDetailsRelevant) return null

    return (
        <View style={tw`mt-4`}>
            <View>
                <Text style={tw`font-ppa-b text-pc-shade-80 text-base`}>
                    Pickup at
                </Text>
                <Text style={tw`font-ppa-b  text-base`} selectable>
                    {pickupTime}
                </Text>
            </View>

            <View style={tw`mt-8`}>
                <Text style={tw`font-ppa-b text-pc-shade-80 text-base`}>
                    Pickup from
                </Text>
                <TouchableOpacity
                    onPress={() =>
                        openMap({
                            query: storeAddress,
                        })
                    }
                >
                    <Text style={tw`font-ppa-b text-base underline`} selectable>
                        {storeAddress}
                    </Text>
                </TouchableOpacity>
            </View>

            {pickupInstructions && (
                <View style={tw`mt-8`}>
                    <Text style={tw`font-ppa-b text-pc-shade-80 text-base`}>
                        Pickup instructions
                    </Text>
                    <Text style={tw`font-ppa-b  text-base`} selectable>
                        {pickupInstructions}
                    </Text>
                </View>
            )}
        </View>
    )
}
