import { useEffect, useState } from 'react'

import {
    ActivityIndicator,
    KeyboardAvoidingView,
    ScrollView,
    View,
} from 'react-native'

import { useNavigateBack } from '@/navigation/hooks/useNavigateBack'
import { ReviewOrderScreenProps } from '@/navigation/types/ScreenProps'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@/components/Button'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { NoOrders } from '@/components/NoOrders'

import { useOrderDetails } from '@/hooks/query/useOrder'

import tw from '@/tailwind/tailwind'

import { postReview } from './api/postReview'
import { AddComment } from './components/AddComment'
import { HowWasText } from './components/HowWasText'
import { ReviewOrderHeader } from './components/ReviewOrderHeader'
import { Tags } from './components/Tags'
import { VoteButtons } from './components/VoteButtons'
import { Review, VoteButtonStatusType } from './types'

export const ReviewOrder = ({ navigation, route }: ReviewOrderScreenProps) => {
    const { orderId } = route.params || {}

    const { data: order, isLoading: isOrderLoading } = useOrderDetails(orderId)
    const navigateBack = useNavigateBack()
    const [selectedVote, setSelectedVote] = useState<VoteButtonStatusType>()
    const [selectedTags, setSelectedTags] = useState<{
        [key: string]: boolean
    }>({})
    const [comment, setComment] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!orderId) navigateBack('HomeTabs')
    }, [orderId])

    // Clear comment if vote changes
    useEffect(() => {
        setComment('')
    }, [selectedVote])

    if (isOrderLoading) return <FullScreenLoader />
    if (!order) return <NoOrders />

    const instanceName = order.cart.instance_name

    const submitReview = async () => {
        if (selectedVote === undefined) return

        const isPositive = selectedVote === 'like'

        const review: Review = {
            popchew_order_id: orderId,
            is_positive: isPositive,
            tags: Object.keys(selectedTags).filter((tag) => selectedTags[tag]),
        }

        if (comment) review.comment = comment

        try {
            setIsLoading(true)
            const response = await postReview(review)

            // navigate to other page with points
            navigation.navigate('Review Order Success', {
                pointsEarned: response.points_earned || 0,
                isPositive,
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <KeyboardAvoidingView style={tw`flex-1`} behavior="padding">
            <ScrollView
                style={tw`flex-1 h-full `}
                contentContainerStyle={{ flexGrow: 1 }}
            >
                <SafeAreaView style={tw`h-full flex-1 `}>
                    <ReviewOrderHeader title="Review Order" />
                    <HowWasText instanceName={instanceName} />

                    <VoteButtons
                        selectedVote={selectedVote}
                        setSelectedVote={setSelectedVote}
                    />

                    {selectedVote && (
                        <>
                            <Tags
                                selectedVote={selectedVote}
                                selectedTags={selectedTags}
                                setSelectedTags={setSelectedTags}
                            />
                            <AddComment
                                selectedVote={selectedVote}
                                comment={comment}
                                setComment={setComment}
                            />
                            <View style={tw`container`}>
                                {isLoading ? (
                                    <View style={tw`h-[60px]`}>
                                        <ActivityIndicator
                                            style={tw`my-auto`}
                                        />
                                    </View>
                                ) : (
                                    <Button
                                        variant="primary"
                                        text="Submit"
                                        onPress={submitReview}
                                        trackableCategory="Review"
                                        trackableName="Submitted review"
                                    />
                                )}
                            </View>
                        </>
                    )}
                </SafeAreaView>
            </ScrollView>
        </KeyboardAvoidingView>
    )
}
