import React, { LegacyRef } from 'react'

import { Text, View } from 'react-native'

import { MessageComponentProps } from 'react-native-flash-message'
import { SafeAreaView } from 'react-native-safe-area-context'

import tw from '@/tailwind/tailwind'

import CheckCircleSvg from '@/svgs/CheckCircleSvg'

const iconDict: { [key: string]: JSX.Element } = {
    info: <CheckCircleSvg color="#fff" />,
}

const bgDict: { [key: string]: string } = {
    info: 'bg-black',
    warning: 'bg-pc-warning',
    none: 'bg-pc-primary',
}

const textColorDict: { [key: string]: string } = {
    info: 'text-white',
    warning: 'text-white',
    none: 'text-black',
}

export const Notification = React.forwardRef(
    (props: MessageComponentProps, ref) => {
        const { message } = props
        const { message: messageText, type } = message

        return (
            <SafeAreaView style={tw`flex-1 px-container`} pointerEvents="none">
                <View
                    style={tw`py-4 px-8 rounded-full ${
                        type && bgDict[type] ? bgDict[type] : 'bg-black'
                    } self-center  items-center flex-row mt-4`}
                >
                    {type && iconDict[type] ? (
                        <View style={tw`mr-3`}>{iconDict['info']}</View>
                    ) : null}
                    <Text
                        style={tw`font-ppa-b text-base ${
                            type && textColorDict[type]
                                ? textColorDict[type]
                                : 'text-white'
                        }`}
                        ref={ref as LegacyRef<Text>}
                    >
                        {messageText}
                    </Text>
                </View>
            </SafeAreaView>
        )
    }
)
