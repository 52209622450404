import { Image, Text, View } from 'react-native'

import { titleCase } from '@/utils/text'

import tw from '@/tailwind/tailwind'

import CardSvg from '@/svgs/CardSvg'
import ChevronRightSvg from '@/svgs/ChevronRightSvg'

import { HiddenPaymentMethod, PaymentMethodNetwork } from '../types'
import { cardIcons } from './cardIcons'

export const CardDetails = ({
    paymentMethod,
    showChevron,
}: {
    paymentMethod: HiddenPaymentMethod | undefined | null
    showChevron?: boolean
}) => {
    const { card_number, name } = paymentMethod ?? {}
    let { network } = paymentMethod ?? {}
    if (network) {
        network = network.toLowerCase() as PaymentMethodNetwork
    }

    const last4 = card_number ? card_number.slice(card_number.length - 4) : null

    const description = last4
        ? `${titleCase(network ?? 'Card')} ending in ${last4}`
        : ''

    return (
        <View style={tw`flex-1 flex-row items-center`}>
            <View style={tw`mr-4`}>
                {network && cardIcons[network] ? (
                    <View style={tw`w-6 h-6 overflow-hidden rounded-sm`}>
                        <Image
                            style={tw`w-full h-full`}
                            resizeMode="contain"
                            source={cardIcons[network]}
                        />
                    </View>
                ) : (
                    <CardSvg />
                )}
            </View>
            <View style={tw`flex-1 shrink`}>
                <Text
                    style={tw`font-ppp-b text-base text-white leading-normal`}
                >
                    {!paymentMethod
                        ? 'Add credit or debit card'
                        : name ?? description}
                </Text>
                {description && name && (
                    <Text style={tw`font-ppp-m text-sm text-white`}>
                        {description}
                    </Text>
                )}
            </View>
            {showChevron && (
                <View style={tw`ml-3`}>
                    <ChevronRightSvg color="white" />
                </View>
            )}
        </View>
    )
}
