import { ListRenderItemInfo, Switch, Text, View } from 'react-native'

import tw from '@/tailwind/tailwind'

import { NotificationSettingCardProps } from '.'
import { PrefsToChange } from './useNotificationPreferences'

export const NotificationSettingCard = ({
    item,
    updatePreferences,
}: {
    item: ListRenderItemInfo<NotificationSettingCardProps>['item']
    updatePreferences: (prefsToChange: PrefsToChange) => void
}) => {
    return (
        <View
            style={tw`flex-row justify-between items-center py-4 border-b border-pc-shade-40`}
        >
            <View style={tw`flex-row items-center flex-1`}>
                <View style={tw`ml-4`}>
                    <Text style={tw`ml-4 font-ppa-b text-lg text-black`}>
                        {item.title}
                    </Text>
                    <Text style={tw`ml-4 font-ppa-b text-sm text-pc-shade-80`}>
                        {item.subtitle}
                    </Text>
                </View>
            </View>
            <View style={tw`ml-3`}>
                <Switch
                    trackColor={{
                        false: tw.color('bg-pc-shade-80'),
                        true: tw.color('bg-pc-success'),
                    }}
                    thumbColor="white"
                    ios_backgroundColor={tw.color('bg-pc-shade-80')}
                    onValueChange={() =>
                        updatePreferences({
                            category: item.category,
                            value: !item.value,
                            name: item.name,
                        })
                    }
                    value={item.value}
                />
            </View>
        </View>
    )
}
