import { Text } from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'

import tw from '@/tailwind/tailwind'

import { TrackableRender, TrackingCategory } from '../Analytics'
import { Button } from '../Button'

type Missing404props = {
    mainText: string
    buttonText: string
    trackableCategory: TrackingCategory
    renderTrackableName: string
    buttonTrackableName: string
    buttonOnPress: () => void
}

export const Missing404 = ({
    mainText,
    buttonText,
    trackableCategory: category,
    renderTrackableName,
    buttonTrackableName,
    buttonOnPress,
}: Missing404props) => {
    return (
        <TrackableRender name={renderTrackableName} category={category}>
            <SafeAreaView
                style={tw`px-container relative flex-col justify-center flex-1 py-16`}
            >
                <Text style={tw`text-center text-5xl mb-8`}>👀</Text>
                <Text style={tw`text-center text-base font-ppa-b mb-8`}>
                    {mainText}
                </Text>
                <Button
                    variant="primary"
                    text={buttonText}
                    trackableCategory={category}
                    trackableName={buttonTrackableName}
                    onPress={buttonOnPress}
                />
            </SafeAreaView>
        </TrackableRender>
    )
}
