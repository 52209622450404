import { useMemo, useState } from 'react'

import { Platform, Text, TouchableOpacity, View } from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'

import { ingestWebsiteJson } from '@/features/Auth/api/ingest'

import { postTrackingEvent } from '@/components/Analytics'
import { BottomSheetTextInput } from '@/components/BottomSheetModal/BottomSheetTextInput'
import { ModalX } from '@/components/Titles/ModalX'

import { validateEmail, validateZip } from '@/utils/validators'

import tw from '@/tailwind/tailwind'

import CheckSvg from '@/svgs/CheckSvg'

export const NotifyBrandsModal = ({
    closeModal,
}: {
    closeModal: () => void
}) => {
    const [email, setEmail] = useState('')
    const [zip, setZip] = useState('')
    const [errorMsg, setErrorMsg] = useState('')

    const isEmailValid = useMemo(() => validateEmail(email), [email])
    const isZipValid = useMemo(() => validateZip(zip), [zip])
    const isDisabled = useMemo(() => !isEmailValid || !isZipValid, [email, zip])

    const onXPress = () => {
        closeModal()
    }

    const onCheckPress = async () => {
        postTrackingEvent({
            name: 'Tapped checkmark on notify me modal',
            category: 'Out of Range',
            onesignal: true,
        })
        if (!isDisabled) {
            setErrorMsg('')
            try {
                // Send data to api - note, could await this, I chose not to for faster interface
                await ingestWebsiteJson({
                    email,
                    zip,
                })
            } catch {
            } finally {
                closeModal()
            }
        } else {
            if (!isEmailValid && !isZipValid)
                setErrorMsg('Email and zip are invalid')
            else if (!isEmailValid) setErrorMsg('Email is invalid')
            else if (!isZipValid) setErrorMsg('Zip is invalid')
        }
    }

    return (
        <SafeAreaView edges={['bottom']} style={tw`flex-1 `}>
            <View style={tw`container flex-1`}>
                <View style={tw`mt-7 mb-3`}>
                    <View style={tw`relative flex-row items-center`}>
                        <ModalX
                            onPress={onXPress}
                            name="Tapped X on notify me screen"
                            category="Out of Range"
                            onesignal
                        />

                        <Text style={tw`ml-4 font-ppa-b text-xl text-center`}>
                            Notify Me
                        </Text>
                    </View>

                    <Text style={tw`flex-row text-2xl font-ppa-wbi mr-20 mt-7`}>
                        Notify me when available
                    </Text>
                </View>
                <View style={tw``}>
                    <View style={tw`mb-1`}>
                        <Text style={tw`label`}>Email</Text>
                        <BottomSheetTextInput
                            style={tw`input border-2 ${
                                errorMsg && !isEmailValid
                                    ? 'border-pc-warning'
                                    : 'border-white'
                            }`}
                            value={email}
                            onChangeText={(value) => setEmail(value)}
                            autoCompleteType="email"
                            textContentType="emailAddress"
                            onSubmitEditing={onCheckPress}
                            autoFocus={Platform.OS !== 'web'}
                        />
                    </View>
                    <View style={tw`mb-1`}>
                        <Text style={tw`label`}>Zip</Text>
                        <BottomSheetTextInput
                            style={tw`input border-2 ${
                                errorMsg && !isZipValid
                                    ? 'border-pc-warning'
                                    : 'border-white'
                            }`}
                            value={zip}
                            onChangeText={(value) => setZip(value)}
                            autoCompleteType={'postal-code'}
                            textContentType={'postalCode'}
                            maxLength={5}
                            keyboardType="numeric"
                            onSubmitEditing={onCheckPress}
                        />
                    </View>
                    <Text
                        style={tw`absolute bottom-12 text-pc-warning mt-5 font-ppa-b text-xs`}
                    >
                        {errorMsg}
                    </Text>
                    <View style={tw`items-end`}>
                        <TouchableOpacity
                            style={tw`h-12 w-12 mt-7 flex justify-center
                                 items-center bg-black rounded-full border-2 border-black`}
                            onPress={onCheckPress}
                        >
                            <CheckSvg />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </SafeAreaView>
    )
}
