import {
    Platform,
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'

import * as Haptics from 'expo-haptics'

import { Menu, MenuSection } from '@/types/Menu'

import { TrackableClick } from '@/components/Analytics'

import tw from '@/tailwind/tailwind'

import { getSectionX } from '../utils/getSectionX'

interface TabItemProps {
    idx: number
    focusedSectionIndex: number
    handleTabClick: (_: number) => void
    numOfSection: number
    menuSection: MenuSection
    scrollHeaderItems: React.MutableRefObject<(View | null)[]>
}

const TabItem = (props: TabItemProps) => {
    const {
        idx,
        focusedSectionIndex,
        handleTabClick,
        numOfSection,
        menuSection,
        scrollHeaderItems,
    } = props

    return (
        <View
            key={idx}
            style={tw`border-b-3 border-grey-800 `}
            ref={(el) => (scrollHeaderItems.current[idx] = el)}
        >
            <View
                style={tw`${idx === numOfSection - 1 ? 'mr-4' : 'mr-6'} ${
                    idx === 0 ? 'ml-4' : ''
                }`}
            >
                <TrackableClick
                    name="Tapped menu navigation"
                    category="Order"
                    properties={{
                        'section name': menuSection.name,
                        'section id': menuSection.section_id,
                        'section number': numOfSection,
                        'section index': focusedSectionIndex,
                        idx,
                    }}
                    onesignal
                    onesignalValue={menuSection.name}
                >
                    <TouchableOpacity
                        hitSlop={{ top: 6, right: 6, bottom: 2, left: 6 }}
                        activeOpacity={1}
                        onPress={() => handleTabClick(idx)}
                    >
                        <Text
                            style={tw`py-3 text-base ${
                                idx === focusedSectionIndex
                                    ? ' text-white'
                                    : 'text-grey-400'
                            }  font-ppp-b  `}
                        >
                            {menuSection.name}
                        </Text>
                    </TouchableOpacity>
                </TrackableClick>
                <View
                    style={tw` border-b-3 w-full absolute top-full z-10 ${
                        idx === focusedSectionIndex
                            ? ' border-pc-primary'
                            : 'border-transparent'
                    } `}
                />
            </View>
        </View>
    )
}

interface TabBarProps {
    menuSections: Menu['sections']
    sectionGridRef: React.RefObject<any>
    scrollHeader: React.RefObject<ScrollView>
    scrollHeaderParent: React.RefObject<View>
    scrollHeaderItems: React.MutableRefObject<(View | null)[]>
    focusedSectionIndex: number
    setBlockUpdateIndex: (_: boolean) => void
    setFocusedSectionIndex: (_: number) => void
    top: number
}

export const TabBar = (props: TabBarProps) => {
    const {
        menuSections,
        sectionGridRef,
        scrollHeader,
        scrollHeaderItems,
        focusedSectionIndex,
        setBlockUpdateIndex,
        setFocusedSectionIndex,
        top,
        scrollHeaderParent,
    } = props

    const handleTabClick = async (idx: number) => {
        if (idx !== focusedSectionIndex) {
            setBlockUpdateIndex(true)
            setFocusedSectionIndex(idx)
            const x = await getSectionX(idx, scrollHeaderItems)
            scrollHeader.current?.scrollTo({
                x: x.fx - 16,
                animated: true,
            })
            sectionGridRef.current?.scrollToLocation({
                animated: true,
                sectionIndex: idx,
                itemIndex: 0,
                viewOffset: 160 + 16 + top,
            })
        }

        if (Platform.OS !== 'web') {
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)
        }
    }

    return (
        <View ref={scrollHeaderParent}>
            <ScrollView
                ref={scrollHeader}
                horizontal
                showsHorizontalScrollIndicator={false}
                style={tw`w-full p-0 flex-grow-0  bg-black`}
            >
                {menuSections.map((section, idx: number) => (
                    <TabItem
                        key={idx}
                        idx={idx}
                        focusedSectionIndex={focusedSectionIndex}
                        handleTabClick={handleTabClick}
                        numOfSection={menuSections.length}
                        menuSection={section}
                        scrollHeaderItems={scrollHeaderItems}
                    />
                ))}
            </ScrollView>
        </View>
    )
}
