import React from 'react'

import { Image, TouchableOpacity, View } from 'react-native'

import { navigateUsingRef } from '@/navigation/navigateUsingRef'
import { BrandsProps } from '@/navigation/types/ScreenProps'

import { Address, DeliveryType } from '@/types/Address'

import { TrackableClick } from '@/components/Analytics'
import { Button } from '@/components/Button'
import { CartNotification } from '@/components/CartNotification'
import { NavbarContainer } from '@/components/NavbarContainer'

import { useProfile } from '@/hooks/query/useProfile'

import { useAccountModal } from '@/contexts/AccountModalContext'

import { getRandomProfileImage } from '@/utils/randomImage'
import { capitalizeFirstLetter } from '@/utils/text'

import tw from '@/tailwind/tailwind'

import ChevronDownSvg from '@/svgs/ChevronDownSvg'

export const Navbar = ({
    navigation,
    deliveryType,
    address,
    isDarkMode = false,
}: {
    navigation: BrandsProps['navigation']
    address: Address | null
    deliveryType: DeliveryType
    isDarkMode?: boolean
}) => {
    const { data: profile } = useProfile()

    const { show: showAccountModal } = useAccountModal()

    const addressName = address?.name || address?.address1

    const onProfilePicPress = () => {
        if (profile) {
            showAccountModal()
            return
        }

        navigateUsingRef('Login')
    }

    return (
        <NavbarContainer>
            <View style={tw`flex-1 items-start`}>
                <TrackableClick
                    name="Tapped profile picture in navbar"
                    category="Account"
                    testID="navbar-image-profile"
                >
                    <TouchableOpacity
                        onPress={onProfilePicPress}
                        hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
                    >
                        <Image
                            source={
                                profile?.profile_image_url
                                    ? profile?.profile_image_url
                                    : getRandomProfileImage()
                            }
                            style={tw`h-8 w-8 rounded-full`}
                        />
                    </TouchableOpacity>
                </TrackableClick>
            </View>
            <View style={tw`flex-3`}>
                <Button
                    variant="secondary"
                    size="sm"
                    text={
                        addressName
                            ? `${capitalizeFirstLetter(
                                  deliveryType
                              )} - ${capitalizeFirstLetter(addressName)}`
                            : 'Enter address'
                    }
                    onPress={() => {
                        navigation.navigate('Location Picker')
                    }}
                    trackableName="Tapped address pill in brands navbar"
                    trackableCategory="Address"
                    icon={ChevronDownSvg}
                />
            </View>

            <View style={tw`ml-4 flex-1 items-end`}>
                <CartNotification isDarkMode={isDarkMode} />
            </View>
        </NavbarContainer>
    )
}
