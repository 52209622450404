import { useState } from 'react'

import { Text, View } from 'react-native'

import { useNavigateBack } from '@/navigation/hooks/useNavigateBack'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@/components/Button'

import { isConnectedToNetwork } from '@/utils/network'

import tw from '@/tailwind/tailwind'

import NoInternetSVG from '@/svgs/NoInternetSvg'

// This is a screen to show whenever there is no internet connection
// Can be used for a failed api call, no internet on app loading, etc.

const NoInternet = () => {
    // state variable for the message text below "Whoops"
    const [messageText, setMessageText] = useState(
        'No internet connection found.\nPlease check your internet settings.'
    )

    // When reload button is clicked, check connection and navigate back if connected
    // If not connected, change message text
    const navigateBack = useNavigateBack()
    const navigateBackIfConnected = async () => {
        const isConnected = await isConnectedToNetwork()
        if (isConnected) {
            navigateBack('HomeTabs')
        } else
            setMessageText(
                'Still no internet connection found.\nPlease check your internet settings.'
            )
    }

    return (
        <SafeAreaView style={tw`flex min-h-full`}>
            <View
                style={tw`container flex items-center justify-center min-h-full`}
            >
                <NoInternetSVG color={tw.color('pc-shade-40')} />
                <Text style={tw`font-ppa-wbi text-2xl text-center`}>
                    Whoops!
                </Text>
                <Text style={tw`font-ppa-b text-pc-shade-80 mt-5 text-center`}>
                    {messageText}
                </Text>

                <View style={tw`mt-6`}>
                    <Button
                        variant="primary"
                        onPress={navigateBackIfConnected}
                        text="Reload"
                        trackableName="Tapped Reload on No Internet"
                        trackableCategory="General"
                    />
                </View>
            </View>
        </SafeAreaView>
    )
}

export { NoInternet }
