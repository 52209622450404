import { Text, TouchableOpacity, View } from 'react-native'

import { BrandScreenProps } from '@/navigation/types/ScreenProps'
import { LocationPickerProps } from '@/navigation/types/ScreenProps'

import { Address } from '@/types/Address'

import { TrackableClick } from '@/components/Analytics'

import tw from '@/tailwind/tailwind'

import FlagSvg from '@/svgs/FlagSvg'
import PencilSvg from '@/svgs/PencilSvg'

export const AddressItem = (props: {
    address: Address
    navigation:
        | LocationPickerProps['navigation']
        | BrandScreenProps['navigation']
    isSelected: boolean
    setSelectedLocation: (address: Address) => Promise<void>
    setTempLocation: (address: Address) => void
}) => {
    const {
        address,
        navigation,
        isSelected,
        setSelectedLocation,
        setTempLocation,
    } = props

    const { address1, address2, name, city, state } = address
    const mainText = name
        ? name
        : `${address1}${address2 ? ' ' + address2 : ''}`

    const subText = name
        ? `${address1}${address2 ? ' ' + address2 : ''}, ${city}, ${state}`
        : `${city}, ${state}`

    return (
        <TrackableClick
            name="Selected an address from address list"
            category="Address"
            onesignal
        >
            {/* // WHEN THE ENTIRE ADDRESS ITEM IS CLICKED, SET SELECTED LOCATION TO THIS ADDRESS */}
            <TouchableOpacity
                style={tw`py-5 container flex-row items-center justify-between  ${
                    isSelected ? 'bg-pc-secondary/10' : ''
                }`}
                onPress={async () => {
                    if (!isSelected) await setSelectedLocation(address)
                }}
            >
                <View style={tw`flex-row items-center `}>
                    <FlagSvg />
                    <View style={tw`ml-4`}>
                        <Text style={tw`font-ppa-b text-base `}>
                            {mainText}
                        </Text>
                        <Text style={tw`text-pc-shade-80 font-ppa-b`}>
                            {subText.length > 30
                                ? `${subText.substring(0, 30)}...`
                                : subText}
                        </Text>
                    </View>
                </View>

                <TrackableClick
                    name="Tapped edit address button"
                    category="Address"
                >
                    <TouchableOpacity
                        style={tw`p-1 absolute right-4 top-7`}
                        onPress={() => {
                            setTempLocation(address)
                            navigation.navigate('Edit Location', {
                                isEdit: true,
                            })
                        }}
                    >
                        <PencilSvg color={tw.color('pc-shade-80')} />
                    </TouchableOpacity>
                </TrackableClick>
            </TouchableOpacity>
        </TrackableClick>
    )
}
