import { Text, View } from 'react-native'

import { Item } from '@/types/Menu'

import { toPrice } from '@/utils/normalizers'

import tw from '@/tailwind/tailwind'

export const MenuItemInformation = ({ item }: { item: Item }) => {
    const { name, price, description } = item
    return (
        <View style={tw`mb-7 container `}>
            <Text style={tw`text-xl font-ppa-wbi`}>{name}</Text>
            <Text style={tw`text-lg font-ppa-b mt-2`}>{toPrice(price)}</Text>
            <Text style={tw`text-pc-shade-80 text-sm font-ppa-b mt-2`}>
                {description}
            </Text>
        </View>
    )
}
