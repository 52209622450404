import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'
import SwitchSelector from 'react-native-switch-selector'

import { Toggle } from '@/components/Toggle'

import { toPrice } from '@/utils/normalizers'

import { CustomTipModal } from './CustomTipModal'

type TipSelectorProps = {
    setTip: React.Dispatch<React.SetStateAction<number>>
    subtotal: number
    couponValue: number
}

export const TipSelector = ({
    setTip,
    subtotal,
    couponValue,
}: TipSelectorProps) => {
    const subtotalAfterCoupon = subtotal + couponValue
    const customTipBottomSheetRef = useRef<BottomSheetModalMethods>(null)
    const toggleRef = useRef<SwitchSelector>(null)
    const [customTip, setCustomTip] = useState<number | null>(null)
    const [localTip, setLocalTip] = useState(0)
    const defaultTipIdx = 1

    const options = useMemo(() => {
        return [
            {
                label: '10%',
                value: '0.1',
            },
            {
                label: '15%',
                value: '0.15',
            },
            {
                label: '20%',
                value: '0.2',
            },
            {
                // if custom tip is not null (can be 0) set to custom tip else set to Custom
                label: customTip !== null ? `${toPrice(customTip)}` : 'Custom',
                value: 'custom',
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customTip, localTip])

    const [previousTipSelectedIdx, setPreviousTipSelectedIdx] =
        useState<number>(defaultTipIdx) // used to return to previous tip if custom tip is selected but not set

    const updateTip = useCallback(
        (tip: number, toggleValue: string) => {
            setTip(tip)
            setLocalTip(tip)

            const prevInx = options.findIndex((o) => o.value === toggleValue)
            setPreviousTipSelectedIdx(prevInx)
        },
        [setTip, options]
    )

    // handle default
    useEffect(() => {
        handleClick(options[defaultTipIdx].value)
    }, [])

    const handleClick = (clickValue: string) => {
        if (clickValue === 'custom') {
            // handle custom tip
            // open custom tip modal
            if (customTip !== null) updateTip(customTip, 'custom')
            customTipBottomSheetRef.current?.present()
            return
        }
        const tip = subtotalAfterCoupon * Number(clickValue)
        updateTip(tip, clickValue)
    }

    const selectedState = toggleRef.current?.state.selected

    // Handle subtotal changes and custom tip changes
    useEffect(() => {
        if (selectedState === undefined) return
        if (selectedState === options.findIndex((o) => o.value === 'custom')) {
            // handle custom tip
            if (customTip !== null) updateTip(customTip, 'custom')
            return
        }

        const tip = subtotalAfterCoupon * Number(options[selectedState].value)
        updateTip(tip, options[selectedState].value)
    }, [subtotalAfterCoupon, customTip])

    return (
        <>
            <Toggle
                toggleProps={{
                    options,
                    initial: defaultTipIdx,
                    onPress: handleClick,
                }}
                trackableName="Toggled tip selector"
                trackableCategory="Checkout"
                ref={toggleRef}
            />
            <CustomTipModal
                bsmRef={customTipBottomSheetRef}
                setCustomTip={setCustomTip}
                previousTipSelectedIdx={previousTipSelectedIdx}
                toggleRef={toggleRef}
            />
        </>
    )
}
