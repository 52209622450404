import { useRef } from 'react'

import { Text, View } from 'react-native'

import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@/components/Button'
import { ModalX } from '@/components/Titles/ModalX'

import tw from '@/tailwind/tailwind'

import LargeCheckSvg from '@/svgs/LargeCheck'

export const PrizeRedeemedModal = ({
    prizeRedeemedModal,
}: {
    prizeRedeemedModal: React.RefObject<BottomSheetModalMethods>
}) => {
    const modal = useRef(prizeRedeemedModal.current)
    const closeModal = () => modal.current?.close()

    return (
        <SafeAreaView edges={['bottom']} style={tw`flex-1 container `}>
            <View style={tw`flex-row justify-end`}>
                <ModalX
                    onPress={() => closeModal()}
                    category="Rewards"
                    name="Tapped x on prize redeemed modal success"
                />
            </View>
            <View style={tw`flex-1 py-8`}>
                <View style={tw`flex-1 justify-center items-center`}>
                    <LargeCheckSvg />
                </View>
            </View>

            <Text style={tw`font-ppa-wbi text-xl`}>prize redeemed</Text>
            <Text style={tw`font-ppa-b text-pc-shade-100 text-base mt-2`}>
                you will recieve an email with details about your prize.
            </Text>
            <View style={tw`mt-4`}>
                <Button
                    variant="primary"
                    text="Done"
                    onPress={() => closeModal()}
                    trackableCategory="Rewards"
                    trackableName="Tapped Done on Prize Redeem Modal"
                />
            </View>
        </SafeAreaView>
    )
}
