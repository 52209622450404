import React from 'react'

import { StyleProp, View, ViewStyle } from 'react-native'

import tw from '@/tailwind/tailwind'

export const NavbarContainer = ({
    children,
    style,
}: {
    children: React.ReactNode
    style?: StyleProp<ViewStyle>
}) => {
    return (
        <View
            style={[
                tw`w-full relative flex-row items-center justify-between px-container pt-4`,
                style,
            ]}
        >
            {children}
        </View>
    )
}
