import * as React from 'react'

import Svg, { Circle, Path, SvgProps } from 'react-native-svg'

const PencilSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            d="M20.219 3.781a2.667 2.667 0 0 0-3.771 0L4.22 15.784a1 1 0 0 0-.28.518l-.646 3.227a1 1 0 0 0 1.177 1.177l3.227-.646a1 1 0 0 0 .518-.28L20.219 7.552a2.667 2.667 0 0 0 0-3.77Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <Circle
            cx={18.5}
            cy={5.5}
            r={2.5}
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </Svg>
)

export default PencilSvg
