import { ActivityIndicator, Text, View } from 'react-native'

import tw from '@/tailwind/tailwind'

import { FullPageView } from './wrappers/FullPageView'

export const SectionIsLoading = ({
    sectionName,
    color,
    tabBarHeight,
}: {
    sectionName: string
    color: string
    tabBarHeight: number
}) => {
    return (
        <FullPageView tabBarHeight={tabBarHeight}>
            <View style={tw`flex-1 justify-center items-center`}>
                <ActivityIndicator color={color} />
                <Text style={[tw`font-ppa-b mt-2 lowercase`, { color }]}>
                    loading {sectionName}
                </Text>
            </View>
        </FullPageView>
    )
}
