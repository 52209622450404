import { useCallback, useMemo } from 'react'

import { Image, PixelRatio, SectionListData, Text, View } from 'react-native'

import { BrandScreenProps } from '@/navigation/types/ScreenProps'
import { TouchableOpacity } from 'react-native-gesture-handler'
import uuid from 'react-native-uuid'

import { useAddToCart } from '@/features/Cart/hooks/cartMutations'
import { countItem } from '@/features/Cart/utils/countItem'
import { useClearCartConditionally } from '@/features/LocationPicker/utils/useClearCartConditionally'

import { DeliveryType } from '@/types/Address'
import { Cart } from '@/types/Cart'
import { Item } from '@/types/Menu'

import { TrackableClick } from '@/components/Analytics'

import { useGetUsableScreenWidth } from '@/hooks/useGetUsableScreenWidth'

import tw from '@/tailwind/tailwind'

import { AddButton } from './AddButton'

interface SectionItemProps {
    item: Item
    section: SectionListData<Item>
    index: number
    instanceId: string
    instanceName: string
    menuId: string
    cart: Cart
    deliveryType: DeliveryType
    sectionContentArr: React.MutableRefObject<(TouchableOpacity | null)[]>
    navigation: BrandScreenProps['navigation']
    isExampleMenu?: boolean
    isLast: boolean
    showPickupDeliveryModal: () => void
}

export const MenuItem = ({
    item,
    index,
    sectionContentArr,
    navigation,
    instanceId,
    instanceName,
    menuId,
    cart,
    deliveryType,
    isExampleMenu = false,
    showPickupDeliveryModal,
    isLast,
}: SectionItemProps) => {
    const { price, name, is_available, image_url, item_id, description } = item

    const { mutate: addToCart } = useAddToCart()
    const clearCartConditionally = useClearCartConditionally()

    const count = useMemo(
        () => countItem(cart.items, item_id),
        [cart.items, item_id]
    )

    const hasRequiredModifierGroups = useMemo(
        () =>
            !item.modifier_groups
                ? false
                : !!item.modifier_groups.find(
                      (modifier_group) => modifier_group.min_selected
                  ),
        [item]
    )

    const navigateToMenuItem = useCallback(() => {
        if (isExampleMenu && showPickupDeliveryModal)
            return showPickupDeliveryModal()
        navigation.navigate('Menu Item', {
            itemId: item.item_id,
            instanceId,
        })
    }, [
        instanceId,
        isExampleMenu,
        item.item_id,
        navigation,
        showPickupDeliveryModal,
    ])

    const quickAddCartItem = useCallback(async () => {
        const handleAddToCart = async (item: Item) => {
            addToCart({
                cartItem: {
                    cart_item_id: uuid.v4().toString(),
                    item,
                    count: 1,
                },
                instanceId,
                instanceName,
                menuId,
                fulfillmentType: deliveryType,
            })
        }

        if (hasRequiredModifierGroups) return navigateToMenuItem()

        if (isExampleMenu) return showPickupDeliveryModal()
        await clearCartConditionally(
            !!cart?.instance_id && instanceId !== cart.instance_id,
            `You already have items in your cart from ${cart?.instance_name}, would you like to clear your current cart and add items from ${instanceName}?`,
            async () => {
                await handleAddToCart(item)
            }
        )
    }, [
        addToCart,
        cart.instance_id,
        cart?.instance_name,
        clearCartConditionally,
        deliveryType,
        hasRequiredModifierGroups,
        instanceId,
        instanceName,
        isExampleMenu,
        item,
        menuId,
        navigateToMenuItem,
        showPickupDeliveryModal,
    ])

    const { width } = useGetUsableScreenWidth()
    const padding = (tw.style('container').padding as number) * 2
    const MENU_ITEM_WIDTH = width - padding

    return (
        <View
            style={tw`${
                !isLast ? 'border-b' : ''
            }  pb-container mb-4 border-grey-700`}
        >
            <TrackableClick
                testID="menu-item"
                name="Tapped menu item"
                category="Order"
                properties={{
                    'item name': item.name,
                    'item price': item.price,
                    'item id': item.item_id,
                    index,
                }}
                onesignal
            >
                <TouchableOpacity
                    onPress={navigateToMenuItem}
                    disabled={!is_available}
                    ref={(el) => (sectionContentArr.current[index] = el)}
                    style={tw`w-full justify-between  flex-row 
  z-20
                `}
                >
                    <View style={tw`px-container shrink-1`}>
                        <Text
                            style={tw`text-base font-ppp-b leading-tight text-white
                        `}
                        >
                            {name}
                        </Text>

                        {description?.replace(/\s+/g, '') ? (
                            <Text
                                style={tw`text-sm text-white font-ppp-m leading-4 mt-2`}
                                numberOfLines={3}
                            >
                                {description}
                            </Text>
                        ) : null}

                        <AddButton
                            disabled={!is_available}
                            item={item}
                            count={count}
                            quickAddCartItem={quickAddCartItem}
                            price={price}
                        />
                    </View>
                    <View style={tw`w-32 h-32 rounded-4xl overflow-hidden`}>
                        {image_url ? (
                            <Image
                                key={item.item_id}
                                style={tw`w-full h-full`}
                                resizeMode="cover"
                                source={{
                                    uri: `${image_url}?width=${PixelRatio.getPixelSizeForLayoutSize(
                                        MENU_ITEM_WIDTH / 2 // rough overestimate of the width of the image
                                    )}`,
                                }}
                            />
                        ) : (
                            <Image
                                key={item.item_id}
                                style={tw`w-full h-full`}
                                resizeMode="cover"
                                source={require('@/assets/images/menu-item-placeholder.png')}
                            />
                        )}
                    </View>
                </TouchableOpacity>
            </TrackableClick>
        </View>
    )
}
