import { TrackingCategory } from './'

export const navigationEventCategoriesAndNames: {
    [key: string]: {
        name: string
        category: TrackingCategory
    }
} = {
    Home: {
        name: 'home',
        category: 'Home',
    },
    BrandPage: {
        name: 'brand page',
        category: 'Order',
    },
    Brands: {
        name: 'brands list',
        category: 'Order',
    },
    Rewards: {
        name: 'rewards',
        category: 'Rewards',
    },
    Welcome: {
        name: 'welcome',
        category: 'Registration',
    },
    'Menu Item': {
        name: 'menu item',
        category: 'Order',
    },
    'Location Picker': {
        name: 'location picker',
        category: 'Address',
    },
    'Edit Location': {
        name: 'edit location',
        category: 'Address',
    },
    NoInternet: {
        name: 'no internet',
        category: 'General',
    },
    Cart: {
        name: 'cart',
        category: 'Checkout',
    },
    Checkout: {
        name: 'checkout',
        category: 'Checkout',
    },
    OrderPlaced: {
        name: 'order placed',
        category: 'Checkout',
    },
    // Login: {
    //     name: 'login stack',
    //     category: 'Registration',
    // },
    PhoneNumberCapture: {
        name: 'mobile',
        category: 'Registration',
    },
    VerifyCode: {
        name: 'sms verification',
        category: 'Registration',
    },
    'Review Order': {
        name: 'review order',
        category: 'Order',
    },
    'Review Order Success': {
        name: 'review order success',
        category: 'Order',
    },
    'Review Order Start': {
        name: 'review order start',
        category: 'Order',
    },
    LootboxAnimation: {
        name: 'loot box animation',
        category: 'Rewards',
    },
    Feed: {
        name: 'social feed',
        category: 'Social Feed',
    },
    'Street Team': {
        name: 'street team',
        category: 'Experiment',
    },
    'Street Team Confirm': {
        name: 'street team confirm',
        category: 'Experiment',
    },
}
