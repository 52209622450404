import { useWindowDimensions } from 'react-native'

import { BORDER_ASPECT_RATIO, MAX_HEIGHT_PERCENTAGE } from '../WebBorder'
import { useIsMobile } from './useIsMobile'

export const useGetUsableScreenWidth = () => {
    const useBorder = !useIsMobile()
    const { height: windowHeight, width: windowWidth } = useWindowDimensions()
    const width = useBorder
        ? BORDER_ASPECT_RATIO * MAX_HEIGHT_PERCENTAGE * windowHeight
        : windowWidth

    return { width }
}
