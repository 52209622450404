import { useMemo } from 'react'

import { Image, Text, TouchableOpacity, View } from 'react-native'

import { CartProps } from '@/navigation/types/ScreenProps'

import { CartItem } from '@/types/Cart'

import { TrackableClick } from '@/components/Analytics'

import { listOfModifiers } from '@/utils/listOfModifiers'
import { toPrice } from '@/utils/normalizers'
import { totalItemPrice } from '@/utils/totalItemPrice'

import tw from '@/tailwind/tailwind'

import { Counter } from './Counter'

interface CartItemCardProps {
    cartItem: CartItem
    handleCountIncrement: (id: string) => void
    handleCountdecrement: (id: string) => void
    navigation: CartProps['navigation']
    instanceId: string
}

export const CartItemCard = ({
    cartItem,
    handleCountIncrement,
    handleCountdecrement,
    navigation,
    instanceId,
}: CartItemCardProps) => {
    const { item } = cartItem
    const { description } = item

    const onCountInc = () => {
        handleCountIncrement(cartItem.cart_item_id)
    }

    const onCountDec = () => {
        handleCountdecrement(cartItem.cart_item_id)
    }

    const totalPrice = useMemo(() => {
        const itemPrice = totalItemPrice(cartItem.item)

        return cartItem.count * itemPrice
    }, [cartItem])

    return (
        <TrackableClick
            name="Tapped an item in the cart"
            category="Checkout"
            properties={{
                'cart item id': cartItem.cart_item_id,
                'item count': cartItem.count,
                'item id': cartItem.item.item_id,
                'item name': cartItem.item.name,
            }}
        >
            <TouchableOpacity
                onPress={() => {
                    navigation.navigate('Menu Item', {
                        cartItemId: cartItem.cart_item_id,
                        isUpdate: true,
                        instanceId,
                        itemId: item.item_id,
                    })
                }}
            >
                <View style={tw`py-3 border-b border-pc-shade-60/50`}>
                    <View style={tw`flex-row  justify-between `}>
                        <View style={tw`flex-row items-center  flex-1 `}>
                            <View style={tw`w-14 h-14`}>
                                {item.image_url ? (
                                    <Image
                                        style={tw`w-full h-full`}
                                        resizeMode="cover"
                                        source={{ uri: item.image_url }}
                                    />
                                ) : (
                                    <Image
                                        style={tw`w-full h-full`}
                                        resizeMode="cover"
                                        source={require('@/assets/images/menu-item-placeholder-mini.png')}
                                    />
                                )}
                            </View>

                            <View style={tw`mx-2.5  flex-shrink`}>
                                <Text style={tw`font-ppa-b text-base`}>
                                    {cartItem.item.name}
                                </Text>
                                <Text
                                    style={tw`text-sm font-ppa-b text-pc-shade-80`}
                                >
                                    {toPrice(totalPrice)}
                                </Text>
                            </View>

                            <View style={tw`ml-auto`}>
                                <Counter
                                    count={cartItem.count}
                                    handleCountIncrement={onCountInc}
                                    handleCountDecrement={onCountDec}
                                />
                            </View>
                        </View>
                    </View>
                    {description ? (
                        <View style={tw`ml-14`}>
                            <Text
                                style={tw`ml-2.5 text-sm font-ppa-b text-pc-shade-80`}
                            >
                                {listOfModifiers(item)}
                            </Text>
                        </View>
                    ) : null}
                </View>
            </TouchableOpacity>
        </TrackableClick>
    )
}
