import { Image, Text, View } from 'react-native'

import { BrandScreenProps, BrandsProps } from '@/navigation/types/ScreenProps'

import { TrackableRender } from '@/components/Analytics'
import { Button } from '@/components/Button'

import { useProfile } from '@/hooks/query/useProfile'

import tw from '@/tailwind/tailwind'

// A component to show whenever no brands are retrieved or no address exists
export const NoAddress = ({
    navigation,
    closeModal = () => {},
}: {
    navigation: BrandScreenProps['navigation'] | BrandsProps['navigation']
    closeModal?: () => void
}) => {
    const { data: user } = useProfile()
    const action = (user?.addresses?.length || 0) > 0 ? 'Select' : 'Add'

    return (
        <TrackableRender
            name="Viewed No Address - Brands list or Pickup Delivery modal"
            category="Address"
        >
            <View style={tw`items-center justify-center flex-1`}>
                <Image
                    style={tw`w-20 h-20`}
                    source={require('@/assets/party-popper-emoji.png')}
                />
                <Text style={tw`font-ppa-wbi text-2xl mt-5 text-center`}>
                    Welcome
                </Text>
                <Text
                    style={tw`font-ppa-b text-pc-shade-80 mt-4 mb-8 text-center`}
                >
                    {action} an address to get started!
                </Text>
                <View style={tw`w-full px-16`}>
                    <Button
                        variant="primary"
                        trackableName={`tapped ${action} Address on No Address screen`}
                        trackableCategory="Address"
                        onPress={() => {
                            navigation.navigate('Location Picker')
                            closeModal()
                        }}
                        text={`${action} an Address`}
                        testID="add-address-button"
                    />
                </View>
            </View>
        </TrackableRender>
    )
}
