import { Image, Platform } from 'react-native'

import Animated, {
    Extrapolate,
    interpolate,
    useAnimatedStyle,
} from 'react-native-reanimated'

import { Brand } from '@/types/Brand'

import tw from '@/tailwind/tailwind'

export const HeaderLogo = ({
    scroll,
    brand,
    amountToScrollForMinHeader,
    onlyShowLogo = false,
}: {
    scroll: Animated.SharedValue<number>
    brand: Brand
    amountToScrollForMinHeader: number
    onlyShowLogo?: boolean
}) => {
    const { logo_image_url, name } = brand

    const animatedStyle = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: interpolate(
                    scroll.value,
                    [0, amountToScrollForMinHeader],
                    [0, Platform.OS === 'web' ? -70 : -53],
                    { extrapolateRight: Extrapolate.CLAMP }
                ),
            },
            {
                scale: interpolate(
                    scroll.value,
                    [0, amountToScrollForMinHeader],
                    [1, 0.7],
                    { extrapolateRight: Extrapolate.CLAMP }
                ),
            },
        ],
        opacity: interpolate(
            scroll.value,
            [0, amountToScrollForMinHeader - 50],
            [1, 0],
            { extrapolateRight: Extrapolate.CLAMP }
        ),
    }))
    return logo_image_url ? (
        <Animated.View
            style={[
                tw`w-${onlyShowLogo ? 'full' : '1/2'} h-${
                    onlyShowLogo ? '80' : '20'
                } z-10`,
                animatedStyle,
            ]}
        >
            <Image
                style={tw`h-full w-full`}
                source={{ uri: logo_image_url }}
                resizeMode="contain"
            />
        </Animated.View>
    ) : (
        <Animated.Text style={[tw`text-xl font-ppa-wbi z-10`, animatedStyle]}>
            {name}
        </Animated.Text>
    )
}
