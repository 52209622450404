import { useQueries } from 'react-query'

import { DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { axios } from '@/lib/httpClient'

type ValidateDeliveryResponse = {
    message: string
}

const isValid = async (
    instance: Instance,
    addressId: string | undefined,
    deliveryType: DeliveryType
) => {
    if (!addressId) return Promise.reject('No addressId')
    if (deliveryType === DeliveryType.pickup) return true

    try {
        const res = await axios.post<ValidateDeliveryResponse>(
            '/order/validate-delivery',
            {
                location_id: instance.store.location_id,
                address_id: addressId,
            }
        )

        return res.data.message === 'Valid delivery'
    } catch (err) {
        return false
    }
}

export const useValidateDeliveryInstances = (
    instances: Instance[],
    addressId: string | undefined,
    deliveryType: DeliveryType
) => {
    return useQueries(
        instances.map((instance) => {
            return {
                queryKey: [
                    'validateDelivery',
                    instance.store.location_id,
                    addressId,
                    deliveryType,
                ],
                queryFn: () => isValid(instance, addressId, deliveryType),
                enabled: Boolean(addressId),
                staleTime: Infinity,
            }
        })
    )
}
