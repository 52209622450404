import { useEffect, useRef, useState } from 'react'

import { RefreshControl, Text, View } from 'react-native'

import { RootStackParamList } from '@/navigation/types/RootStackParamList'
import { OrderPlacedProps } from '@/navigation/types/ScreenProps'
import { BottomSheetModal as BottomSheetModalType } from '@gorhom/bottom-sheet'
import { NavigationContainerRef } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'

import { useInstance } from '@/features/Cart/api/useInstance'
import { useLootboxInfo } from '@/features/LootBox/api/useLootboxInfo'

import { FullScreenLoader } from '@/components/FullScreenLoader'
import { NoOrders } from '@/components/NoOrders'

import { useBrand } from '@/hooks/query/useBrand'
import { useOrderDetails, useOrderStatus } from '@/hooks/query/useOrder'
import { useOrderRefetchInterval } from '@/hooks/query/useOrderRefetchInterval'
import { useRefreshFix } from '@/hooks/useRefreshFix'

import { DORITOS_BRAND_ID } from '@/utils/constants'
import { getOrderStatusText } from '@/utils/getOrderStatusText'
import { showMessage } from '@/utils/showMessage'

import tw from '@/tailwind/tailwind'

import { ClaimLootboxModal } from '../../LootBox/components/ClaimLootboxModal'
import { EligibleForLootbox } from './EligibleForLootBox'
import { OrderItems } from './OrderItems'
import { OrderPlacedBottom } from './OrderPlacedBottom'
import { OrderPlacedDeliveryStatus } from './OrderPlacedDeliveryStatus'
import { OrderPlacedHeader } from './OrderPlacedHeader'
import { OrderPlacedInstanceCard } from './OrderPlacedInstanceCard'
import { OrderTotal } from './OrderTotal'
import { PickupDetails } from './PickupDetails'
import { PointsEarned } from './PointsEarned'
import { ReviewOrderPrompt } from './ReviewOrderPrompt'

export type OrderPlacedViewNavigationType =
    | OrderPlacedProps['navigation']
    | NavigationContainerRef<RootStackParamList>
    | null

type OrderPlacedViewProps = {
    orderId: string
    isModal: boolean
    navigation: OrderPlacedViewNavigationType
    closeModal?: () => void
}

export const OrderPlacedView = ({
    orderId,
    isModal,
    closeModal,
    navigation,
}: OrderPlacedViewProps) => {
    const { data: order, isLoading: isOrderLoading } = useOrderDetails(orderId)
    useBrand(order?.brand_id) // preload brand for thumbnail
    const {
        refetch: refetchOrderStatus,
        isLoading: isOrderStatusLoading,
        data: orderStatus,
        isError: isOrderStatusError,
    } = useOrderStatus(orderId, order?.created_on)

    useEffect(() => {
        if (isOrderStatusError) {
            showMessage({
                message: 'Unable to fetch order status',
                type: 'warning',
            })
            navigation?.navigate('HomeTabs')
        }
    }, [isOrderStatusError])

    const [showedLootboxModal, setShowedLootboxModal] = useState<boolean>(false)
    const {
        data: lootboxInfo,
        isLoading: lootboxInfoIsLoading,
        isError: lootboxInfoIsError,
    } = useLootboxInfo({ orderId })
    const claimLootboxModalRef = useRef<BottomSheetModalType>(null)
    const showLootboxModal = () => {
        claimLootboxModalRef.current?.present()
        setShowedLootboxModal(true)
    }

    useEffect(() => {
        if (lootboxInfoIsLoading || isOrderStatusLoading) return
        if (!orderStatus || isOrderStatusError)
            return console.error(
                'Missing order state for showing lootbox modal',
                orderStatus,
                isOrderStatusError
            )
        if (
            !lootboxInfo?.reward?.custom_data?.modal_splash_image_url ||
            lootboxInfoIsError
        )
            return console.error('Missing lootbox custom data', lootboxInfo)
        const state = orderStatus?.order_state
        if (state === 'Dispatched' || state === 'Delivered') {
            setTimeout(() => {
                if (lootboxInfo) showLootboxModal()
            }, 750)
        }
    }, [isOrderStatusLoading, lootboxInfoIsLoading])

    const instanceId = order?.cart.instance_id
    const { data: instance, isLoading: isInstanceLoading } =
        useInstance(instanceId)

    const [bottomHeight, setBottomHeight] = useState(0)

    const { onRefresh, isRefreshing } = useRefreshFix([refetchOrderStatus])

    const is12HrsPassedAfterOrderPlaced =
        new Date().getTime() -
            new Date(order?.created_on ?? new Date()).getTime() >
        60 * 60 * 12 * 1000 // 12 hours

    useOrderRefetchInterval({
        // if it's not been 12 hours since order placed, then set interval for 1 minute
        // otherwise don't start the interval
        intervalTimeInSec: !is12HrsPassedAfterOrderPlaced ? 10 : undefined,
        onInterval: refetchOrderStatus,
    })

    if (isOrderLoading || isInstanceLoading || isOrderStatusLoading)
        return <FullScreenLoader />

    if (!order || !instance) return <NoOrders />

    const isDelivery = order.fulfillment_type === 'delivery'
    const Container = isModal ? View : SafeAreaView

    const orderStatusText = getOrderStatusText(order, orderStatus)

    return (
        <>
            <Container style={tw`flex-1 container relative`}>
                <OrderPlacedHeader
                    order={order}
                    isModal={isModal}
                    closeModal={closeModal}
                />

                <ScrollView
                    refreshControl={
                        <RefreshControl
                            refreshing={isRefreshing}
                            onRefresh={onRefresh}
                        />
                    }
                    showsVerticalScrollIndicator={false}
                    style={tw`flex-1`}
                    contentContainerStyle={tw`pb-[${bottomHeight}px]`}
                >
                    {showedLootboxModal &&
                        lootboxInfo &&
                        lootboxInfo.reward &&
                        navigation && (
                            <EligibleForLootbox
                                lootboxInfo={lootboxInfo}
                                order={order}
                                navigation={navigation}
                            />
                        )}
                    <ReviewOrderPrompt order={order} />
                    <OrderPlacedInstanceCard order={order} />

                    <View style={tw`mt-5 mb-2`}>
                        <Text
                            style={tw`font-ppa-b text-pc-shade-80 text-base mb-2`}
                        >
                            Order Status
                        </Text>
                        {orderStatusText.longTextEl}
                    </View>
                    {!is12HrsPassedAfterOrderPlaced &&
                    !orderStatusText.isFailed ? (
                        isDelivery ? (
                            <OrderPlacedDeliveryStatus
                                deliveryStatus={orderStatus?.status || null}
                                order={order}
                            />
                        ) : (
                            <PickupDetails order={order} instance={instance} />
                        )
                    ) : null}
                    <OrderItems order={order} />
                    <OrderTotal order={order} />
                    {order.brand_id !== DORITOS_BRAND_ID && (
                        <PointsEarned order={order} />
                    )}
                </ScrollView>

                <OrderPlacedBottom
                    order={order}
                    setBottomHeight={setBottomHeight}
                    isOrderFailed={orderStatusText?.isFailed}
                    trackingUrl={orderStatus?.track_url ?? undefined}
                />
            </Container>
            {lootboxInfo && (
                <ClaimLootboxModal
                    orderId={orderId}
                    bsmRef={claimLootboxModalRef}
                    lootboxInfo={lootboxInfo}
                />
            )}
        </>
    )
}
