import { Platform } from 'react-native'

export const enableHotJar = async () => {
    if (Platform.OS !== 'web') return

    const hotjarImport = await import('react-hotjar')
    const hotjar = hotjarImport.hotjar

    hotjar.initialize(3090760, 6)
}

type updateHotJarPropsType = {
    first?: string | null
    last?: string | null
    email?: string | null
    userId: string
    phone?: string | null
}

export const updateHotJar = async (props: updateHotJarPropsType) => {
    const { userId, first, email, last, phone } = props
    if (Platform.OS !== 'web') return

    const hotjarImport = await import('react-hotjar')
    const hotjar = hotjarImport.hotjar

    if (hotjar.initialized()) {
        const data: Partial<updateHotJarPropsType> = {}
        if (first) data.first = first
        if (last) data.last = last
        if (phone) data.phone = phone
        if (email) data.email = email

        try {
            hotjar.identify(userId, data)
        } catch (_e) {
            // NEVER THROW AN ERROR IN HERE, as it will cause functions that call this to fail
        }
    }
}
