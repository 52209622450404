import React from 'react'

import SwitchSelector from 'react-native-switch-selector'

import { DeliveryType } from '@/types/Address'

import { postTrackingEvent } from '@/components/Analytics'
import { Toggle } from '@/components/Toggle'

import { useStore } from '@/lib/store'

type SwitchProps = {
    onSwitchChange: (value: DeliveryType) => void
}

export const DeliveryTypeSwitch = React.forwardRef<SwitchSelector, SwitchProps>(
    ({ onSwitchChange }, ref) => {
        const deliveryType = useStore((state) => state.location.deliveryType)

        const options = [
            { label: 'Delivery', value: 'delivery' },
            { label: 'Pickup', value: 'pickup' },
        ]

        return (
            <Toggle
                variant="legacy"
                // 0 = delivery, 1 = pickup
                toggleProps={{
                    initial: deliveryType === DeliveryType.delivery ? 0 : 1,
                    options,
                    onPress: (value: string | DeliveryType) => {
                        const selectedChoiceValue = value as DeliveryType
                        if (value !== deliveryType) {
                            onSwitchChange(selectedChoiceValue)
                            postTrackingEvent({
                                name: 'Tapped fulfillment type switch',
                                category: 'Address',
                                properties: {
                                    'fulfillment type': value,
                                },
                            })
                        }
                    },
                }}
                ref={ref}
                trackableName="toggled location picker"
                trackableCategory="Address"
                additionalTrackableProps={{ prevValue: deliveryType }}
            />
        )
    }
)
