import { DeliveryType } from '@/types/Address'
import { Cart, CartItem } from '@/types/Cart'
import { Menu } from '@/types/Menu'

import { postTrackingEvent } from '@/components/Analytics'

import { sendOneSignalOutcome } from '@/lib/onesignal'
import { useOptimisticMutation } from '@/lib/queryClient'

import { getUUID } from '@/utils/id'

import { setCart } from '../api/setCart'
import { cartQueryKey } from '../api/useCart'

// WRAPPER AROUND useOptimisticCartMutation, SPECIFIC TO CART QUERIES
export const useOptimisticCartMutation = <I = void>(
    updateOptimistically: (previousCart: Cart, input: I) => Cart
) => useOptimisticMutation<Cart, I>(cartQueryKey, updateOptimistically, setCart)

// ADD AN ITEM TO THE CART
export const useAddToCart = useOptimisticCartMutation<{
    cartItem: CartItem
    menuId: string
    instanceId: string
    instanceName: string
    fulfillmentType: DeliveryType
}>((cart, { cartItem, menuId, instanceId, instanceName, fulfillmentType }) => {
    if (cart.items.length === 0) sendOneSignalOutcome('Created new cart')

    postTrackingEvent({
        name: 'Added item to cart',
        category: 'Order',
        properties: {
            price: cartItem.item.price,
            countAdded: cartItem.count,
            name: cartItem.item.name,
        },
        onesignal: true,
        onesignalValue: 'Added item to cart',
    })
    return {
        ...cart,
        items: [...cart.items, cartItem],
        instance_id: instanceId,
        instance_name: instanceName,
        menu_id: menuId,
        fulfillment_type: fulfillmentType,
    }
})

// UPDATE AN ITEM IN THE CART
export const useUpdateItemInCart = useOptimisticCartMutation<CartItem>(
    (cart, cartItem) => {
        // find current cart item. If not found, return.
        const itemIdx = cart.items.findIndex(
            (cItem) => cItem.cart_item_id === cartItem.cart_item_id
        )
        if (itemIdx < 0) return cart
        sendOneSignalOutcome('Updated item in cart')
        cart.items.splice(itemIdx, 1, cartItem)
        return cart
    }
)

// INCREASE THE QUANTITY OF A CART ITEM
export const useIncreaseCount = useOptimisticCartMutation<string>(
    (cart, cartItemId) => {
        const cItemInCart = cart.items.find(
            (cItem) => cItem.cart_item_id === cartItemId
        )
        if (!cItemInCart) return cart
        sendOneSignalOutcome('Increased quanity of item in cart')
        cItemInCart.count++
        return cart
    }
)

// DECREASE THE QUANTITY OF A CART ITEM - ALSO HANDLES DELETION
export const useDecreaseCount = useOptimisticCartMutation<string>(
    (cart, cartItemId) => {
        // get index of item
        const cItemInCartIdx = cart.items.findIndex(
            (cItem) => cItem.cart_item_id === cartItemId
        )

        // if item not in cart return
        if (cItemInCartIdx < 0) return cart
        const cItemInCart = cart.items[cItemInCartIdx]

        // delete item if count is 1
        if (cItemInCart.count === 1) {
            cart.items.splice(cItemInCartIdx, 1)

            // if there are no items in cart, clear instance id
            if (cart.items.length === 0) {
                cart.instance_id = null
                cart.instance_name = null
                cart.items = []
            }
            sendOneSignalOutcome('Removed item from cart')
            return cart
        }

        cItemInCart.count--
        sendOneSignalOutcome('Decreased quanity of item in cart')

        return cart
    }
)

// UPDATE CART FULFILLMENT TYPE
export const useUpdateFulfillment = useOptimisticCartMutation<DeliveryType>(
    (cart, fulfillmentType) => ({
        ...cart,
        fulfillment_type: fulfillmentType,
    })
)

// UPDATE CART MENU ID
export const useUpdateCartMenuId = useOptimisticCartMutation<Menu['menu_id']>(
    (cart, menu_id) => ({
        ...cart,
        menu_id,
    })
)

// CLEAR THE CART
export const useClearCart = useOptimisticCartMutation((cart) => {
    sendOneSignalOutcome('Cleared cart')
    return {
        ...cart,
        cart_id: getUUID(),
        items: [],
        instance_id: null,
        instance_name: null,
        order_success: null,
    }
})

// REMOVE AN ITEM FROM THE CART
export const useRemoveFromCart = useOptimisticCartMutation<string>(
    (cart, cartItemId) => {
        cart.items = cart.items.filter(
            (cItem) => cItem.cart_item_id !== cartItemId
        )
        sendOneSignalOutcome('Removed item from cart')
        return cart
    }
)
