import { FlatList, Text, TouchableOpacity, View } from 'react-native'

import tw from '@/tailwind/tailwind'

import { BrandPageType } from '..'

export type BrandTabBarProps = {
    item: BrandPageType
    index: number
    isSelected: boolean
    changePage: (idx: number) => void
}

export const BrandTabBarItem = ({
    item,
    index,
    isSelected,
    changePage,
}: BrandTabBarProps) => {
    const { title } = item

    return (
        <TouchableOpacity
            style={tw`${isSelected ? 'bg-white' : ''} mx-2 px-1`}
            onPress={() => changePage(index)}
            hitSlop={{ top: 6, right: 6, bottom: 6, left: 6 }}
        >
            <Text
                style={tw`text-xl font-ppa-wbi ${
                    !isSelected ? 'text-white' : ''
                }`}
            >
                {title}
            </Text>
        </TouchableOpacity>
    )
}

export const BrandTabBar = ({
    data,
    selectedPageIdx,
    changePage,
    tabBarRef,
    tabBarHeight,
}: {
    data: BrandPageType[]
    selectedPageIdx: number
    changePage: (idx: number) => void
    tabBarRef: React.MutableRefObject<FlatList<BrandPageType> | null>
    tabBarHeight: number
}) => {
    return (
        <View style={tw`bg-black`}>
            <View style={tw`bg-white/10`}>
                <FlatList
                    ref={tabBarRef}
                    style={tw`grow-0 min-h-[${tabBarHeight}px] max-h-[${tabBarHeight}px]`}
                    contentContainerStyle={tw`px-2 py-4`}
                    data={data}
                    horizontal
                    keyExtractor={(page) => page.title}
                    showsHorizontalScrollIndicator={false}
                    renderItem={(itemData) => {
                        const data: BrandTabBarProps = {
                            isSelected: itemData.index === selectedPageIdx,
                            item: itemData.item,
                            index: itemData.index,
                            changePage: (idx: number) => changePage(idx),
                        }

                        return BrandTabBarItem(data)
                    }}
                    onScrollToIndexFailed={(info) => {
                        const wait = new Promise((resolve) =>
                            setTimeout(resolve, 500)
                        )
                        wait.then(() => {
                            tabBarRef.current?.scrollToIndex({
                                index: info.index,
                                animated: true,
                            })
                        })
                    }}
                />
            </View>
        </View>
    )
}
