import { Image, View } from 'react-native'

import { useBrand } from '@/hooks/query/useBrand'

import tw from '@/tailwind/tailwind'

export const BrandLogoThumbnail = ({
    brandId,
    size = 48,
}: {
    brandId: string
    size?: number
}) => {
    const { data: brand } = useBrand(brandId)
    const { color, logo_image_url } = brand || {}
    const hasBrandIcon = color && logo_image_url
    return (
        <View
            style={tw`absolute rounded-full overflow-hidden w-[${size}px] h-[${size}px] p-1 ${
                hasBrandIcon ? `bg-[${color}]` : `bg-pc-primary`
            }  border-2 border-white`}
        >
            {hasBrandIcon ? (
                <Image
                    source={{ uri: logo_image_url }}
                    style={tw`w-full h-full`}
                    resizeMode="contain"
                />
            ) : (
                <Image
                    source={require('@/assets/mouth.png')}
                    style={tw`w-full h-full`}
                    resizeMode="contain"
                />
            )}
        </View>
    )
}
