import { axios } from '@/lib/httpClient'

import { ApiErrors } from '@/utils/handleApiError'

import { PaymentMethod, PaymentMethodResponse } from '../types'

export const createPaymentMethod = async (paymentMethod: PaymentMethod) => {
    const response = await axios.post<PaymentMethodResponse>(
        '/order/payment-method',
        paymentMethod
    )

    return response.data
}

export const createPaymentApiErrors: ApiErrors = {
    // Handled with generic error message:
    // 500 Flipdish payment account failure
    // 500 Unable to create popchew payment account
    genericMessage: 'Failed to add payment method',
    specificErrors: [
        {
            statusCode: 400,
            backendMessage: 'Invalid card info',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Missing first',
            frontendMessage: 'Missing first name',
        },
        {
            statusCode: 400,
            backendMessage: 'Missing last',
            frontendMessage: 'Missing last name',
        },
        {
            statusCode: 400,
            backendMessage: 'Incorrect network',
            frontendMessage:
                'Popchew does not support this card network. Accepted networks include: Visa, Mastercard, Discover, and Amex.',
        },
        {
            statusCode: 400,
            backendMessage: 'Card expired',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid security code',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Card declined',
            frontendMessage: 'Card not accepted. Try a different card',
        },
        {
            statusCode: 400,
            backendMessage: 'Card not supported',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid card number',
            frontendMessage: null,
        },
    ],
}
