import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const BookmarkSvg = (props: SvgProps) => (
    <Svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M12.667 14 8 10.667 3.333 14V3.333A1.333 1.333 0 0 1 4.667 2h6.666a1.333 1.333 0 0 1 1.334 1.333V14Z"
            fill="#fff"
        />
    </Svg>
)

export default BookmarkSvg
