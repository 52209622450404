import { axios } from '@/lib/httpClient'

import storage from '@/utils/storage'

import { AuthorizeResponse } from '../types'
import { isAuthenticated } from '../utils/isAuthenticated'

export const anonAuthorize = async () => {
    const response = await axios.post<AuthorizeResponse>('/auth/anon')

    await storage.setAccessToken(response.data.access_token)
    await storage.setRefreshToken(response.data.refresh_token)
    return response.data
}

export const anonAuthorizeIfNotAuth = async () => {
    const isAuth = await isAuthenticated()
    if (!isAuth) await anonAuthorize()
}
