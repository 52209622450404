import { useEffect } from 'react'

import { Image, Platform, Text, TouchableOpacity, View } from 'react-native'

import { BrandsProps } from '@/navigation/types/ScreenProps'
import { ResizeMode, Video } from 'expo-av'
import CountDown from 'react-native-countdown-component'
import { SharedValue } from 'react-native-reanimated/lib/types/lib/reanimated2/commonTypes'
import { useQueryClient } from 'react-query'

import { useDefaultMenuQueryFn } from '@/features/BrandPage/screens/Menu/api/fetchDefaultMenu'
import {
    useDefaultMenuQueryKey,
    useMenuQueryFn,
    useMenuQueryKey,
} from '@/features/BrandPage/screens/Menu/api/useMenu'

import { Brand } from '@/types/Brand'

import { TrackableClick } from '@/components/Analytics'

import { useBrandQueryFn, useBrandQueryKey } from '@/hooks/query/useBrand'
import { useInstances } from '@/hooks/query/useInstances'
import { useGetUsableScreenWidth } from '@/hooks/useGetUsableScreenWidth'

import { DORITOS_BRAND_ID } from '@/utils/constants'
import { getHashFromInstanceArray } from '@/utils/getHashFromInstanceArray'

import tw from '@/tailwind/tailwind'

import ChevronRightSvg from '@/svgs/ChevronRightSvg'

interface BrandsCardProps {
    item: Brand
    isAvailable: boolean
    isOpen: boolean
    navigation: BrandsProps['navigation']
    containerNativeID: string
    hasWindow: boolean | undefined
    angle: SharedValue<number>
    isLoading: boolean
}

const isWeb = Platform.OS === 'web'

// const HEAD_ROTATION = 40 // deg
// const HEAD_PERSPECTIVE = 200 // px
const BRAND_HEAD_SIZE = isWeb ? 170 : 190 // px, 40 ish rem
// const BRAND_HEAD_SCALE = 0.85 // unitless
// const ROTATION_RANGE = [-HEAD_ROTATION, 0, HEAD_ROTATION] // deg

export const BrandCard = ({
    item: brand,
    isAvailable,
    isOpen,
    isLoading,
    // angle,
    navigation,
}: // containerNativeID,
// hasWindow,
BrandsCardProps) => {
    const { id: brandId } = brand
    const queryClient = useQueryClient()
    const { width } = useGetUsableScreenWidth()

    const isVideo =
        brand.background_image_url?.endsWith('.mp4') ||
        brand.background_image_url?.endsWith('mov')

    const isDoritos = brandId === DORITOS_BRAND_ID

    // const { data: defaultMenu, isLoading: isDefaultMenuLoading } =
    //     useDefaultMenu(brandId)
    // const { data: cart } = useCart()
    // const deliveryType = useStore((state) => state.location.deliveryType)

    const { data: instances } = useInstances()

    // const blendedColor = useMemo<[number, number, number, number]>(() => {
    //     const [r, g, b, a] = hexToRGBA(brand.color || '#000000')

    //     // front color for color blend rgba(71, 71, 71, 0.5)
    //     // but I had to change it to rgba(101, 101, 101, 0.5) to make it look more apparent
    //     // Equation for "Difference" blend mode C = |B - F|
    //     // where B = base color, F = front color
    //     return [
    //         Math.abs(r - 101),
    //         Math.abs(g - 101),
    //         Math.abs(b - 101),
    //         Math.abs(a - 0.5),
    //     ]
    // }, [brand.color])

    /** START PREFETCH USEFUL DATA FOR ONCE USER CLICKS BRAND CARD */

    useEffect(() => {
        queryClient.prefetchQuery(
            useDefaultMenuQueryKey(brandId),
            () => useDefaultMenuQueryFn(brandId),
            { staleTime: Infinity }
        )
        queryClient.prefetchQuery(
            useBrandQueryKey(brandId),
            () => useBrandQueryFn(brandId),
            { staleTime: Infinity }
        )
    }, [])

    // find the closest loc of this brand and prefetch the menu
    useEffect(() => {
        const brandInstances = instances?.filter(
            (instance) => instance.brand_id === brandId
        )
        brandInstances?.sort((instanceOne, instanceTwo) =>
            instanceOne.distance_from_address <
            instanceTwo.distance_from_address
                ? -1
                : 1
        )

        if (!brandInstances || brandInstances.length === 0) return

        const closestLoc = brandInstances[0]
        const closestLocInstanceId = closestLoc.instance_id
        queryClient.prefetchQuery(
            useMenuQueryKey(closestLocInstanceId),
            () => useMenuQueryFn(closestLocInstanceId),
            { staleTime: 1000 * 60 }
        )
    }, [getHashFromInstanceArray(instances)])

    /** END PREFETCH USEFUL DATA FOR ONCE USER CLICKS BRAND CARD */

    /** DORITOS CLOCK */

    const doritosEndsOn = new Date('2022-12-22T02:00:00')
    const now = new Date()

    const calculateSeconds = (date1: Date, date2: Date) => {
        const difference = date1.getTime() - date2.getTime()
        if (difference <= 0) return 0
        return Math.ceil(difference / 1000)
    }

    const clockUntil = calculateSeconds(doritosEndsOn, now)
    const showCountdown = isDoritos && clockUntil > 0

    return (
        <View
            style={tw`relative w-full my-1 rounded-[58px] border border-white/25 `}
        >
            <TrackableClick
                testID={`brand-card-${isAvailable ? 'open' : 'close'}`}
                name="Tapped brand in brands list"
                category="Order"
                properties={{
                    brand_id: brand.id,
                    brand_name: brand.name,
                }}
                onesignal
            >
                <TouchableOpacity
                    style={tw`relative h-[${
                        width * 1.4
                    }px] w-full  rounded-[58px] overflow-hidden`}
                    disabled={false}
                    onPress={() => {
                        navigation.navigate(
                            'BrandPage' as 'Rewards',
                            {
                                brandId: brand.id,
                            } as unknown as undefined
                        )
                    }}
                    activeOpacity={1}
                >
                    <View style={tw`w-full h-full bg-pc-shade-20`}>
                        {isVideo ? (
                            <Video
                                style={tw`w-full h-full items-center absolute z-0`}
                                source={{
                                    uri: brand.background_image_url || '',
                                }}
                                resizeMode={ResizeMode.COVER}
                                shouldPlay
                                isLooping
                                useNativeControls={false}
                                volume={0.0}
                                isMuted
                                posterSource={{
                                    uri: 'https://popchew-web.b-cdn.net/default-bg.png',
                                }}
                            />
                        ) : (
                            <Image
                                style={tw`h-full w-full absolute z-0`}
                                source={{
                                    uri:
                                        brand.background_image_url ||
                                        'https://popchew-web.b-cdn.net/default-bg.png',
                                }}
                                resizeMode="cover"
                            />
                        )}

                        <View
                            style={tw`w-full h-full justify-center items-center px-container`}
                        >
                            {/* Availability text */}
                            <View
                                style={tw`px-[10px] py-[7px] absolute top-2 rounded-full border justify-center overflow-hidden  ${
                                    isLoading
                                        ? 'bg-transparent border-transparent'
                                        : `bg-black border-white`
                                }`}
                            >
                                <Text
                                    style={tw`font-ppa-wbi text-xs text-white text-center`}
                                >
                                    {isLoading
                                        ? ' '
                                        : isAvailable
                                        ? isOpen
                                            ? 'Available'
                                            : 'No stores open'
                                        : 'Unavailable'}
                                </Text>
                            </View>

                            {/* Brand face */}

                            <View
                                style={tw`w-full ${
                                    isDoritos && isWeb ? 'h-full' : ''
                                }`}
                            >
                                {!isDoritos && (
                                    <View
                                        style={tw`absolute h-full w-full items-center justify-start ${
                                            isWeb ? '-top-20' : '-top-25'
                                        }`}
                                    >
                                        {brand.logo_image_url ? (
                                            <Image
                                                style={tw`h-full w-30`}
                                                source={{
                                                    uri: brand?.logo_image_url,
                                                }}
                                                resizeMode="contain"
                                            />
                                        ) : (
                                            <Text
                                                style={tw`font-ppa-wbi text-xl`}
                                            >
                                                {brand.name}
                                            </Text>
                                        )}
                                    </View>
                                )}

                                <View
                                    style={tw`w-full ${
                                        isDoritos
                                            ? 'h-full '
                                            : `h-[${BRAND_HEAD_SIZE}px] mt-15`
                                    }`}
                                >
                                    <Image
                                        style={tw`h-full w-full ${
                                            showCountdown ? 'top-16' : ''
                                        }`}
                                        source={{
                                            uri: !isDoritos
                                                ? brand?.main_image_url ||
                                                  'https://popchew-web.b-cdn.net/default-main.png'
                                                : brand?.logo_image_url,
                                        }}
                                        resizeMode="contain"
                                    />
                                    {/* {hasWindow === true ? (
                                    <MouseTrackingImage
                                        containerNativeID={containerNativeID}
                                        xOutputRange={ROTATION_RANGE}
                                        yOutputRange={ROTATION_RANGE}
                                        perspective={HEAD_PERSPECTIVE}
                                        style={tw`h-full w-full`}
                                        source={{
                                            uri:
                                                brand?.main_image_url ||
                                                'https://popchew-web.b-cdn.net/default-main.png',
                                        }}
                                        resizeMode="contain"
                                    />
                                ) : hasWindow === false ? (
                                    <LeftRightPivotImage
                                        angle={angle}
                                        scale={BRAND_HEAD_SCALE}
                                        width={BRAND_HEAD_SIZE}
                                        maxAngle={HEAD_ROTATION} // Deg
                                        style={tw`h-full w-full`}
                                        source={{
                                            uri:
                                                brand?.main_image_url ||
                                                'https://popchew-web.b-cdn.net/default-main.png',
                                        }}
                                        resizeMode="contain"
                                    />
                                ) : null} */}
                                </View>
                            </View>

                            {/* {!isDefaultMenuLoading &&
                    !!defaultMenu?.sections &&
                    defaultMenu?.sections.length > 0 && (
                        <View style={tw`absolute bottom-12 w-full`}>
                            <ScrollView
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                contentContainerStyle={tw`px-3`}
                            >
                                {(defaultMenu?.sections[0].items || []).map(
                                    (item) => {
                                        return (
                                            <MenuItemCard
                                                key={item.item_id}
                                                item={item}
                                                navigation={navigation}
                                                cart={cart}
                                                deliveryType={deliveryType}
                                                menuId={defaultMenu.menu_id}
                                                blendedColor={blendedColor}
                                                brandId={brand.id}
                                            />
                                        )
                                    }
                                )}
                            </ScrollView>
                        </View>
                    )} */}
                            {showCountdown && (
                                <View style={tw`absolute top-22`}>
                                    <Text
                                        style={tw`text-white font-ppa-b text-center mb-2`}
                                    >
                                        COLLAB ENDING IN
                                    </Text>
                                    <CountDown
                                        until={clockUntil}
                                        digitTxtStyle={tw``}
                                        digitStyle={tw`border border-white bg-black rounded-xl`}
                                        timeLabelStyle={tw`text-white`}
                                        size={22}
                                    />
                                </View>
                            )}

                            <View style={tw`absolute bottom-3`}>
                                <View style={tw`flex-row`}>
                                    <Text
                                        style={tw`text-white font-ppa-b text-sm`}
                                    >
                                        See all
                                    </Text>
                                    <ChevronRightSvg
                                        color="#fff"
                                        strokeWidth={3}
                                        width={15}
                                        height={20}
                                        viewBox="0 0 20 25"
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </TouchableOpacity>
            </TrackableClick>
        </View>
    )
}
