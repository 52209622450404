import { axios } from '@/lib/httpClient'

interface IngestWebsite {
    email: string
    zip: string
    source?: IngestSource
    brand?: string
}

enum IngestSource {
    eatPopchew = 'eatpopchew.com',
    popchew = 'popchew.com',
    wingSzn = 'wingszn.com',
    snapBack = 'snapbackkitchen.com',
}

export const ingestWebsiteJson = async (data: IngestWebsite) => {
    data.source = IngestSource.eatPopchew
    const response = await axios.post('/ingest/website/json', data)
    return response.data
}
