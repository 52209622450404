import { Image, Text, TouchableOpacity, View } from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'

import { TrackableClick } from '@/components/Analytics'
import { CartNotification } from '@/components/CartNotification'
import { NavbarContainer } from '@/components/NavbarContainer'

import { useProfile } from '@/hooks/query/useProfile'

import { useAccountModal } from '@/contexts/AccountModalContext'

import { getRandomProfileImage } from '@/utils/randomImage'

import tw from '@/tailwind/tailwind'

import { navigateUsingRef } from '../../Navigation/navigateUsingRef'
import DefaultRewards from './components/DefaultRewards'

export const Rewards = () => {
    const { data: profile } = useProfile()

    const { show: showAccountModal } = useAccountModal()

    const onProfilePicPress = () => {
        if (profile) {
            showAccountModal()
            return
        }

        navigateUsingRef('Login')
    }

    return (
        <SafeAreaView edges={['left', 'right', 'top']} style={tw`flex-1`}>
            <NavbarContainer>
                <View style={tw`flex-1 items-start`}>
                    <TrackableClick
                        name="Tapped profile picture in rewards navbar"
                        category="Account"
                    >
                        <TouchableOpacity onPress={onProfilePicPress}>
                            <Image
                                source={
                                    profile?.profile_image_url
                                        ? profile?.profile_image_url
                                        : getRandomProfileImage()
                                }
                                style={tw`h-8 w-8 rounded-full`}
                            />
                        </TouchableOpacity>
                    </TrackableClick>
                </View>

                <Text style={tw`font-ppa-wbi text-2xl text-center flex `}>
                    Rewards
                </Text>
                <View style={tw`flex-1 items-end flex-grow flex-shrink-0`}>
                    <CartNotification />
                </View>
            </NavbarContainer>

            <DefaultRewards />
        </SafeAreaView>
    )
}
