import { TouchableOpacity } from 'react-native'

import { useNavigateBack } from '@/navigation/hooks/useNavigateBack'
import Animated, {
    Extrapolate,
    interpolate,
    useAnimatedStyle,
} from 'react-native-reanimated'

import tw from '@/tailwind/tailwind'

import XSvg from '@/svgs/XSvg'

export const XButton = ({
    scroll,
    top,
}: {
    scroll: Animated.SharedValue<number>
    top: number
}) => {
    const navigateBack = useNavigateBack()
    const animatedStyle = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: interpolate(scroll.value, [0, 100], [42, 0], {
                    extrapolateRight: Extrapolate.CLAMP,
                }),
            },
            {
                translateX: interpolate(scroll.value, [0, 100], [0, 16], {
                    extrapolateRight: Extrapolate.CLAMP,
                }),
            },
            {
                scale: interpolate(scroll.value, [0, 100], [1, 0.75], {
                    extrapolateRight: Extrapolate.CLAMP,
                }),
            },
        ],
    }))
    return (
        <Animated.View
            style={[
                tw`w-10 h-10 rounded-full overflow-hidden absolute flex top-[${
                    // arbitrary numbers that look nice accross devices
                    top > 15 ? top : 12
                }px] right-8 z-20`,
                animatedStyle,
            ]}
        >
            <TouchableOpacity
                style={tw`bg-white rounded-full justify-center items-center h-10 w-full z-10`}
                onPress={() => navigateBack('HomeTabs')}
            >
                <XSvg />
            </TouchableOpacity>
        </Animated.View>
    )
}
