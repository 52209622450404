import { QueryKey, useQuery } from 'react-query'

import { anonAuthorizeIfNotAuth } from '@/features/Auth/api/anonAuthorize'

import { GetProfileResponse } from '@/types/User'

import { axios } from '@/lib/httpClient'
import { updateOneSignalUser } from '@/lib/onesignal'
import { useStore } from '@/lib/store'

import { updateHotJar } from '@/utils/enableHotJot'
import storage from '@/utils/storage'
import { updateUxcamUser } from '@/utils/uxcam'

const ONE_HOUR = 1000 * 60 * 60
const FIVE_SECONDS = 1000 * 5

export const profileQueryKey: QueryKey = 'profile'
export const profileQueryFn = async () => {
    await anonAuthorizeIfNotAuth()

    const response = await axios.get<GetProfileResponse>('/order/profile')
    return response.data
}

export const useProfile = () => {
    const setSelectedLocation = useStore((state) => state.setSelectedLocation)
    return useQuery<GetProfileResponse>(profileQueryKey, profileQueryFn, {
        onSuccess: async (profile) => {
            // Make last selected address the globally selected one
            const addressId = await storage.getAddressId()
            if (addressId) {
                const address = profile.addresses.find(
                    (adrs) => adrs.address_id === addressId
                )
                if (address) {
                    await setSelectedLocation(address)
                } else {
                    await storage.removeAddressId()
                }
            }

            const { user_id, email, phone, first, last } = profile

            // update onesignal
            updateOneSignalUser({
                id: user_id,
                email,
                phone,
            })

            // update uxcam
            updateUxcamUser({ first, last, userId: user_id, email })

            // update hotjar
            updateHotJar({ first, last, userId: user_id, email, phone })
        },
        cacheTime: ONE_HOUR,
        staleTime: FIVE_SECONDS,
    })
}
