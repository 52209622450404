import * as React from 'react'

import Svg, { Path, Rect, SvgProps } from 'react-native-svg'

const JuiceCupSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            d="M6 10h12l-.835 9.181A2 2 0 0 1 15.174 21H8.826a2 2 0 0 1-1.991-1.819L6 10Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <Rect
            x={4}
            y={6}
            width={16}
            height={4}
            rx={2}
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <Path
            d="m14 3-2 3M7 14h10"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </Svg>
)

export default JuiceCupSvg
