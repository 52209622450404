import React from 'react'

import { Platform, View, ViewToken } from 'react-native'

import { FlatList } from 'react-native-gesture-handler'

import tw from '@/tailwind/tailwind'

import { BrandPageType } from '..'

export const BrandPageSections = ({
    data,
    brandSectionListRef,
    setSelectedPageIdx,
    scrollTabs,
    viewWidth,
}: {
    data: BrandPageType[]
    brandSectionListRef: React.MutableRefObject<FlatList<BrandPageType> | null>
    setSelectedPageIdx: (idx: number) => void
    scrollTabs: (idx: number) => void
    viewWidth: number
}) => {
    const onViewRef = React.useRef(
        (viewableItems: {
            viewableItems: ViewToken[]
            changed: ViewToken[]
        }) => {
            if (viewableItems.viewableItems[0]) {
                const index = viewableItems.viewableItems[0].index
                if (index || index === 0) {
                    setSelectedPageIdx(index)
                    scrollTabs(index)
                }
            }
        }
    )
    const viewConfigRef = React.useRef({ itemVisiblePercentThreshold: 50 })
    return (
        <FlatList
            ref={brandSectionListRef}
            data={data}
            horizontal
            showsHorizontalScrollIndicator={true}
            onScrollToIndexFailed={(info) => {
                const wait = new Promise((resolve) => setTimeout(resolve, 500))
                wait.then(() => {
                    brandSectionListRef.current?.scrollToIndex({
                        index: info.index,
                        animated: false,
                    })
                })
            }}
            pagingEnabled={Platform.OS !== 'web'}
            scrollEnabled={Platform.OS !== 'web'}
            overScrollMode={'never'}
            nestedScrollEnabled
            onViewableItemsChanged={onViewRef.current}
            viewabilityConfig={viewConfigRef.current}
            keyExtractor={(item) => item.title}
            renderItem={(item) => {
                const { component: content } = item.item
                return (
                    <View style={tw`flex-1 w-[${viewWidth}px]`}>{content}</View>
                )
            }}
        />
    )
}
