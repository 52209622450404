import {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import { Image, ListRenderItemInfo, Text, View } from 'react-native'

import {
    BottomSheetModal as BottomSheetModalType,
    TouchableOpacity,
} from '@gorhom/bottom-sheet'
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'
import randomColor from 'randomcolor'
import Animated from 'react-native-reanimated'
import { useQuery } from 'react-query'

import { useUserPoints } from '@/features/Auth/api/useUserPoints'

import { Prize } from '@/types/Prize'

import { BottomSheetModal } from '@/components/BottomSheetModal'

import { showMessage } from '@/utils/showMessage'

import tw from '@/tailwind/tailwind'

import StarGroupingSvg from '@/svgs/StarGroupingSvg'

import { HandleContentChangeType, HandleScrollEventType } from '../..'
import { getPrizes } from '../../api/getPrizes'
import { SectionIsLoading } from '../../components/SectionIsLoading'
import { AnimatedFlatList } from '../../components/wrappers/AnimatedFlatList'
import { BrandPageSectionWrapper } from '../../components/wrappers/BrandPageSectionWrapper'
import { FullPageView } from '../../components/wrappers/FullPageView'
import { StickyHeader } from '../../components/wrappers/StickyHeader'
import { PrizeModal } from './components/PrizeModal'

const NoPrizes = ({ tabBarHeight }: { tabBarHeight: number }) => {
    return (
        <FullPageView tabBarHeight={tabBarHeight}>
            <View style={tw`flex-1 justify-center items-center`}>
                <Text style={tw`font-ppa-b`}>
                    No prizes added to the store yet.
                </Text>
            </View>
        </FullPageView>
    )
}

interface PrizeItemType extends ListRenderItemInfo<Prize> {
    setSelectedPrize: Dispatch<SetStateAction<Prize | undefined>>
    prizeModalRef: React.RefObject<BottomSheetModalMethods>
}
const PrizeItemRender = (prizeItem: PrizeItemType) => {
    const { item: prize, prizeModalRef, setSelectedPrize } = prizeItem
    const { image_url, name, points_cost } = prize
    const openModal = () => prizeModalRef.current?.present()

    const bgColor = useMemo(() => randomColor(), [])

    return (
        <View
            style={tw`w-full bg-pc-shade-20 rounded-md overflow-hidden flex-1 m-2`}
        >
            <TouchableOpacity
                onPress={() => {
                    setSelectedPrize(prize)
                    openModal()
                }}
            >
                <View style={tw`h-28 bg-[${bgColor}]`}>
                    <Image
                        style={tw`w-full h-full`}
                        resizeMode="contain"
                        source={{
                            uri: image_url,
                        }}
                    />
                </View>
                <View style={tw`p-2`}>
                    <Text style={tw`font-ppa-black pb-6`}>{name}</Text>
                    <View
                        style={tw`rounded-full bg-white self-start py-2 px-4 mt-auto`}
                    >
                        <Text style={tw`font-ppa-black text-xs`}>
                            {points_cost === 0
                                ? 'free'
                                : `${points_cost.toLocaleString()} ${
                                      points_cost === 1 ? 'pt' : 'pts'
                                  }`}
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}

export const Prizes = ({
    brandId,
    scroll,
    handleScroll,
    handleContentSizeChange,
    pageHeight,
    viewWidth,
    top,
    tabBarHeight,
    showPrizeId,
}: {
    brandId: string
    scroll: Animated.SharedValue<number>
    handleScroll: HandleScrollEventType
    handleContentSizeChange: HandleContentChangeType
    pageHeight: number
    viewWidth: number
    top: number
    tabBarHeight: number
    showPrizeId?: string
}) => {
    const [selectedPrize, setSelectedPrize] = useState<Prize>()
    const prizeModalRef = useRef<BottomSheetModalType>(null)
    const { data: userPointsRes } = useUserPoints()
    const userPoints = userPointsRes?.points

    const { data: prizesResponse, isLoading: isPrizesLoading } = useQuery(
        ['prizes'],
        () => getPrizes(''),
        {
            staleTime: Infinity,
        }
    )

    // filter out everything besides brand prizes and generic prizes
    // sort brand prizes before generic prizes then by point cost lowest to highest
    const GENERIC_PRIZE_BRAND_ID = 'some-other-id'
    const prizes = useMemo(
        () =>
            prizesResponse?.prizes
                .filter(
                    (prize) =>
                        prize.brand_id === brandId ||
                        prize.brand_id === GENERIC_PRIZE_BRAND_ID
                )
                .sort((a, b) => {
                    if (a.brand_id === b.brand_id)
                        return a.points_cost > b.points_cost ? 1 : -1

                    return a.brand_id === brandId
                        ? -1
                        : a.brand_id > b.brand_id
                        ? 1
                        : -1
                }) || [],

        [brandId, prizesResponse]
    )

    // Ability to show prize on entry
    useEffect(() => {
        if (showPrizeId && !isPrizesLoading) {
            const prize = prizes.find((prize) => prize.id === showPrizeId)
            if (prize) {
                setSelectedPrize(prize)
                prizeModalRef.current?.present()
            } else {
                showMessage({
                    message: 'Prize not found for this brand',
                    type: 'warning',
                })
            }
        }
    }, [showPrizeId, isPrizesLoading])

    const isPrizes = prizes.length > 0

    let content = (
        <SectionIsLoading
            color={'black'}
            sectionName="prizes"
            tabBarHeight={tabBarHeight}
        />
    )

    if (!isPrizesLoading) {
        content = !isPrizes ? (
            <NoPrizes tabBarHeight={tabBarHeight} />
        ) : (
            <>
                <AnimatedFlatList
                    flatlistProps={{
                        renderItem: (itemPrize) => (
                            <PrizeItemRender
                                {...itemPrize}
                                setSelectedPrize={setSelectedPrize}
                                prizeModalRef={prizeModalRef}
                            />
                        ),
                        data: prizes,
                        numColumns: 2,
                        keyExtractor: (prize: Prize) => prize.id,
                    }}
                    handleContentSizeChange={handleContentSizeChange}
                    pageHeight={pageHeight}
                    viewWidth={viewWidth}
                    handleScroll={handleScroll}
                    top={top}
                    additionalTopSpacing={80}
                    tabBarHeight={tabBarHeight}
                />
                <BottomSheetModal
                    snapPoints={['75%']}
                    keyboardBehavior="interactive"
                    ref={prizeModalRef}
                >
                    <PrizeModal
                        prizeModalRef={prizeModalRef}
                        selectedPrize={selectedPrize}
                    />
                </BottomSheetModal>
            </>
        )
    }

    return (
        <>
            <BrandPageSectionWrapper
                pageHeight={pageHeight}
                viewWidth={viewWidth}
                backgroundColor={tw.color('bg-pc-light')}
            >
                {content}
            </BrandPageSectionWrapper>

            <StickyHeader
                tabBarHeight={tabBarHeight}
                scroll={scroll}
                top={top}
                styles={tw`px-2`}
                topSpacing={16}
                children={
                    <View
                        style={tw`bg-pc-secondary w-full rounded flex-row justify-between py-2 px-container items-center`}
                    >
                        <View>
                            <Text style={tw`text-white font-ppa-b`}>
                                Points
                            </Text>
                            <Text style={tw`text-white text-lg font-ppa-wbi`}>
                                {userPoints !== undefined
                                    ? userPoints.toLocaleString()
                                    : 'Loading'}
                            </Text>
                        </View>
                        <StarGroupingSvg />
                    </View>
                }
            />
        </>
    )
}
