import { useEffect, useState } from 'react'

import {
    ActivityIndicator,
    Image,
    RefreshControl,
    Text,
    View,
} from 'react-native'

import { navigateUsingRef } from '@/navigation/navigateUsingRef'
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'

import { TrackableClick } from '@/components/Analytics'
import { CartNotification } from '@/components/CartNotification'
import { ComingSoonPill } from '@/components/Elements/ComingSoonPill'
import { NavbarContainer } from '@/components/NavbarContainer'
import { Toggle } from '@/components/Toggle'

import { useProfile } from '@/hooks/query/useProfile'
import { useRefreshFix } from '@/hooks/useRefreshFix'

import { useAccountModal } from '@/contexts/AccountModalContext'

import { getRandomProfileImage } from '@/utils/randomImage'

import tw from '@/tailwind/tailwind'

import { useOrderFeed } from './api/useOrderFeed'
import { SocialFeedItem } from './components/SocialFeedItem'

const FriendsView = () => {
    return (
        <View style={tw`flex-1 flex justify-center`}>
            <Text style={tw`text-center font-ppa-wbi text-3xl px-10 mb-3`}>
                all the homies love mayo
            </Text>
            <ComingSoonPill />
        </View>
    )
}

export const SocialFeed = () => {
    const { data: profile } = useProfile()
    const {
        data: lastNOrders,
        refetch: fetchMoreOrders,
        isLoading: isOrderFeedLoading,
    } = useOrderFeed()

    const [orderFeed, setOrderFeed] = useState<OrderFeedOrder[]>([])
    const [view, setView] = useState<'global' | 'friends'>('global')

    const { onRefresh, isRefreshing } = useRefreshFix([fetchMoreOrders])

    useEffect(() => {
        if (!lastNOrders) return
        if (lastNOrders.length === 0) return
        if (orderFeed.length === 0) return
        // when get new orders in
        // for each new order, from last to first
        const tempOrders: OrderFeedOrder[] = []
        for (const order of lastNOrders) {
            // see if it was made after the first order was made
            // if it is add to a temp array

            const mostRecentOrderSaved = orderFeed[0]

            if (
                new Date(order.created_on) >
                new Date(mostRecentOrderSaved.created_on)
            ) {
                tempOrders.push(order)
            } else {
                break
            }
        }

        // combine arrays
        setOrderFeed((oldArray) => [...tempOrders, ...oldArray])
    }, [lastNOrders])

    useEffect(() => {
        if (lastNOrders) setOrderFeed(lastNOrders)
    }, [isOrderFeedLoading])

    const { show: showAccountModal } = useAccountModal()

    const onProfilePicPress = () => {
        if (profile) {
            showAccountModal()
            return
        }

        navigateUsingRef('Login')
    }

    const toggleOptions = [
        { label: 'global 🌎', value: 'global' },
        { label: 'friends 👫', value: 'friends' },
    ]

    const loading = (
        <View style={tw`flex-1 flex  justify-center `}>
            <ActivityIndicator />
        </View>
    )

    return (
        <SafeAreaView style={tw`flex-1`} edges={['right', 'left', 'top']}>
            <NavbarContainer>
                <View style={tw`flex-1 items-start`}>
                    <TrackableClick
                        name="Tapped profile picture in feed navbar"
                        category="Account"
                    >
                        <TouchableOpacity onPress={onProfilePicPress}>
                            <Image
                                source={
                                    profile?.profile_image_url
                                        ? profile?.profile_image_url
                                        : getRandomProfileImage()
                                }
                                style={tw`h-8 w-8 rounded-full`}
                            />
                        </TouchableOpacity>
                    </TrackableClick>
                </View>

                <Text style={tw`font-ppa-wbi text-2xl text-center flex `}>
                    Feed
                </Text>
                <View style={tw`flex-1 items-end flex-grow flex-shrink-0`}>
                    <CartNotification />
                </View>
            </NavbarContainer>
            <View style={tw`px-container mt-8`}>
                <Toggle
                    variant="legacy"
                    toggleProps={{
                        options: toggleOptions,
                        initial: 0,
                        onPress: (value) => {
                            if (value !== 'global' && value !== 'friends')
                                return
                            setView(value)
                        },
                    }}
                    trackableName={'toggled social feed option'}
                    trackableCategory={'Social Feed'}
                    additionalTrackableProps={{ prevValue: view }}
                />
            </View>

            {view === 'friends' ? (
                <FriendsView />
            ) : isOrderFeedLoading ? (
                loading
            ) : (
                <FlatList
                    refreshControl={
                        <RefreshControl
                            refreshing={isRefreshing}
                            onRefresh={onRefresh}
                        />
                    }
                    showsVerticalScrollIndicator={false}
                    data={orderFeed}
                    keyExtractor={(item) =>
                        item.first + item.last + item.city + item.created_on
                    }
                    renderItem={({ item: feedItem }) => (
                        <SocialFeedItem
                            key={
                                feedItem.first +
                                feedItem.last +
                                feedItem.city +
                                feedItem.created_on
                            }
                            brand_name={feedItem.brand_name}
                            brand_id={feedItem.brand_id}
                            first={feedItem.first}
                            last={feedItem.last}
                            city={feedItem.city}
                            state={feedItem.state}
                            created_on={feedItem.created_on}
                            items={feedItem.items}
                        />
                    )}
                />
            )}
        </SafeAreaView>
    )
}
