import { useEffect } from 'react'

import { OrderPlacedProps } from '@/navigation/types/ScreenProps'

import { OrderPlacedView } from '../components/OrderPlacedView'

export const OrderPlaced = ({ navigation, route }: OrderPlacedProps) => {
    const { orderId } = route.params || {}

    useEffect(() => {
        if (!orderId) navigation.navigate('HomeTabs')
    }, [orderId])
    if (!orderId) return null

    return (
        <OrderPlacedView
            orderId={orderId}
            isModal={false}
            navigation={navigation}
        />
    )
}
