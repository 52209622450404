import { Cart, CartResponse } from '@/types/Cart'

import { axios, parseAxiosError } from '@/lib/httpClient'
import { queryClient } from '@/lib/queryClient'

import { showMessage } from '@/utils/showMessage'
import storage from '@/utils/storage'

import { cartQueryKey } from './useCart'

export const setCart = async (cart: Cart) => {
    storage.setCartId(cart.cart_id)
    const newCart: Partial<Cart> = {
        ...cart,
        items: [...cart.items],
    }

    try {
        const response = await axios.post<CartResponse>('/order/cart', newCart)
        return response.data as Cart
    } catch (err) {
        const { status } = parseAxiosError(err)
        // we're doing the following as there is currently an error
        // where peoples carts are being created without menu_ids and getting 502
        // which is blocking swiching fulfillment types
        if (status === 502) {
            if (cart.items.length > 0)
                showMessage({
                    type: 'warning',
                    message:
                        'An unexpected error occured and your cart was emptied.',
                })
            console.error('Cart got a 502, so we cleared it')
            await storage.removeCartId()
            queryClient.invalidateQueries(cartQueryKey)
        }
    }
}
