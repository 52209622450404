import { useEffect, useState } from 'react'

import { ActivityIndicator, Text, TextInput, View } from 'react-native'

import { ConfirmAddressProps } from '@/navigation/types/ScreenProps'
import DropDownPicker from 'react-native-dropdown-picker'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'

import { DeliveryType, StateAbbreviation } from '@/types/Address'

import { Button } from '@/components/Button'

// import { FullScreenLoader } from '@/components/FullScreenLoader' // replace when merged in with dev
import { useStore } from '@/lib/store'

import { STATES } from '@/utils/constants'

import tw from '@/tailwind/tailwind'

import XSvg from '@/svgs/XSvg'

import { getAddress } from '../../api/getAddress'
import { useCreateAddress } from '../../api/useCreateAddress'
import { useSetLocationWithClearCart } from '../../utils/useSetLocationWithClearCart'

export const ConfirmAddress = ({ navigation, route }: ConfirmAddressProps) => {
    const {
        addressId,
        fulfillment_type = DeliveryType.pickup,
        brandId,
    } = route.params || {}
    const { mutateAsync: createAddress, isLoading: isCreateAddressLoading } =
        useCreateAddress()
    const setLocationWithClearCart = useSetLocationWithClearCart()
    const setDeliveryType = useStore((state) => state.setDeliveryType)
    setDeliveryType(fulfillment_type)

    const [open, setOpen] = useState(false)
    const [state, setState] = useState<StateAbbreviation | null>(null)
    const [states, setStates] = useState(
        STATES.map((stateAbbrv) => {
            return {
                label: stateAbbrv,
                value: stateAbbrv,
            }
        })
    )

    const addressInitial = {
        address1: '',
        address2: '',
        city: '',
        zip: '',
        delivery_instructions: '',
        lat: '',
        lon: '',
    }

    const [address, setAddress] = useState(addressInitial)
    const [isLoadingAddressDetails, setIsLoadingAddressDetails] =
        useState(false)

    const handleAddressChange =
        (inputName: keyof typeof addressInitial) => (inputValue: string) => {
            setAddress({
                ...address,
                [inputName]: inputValue,
            })
        }

    useEffect(() => {
        const getAddressDetails = async () => {
            setIsLoadingAddressDetails(true)
            try {
                const addressDetails = await getAddress({ addressId })
                const {
                    address1,
                    address2 = '',
                    city,
                    state,
                    zip,
                    delivery_instructions = '',
                    lat,
                    lon,
                } = addressDetails

                setAddress({
                    address1,
                    address2,
                    city,
                    zip,
                    delivery_instructions,
                    lat,
                    lon,
                })
                setState(state)
            } catch {
            } finally {
                setIsLoadingAddressDetails(false)
            }
        }

        getAddressDetails()
    }, [])

    useEffect(() => {
        if (!addressId) navigation.navigate('HomeTabs')
    }, [])

    if (!addressId) return null
    if (isLoadingAddressDetails)
        return (
            <SafeAreaView style={tw`flex-1 items-center justify-center`}>
                <ActivityIndicator />
            </SafeAreaView>
        )

    const onConfirm = async () => {
        if (!state) return
        const data = await createAddress({
            ...address,
            state,
            fulfillment_type,
        })

        await setLocationWithClearCart({
            ...address,
            state,
            address_id: data.user_address_id,
        })

        if (brandId) {
            navigation.navigate('BrandPage', { brandId })
        } else {
            navigation.navigate('HomeTabs')
        }
    }

    return (
        <SafeAreaView style={tw`container flex-1`}>
            <View style={tw`flex-row items-center pb-8`}>
                <TouchableOpacity
                    hitSlop={{ top: 6, right: 6, bottom: 6, left: 6 }}
                    onPress={() => navigation.navigate('HomeTabs')}
                >
                    <XSvg />
                </TouchableOpacity>

                <Text style={tw`font-ppa-b text-lg ml-4 self-start`}>
                    Confirm your address
                </Text>
            </View>

            <TextInput
                style={tw`input mb-2.5`}
                placeholder="Address"
                onChangeText={handleAddressChange('address1')}
                value={address.address1}
            />
            <TextInput
                style={tw`input mb-2.5`}
                placeholder="Apt/Suite/Floor (optional)"
                onChangeText={handleAddressChange('address2')}
                value={address.address2}
            />
            <TextInput
                style={tw`input mb-2.5`}
                placeholder="City"
                onChangeText={handleAddressChange('city')}
                value={address.city}
            />

            <DropDownPicker
                style={tw`input mb-2.5 flex-row rounded-none`}
                textStyle={tw`font-ppa-b`}
                dropDownContainerStyle={tw`rounded-none`}
                listItemContainerStyle={tw`px-container py-1 my-1 flex-row`}
                disableBorderRadius={true}
                open={open}
                value={state}
                items={states}
                setOpen={setOpen}
                setValue={setState}
                setItems={setStates}
            />
            <TextInput
                style={tw`input mb-2.5`}
                placeholder="Zip"
                onChangeText={handleAddressChange('zip')}
                value={address.zip}
            />
            <TextInput
                style={tw`input`}
                placeholder="Delivery Notes"
                onChangeText={handleAddressChange('delivery_instructions')}
                value={address.delivery_instructions}
            />

            <View style={tw`flex-1 flex-col justify-end`}>
                {isCreateAddressLoading ? (
                    <View style={tw`h-14 flex items-center justify-center`}>
                        <ActivityIndicator />
                    </View>
                ) : (
                    <Button
                        onPress={() => onConfirm()}
                        variant="primary"
                        text="Confirm"
                        trackableCategory="Address"
                        trackableName="Tapped confirm on confirm your address page (deeplink)"
                    />
                )}
            </View>
        </SafeAreaView>
    )
}
