import { Platform, View } from 'react-native'

import { BrandScreenProps } from '@/navigation/types/ScreenProps'
import { LinearGradient } from 'expo-linear-gradient'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@/components/Button'

import tw from '@/tailwind/tailwind'

export const ViewCartButton = ({
    navigation,
}: {
    navigation: BrandScreenProps['navigation']
}) => {
    return (
        <View style={tw`absolute bottom-0 w-full`} pointerEvents="box-none">
            <LinearGradient
                colors={['transparent', 'rgba(0,0,0,0.8)']}
                style={tw`absolute h-full w-full top-0 left-0`}
                pointerEvents="none"
            />

            <SafeAreaView
                edges={['bottom']}
                style={tw`pt-4 px-4 ${Platform.OS === 'web' ? 'pb-4 ' : ''}`}
                pointerEvents="box-none"
            >
                <Button
                    variant="action"
                    text="View Cart"
                    onPress={() => navigation.navigate('Cart')}
                    trackableName="Tapped View Cart"
                    trackableCategory="Brands"
                />
            </SafeAreaView>
        </View>
    )
}
