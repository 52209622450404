import { useMemo } from 'react'

import { useCart } from '@/features/Cart/api/useCart'

export const useCountCartItems = () => {
    const { data: cart } = useCart()

    const count = useMemo(
        () => cart?.items.reduce((prev, curr) => prev + curr.count, 0),
        [cart]
    )

    return cart ? count || 0 : 0
}
