import { Alert, Platform } from 'react-native'

interface ShowAlertProps<BT extends string> {
    title: string
    description: string
    buttonTexts: BT[]
    buttonHandlers: {
        [key in BT]: () => void
    }
}

export function showAlert<BT extends string>({
    title,
    description,
    buttonTexts,
    buttonHandlers,
}: ShowAlertProps<BT>) {
    if (Platform.OS !== 'web') {
        Alert.alert(
            title,
            description,
            buttonTexts.map((bt) => ({
                text: bt,
                onPress: buttonHandlers[bt],
            }))
        )
    } else {
        // since the confirm dialog halts the rendering of the page,
        // this lets any animations to completely render and then show confirm dialog
        setTimeout(async () => {
            if (buttonTexts.length === 1) {
                alert(description)
                return
            }

            const isYesClicked = confirm(description)

            const onYesClick = buttonHandlers[buttonTexts[0]]
            const onCancelClick = buttonHandlers[buttonTexts[1]]

            if (isYesClicked) {
                onYesClick()
            } else {
                onCancelClick()
            }
        }, 200)
    }
}
