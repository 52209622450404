import React, { useState } from 'react'

import {
    FlatListProps,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
} from 'react-native'

import Animated from 'react-native-reanimated'
import { useDebouncedCallback } from 'use-debounce'

import tw from '@/tailwind/tailwind'

import { HandleContentChangeType, HandleScrollEventType } from '../..'
import { INITIAL_HEADER_HEIGHT } from '../../utils/constants'

export const AnimatedFlatList = ({
    flatlistProps,
    pageHeight,
    viewWidth,
    additionalTopSpacing = 0,
    handleScroll,
    handleContentSizeChange,
    top,
    tabBarHeight,
}: {
    flatlistProps: FlatListProps<any>
    pageHeight: number
    viewWidth: number
    additionalTopSpacing?: number
    handleScroll: HandleScrollEventType
    handleContentSizeChange: HandleContentChangeType
    top: number
    tabBarHeight: number
}) => {
    flatlistProps
    const { data, renderItem, keyExtractor, numColumns } = flatlistProps

    const [blockUpdateIndex, setBlockUpdateIndex] = useState<boolean>(false)
    /**
     * Debounce callback
     * This is neccesary for web, as none of the scrollEnd methods word
     * https://github.com/necolas/react-native-web/issues/2249
     * Makes sure that after anyone has stopped scrolling for x ms
     * We mark the scroll as complete by unblocking update index
     */
    const debouncedSetBlockUpdateIndex = useDebouncedCallback(
        // function
        () => {
            setBlockUpdateIndex(false)
        },
        // delay in ms
        160
    )

    const handleFlatListScroll = async (
        event: NativeSyntheticEvent<NativeScrollEvent>
    ) => {
        if (Platform.OS === 'web') {
            debouncedSetBlockUpdateIndex()
        }
        if (blockUpdateIndex) return

        handleScroll(event)
    }

    return (
        <Animated.FlatList
            onLayout={() => {
                handleFlatListScroll({
                    nativeEvent: { contentOffset: { y: 0 } },
                } as NativeSyntheticEvent<NativeScrollEvent>)
            }}
            data={data}
            numColumns={numColumns}
            keyExtractor={keyExtractor}
            onScroll={handleFlatListScroll}
            scrollEventThrottle={16}
            onContentSizeChange={handleContentSizeChange}
            contentContainerStyle={{
                padding: 8,
                paddingTop:
                    INITIAL_HEADER_HEIGHT +
                    tabBarHeight +
                    additionalTopSpacing +
                    top,
            }}
            style={[tw`h-[${pageHeight}px] w-[${viewWidth}px]`]}
            bounces={false}
            renderItem={renderItem}
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled
            onMomentumScrollEnd={() => {
                setBlockUpdateIndex(false)
            }}
            onScrollEndDrag={() => {
                setBlockUpdateIndex(false)
            }}
        />
    )
}
