import React from 'react'

import { Linking, Text, TextProps } from 'react-native'

interface Props {
    instructions: string
    textStyle?: TextProps['style']
    clickableTextStyle?: TextProps['style']
}

/**
 *
 * This component is used to render unstructured text with email or phone number as hyperlink
 *
 *
 * EXAMPLE INPUT:
 * Thanks for your order! Your food is being prepared by Eleven B. You can call them at 6129917883 If you have any questions.
 * Or you can reach our customer support team at any time by calling 888-711-1774 or by emailing support@popchew.com. Enjoy your meal!
 *
 * EXAMPLE OUTPUT (as html):
 * Thanks for your order! Your food is being prepared by Eleven B. You can call them at <a href="tel:6129917883">6129917883</a>
 * If you have any questions. Or you can reach our customer support team at any time by calling
 * <a href="tel:8887111774">888-711-1774</a> or by emailing <a href="mailto:support@popchew.com">support@popchew.com</a>. Enjoy your meal!
 *
 */

const EMAIL_REGEX = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g
const PHONE_REGEX =
    /(\+?\d{1,2}[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}|\(\d{3}\)[-.\s]?\d{3}[-.\s]?\d{4}\b/g

export const TextWithEmailOrPhone: React.FC<Props> = ({
    instructions,
    textStyle,
    clickableTextStyle,
}) => {
    const handleEmailClick = (email: string) => {
        Linking.openURL(`mailto:${email}`)
    }

    const handlePhoneClick = (phone: string) => {
        Linking.openURL(`tel:${phone}`)
    }

    const renderTextWithLinks = (text: string) => {
        const emailMatches = text.match(EMAIL_REGEX)
        const phoneMatches = text.match(PHONE_REGEX)

        if (!emailMatches && !phoneMatches) {
            return <Text>{text}</Text>
        }

        const parts = text.split(
            /(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b|\+?\d{1,2}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3,4}[-.\s]?\d{3,4}|\(\d{3}\)[-.\s]?\d{3}[-.\s]?\d{4}\b)/g
        )

        return (
            <Text>
                {parts.map((part, i) => {
                    if (emailMatches?.includes(part)) {
                        return (
                            <Text
                                style={clickableTextStyle}
                                key={`${part}-${i}`}
                                onPress={() => handleEmailClick(part)}
                            >
                                {part}
                            </Text>
                        )
                    } else if (phoneMatches?.includes(part)) {
                        return (
                            <Text
                                style={clickableTextStyle}
                                key={`${part}-${i}`}
                                onPress={() => handlePhoneClick(part)}
                            >
                                {part}
                            </Text>
                        )
                    } else {
                        return (
                            <Text style={textStyle} key={`${part}-${i}`}>
                                {part}
                            </Text>
                        )
                    }
                })}
            </Text>
        )
    }

    return renderTextWithLinks(instructions)
}
