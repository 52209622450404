import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const BellSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18 17H7"
        />
        <Path
            d="m6.3 10.992-.998.05.999-.05Zm.079 1.55.998-.05-.998.05Zm-1.757 3.951-.632-.775.632.775Zm-.262.214-.632-.776.632.776ZM9 17h1a1 1 0 0 0-1-1v1Zm6 0v-1a1 1 0 0 0-1 1h1Zm4.64-.293.632-.776-.632.776Zm-.262-.214-.632.775.632-.775Zm-1.756-3.951-1-.05 1 .05Zm.077-1.55.999.05-.999-.05Zm-10.4-.05A4.706 4.706 0 0 1 12 6V4a6.706 6.706 0 0 0-6.698 7.043l1.998-.1Zm.078 1.55-.077-1.55-1.998.1.078 1.55 1.997-.1Zm-2.123 4.776a5.788 5.788 0 0 0 2.123-4.776l-1.997.1a3.788 3.788 0 0 1-1.39 3.126l1.264 1.55Zm-.262.214.262-.214-1.264-1.55-.262.213 1.264 1.55ZM4.464 16c.786 0 1.136.986.528 1.482l-1.264-1.55c-.85.692-.36 2.068.736 2.068v-2ZM9 16H4.464v2H9v-2Zm1 2v-1H8v1h2Zm2 2a2 2 0 0 1-2-2H8a4 4 0 0 0 4 4v-2Zm2-2a2 2 0 0 1-2 2v2a4 4 0 0 0 4-4h-2Zm0-1v1h2v-1h-2Zm5.536-1H15v2h4.536v-2Zm-.528 1.482C18.4 16.986 18.75 16 19.536 16v2c1.096 0 1.585-1.376.736-2.069l-1.264 1.55Zm-.262-.214.262.214 1.264-1.55-.262-.214-1.264 1.55Zm-2.123-4.776a5.788 5.788 0 0 0 2.123 4.776l1.264-1.55a3.788 3.788 0 0 1-1.39-3.126l-1.997-.1Zm.078-1.55-.078 1.55 1.997.1.078-1.55-1.997-.1ZM12 6a4.706 4.706 0 0 1 4.7 4.942l1.998.1A6.706 6.706 0 0 0 12 4v2Z"
            fill="currentColor"
        />
        <Path
            d="M12 5V3"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </Svg>
)

export default BellSvg
