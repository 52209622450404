import { pushGtmTag } from '@/lib/GTM'
import { postMixpanelEvent } from '@/lib/mixpanel'
import { sendOneSignalOutcome } from '@/lib/onesignal'

import { TrackableProps } from './types'

export const postTrackingEvent = async ({
    name,
    category,
    properties,
    onesignal = false,
    onesignalValue,
    mixpanel = true,
    gtm = true,
}: TrackableProps): Promise<void> => {
    if (mixpanel) postMixpanelEvent(name, category, properties)

    if (gtm)
        pushGtmTag(name, {
            category,
            ...properties,
        })

    if (onesignal) sendOneSignalOutcome(name, onesignalValue)
}
