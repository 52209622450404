import { useMemo } from 'react'

import { useQuery } from 'react-query'

import { useValidateDeliveryInstances } from '@/features/LocationPicker/api/useValidateDeliveryInstances'

import { Address, DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { axios } from '@/lib/httpClient'
import { useStore } from '@/lib/store'

import { DORITOS_BRAND_ID } from '@/utils/constants'
import { getHashFromInstanceBoolArrayQuery } from '@/utils/getHashFromInstanceArray'
import { sortInstances } from '@/utils/instance/sortInstances'

type GetInstancesResponse = {
    user_address_id: string
    instances: Instance[]
}

const getInstances = async (
    address: Address | null,
    deliveryType: DeliveryType
) => {
    if (!address) Promise.reject(new Error('Selected location is missing'))

    const response = await axios.post<GetInstancesResponse>('/order/address', {
        fulfillment_type: deliveryType,
        ...address,
    })

    return response.data.instances
}

export const instancesQueryKey = (
    addressId: string | undefined,
    deliveryType: DeliveryType
) => ['instances', addressId, deliveryType]

export const useInstances = () => {
    const deliveryType = useStore((state) => state.location.deliveryType)
    const selectedLocation = useStore(
        (state) => state.location.selectedLocation
    )

    const {
        data: instancArr = [],
        isLoading: isLoadingInstances,
        refetch,
        dataUpdatedAt,
    } = useQuery(
        instancesQueryKey(selectedLocation?.address_id, deliveryType),
        async () => {
            let instances = await getInstances(selectedLocation, deliveryType)

            sortInstances(instances, deliveryType)

            // remove doritos if pickup
            if (deliveryType === DeliveryType.pickup)
                instances = instances.filter(
                    (instance) => instance.brand_id !== DORITOS_BRAND_ID
                )

            return instances
        },
        {
            enabled: Boolean(selectedLocation),
            staleTime: 60 * 1000,
        }
    )

    /** START If Delivery, validate instances are in delivery range */

    const isDelivery = deliveryType === DeliveryType.delivery

    const validatedDeliveryInstancesBools = useValidateDeliveryInstances(
        instancArr,
        selectedLocation?.address_id,
        deliveryType
    )

    // filter out instances that are not in delivery range
    const mappedInstances = useMemo(() => {
        return instancArr.filter(
            (_instance, idx) => validatedDeliveryInstancesBools[idx].data
        )
    }, [getHashFromInstanceBoolArrayQuery(validatedDeliveryInstancesBools)])

    const areAnyInstancesLoading = validatedDeliveryInstancesBools.some(
        (query) => query.isLoading
    )

    /** END validate delivery logic */

    const isLoading =
        isLoadingInstances || (isDelivery && areAnyInstancesLoading)

    const instancesToReturn = isDelivery ? mappedInstances : instancArr

    return { data: instancesToReturn, isLoading, refetch, dataUpdatedAt }
}

export const useInstancesByBrandId = (brandId: string) => {
    const instancesQuery = useInstances()

    const data = useMemo(
        () =>
            instancesQuery.data
                ? instancesQuery.data.filter(
                      (instance) => instance.brand_id === brandId
                  )
                : [],
        [instancesQuery.data, brandId]
    )

    return { ...instancesQuery, data }
}
