import { useEffect, useMemo, useRef, useState } from 'react'

import { Image, Platform, View } from 'react-native'

import Animated, {
    Easing,
    useAnimatedStyle,
    useDerivedValue,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated'

import tw from '@/tailwind/tailwind'

type MouthAnimationProps = {
    onAnimationDone: () => void
    finishUpAnimation: boolean
}

export const MouthAnimation = ({
    onAnimationDone,
    finishUpAnimation,
}: MouthAnimationProps) => {
    const initialWidth = 0
    const maxWidth = 104
    const initialDuration = 45000
    // const initialDuration = 5000
    const superSpeedDuration = 150

    const startTime = useMemo(() => Date.now(), [])
    const [duration, setDuration] = useState(initialDuration)
    const progress = useSharedValue(initialWidth)
    const width = useDerivedValue(() => progress.value * maxWidth)
    const timer: React.MutableRefObject<NodeJS.Timer | null> = useRef(null)

    // handle navigating on animation end
    useEffect(() => {
        // delay navigation to give time for human to process animation
        const visualDelayBeforeNavigation = 200

        if (timer.current) clearInterval(timer.current)
        timer.current = setTimeout(() => {
            onAnimationDone()
        }, duration + visualDelayBeforeNavigation)

        return () => {
            if (timer.current) clearTimeout(timer.current)
        }
    }, [duration])

    const handleClick = () => {
        // get starting value for animaiton
        const currentMs = Date.now()
        const elapsedMs = currentMs - startTime
        const elapsedProgress = elapsedMs / initialDuration
        progress.value = elapsedProgress

        setDuration(superSpeedDuration)

        progress.value = withTiming(1, {
            // solve bug on web
            duration: Platform.OS === 'web' ? 0 : superSpeedDuration,
            easing: Easing.linear,
        })
    }

    useEffect(() => {
        if (finishUpAnimation) handleClick()
    }, [finishUpAnimation])

    useEffect(() => {
        progress.value = 1
    }, [])

    const style = useAnimatedStyle(() => {
        return {
            width: withTiming(width.value, {
                duration,
                // makes it faster in the start
                easing: Easing.bezier(0, 0.35, 0.9, 1),
            }),
        }
    })

    return (
        <View style={tw`relative`}>
            <Image
                source={require('@/assets/grey-logo.png')}
                style={tw`w-[104px] h-[65px]`}
            />
            <Animated.View
                style={[tw`absolute left-0 overflow-hidden `, style]}
            >
                <Image
                    source={require('@/assets/mouth-large.png')}
                    style={[tw`w-[104px] h-[65px]`]}
                />
            </Animated.View>
        </View>
    )
}
