import { useEffect, useState } from 'react'

import { Keyboard } from 'react-native'

export const useKeyboard = () => {
    const [isKeyboardVisible, setKeyboardVisible] = useState(false)
    const [keyBoardWillShow, setKeyBoardWillShow] = useState(false)

    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            () => {
                setKeyboardVisible(true)
            }
        )

        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            () => {
                setKeyboardVisible(false)
                setKeyBoardWillShow(false)
            }
        )

        const keyboardWillShowListener = Keyboard.addListener(
            'keyboardWillShow',
            () => {
                setKeyBoardWillShow(true)
            }
        )

        return () => {
            keyboardDidHideListener.remove()
            keyboardDidShowListener.remove()
            keyboardWillShowListener.remove()
        }
    }, [])

    return {
        isKeyboardOpen: isKeyboardVisible,
        isKeyboardOpening: keyBoardWillShow,
    }
}
