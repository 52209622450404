import { Image, View } from 'react-native'

import { useBrand } from '@/hooks/query/useBrand'

import tw from '@/tailwind/tailwind'

const ThumbnailVariant = {
    small: 'h-36',
}

export const Thumbnail = ({
    brandId,
    variant = 'small',
}: {
    brandId?: string | undefined
    variant?: keyof typeof ThumbnailVariant
}) => {
    const { data: brand } = useBrand(brandId)
    const backgroundImage = brand?.background_image_url
    const mainImage = brand?.main_image_url

    const hasBrandAssets = backgroundImage && mainImage

    return (
        <View
            style={tw`w-full h-full relative justify-center items-center ${ThumbnailVariant[variant]} rounded overflow-hidden`}
        >
            {hasBrandAssets ? (
                <>
                    <Image
                        source={{ uri: mainImage }}
                        resizeMode="contain"
                        style={tw`w-1/2 h-4/6 absolute z-10`}
                    />
                    <Image
                        source={{ uri: backgroundImage }}
                        resizeMode="cover"
                        style={tw`w-full h-full`}
                    />
                </>
            ) : (
                <Image
                    source={require('@/assets/images/menu-item-placeholder.png')}
                    style={tw`w-full h-full`}
                />
            )}
        </View>
    )
}
