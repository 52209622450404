import { useRef, useState } from 'react'

import { Animated, LayoutAnimation, Text, View } from 'react-native'

import { GeneralProps } from '@/navigation/types/ScreenProps'
import { useNavigation } from '@react-navigation/native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { TrackableClick } from '@/components/Analytics'

import { timeAgo } from '@/utils/timeAgo'

import tw from '@/tailwind/tailwind'

import ChevronDownSvg from '@/svgs/ChevronDownSvg'

import { SocialFeedItemIcon } from './SocialFeedItemIcon'

const toggleAnimation = (animationDuration = 300) => {
    return {
        duration: animationDuration,
        update: {
            duration: animationDuration,
            property: LayoutAnimation.Properties.scaleXY,
            type: LayoutAnimation.Types.easeInEaseOut,
        },
        delete: {
            duration: animationDuration,
            property: LayoutAnimation.Properties.opacity,
            type: LayoutAnimation.Types.easeInEaseOut,
        },
    }
}

export const SocialFeedItem = ({
    first,
    last,
    brand_name: brandName,
    brand_id: brandId,
    city,
    state,
    created_on: createdAt,
    items,
}: OrderFeedOrder) => {
    const timeSince = timeAgo(new Date(createdAt).getTime())
    const navigation = useNavigation<GeneralProps['navigation']>()
    const hasLocation = city && state
    const animationDuration = 300

    const [isToggled, setIsToggled] = useState(false)
    const animationController = useRef(new Animated.Value(0)).current

    const toggleListItem = () => {
        const config = {
            duration: animationDuration,
            toValue: isToggled ? 0 : 1,
            useNativeDriver: true,
        }
        Animated.timing(animationController, config).start()
        LayoutAnimation.configureNext(toggleAnimation(animationDuration))
        setIsToggled(!isToggled)
    }

    const arrowTransform = animationController.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '180deg'],
    })

    return (
        <View
            style={tw`px-container py-6 border-b-2 border-pc-shade-20 overflow-hidden`}
        >
            <View style={tw`flex-row `}>
                <SocialFeedItemIcon
                    brandName={brandName}
                    brandId={brandId}
                    first={first}
                    last={last}
                    profileImage={''}
                />
                <View style={tw`flex-1 px-container`}>
                    <Text style={tw`font-ppa-b text-pc-shade-100 text-base`}>
                        {first} {last} ordered{' '}
                        <TrackableClick
                            name={'Clicked brand name in social feed'}
                            category={'Social Feed'}
                            properties={{ brandId, brandName }}
                        >
                            <Text
                                style={tw`underline`}
                                onPress={() =>
                                    navigation.navigate('BrandPage', {
                                        brandId,
                                    })
                                }
                            >
                                {brandName}
                            </Text>
                        </TrackableClick>
                    </Text>
                    {hasLocation && (
                        <Text style={tw`text-pc-shade-100`}>
                            in {city.trim()}, {state}
                        </Text>
                    )}
                </View>
                <View style={tw`flex-col justify-between items-end`}>
                    <Text style={tw`text-pc-shade-60 font-ppa-b`}>
                        {timeSince}
                    </Text>
                    <TouchableOpacity
                        hitSlop={{ top: 32, right: 32, bottom: 32, left: 32 }}
                        style={tw`flex-row justify-end items-end`}
                        onPress={() => toggleListItem()}
                    >
                        <Animated.View
                            style={{ transform: [{ rotateZ: arrowTransform }] }}
                        >
                            <ChevronDownSvg color={tw.color('pc-shade-60')} />
                        </Animated.View>
                    </TouchableOpacity>
                </View>
            </View>

            {isToggled && (
                // pointerEvents={'none'} necessary for hitSlop on arrow to work correctly
                <View style={[tw`ml-20 mt-2 z-0`]} pointerEvents={'none'}>
                    {items.map((item, idx) => {
                        return (
                            <View key={idx}>
                                <Text style={tw`text-pc-shade-100`}>
                                    {item.quantity}x {item.name}
                                </Text>
                                {item.modifiers.map((modifier, idx) => {
                                    return (
                                        <Text
                                            key={idx}
                                            style={tw`ml-2 text-pc-shade-100`}
                                        >
                                            -{' '}
                                            {modifier.quantity &&
                                            modifier.quantity > 1
                                                ? `${modifier.quantity}x `
                                                : ''}
                                            {modifier.name}
                                        </Text>
                                    )
                                })}
                            </View>
                        )
                    })}
                </View>
            )}
        </View>
    )
}
