import { Text, View } from 'react-native'

import Checkbox from 'expo-checkbox'

import { postTrackingEvent } from '@/components/Analytics'

import tw from '@/tailwind/tailwind'

interface SignupForEmailCheckboxProps {
    signupForEmail: boolean
    setSignupForEmail: (value: boolean) => void
}

export const SignupForEmailCheckbox = ({
    signupForEmail,
    setSignupForEmail,
}: SignupForEmailCheckboxProps) => {
    const handleCheckboxChange = async (value: boolean) => {
        try {
            setSignupForEmail(value)
            await postTrackingEvent({
                category: 'Checkout',
                name: `Tapped signup for email to ${value}`,
            })
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <View style={tw`py-8`}>
            <View style={tw`flex-row bg-pc-shade-20 mb-4 p-[17px]`}>
                <Text style={tw`w-4/5 font-ppa-b text-base leading-[21px]`}>
                    Sign me up to receive emails from PepsiCo Tasty Rewards,
                    PepsiCo and its brands so I never miss out on exciting
                    updates, offers or sweepstakes.
                </Text>
                <View style={tw`flex-1 justify-center items-center`}>
                    <Checkbox
                        color={signupForEmail ? '#0066FF' : '#000'}
                        value={signupForEmail}
                        onValueChange={handleCheckboxChange}
                    />
                </View>
            </View>

            <Text style={tw`font-ppa-reg text-[13px] leading-[18px]`}>
                <Text style={tw`font-ppa-b text-[13px]`}>
                    {'Privacy Notice: '}
                </Text>
                By checking the box, you agree to share your contact details and
                order history with PepsiCo to receive personalized marketing
                communications. Stop sharing at any time by using the
                unsubscribe link in emails.
            </Text>
        </View>
    )
}
