const plugin = require('tailwindcss/plugin')

module.exports = {
    theme: {
        extend: {
            fontFamily: {
                'ppa-b': ['PPAgrandir-TextBold', 'sans-serif'],
                'ppa-wbi': ['PPAgrandir-WideBlackItalic', 'sans-serif'],
                'ppa-black': ['PPAgrandir-GrandHeavy', 'sans-serif'],
                'ppa-reg': ['PPAgrandir-Regular', 'sans-serif'],
                'ppp-sb': ['PPPangramSansRounded-SemiBold', 'sans-serif'],
                'ppp-b': ['PPPangramSansRounded-Bold', 'sans-serif'],
                'ppp-reg': ['PPPangramSansRounded-Regular', 'sans-serif'],
                'ppp-m': ['PPPangramSansRounded-Medium', 'sans-serif'],
                'me-bl': ['MonumentExtended-Black', 'sans-serif'],
            },
            colors: {
                'pc-light': 'white',
                'pc-dark': 'black',
                'pc-primary': '#FFFC00',
                'pc-secondary': '#0066FF',
                'pc-warning': '#FE5C00',
                'pc-success': '#3BCA8F',
                // If a design in figma has a shade of grey that is not one of these
                // ping designer to change and go with the closest one
                'pc-shade-120': '#606060',
                'pc-shade-100': '#6C6C6C',
                'pc-shade-80': '#979797',
                'pc-shade-60': '#C2C2C2',
                'pc-shade-40': '#E0E0E0',
                'pc-shade-20': '#EEEEEE',
                // bighuman
                'grey-800': '#303137',
                'grey-700': '#434450',
                'grey-500': '#6D6F81',
                'grey-400': '#9D9FB0',
                'grey-300': '#B0B0BC',
                'grey-200': '#CBCDD3',
                'grey-100': '#ECEDF0',
            },
            padding: {
                container: '16px',
            },
            text: {
                'page-title': 'text-2xl font-ppa-wbi',
            },
            flex: {
                2: '2 2 0%',
                5: '5 5 0%',
                10: '10 10 0%',
            },
            opacity: {
                85: '0.85',
            },
            zIndex: {
                100: '100',
            },
            borderRadius: {
                '4xl': '2rem',
            },
            borderWidth: {
                3: '3px',
            },
        },
        screens: {
            sm: '380px',
            md: '420px',
            lg: '680px',
            // or maybe name them after devices for `tablet:flex-row`
            tablet: '1024px',
        },
    },
    plugins: [
        plugin(({ addUtilities }) => {
            addUtilities({
                container: { padding: 16 },
                input: `py-[17px] bg-pc-shade-20 font-ppa-b text-base px-3.5 leading-snug`,
                label: `text-pc-shade-80 font-ppa-b text-sm mb-1`,
                'page-title': `text-xl font-ppa-b ml-4`,
            })
        }),
    ],
}
