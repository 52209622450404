import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const CheckSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M3.333 8.667 6 11.333l6.667-6.666"
            stroke={color || '#fff'}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default CheckSvg
