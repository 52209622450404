import * as React from 'react'

import Svg, { Circle, Path, SvgProps } from 'react-native-svg'

const FeedSvg = ({ color, ...props }: SvgProps) => {
    return (
        <Svg
            width={31}
            height={26}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Circle cx={10.581} cy={5.14} r={5.14} fill={color} />
            <Path
                d="M3.544 13.007a6.93 6.93 0 0 1 4.329-1.519h4.942c1.677 0 3.296.616 4.55 1.73l.206.184c.376.334.692.73.932 1.172l.551 1.01c.595 1.09.982 2.28 1.142 3.51l.4 3.062a1.798 1.798 0 0 1-3.481.823l-1.999-5.746V26H5.442v-8.767l-2.037 5.856a1.634 1.634 0 0 1-3.164-.749l.46-3.533c.137-1.044.427-2.061.863-3.02l.4-.879a4.6 4.6 0 0 1 1.314-1.688l.266-.213Z"
                fill={color}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.092 7.318a3.659 3.659 0 1 0 0-7.318 3.659 3.659 0 0 0 0 7.318Zm-1.928.86c-1.12 0-2.207.382-3.082 1.082l-.19.151a3.272 3.272 0 0 0-.54.55c.766.729 1.451 1.543 2.04 2.427l.042-.12v.182a14.125 14.125 0 0 1 2.227 6.06h4.66v-6.242l1.423 4.09a1.28 1.28 0 0 0 2.478-.585l-.285-2.18a7.15 7.15 0 0 0-.813-2.499l-.392-.718a3.105 3.105 0 0 0-.663-.835l-.148-.131a4.875 4.875 0 0 0-3.238-1.231h-3.519Z"
                fill={color}
            />
        </Svg>
    )
}

export default FeedSvg
