import { useQuery } from 'react-query'

import { axios } from '@/lib/httpClient'

const getOrderFeed = async (params?: OrderFeedParams) => {
    const { brandId, limit } = params || {}
    const response = await axios.get<OrderFeedResponse>(
        `/order/feed?${brandId ? `brand_id=${brandId}` : ''}${
            limit ? `&limit=${limit}` : ''
        }`
    )
    return response.data.order_feed
}

export const useOrderFeed = (params?: useOrderFeedParams) =>
    useQuery(
        ['orderFeed', params?.isFriendsOnly, params?.brandId, params?.limit],
        () => getOrderFeed(params),
        {
            refetchOnWindowFocus: false,
        }
    )
