import { MenuResponse } from '@/types/Menu'

import { axios } from '@/lib/httpClient'

export const fetchMenu = async (instanceId: string | undefined) => {
    return typeof instanceId === 'undefined'
        ? Promise.reject(new Error('Invalid instance id'))
        : (
              await axios.get<MenuResponse>('/order/menu', {
                  params: {
                      instance_id: instanceId,
                  },
              })
          ).data
}
