import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

interface BinProps extends SvgProps {
    size?: 'sm' | 'lg'
}

const BinSvg = ({ color, size = 'sm', ...props }: BinProps) => (
    <Svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color ?? '#000'}
        {...props}
    >
        {size === 'sm' ? (
            <Path
                d="m11.666 3.667-.578 8.095A1.333 1.333 0 0 1 9.758 13H4.24c-.7 0-1.28-.54-1.33-1.238l-.578-8.095m3.333 2.666v4m2.667-4v4m.666-6.666v-2A.667.667 0 0 0 8.333 1H5.666a.667.667 0 0 0-.667.667v2m-3.333 0h10.667"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        ) : (
            <Path
                d="m19 7-.867 12.142A2 2 0 0 1 16.138 21H7.862a2 2 0 0 1-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3M4 7h16"
                stroke="currentColor" //"#111827"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        )}
    </Svg>
)

export default BinSvg
