import { Text, View } from 'react-native'

import tw from '@/tailwind/tailwind'

export const ComingSoonPill = () => {
    return (
        <View
            style={[
                tw`bg-pc-success text-center py-2 px-8 rounded-full self-center`,
                {
                    transform: [
                        {
                            rotate: '-5deg',
                        },
                    ],
                },
            ]}
        >
            <Text style={tw`text-white text-sm font-ppa-b`}>Coming Soon</Text>
        </View>
    )
}
