import clone from 'clone'

import { Order } from '@/types/Order'

export const removeNonSelectedItemsFromOrder = (order: Order): Order => {
    const clonedOrder = clone(order)

    // For each item in order
    clonedOrder.cart.items = clonedOrder.cart.items.map((cartItem) => {
        const item = cartItem.item
        item.modifier_groups =
            // for each modifier group in item
            item.modifier_groups?.map((mGroup) => {
                // remove modifiers that are not selected
                const modifiers = mGroup.modifiers.filter(
                    (modifier) => modifier.is_selected
                )

                return { ...mGroup, modifiers }
            }) || []
        return cartItem
    })

    return clonedOrder
}
