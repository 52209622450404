import { View } from 'react-native'

import tw from '@/tailwind/tailwind'

export const BrandPageSectionWrapper = ({
    children,
    pageHeight,
    viewWidth,
    backgroundColor = 'black',
}: {
    children: JSX.Element
    pageHeight: number
    viewWidth: number
    backgroundColor?: string
}) => {
    return (
        <View
            style={[
                tw`h-[${pageHeight}px] w-[${viewWidth}px] `,
                { backgroundColor },
            ]}
        >
            {children}
        </View>
    )
}
