import { View } from 'react-native'

import { DeliveryType } from '@/types/Address'

import tw from '@/tailwind/tailwind'

import { CheckoutTotals } from '../utils/calcCheckoutTotals'
import { OrderNumberBlock } from './OrderNumberBlock'

type OrderDetailsProps = {
    checkoutTotals: CheckoutTotals
    fulfillmentType: DeliveryType
}

export const OrderDetails = ({
    checkoutTotals,
    fulfillmentType,
}: OrderDetailsProps) => {
    return (
        <View style={tw`w-full`}>
            <View style={tw`px-container`}>
                <OrderNumberBlock
                    label="Subtotal"
                    price={checkoutTotals.subtotal}
                />
                {checkoutTotals.couponValue ? (
                    <OrderNumberBlock
                        label="Coupon"
                        price={checkoutTotals.couponValue}
                    />
                ) : null}
                {fulfillmentType === DeliveryType.delivery && (
                    <OrderNumberBlock
                        label="Delivery Fee"
                        price={checkoutTotals.deliveryFee}
                    />
                )}
                <OrderNumberBlock
                    label="Taxes and Fees"
                    price={checkoutTotals.taxesAndFees}
                />
                <OrderNumberBlock
                    label="Tip"
                    price={checkoutTotals.tip}
                    isLast
                />
            </View>
        </View>
    )
}
