import { CartItem } from '@/types/Cart'

import { totalItemPrice } from './totalItemPrice'

export const totalCartPrice = (cartItems: CartItem[]) => {
    return cartItems.reduce(
        (prev, curr) => prev + curr.count * totalItemPrice(curr.item),
        0
    )
}
