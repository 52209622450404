import { FC } from 'react'

import { Text, TouchableOpacity, View } from 'react-native'

import { TrackableClick } from '@/components/Analytics'

import tw from '@/tailwind/tailwind'

import MinusSvg from '@/svgs/MinusSvg'
import PlusSvg from '@/svgs/PlusSvg'

export interface QuantityControlsProps {
    quantity: number
    setQuantity: (_: number) => void
    testID?: string
}

export const QuantityControls: FC<QuantityControlsProps> = ({
    quantity,
    setQuantity,
    testID,
}) => {
    return (
        <View style={tw`flex-row justify-center items-center mb-7`}>
            <TrackableClick
                name="Tapped reduce cart quantity of item from menu"
                category="Order"
                properties={{
                    'qty before': quantity,
                    'qty after': quantity <= 1 ? quantity : quantity - 1,
                }}
                testID={`${testID}-reduce`}
            >
                <TouchableOpacity
                    disabled={quantity <= 1}
                    style={tw`h-14 w-14 rounded-full border border-pc-shade-20 flex justify-center items-center`}
                    onPress={() => {
                        if (quantity <= 1) return
                        setQuantity(quantity - 1)
                    }}
                >
                    <View style={tw`${quantity <= 1 ? 'opacity-20' : ''}`}>
                        <MinusSvg />
                    </View>
                </TouchableOpacity>
            </TrackableClick>
            <Text style={tw`text-2xl font-ppa-b mx-3 min-w-[32px] text-center`}>
                {quantity}
            </Text>
            <TrackableClick
                name="Tapped increase cart quantity of item from menu"
                category="Order"
                properties={{
                    'qty before': quantity,
                    'qty after': quantity + 1,
                }}
                testID={`${testID}-increase`}
            >
                <TouchableOpacity
                    style={tw`h-14 w-14 rounded-full border border-pc-shade-20 flex justify-center items-center`}
                    onPress={() => setQuantity(quantity + 1)}
                >
                    <PlusSvg />
                </TouchableOpacity>
            </TrackableClick>
        </View>
    )
}
