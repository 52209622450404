import * as React from 'react'

import Svg, { Circle, Path, SvgProps } from 'react-native-svg'

const CartSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            d="m4.833 6.5-.34-1.703A.667.667 0 0 1 5.147 4h7.742c.503 0 .825.536.588.98l-1.955 3.667a.667.667 0 0 1-.589.353H5.88a.667.667 0 0 1-.654-.536L4.833 6.5Zm0 0-.732-3.955A.667.667 0 0 0 3.445 2H2"
            stroke="currentColor"
            strokeWidth={1.333}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Circle
            cx={10.666}
            cy={12.667}
            r={1.333}
            stroke="currentColor"
            strokeWidth={1.333}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Circle
            cx={5.333}
            cy={12.667}
            r={1.333}
            stroke="currentColor"
            strokeWidth={1.333}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default CartSvg
