import { View } from 'react-native'

export const getSectionX = (
    sectionIdx: number,
    r: React.MutableRefObject<(View | null)[]>
): Promise<{ fx: number }> => {
    return new Promise((resolve) => {
        r.current[sectionIdx]?.measure((fx, _fy, _width, _height, _px, _py) => {
            resolve({ fx })
        })
    })
}
