import { useCallback } from 'react'

import { Instance } from '@/types/Instance'
import { Item, Modifier } from '@/types/Menu'
import { Menu } from '@/types/Menu'

import { showMessage } from '@/utils/showMessage'

import { useCart } from '../api/useCart'
import { useRemoveFromCart } from './cartMutations'

const isItemSameAndValid = (item: Item, menuItem: Item) => {
    const isAvailable = menuItem.is_available
    const isSamePrice = item.price === menuItem.price
    const isSameTax = item.tax_rate === menuItem.tax_rate

    return isAvailable && isSamePrice && isSameTax
}

export const useValidateCart = () => {
    const { data: cart } = useCart()
    const { mutateAsync: removeItemFromCart } = useRemoveFromCart()

    const validateCart = useCallback(
        async (instanceId: Instance['instance_id'], menu: Menu) => {
            if (!cart) return
            if (cart.instance_id !== instanceId) return
            let removedItemsCt = 0

            // create a structured menu that is faster to search
            const structuredMenu: { [itemId: Item['item_id']]: Item } = {}
            for (const section of menu.sections) {
                for (const item of section.items) {
                    structuredMenu[item.item_id] = item
                }
            }

            // create a dict of all modifiers on menu for fast search
            const structuredModifierDict: {
                [modifierId: Modifier['item_id']]: Modifier
            } = {}
            for (const menuItem of Object.values(structuredMenu)) {
                if (!menuItem.modifier_groups) continue
                for (const modifierGroup of menuItem.modifier_groups) {
                    for (const modifier of modifierGroup.modifiers) {
                        structuredModifierDict[modifier.item_id] = modifier
                    }
                }
            }

            // for each item in cart
            for (const cartItem of cart.items) {
                const { item } = cartItem
                const { item_id: itemId } = item

                //  find item in menu and check availability
                const menuItem = structuredMenu[itemId]
                if (!menuItem) {
                    await removeItemFromCart(cartItem.cart_item_id)
                    removedItemsCt++
                    continue
                }

                if (!isItemSameAndValid(item, menuItem)) {
                    await removeItemFromCart(cartItem.cart_item_id)
                    removedItemsCt++
                    continue
                }

                // for each modifier on item, check that it's valid else remove whole item
                if (!item.modifier_groups) continue

                for (const modifierGroup of item.modifier_groups) {
                    // for each modifier on item
                    for (const modifier of modifierGroup.modifiers) {
                        if (!modifier.is_selected) continue
                        // find the menu verion with same id
                        const menuModifier =
                            structuredModifierDict[modifier.item_id]

                        // if there isn't a menu version remove the item
                        if (!menuModifier) {
                            await removeItemFromCart(cartItem.cart_item_id)
                            removedItemsCt++
                            continue
                        }

                        // if item is not same or invalid remove it
                        if (!isItemSameAndValid(modifier, menuModifier)) {
                            await removeItemFromCart(cartItem.cart_item_id)
                            removedItemsCt++
                            continue
                        }
                    }
                }
            }

            if (removedItemsCt) {
                return showMessage({
                    type: 'warning',
                    message:
                        'One or more of the items in your cart are no longer available and have been removed.',
                })
            }
        },
        [cart]
    )

    return [validateCart]
}
