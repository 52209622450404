import { Text, View } from 'react-native'

import { DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { useInstanceAvailability } from '@/utils/instance/useInstanceAvailability'

import tw from '@/tailwind/tailwind'

type StoreStatusProps = {
    instance: Instance
    fulfillmentType: DeliveryType
}
export const StoreStatus = ({
    instance,
    fulfillmentType,
}: StoreStatusProps) => {
    const { isOpen, opensSoon, message } = useInstanceAvailability(
        instance,
        fulfillmentType
    )

    return (
        <>
            {isOpen === false && (
                <View style={tw`mt-3 bg-pc-shade-20 `}>
                    <View style={tw`p-3 flex-row justify-between items-center`}>
                        <Text style={tw`font-ppa-b text-pc-warning`}>
                            Store is now closed. {opensSoon && ' ' + message}
                        </Text>
                    </View>
                </View>
            )}
        </>
    )
}
