import { DateTime } from 'luxon'

import { DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { days } from '@/utils/dates'

interface DateTimeRange {
    // day: DayOfWeek
    start: DateTime
    end: DateTime
}

export type InstanceHoursStatus = {
    isOpen: boolean
    opensSoon: boolean
    message: string
}

const ONE_DAY_MS = 86400000
const ONE_HOUR_MS = 3600000

const getTimeFromTimeStamp = (timestamp: string): DateTime => {
    const [hours, minutes, seconds] = timestamp.split(':')
    return DateTime.fromObject({
        hour: Number(hours) || 0,
        minute: Number(minutes) || 0,
        second: Number(seconds) || 0,
    })
}

export const getInstanceAvailability = (
    fulfillment_type: DeliveryType,
    instanceHours: Instance['hours'],
    instanceTimezone: Instance['timezone'],
    hourOverrides: Instance['hours_overrides'] = []
): InstanceHoursStatus => {
    const status: InstanceHoursStatus = {
        isOpen: false,
        message: '',
        opensSoon: false,
    }

    // Luxon appears to have incorrect offset for standard America/New_York. It is -5 hours, but Luxon returns -4 hours. Weird
    // For now we will assume user always orders from same time zone as instance
    // With this assumption, could use instance time zone or user time zone
    // I will use user timezone for now

    // If we want to stop this assumption, we need to uncomment the tzOffsetMS calc and use nowInstanceOffsetMS in place of currentInstanceOffsetMs
    const now = DateTime.now() // Now with local time zone
    const currentOffsetMS = now.offset * 60 * 1000
    // const nowInstance = DateTime.now().setZone(instanceTimezone) // Now in instance time zone
    // const nowInstanceOffsetMS = nowInstance.offset * 60 * 1000 // Instance time zone offset in ms
    // const tzOffsetMS = nowInstanceOffsetMS - tcurrentOffsetMSzOffsetMS // Time zone offset in ms
    const tzOffsetMS = 0

    const getInstanceTimeFromTimeStamp = (timestamp: string): DateTime => {
        return getTimeFromTimeStamp(timestamp).plus(tzOffsetMS)
    }

    const today = new Date()
    const todayIndex = today.getDay()
    const todayName = days[todayIndex]
    const tomorrowName = days[(todayIndex + 1) % 7]

    const todayRanges = instanceHours[todayName][fulfillment_type].ranges
    const tomorrowRanges = instanceHours[tomorrowName][fulfillment_type].ranges

    if (!todayRanges) return status

    todayRanges.sort((a, b) => {
        return a.start_time > b.start_time ? 1 : -1
    })

    const timeRanges: DateTimeRange[] = todayRanges.map((range) => {
        return {
            start: getInstanceTimeFromTimeStamp(range.start_time),
            end: getInstanceTimeFromTimeStamp(range.end_time),
        }
    })

    if (tomorrowRanges) {
        const upToMidnightRange = todayRanges?.find(
            (range) => range.end_time === '23:59:00'
        )
        const midnightRange = tomorrowRanges?.find(
            (range) => range.start_time === '00:00:00'
        )

        if (midnightRange && upToMidnightRange) {
            timeRanges.pop()
            timeRanges.push({
                start: getInstanceTimeFromTimeStamp(
                    upToMidnightRange.start_time
                ),
                end: getInstanceTimeFromTimeStamp(midnightRange.end_time).plus(
                    ONE_DAY_MS
                ),
            })
        }
    }

    let opensAt: DateTime | null = null
    for (let r of timeRanges) {
        if (r.start < now && r.end > now) {
            status.isOpen = true
        }
        if (r.start > now && r.start < now.plus(ONE_HOUR_MS)) {
            opensAt = r.start
        }
    }

    // HOUR OVERRIDES
    const overridesFormat = "yyyy-MM-dd'T'HH:mm:ss"
    for (let ho of hourOverrides) {
        if (ho.delivery_type !== fulfillment_type) continue
        const start = DateTime.fromFormat(ho.start_time, overridesFormat).plus(
            currentOffsetMS
        )
        const end = DateTime.fromFormat(ho.end_time, overridesFormat).plus(
            currentOffsetMS
        )
        if (start < now && end > now) {
            if (ho.store_open) {
                status.isOpen = true
            } else {
                status.isOpen = false
            }
        }
        if (start > now && start < now.plus(ONE_HOUR_MS)) {
            if (ho.store_open) opensAt = start
        }
    }

    // If not open, find opens soon time and set message
    if (!status.isOpen) {
        if (opensAt) {
            status.opensSoon = true
            status.message = `Opens at\n ${opensAt.toLocaleString(
                DateTime.TIME_SIMPLE
            )}`
        } else {
            status.message = 'Closed'
        }
    }

    return status
}
