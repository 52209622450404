import { Text, TouchableOpacity, View } from 'react-native'

import { TrackableClick } from '@/components/Analytics'

import tw from '@/tailwind/tailwind'

import BinSvg from '@/svgs/BinSvg'
import MinusSvg from '@/svgs/MinusSvg'
import PlusSvg from '@/svgs/PlusSvg'

interface CounterProps {
    handleCountIncrement: () => void
    handleCountDecrement: () => void
    count: number
}

export const Counter = ({
    handleCountIncrement,
    handleCountDecrement,
    count,
}: CounterProps) => {
    return (
        <View style={tw`flex-row items-center`}>
            <TrackableClick
                name="Tapped reduce quantity of item icon from cart"
                category="Checkout"
                properties={{
                    'qty before': count,
                    'qty after': count - 1,
                }}
            >
                <TouchableOpacity
                    onPress={handleCountDecrement}
                    style={tw`justify-center items-center w-8 h-8 border border-pc-shade-20 rounded-full`}
                >
                    {count > 1 ? <MinusSvg /> : <BinSvg />}
                </TouchableOpacity>
            </TrackableClick>
            <Text
                style={tw`font-ppa-b text-sm mx-1.5 min-w-[20px] text-center`}
            >
                {count}
            </Text>
            <TrackableClick
                name="Tapped increase quantity of item from cart"
                category="Checkout"
                properties={{
                    'qty before': count,
                    'qty after': count + 1,
                }}
            >
                <TouchableOpacity
                    onPress={handleCountIncrement}
                    style={tw`justify-center items-center w-8 h-8 border border-pc-shade-20 rounded-full`}
                >
                    <PlusSvg />
                </TouchableOpacity>
            </TrackableClick>
        </View>
    )
}
