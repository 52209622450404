import { useMutation, useQueryClient } from 'react-query'

import { GetProfileResponse } from '@/types/User'

import { axios } from '@/lib/httpClient'
import { sendOneSignalOutcome } from '@/lib/onesignal'

import { CreateAddressRequest, CreateAddressResponse } from '../types'

export const useCreateAddress = (shouldUpdateCache = true) => {
    const queryClient = useQueryClient()
    return useMutation(
        async (newAddress: CreateAddressRequest) => {
            let addressCopy = { ...newAddress, no_instances: true }
            if (shouldUpdateCache) {
                delete addressCopy?.address_id
            }

            const response = await axios.post<CreateAddressResponse>(
                '/order/address',
                addressCopy
            )
            if (shouldUpdateCache) sendOneSignalOutcome('Created user address')

            return response.data
        },
        {
            onMutate: async (newAddress) => {
                if (!shouldUpdateCache) return
                await queryClient.cancelQueries('profile')
                const prevProfile =
                    queryClient.getQueryData<GetProfileResponse>('profile')

                queryClient.setQueryData('profile', {
                    ...(prevProfile || {}),
                    addresses: [...(prevProfile?.addresses || []), newAddress],
                })

                return { prevProfile }
            },
            onError: (error, _variables, context) => {
                console.error(`error mutating profile`, error)
                if (!context) return
                queryClient.setQueryData('profile', context.prevProfile)
            },
            onSuccess: () => {
                if (!shouldUpdateCache) return
                queryClient.refetchQueries('profile')
            },
        }
    )
}
