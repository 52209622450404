import React from 'react'

import SwitchSelector from 'react-native-switch-selector'

import { DeliveryType } from '@/types/Address'

import tw from '@/tailwind/tailwind'

import { TrackableElementProps, postTrackingEvent } from '../Analytics'

const variants = {
    default: {
        backgroundColor: tw.color('grey-700'),
        borderColor: tw.color('grey-700'),
        buttonColor: tw.color('black'),
        borderRadius: 12,
        textColor: tw.color('white'),
        textStyle: tw`font-ppp-b text-sm `,
        selectedTextStyle: tw`font-ppp-b text-sm text-pc-primary`,
        textContainerStyle: tw`mt-0.5 rounded-xl`,
        selectedTextContainerStyle: tw`mt-0.5 rounded-xl`,
        hasPadding: true,
        valuePadding: 2,
        height: 52,
    },
    legacy: {
        borderColor: tw.color('pc-shade-40'),
        buttonColor: 'black',
        hasPadding: true,
        valuePadding: 6,
        textStyle: tw`font-ppa-b`,
        selectedTextStyle: tw`font-ppa-b`,
        selectedColor: 'white',
        textColor: tw.color('pc-shade-100'),
        backgroundColor: tw.color('pc-shade-40'),
        height: 56,
        textContainerStyle: tw`py-2`,
        selectedTextContainerStyle: tw`py-2`,
    },
}

type ToggleProps = {
    toggleProps: {
        options: { label: string; value: string }[]
        initial: number
        onPress?: (value: DeliveryType | string) => void
    }
    trackableName: TrackableElementProps['name']
    trackableCategory: TrackableElementProps['category']
    additionalTrackableProps?: TrackableElementProps['properties']
    variant?: keyof typeof variants
}

export const Toggle = React.forwardRef<SwitchSelector, ToggleProps>(
    (props, ref) => {
        const { variant } = props
        interface SwitchFix extends React.Component {}
        const Switch = SwitchSelector as any as {
            new (): SwitchFix
        }

        const {
            toggleProps,
            trackableName,
            trackableCategory,
            additionalTrackableProps,
        } = props

        const { onPress } = toggleProps

        const switchProps = {
            ...variants[variant ?? 'default'],
            ...toggleProps,
        }

        const handleClick = (value: string) => {
            postTrackingEvent({
                name: trackableName,
                category: trackableCategory,
                properties: {
                    ...additionalTrackableProps,
                    nextValue: value,
                },
            })
            if (onPress) onPress(value)
        }

        delete toggleProps['onPress']

        return <Switch {...switchProps} ref={ref} onPress={handleClick} />
    }
)
