import RNUxcam from '../utils/uxcam'

const enableMobileAndroidAndIOSSessionReplay = () => {
    RNUxcam.optIntoSchematicRecordings() // Add this line to enable iOS screen recordings
    const configuration = {
        userAppKey: '4xaq162cabx9p10',
        /*
            disable advanced gestures if you're having issues with
            swipe gestures and touches during app interaction
        */
        // enableAdvancedGestureRecognition: false
    }
    RNUxcam.startWithConfiguration(configuration)
}

export const enableSessionReplay = () => {
    try {
        enableMobileAndroidAndIOSSessionReplay()
    } catch (_e) {
        // NEVER THROW AN ERROR IN HERE, as it will cause functions that call this to fail
    }
}
