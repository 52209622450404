import { useState } from 'react'

import { View } from 'react-native'

import { OrderPlacedDeliveryStatus } from '@/features/OrderPlaced/components/OrderPlacedDeliveryStatus'

import { DeliveryType } from '@/types/Address'
import { DeliveryTrackingStatus } from '@/types/Order'

import { Button } from '@/components/Button'

import { useAllBrands } from '@/hooks/query/useAllBrands'

import tw from '@/tailwind/tailwind'

const deliveryStatuses: DeliveryTrackingStatus[] = [
    null, // hides the component
    'Unassigned',
    'Accepted',
    'OnTheWay',
    'ArrivedAtLocation',
    'Delivered',
    'CannotDeliver',
]

export const DeveloperOrderDeliveryStatus = () => {
    const { data } = useAllBrands()
    const brands = [{ id: '', name: 'Popchew' }, ...(data || [])]

    const [deliveryStatus, setDeliveryStatus] =
        useState<DeliveryTrackingStatus>(null)
    const [showStatusComponent, setShowStatusComponent] =
        useState<boolean>(false)
    const [brandId, setBrandId] = useState<string>('')

    return (
        <View style={tw`flex-col w-full`}>
            <Button
                variant="link"
                text="Delivery status component"
                onPress={() => setShowStatusComponent(!showStatusComponent)}
                trackableCategory="Developer Tools"
                trackableName="Tapped Delivery Status Component"
            />
            {showStatusComponent && (
                <>
                    <View style={tw`flex-row`}>
                        {brands.map((brand, index) => (
                            <View style={tw`m-2 flex-1`}>
                                <Button
                                    variant="link"
                                    key={index}
                                    text={brand.name.substring(0, 8)}
                                    onPress={() => {
                                        setBrandId(brand.id)
                                    }}
                                    trackableCategory="Developer Tools"
                                    trackableName="Tapped brands in Developer Tools Screen"
                                />
                            </View>
                        ))}
                    </View>
                    <View style={tw`flex-row`}>
                        {deliveryStatuses.map((status, index) => (
                            <View style={tw`m-2 flex-1`} key={status}>
                                <Button
                                    variant="link"
                                    key={index}
                                    text={index.toString()}
                                    onPress={() => {
                                        setDeliveryStatus(status)
                                    }}
                                    trackableCategory="Developer Tools"
                                    trackableName="Tapped Delivery Statuses in Developer Tools"
                                />
                            </View>
                        ))}
                    </View>
                    <OrderPlacedDeliveryStatus
                        deliveryStatus={deliveryStatus}
                        order={{
                            popchew_order_id: 'blah',
                            created_on: '',
                            points_earned: 0,
                            fulfillment_type: 'delivery',
                            cart: {
                                message: '',
                                cart_id: '',
                                instance_id: '',
                                instance_name: '',
                                fulfillment_type: DeliveryType.delivery,
                                items: [],
                            },
                            brand_id: brandId,
                            address: {
                                address1: '79 Mercer St',
                                city: 'New York',
                                zip: '10003',
                                state: 'NY',
                                lat: '0',
                                lon: '0',
                            },
                        }}
                    />
                </>
            )}
        </View>
    )
}
