import {
    MessageOptions,
    showMessage as showFlashMessage,
} from 'react-native-flash-message'

import { postTrackingEvent } from '@/components/Analytics'

export const showMessage = (options: MessageOptions) => {
    const {
        message,
        type,
        position,
        autoHide,
        color,
        description,
        duration,
        floating,
        hideOnPress,
        animated,
        backgroundColor,
    } = options

    postTrackingEvent({
        name: 'Viewed flash message',
        category: 'Notifications',
        properties: {
            message,
            type,
            position,
            autoHide,
            color,
            description,
            duration,
            floating,
            hideOnPress,
            animated,
            backgroundColor,
        },
    })

    showFlashMessage(options)
}

export const showErrorMessage = (message: string) => {
    return showMessage({
        message,
        type: 'warning',
    })
}
