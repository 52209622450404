import React, { useEffect, useMemo, useRef, useState } from 'react'

import { FlatList, RefreshControl, SafeAreaView, View } from 'react-native'

import { BrandsProps } from '@/navigation/types/ScreenProps'
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import { useIsAuth } from '@/features/Auth/utils/isAuthenticated'
import { Navbar } from '@/features/Brands/components/Navbar'
import { useCart } from '@/features/Cart/api/useCart'
import { useClearCart } from '@/features/Cart/hooks/cartMutations'
import { useLootboxInfo } from '@/features/LootBox/api/useLootboxInfo'
import { ClaimLootboxModal } from '@/features/LootBox/components/ClaimLootboxModal'

import { TrackableRender } from '@/components/Analytics'
import { LoadingView } from '@/components/Loading'

import { useAllBrands } from '@/hooks/query/useAllBrands'
import { useEligibleRewards } from '@/hooks/query/useEligibleRewards'
import { useFeatureFlags } from '@/hooks/query/useFeatureFlags'
import { useInstances } from '@/hooks/query/useInstances'
import { useProfile } from '@/hooks/query/useProfile'
import { useTrackingYGyroAnimation } from '@/hooks/useGyro'
import { useHasWindow } from '@/hooks/useHasWindow'
import { useRefreshFix } from '@/hooks/useRefreshFix'

import { useAccountModal } from '@/contexts/AccountModalContext'

import { useStore } from '@/lib/store'

import {
    DORITOS_BRAND_ID,
    WELCOME_FEATURE_FLAG,
    WELCOME_LOOTBOX_ID,
} from '@/utils/constants'
import { removeClosedInstances } from '@/utils/instance/removeClosedInstances'

import tw from '@/tailwind/tailwind'

import { BrandCard } from './BrandCard'
import { NoAddress } from './NoAddress'

export interface BrandAvailability {
    brandId: string
    isAvailable: boolean
    isOpen: boolean
}

export const BrandsScreen = ({ navigation, route }: BrandsProps) => {
    // Ability to open account settings modals
    const { showAccountSettingsModal } = route.params || {}
    const { isAnon } = useIsAuth()
    const { context, show } = useAccountModal()
    const globalNavigation = useNavigation()
    const { featureFlags, dataUpdatedAt: ffUpdatedAt } = useFeatureFlags()
    const { data: profile } = useProfile()
    const { data: rewards } = useEligibleRewards()
    const { data: welcomeLootbox } = useLootboxInfo({
        rewardId: WELCOME_LOOTBOX_ID,
    })
    const [hasShownLootboxModal, setHasShowLootboxModal] = useState(false)

    const claimLootboxModalRef = useRef<BottomSheetModalMethods>(null)

    const userIsEligibleForWelcomeLootbox = useMemo(() => {
        if (!rewards) return false
        return rewards.some((reward) => reward.id === WELCOME_LOOTBOX_ID)
    }, [rewards])

    // if:
    //      welcome lootbox is toggled on and
    //      user has no orders,
    //      welcome lootbox is not claimed
    // show the welcome lootbox modal
    useEffect(() => {
        if (!featureFlags[WELCOME_FEATURE_FLAG]) return
        if (!profile) return
        if (profile.orders.length > 0) return
        if (!rewards) return
        if (!welcomeLootbox) return
        if (!userIsEligibleForWelcomeLootbox) return

        if (claimLootboxModalRef.current && !hasShownLootboxModal) {
            claimLootboxModalRef.current.present()
            setHasShowLootboxModal(true)
        }
    }, [
        ffUpdatedAt,
        welcomeLootbox,
        claimLootboxModalRef.current,
        userIsEligibleForWelcomeLootbox,
    ])

    useEffect(() => {
        if (showAccountSettingsModal && context.setShowChildModal) {
            context.setShowChildModal(showAccountSettingsModal)
        }
    }, [route])

    useFocusEffect(() => {
        if (context.showChildModal) {
            if (!isAnon) show()
            else {
                globalNavigation.navigate('Login', {
                    screen: 'PhoneNumberCapture',
                    params: {
                        returnToOnFinish: 'HomeTabs',
                        returnToOnFinishParams: {
                            screen: 'Brands',
                            params: {
                                showAccountSettingsModal,
                            },
                        },
                    },
                })
            }
        }
    })

    const deliveryType = useStore((state) => state.location.deliveryType)
    const selectedLocation = useStore(
        (state) => state.location.selectedLocation
    )

    const { data: cart, isLoading: isCartLoading } = useCart()
    const { mutateAsync: clearCart } = useClearCart()

    const {
        data: brands,
        isLoading: isBrandsLoading,
        refetch: refetchBrands,
        isFetching: isBrandsFetching,
    } = useAllBrands()

    const sortedBrands = useMemo(() => {
        if (!brands) return []
        const brandsCopy = [...brands]
        return brandsCopy.sort((brand) =>
            brand.id === DORITOS_BRAND_ID ? -1 : 1
        )
    }, [isBrandsFetching])

    const isLoading = isBrandsLoading || isCartLoading

    const {
        data: instances,
        isLoading: isInstancesLoading,
        refetch: refetchInstances,
    } = useInstances()

    const { onRefresh, isRefreshing } = useRefreshFix([
        refetchBrands,
        refetchInstances,
    ])

    const brandsWithAvailability = useMemo(() => {
        if (!instances || !brands) return []
        return brands.map((brand) => {
            const brandInstances = instances.filter(
                (instance) => instance.brand_id === brand.id
            )

            const brandOpenInstances = removeClosedInstances(
                brandInstances,
                deliveryType
            )

            return {
                brandId: brand.id,
                isAvailable: brandInstances.length > 0,
                isOpen: brandOpenInstances.length > 0,
            } as BrandAvailability
        })
    }, [brands, instances])

    // Check if window available (mouse instead of gyro movement)
    const hasWindow = useHasWindow()
    const angle = useTrackingYGyroAnimation(50)

    useEffect(() => {
        if (!isLoading && !selectedLocation && cart?.items.length !== 0)
            clearCart()
    }, [isLoading, selectedLocation, deliveryType])

    return (
        <>
            <SafeAreaView
                style={tw`flex-1 w-full ${
                    isLoading || !selectedLocation ? '' : 'bg-black'
                }`}
            >
                <Navbar
                    navigation={navigation}
                    deliveryType={deliveryType}
                    address={selectedLocation}
                    isDarkMode
                />

                <View style={tw`flex-1 mt-5 justify-start w-full relative`}>
                    {isLoading ? (
                        <View style={tw`flex-1 justify-center`}>
                            <LoadingView />
                        </View>
                    ) : !selectedLocation ? (
                        <NoAddress navigation={navigation} />
                    ) : (
                        <>
                            <TrackableRender
                                name="Viewed Brands list"
                                category="Order"
                                onesignal
                            >
                                <FlatList
                                    refreshControl={
                                        <RefreshControl
                                            refreshing={isRefreshing}
                                            onRefresh={onRefresh}
                                            tintColor="#fff"
                                        />
                                    }
                                    style={tw`w-full z-10`}
                                    testID="brand-list-container"
                                    data={sortedBrands}
                                    keyExtractor={(item) => item.id}
                                    initialNumToRender={3}
                                    renderItem={({ item }) => {
                                        const availability =
                                            brandsWithAvailability.find(
                                                (brand) =>
                                                    brand.brandId === item.id
                                            )
                                        return (
                                            <BrandCard
                                                key={item.id}
                                                angle={angle}
                                                containerNativeID=""
                                                hasWindow={hasWindow}
                                                navigation={navigation}
                                                item={item}
                                                isLoading={isInstancesLoading}
                                                isAvailable={
                                                    availability?.isAvailable ??
                                                    false
                                                }
                                                isOpen={
                                                    availability?.isOpen ??
                                                    false
                                                }
                                            />
                                        )
                                    }}
                                    showsVerticalScrollIndicator={false}
                                />
                            </TrackableRender>
                        </>
                    )}
                </View>
            </SafeAreaView>
            {welcomeLootbox && (
                <ClaimLootboxModal
                    bsmRef={claimLootboxModalRef}
                    lootboxInfo={welcomeLootbox}
                />
            )}
        </>
    )
}
