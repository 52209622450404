import { useRef } from 'react'

import clone from 'clone'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { postTrackingEvent } from '@/components/Analytics'

import * as ExpoPushNotifications from '@/lib/expoNotifications'

import { NotificationCategoryProps } from '.'

type prefsType = NotificationCategoryProps[]

// Converts preferences to preferencesArrafy format for FlatLists
const preferencesToArray = (
    preferences: ExpoPushNotifications.NotificationPreferences
): NotificationCategoryProps[] =>
    Object.keys(preferences)
        .filter((categoryName) => categoryName !== 'message')
        .map((categoryName) => {
            const notifications = preferences[categoryName]['notifications']
            return {
                name: categoryName,
                title: preferences[categoryName].title,
                notifications: Object.keys(notifications).map(
                    (notificationName) => ({
                        name: notificationName,
                        title: notifications[notificationName].title,
                        subtitle: notifications[notificationName].subtitle,
                        value: notifications[notificationName].value,
                        category: categoryName, // Important - add category to be used when updating preferences with checkboxes
                    })
                ),
                value: preferences[categoryName].value,
            }
        })

const getPreferences = async () => {
    const prefs = await ExpoPushNotifications.getNotificationPreferences()
    return preferencesToArray(prefs)
}

// Utilizes the useQuery functionality but not a perfect implementation
const prefsQueryKey = ['notificationPreferences']
export const useNotificationPreferences = () =>
    useQuery<NotificationCategoryProps[]>(prefsQueryKey, getPreferences, {
        enabled: true,
        retry: false,
    })

export type PrefsToChange = {
    category: string
    name: string
    value: boolean
}

export const useUpdateNotificationPreferences = () => {
    const currentChanges = useRef(0)
    const queryClient = useQueryClient()

    return useMutation(
        async (prefsToChange: PrefsToChange) => {
            const { category, name, value } = prefsToChange
            let changePrefsRequest: ExpoPushNotifications.ChangeNotificationPrefsRequest
            if (name) {
                changePrefsRequest = {
                    [category]: { notifications: { [name]: { value } } },
                }
            } else changePrefsRequest = { [category]: { value } }
            await ExpoPushNotifications.setNotificationPreferences(
                changePrefsRequest
            )
            postTrackingEvent({
                name: 'Toggled notification setting',
                category: 'Notifications',
                properties: {
                    name,
                    category,
                    'old state': !value,
                    'new state': value,
                },
                onesignal: true,
                onesignalValue: name,
            })
        },
        {
            onMutate: async (prefsToChange) => {
                const { category, value, name } = prefsToChange

                currentChanges.current++
                await queryClient.cancelQueries(prefsQueryKey)
                const prevPrefs =
                    (queryClient.getQueryData(prefsQueryKey) as prefsType) || []

                const newPrefs = clone(prevPrefs)
                if (name)
                    newPrefs.forEach((cat) => {
                        if (cat.name === category) {
                            cat.notifications.forEach((notification) => {
                                if (notification.name === name)
                                    notification.value = value
                            })
                        }
                    })
                else
                    newPrefs.forEach((cat) => {
                        if (cat.name === category) cat.value = value
                    })
                queryClient.setQueryData(prefsQueryKey, newPrefs)
                return { prevPrefs }
            },
            onError: (_error, _variables, context) => {
                if (!context) return
                queryClient.setQueryData(prefsQueryKey, context.prevPrefs)
            },
            onSuccess: () => {
                if (currentChanges.current === 1)
                    queryClient.invalidateQueries(prefsQueryKey)
            },
            onSettled: () => {
                currentChanges.current--
            },
        }
    )
}
