import * as React from 'react'

import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg'

const YoutubeSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <G clipPath="url(#a)">
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.042 6.958c.28.279.48.627.583 1.008.37 1.403.375 4.332.375 4.332s0 2.93-.375 4.333a2.257 2.257 0 0 1-1.591 1.591C17.63 18.6 12 18.6 12 18.6s-5.63 0-7.032-.377a2.253 2.253 0 0 1-1.592-1.591C3 15.228 3 12.298 3 12.298s0-2.929.376-4.332a2.254 2.254 0 0 1 1.592-1.597C6.369 5.992 12 5.992 12 5.992s5.63 0 7.034.382c.381.103.729.304 1.008.584Zm-5.161 5.335-4.678 2.7v-5.4l4.678 2.7Z"
                fill="currentColor"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path
                    fill="currentColor"
                    transform="translate(3 6)"
                    d="M0 0h18v12.601H0z"
                />
            </ClipPath>
        </Defs>
    </Svg>
)

export default YoutubeSvg
