import { Platform, Text, TouchableOpacity, View } from 'react-native'

import { CommonNavigation } from '@/navigation/types/ScreenProps'
import { useNavigation } from '@react-navigation/native'
import { LinearGradient } from 'expo-linear-gradient'
import { SafeAreaView } from 'react-native-safe-area-context'

import { DeliveryType } from '@/types/Address'
import { Brand } from '@/types/Brand'
import { Instance } from '@/types/Instance'

import { TrackableClick } from '@/components/Analytics'

import { useQueryState } from '@/lib/queryClient'
import { useStore } from '@/lib/store'

import { capitalizeFirstLetter } from '@/utils/text'

import tw from '@/tailwind/tailwind'

import ChevronDownSvg from '@/svgs/ChevronDownSvg'

export const LocationSelector = ({
    brand,
    showPickupDeliveryModal,
}: {
    brand: Brand | undefined
    showPickupDeliveryModal: () => void
}) => {
    const navigation = useNavigation<CommonNavigation['navigation']>()

    const deliveryType = useStore((state) => state.location.deliveryType)
    const isDelivery = deliveryType === DeliveryType.delivery

    const selectedLocation = useStore(
        (state) => state.location.selectedLocation
    )
    const { data: selectedInstance } = useQueryState<Instance>([
        'selectedInstance',
        brand?.id,
        deliveryType,
    ])

    const text = selectedLocation
        ? isDelivery
            ? selectedLocation.name || selectedLocation.address1
            : selectedInstance
            ? selectedInstance.store.address.address1 || selectedInstance.name
            : 'Choose Location'
        : 'Enter Address'

    return (
        <TrackableClick
            name="Tapped address pill in navbar"
            category="Address"
            properties={{ 'delivery type': deliveryType }}
        >
            <View style={tw`absolute top-0 z-20 w-full`}>
                <LinearGradient
                    colors={['rgba(0, 0, 0, 0.5)', 'transparent']}
                    style={tw`absolute top-0 w-full`}
                    {...(Platform.OS !== 'web'
                        ? { end: { x: 0.5, y: 1 } }
                        : {})}
                >
                    <SafeAreaView>
                        <TouchableOpacity
                            style={tw`flex-row items-center  rounded-full px-4 py-2 self-center ${
                                Platform.OS === 'web' ? 'mt-2' : ''
                            }`}
                            onPress={
                                isDelivery
                                    ? () => {
                                          navigation.navigate('Location Picker')
                                      }
                                    : () => showPickupDeliveryModal()
                            }
                        >
                            <Text
                                style={tw`text-white font-ppa-b text-lg mr-3`}
                            >
                                {`${capitalizeFirstLetter(
                                    text.length > 15
                                        ? text.substring(0, 15) + '...'
                                        : text
                                )} - ${capitalizeFirstLetter(deliveryType)}`}
                            </Text>
                            <ChevronDownSvg color={'white'} />
                        </TouchableOpacity>
                    </SafeAreaView>
                </LinearGradient>
            </View>
        </TrackableClick>
    )
}
