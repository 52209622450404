import { useRef } from 'react'

import {
    Image,
    ImageBackground,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'

import { BrandScreenProps } from '@/navigation/types/ScreenProps'
import { BottomSheetModal as BottomSheetModalType } from '@gorhom/bottom-sheet'

import { TrackableClick, TrackableRender } from '@/components/Analytics'
import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'

import { useStore } from '@/lib/store'

import tw from '@/tailwind/tailwind'

import { NotifyBrandsModal } from '../../Brands/screens/BrandsScreen/NotifyBrandsModal'

// A component to show whenever no brands are retreived or no address exists
export const NoBrandsLocations = ({
    navigation,
    closeModal = () => {},
}: {
    navigation: BrandScreenProps['navigation']
    closeModal?: () => void
}) => {
    const notifyBrandsRef = useRef<BottomSheetModalType>(null)
    const showNotifyModal = () => notifyBrandsRef.current?.present()
    const hideNotifyModal = () => notifyBrandsRef.current?.close()

    const deliveryType = useStore((state) => state.location.deliveryType)

    return (
        <TrackableRender
            name="Viewed No Brands screen"
            category="Out of Range"
            onesignal
        >
            <ScrollView
                style={tw`flex min-h-full`}
                showsVerticalScrollIndicator={false}
            >
                <View style={tw`items-center`}>
                    <Image
                        style={tw`w-45 h-23`}
                        source={require('@/assets/no-brands-error.png')}
                    />
                    <Text style={tw`font-ppa-wbi text-2xl mt-5 text-center`}>
                        Whoops!
                    </Text>
                    <Text
                        style={tw`font-ppa-b text-pc-shade-80 mt-5 text-center`}
                    >
                        {`No ${deliveryType} locations found in your area.`}
                    </Text>
                    <View style={tw`mt-7`}>
                        <Button
                            variant="primary"
                            onPress={() => {
                                closeModal()
                                navigation.navigate('Location Picker')
                            }}
                            text="Try Another Address"
                            trackableCategory="Out of Range"
                            trackableName="Tapped Try Another Address on pickup delivery modal"
                        />
                    </View>
                </View>
                <View
                    style={tw`flex-0 w-100 border-b-2 border-slate-100 mt-6`}
                ></View>
                <View style={tw`flex-1 mt-8 items-center`}>
                    <View style={tw`flex-1 justify-start items-center w-[90%]`}>
                        <TrackableClick
                            category="Out of Range"
                            name="Tapped Notify Me section on pickup delivery modal"
                            onesignal
                        >
                            <TouchableOpacity
                                onPress={showNotifyModal}
                                style={tw`flex-1 justify-start items-center w-full`}
                            >
                                <ImageBackground
                                    source={require('@/assets/notify-when-available-phone.png')}
                                    style={styles.notifyImage}
                                >
                                    <View style={tw`mt-[58%]`}>
                                        <Button
                                            variant="primary"
                                            onPress={showNotifyModal}
                                            text="Notify Me"
                                            trackableCategory="Out of Range"
                                            trackableName="Tapped Notify Me section on no pickup delivery screen"
                                            onesignal
                                        />
                                    </View>
                                </ImageBackground>
                                <View
                                    style={tw`w-[98%] flex-0 border-b-2 border-black`}
                                ></View>
                            </TouchableOpacity>
                        </TrackableClick>
                    </View>
                </View>
            </ScrollView>
            <BottomSheetModal
                ref={notifyBrandsRef}
                snapPoints={['70%']}
                handleComponent={null}
                keyboardBehavior="interactive"
                trackableName="notify brands"
                trackableCategory="Out of Range"
            >
                <NotifyBrandsModal closeModal={hideNotifyModal} />
            </BottomSheetModal>
        </TrackableRender>
    )
}

const styles = StyleSheet.create({
    notifyImage: {
        width: '100%',
        aspectRatio: 0.91218,
        alignItems: 'center',
    },
})
