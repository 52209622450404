import { useRef } from 'react'

import { AxiosError } from 'axios'
import clone from 'clone'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'

import { axiosPost } from './httpClient'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
        mutations: {
            retry: false,
        },
    },
})

export const useQueryState = <T>(queryKey: Array<any>) => {
    const queryClient = useQueryClient()

    const { data } = useQuery<T | undefined>(
        queryKey,
        async () => (await queryClient.getQueryData(queryKey)) as T
    )

    return {
        data,
        setData: (data: T | undefined) => {
            queryClient.setQueryData<T | undefined>(queryKey, data)
            queryClient.invalidateQueries(queryKey)
        },
    }
}

// Can be used in the case that type T is sent to and received from the api
// AND the version sent is modified using type I
export const useOptimisticMutation =
    <T, I = void>(
        queryName: string,
        updateOptimistically: (previousItem: T, input: I) => T,
        postUpdate: (item: T) => Promise<T | undefined>
    ) =>
    () => {
        const currentChanges = useRef(0)
        const queryClient = useQueryClient()

        return useMutation(
            async () => {
                // item is already modified optimistically by onMutate at this point
                const item = queryClient.getQueryData(queryName) as T
                return await postUpdate(item)
            },
            {
                mutationKey: queryName,
                onMutate: async (input: I) => {
                    currentChanges.current++
                    await queryClient.cancelQueries(queryName)
                    const previousItem = queryClient.getQueryData(
                        queryName
                    ) as T
                    const updatedItem = updateOptimistically(
                        clone(previousItem),
                        input
                    )
                    queryClient.setQueryData(queryName, updatedItem)
                    return { previousItem }
                },
                onError: (error, _variables, context) => {
                    console.error(`error mutating ${queryName}`, error)
                    if (!context) return
                    queryClient.setQueryData(queryName, context.previousItem)
                },
                onSuccess: () => {
                    if (currentChanges.current === 1) {
                        queryClient.invalidateQueries(queryName)
                    }
                },
                onSettled: () => {
                    currentChanges.current--
                },
            }
        )
    }

// Use mutation with axios types! Could add context and more options, etc.
export const useAxiosPostMutation = <Input, Output>(
    url: string,
    onError?: (error: AxiosError, variables: Input) => void,
    onSuccess?: (data: Output, variables: Input) => void,
    onSettled?: (
        data: Output | undefined,
        error: AxiosError | null,
        variables: Input
    ) => void
) => {
    const submitData = axiosPost<Input, Output>(url)
    return () =>
        useMutation(async (data: Input) => await submitData(data), {
            onError,
            onSuccess,
            onSettled,
        })
}
