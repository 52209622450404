import { FC, useMemo } from 'react'

import { TouchableOpacity, TouchableOpacityProps } from 'react-native'

import tw from '@/tailwind/tailwind'

import ChevronLeftSvg from '@/svgs/ChevronLeftSvg'
import ChevronRightSvg from '@/svgs/ChevronRightSvg'

interface ChevronButtonProp extends TouchableOpacityProps {
    direction?: 'left' | 'right'
    testID?: string
}

const ChevronButton: FC<ChevronButtonProp> = ({
    style,
    direction,
    ...touchableProps
}) => {
    const Chevron = useMemo(
        () => (direction === 'left' ? ChevronLeftSvg : ChevronRightSvg),
        [direction]
    )
    return (
        <TouchableOpacity
            {...touchableProps}
            style={[
                tw`mt-2 bg-black h-12 w-12 rounded-full flex justify-center items-center  ${
                    direction === 'left' ? 'self-start' : 'self-end'
                } `,
                style,
            ]}
        >
            <Chevron color={'#fff'} />
        </TouchableOpacity>
    )
}

export { ChevronButton }
