import React from 'react'

import { Text, TouchableOpacity, View } from 'react-native'

import { SvgProps } from 'react-native-svg'

import tw from '@/tailwind/tailwind'

import {
    TrackableClick,
    TrackableElementProps,
    TrackingCategory,
} from '../Analytics'

const variants = {
    primary: {
        button: tw`bg-black`,
        text: tw`text-white`,
    },
    action: {
        button: tw`bg-pc-primary`,
        text: tw`text-black font-me-bl`,
    },
    primaryStrong: {
        button: tw`bg-black`,
        text: tw`text-white font-ppa-wbi`,
    },
    secondary: {
        button: tw`bg-pc-shade-20`,
        text: tw`text-black`,
    },
    warning: {
        button: tw`bg-pc-warning`,
        text: tw`text-white`,
    },
    success: {
        button: tw`bg-pc-success`,
        text: tw`text-white`,
    },
    info: {
        button: tw`bg-pc-secondary`,
        text: tw`text-white`,
    },
    link: {
        button: tw`bg-transparent`,
        text: tw`text-black`,
    },
    icon: {
        button: tw`bg-black flex justify-center items-center`,
    },
}

const sizes = {
    icon: {
        default: {
            button: tw`h-14 w-14`,
        },
        sm: {
            button: tw`h-[34px] w-[34px]`,
        },
    },
    standard: {
        default: {
            button: tw`px-12 py-[17px]`,
            text: tw`text-lg leading-[22px]`,
        },
        sm: {
            button: tw`px-8 py-4`,
            text: tw`text-base leading-[18px]`,
        },
        xs: {
            button: tw`px-3 py-[6.5px]`,
            text: tw`text-[11px] leading-[13px]`,
        },
    },
}

interface CommonProps
    extends Omit<TrackableElementProps, 'name' | 'category' | 'properties'> {
    onPress?: () => void
    disabled?: boolean
    testID?: string
    trackableName: string
    trackableCategory: TrackingCategory
    trackableProperties?: Record<string, unknown>
}

interface CommonButtonProps extends CommonProps {
    full?: boolean
    text: string
    icon?: (props: SvgProps) => JSX.Element
    iconToRight?: boolean
    size?: 'default' | 'sm' | 'xs'
}

interface ButtonProps extends CommonButtonProps {
    variant: keyof typeof variants
}

type LinkButtonProps = CommonButtonProps & {
    variant: 'link'
    textColor?: 'blue' | 'green' | 'red'
    full?: never
    size?: never
}

type SecondaryButtonProps = CommonButtonProps & {
    variant: 'secondary'
    textColor?: 'blue' | 'gray'
}

interface Icon extends CommonProps {
    variant: 'icon'
    size: 'default' | 'sm'
    icon: (props: SvgProps) => JSX.Element
}

const Button = (
    props: Icon | LinkButtonProps | SecondaryButtonProps | ButtonProps
) => {
    const {
        variant,
        size = 'default',
        icon: Icon,
        onPress,
        disabled,
        trackableName,
        trackableCategory,
        trackableProperties,
        testID,
    } = props

    let full,
        text,
        sizeStyles: any,
        iconToRight: boolean | undefined = false
    let textColor

    if (variant === 'icon') {
        sizeStyles = sizes['icon'][size as Icon['size']]
    } else {
        ;({ full, text, iconToRight } = props)
        sizeStyles = sizes['standard'][size]
    }

    if (variant === 'secondary' || variant === 'link') {
        textColor = props.textColor
    }

    return (
        <TrackableClick
            name={trackableName}
            category={trackableCategory}
            properties={trackableProperties}
            testID={testID}
        >
            <TouchableOpacity
                disabled={disabled}
                onPress={onPress}
                style={[
                    tw`relative rounded-full ${full ? 'w-full' : ''}`,
                    variants[variant]?.button,
                    sizeStyles.button,
                    disabled && tw`bg-pc-shade-20`,
                ]}
            >
                {variant === 'icon' ? (
                    <Icon color="#fff" />
                ) : (
                    <View style={tw`flex-row items-center justify-center`}>
                        {!!Icon && variant === 'link' ? (
                            <Icon
                                style={tw`mr-3`}
                                color={
                                    (textColor
                                        ? tw.style(
                                              textColor === 'blue'
                                                  ? 'text-pc-secondary'
                                                  : textColor === 'green'
                                                  ? 'text-pc-success'
                                                  : textColor === 'red'
                                                  ? 'text-pc-warning'
                                                  : variants[variant].text
                                          ).color
                                        : disabled
                                        ? tw`text-pc-shade-60`.color
                                        : variants[variant].text.color) as any
                                }
                            />
                        ) : null}
                        <Text
                            numberOfLines={1}
                            style={[
                                tw`font-ppa-b text-center ${
                                    Icon ? 'mr-3' : ''
                                }`,
                                variants[variant]?.text,
                                sizeStyles.text,
                                tw.style(
                                    textColor
                                        ? textColor === 'blue'
                                            ? 'text-pc-secondary'
                                            : textColor === 'green'
                                            ? 'text-pc-success'
                                            : textColor === 'red'
                                            ? 'text-pc-warning'
                                            : textColor === 'gray'
                                            ? 'text-pc-shade-80'
                                            : variants[variant].text
                                        : variants[variant]?.text
                                ),
                                disabled && tw`text-pc-shade-60`,
                            ]}
                        >
                            {text}
                        </Text>

                        {!!Icon && variant !== 'link' ? (
                            <View
                                style={
                                    iconToRight ? tw`absolute right-0` : null
                                }
                            >
                                <Icon
                                    color={
                                        (textColor
                                            ? tw.style(
                                                  textColor === 'blue'
                                                      ? 'text-pc-secondary'
                                                      : textColor === 'gray'
                                                      ? 'text-pc-shade-80'
                                                      : textColor === 'green'
                                                      ? 'text-pc-success'
                                                      : textColor === 'red'
                                                      ? 'text-pc-warning'
                                                      : variants[variant].text
                                              ).color
                                            : disabled
                                            ? tw`text-pc-shade-60`.color
                                            : variants[variant].text
                                                  .color) as any
                                    }
                                />
                            </View>
                        ) : null}
                    </View>
                )}
            </TouchableOpacity>
        </TrackableClick>
    )
}

export { Button }
