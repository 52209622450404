import { DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { getInstanceAvailability } from './getInstanceAvailability'

export const removeClosedInstances = (
    instances: Instance[],
    deliveryType: DeliveryType
) =>
    instances.filter(
        (instance) =>
            getInstanceAvailability(
                deliveryType,
                instance.hours,
                instance.timezone,
                instance.hours_overrides
            ).isOpen
    )
