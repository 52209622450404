import { Text, TouchableOpacity, View } from 'react-native'

import {
    ModifierGroup as ModifierGroupType,
    Modifier as ModifierType,
} from '@/types/Menu'

import { TrackableClick } from '@/components/Analytics'

import { DORITOS_BRAND_ID } from '@/utils/constants'
import { toPrice } from '@/utils/normalizers'

import tw from '@/tailwind/tailwind'

import CheckSvg from '@/svgs/CheckSvg'

interface ModiferProps extends ModifierType {
    isXOR: boolean
    modifier_group_idx: number
    modifier_idx: number
    modifiersState: ModifierGroupType[]
    toggleModifier: (modifier_group_idx: number, modifier_idx: number) => void
    maxItemsReached: boolean
    testID?: string
    brandId: string
}

export const Modifier = (modifier: ModiferProps) => {
    const {
        name,
        isXOR,
        modifiersState,
        modifier_group_idx,
        modifier_idx,
        toggleModifier,
        maxItemsReached,
        is_available,
        description,
        price,
        brandId,
    } = modifier

    const isActive =
        modifiersState[modifier_group_idx].modifiers[modifier_idx].is_selected

    const isDisabled = !is_available || (!isActive && maxItemsReached && !isXOR)

    return brandId === DORITOS_BRAND_ID && !is_available ? (
        <></>
    ) : (
        <TrackableClick
            testID="modifier-option"
            name="Toggled menu item modifier"
            category="Order"
            properties={{
                'modifier name': name,
                'modifier idx': modifier_idx,
                'modifier group idx': modifier_group_idx,
                'to active': isActive,
                'modifier price': price,
            }}
        >
            <TouchableOpacity
                disabled={isDisabled}
                style={tw` my-1 py-1.5`}
                onPress={() => {
                    toggleModifier(modifier_group_idx, modifier_idx)
                }}
            >
                <View style={tw`flex-row items-center  `}>
                    <View
                        style={tw`h-6 w-6 flex justify-center items-center ${
                            isActive ? 'bg-black' : 'bg-white'
                        } ${isXOR ? 'rounded-full' : ''} border-2 ${
                            isActive ? 'border-black' : 'border-pc-shade-60'
                        } ${isDisabled ? 'border-pc-shade-40' : ''}`}
                    >
                        <CheckSvg />
                    </View>
                    <View style={tw`flex-1 justify-between flex-row`}>
                        <Text
                            style={tw`text-base font-ppa-b ml-3.5 flex-shrink ${
                                isDisabled ? 'text-pc-shade-40' : ''
                            }`}
                        >
                            {name}
                        </Text>
                        {price && is_available ? (
                            <Text
                                style={tw`font-ppa-b text-pc-secondary flex-shrink-0 ml-4`}
                            >
                                {price > 0 && '+ '}
                                {toPrice(price)}
                            </Text>
                        ) : null}
                    </View>
                </View>

                {is_available && description ? (
                    <View style={tw`flex-row`}>
                        <View style={tw`w-6`}></View>
                        <Text
                            style={tw`ml-3.5 text-sm font-ppa-b text-pc-shade-80`}
                        >
                            {description}
                        </Text>
                    </View>
                ) : null}
            </TouchableOpacity>
        </TrackableClick>
    )
}
