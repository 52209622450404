import { Text } from 'react-native'

import { OrderDetailResponse } from '@/types/Order'

import { toPrice } from '@/utils/normalizers'

import tw from '@/tailwind/tailwind'

export const OrderTotal = ({ order }: { order: OrderDetailResponse }) => {
    return (
        <Text style={tw`font-ppa-b text-base my-8 text-pc-shade-80`}>
            Total{' '}
            <Text style={tw`text-black ml-2 text-lg`}>
                {toPrice(order.ticket_amount || 0)}
            </Text>
        </Text>
    )
}
