import { useCallback, useState } from 'react'

import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from 'react-query'

export const useRefreshFix = (
    fetchFuncs: Array<
        <TPageData>(
            options?:
                | (RefetchOptions & RefetchQueryFilters<TPageData>)
                | undefined
        ) => Promise<QueryObserverResult<any, unknown>>
    >
) => {
    const [isRefreshing, setIsRefreshing] = useState(false)

    // stops loader from being buggy from react query
    // https://github.com/facebook/react-native/issues/32836#issuecomment-1157008358
    const onRefresh = useCallback(async () => {
        setIsRefreshing(true)
        await Promise.all(fetchFuncs.map((func) => func()))
        setIsRefreshing(false)
    }, [])

    return { onRefresh, isRefreshing }
}
