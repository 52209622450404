import { TouchableOpacity, View } from 'react-native'

import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { SvgProps } from 'react-native-svg'

import tw from '@/tailwind/tailwind'

import AccountSvg from '@/svgs/AccountSvg'
import BubbleStarSvg from '@/svgs/BubbleStarSvg'
import FeedSvg from '@/svgs/FeedSvg'
import GiftBoxSvg from '@/svgs/GiftBoxSvg'
import MiniTriangleSvg from '@/svgs/MiniTriangle'
import TabHomeSvg from '@/svgs/TabHomeSvg'

import { postTrackingEvent } from '../Analytics'

const ICONS: {
    [key: string]: {
        svg: ({ color, ...props }: SvgProps) => JSX.Element
        focusColor: string | undefined
    }
} = {
    Home: { svg: GiftBoxSvg, focusColor: tw.color('pc-primary') },
    Brands: { svg: TabHomeSvg, focusColor: tw.color('pc-success') },
    Rewards: {
        svg: BubbleStarSvg,
        focusColor: tw.color('pc-primary'),
    },
    Account: { svg: AccountSvg, focusColor: tw.color('pc-primary') },
    Feed: { svg: FeedSvg, focusColor: tw.color('pc-secondary') },
}

export const TabBar = ({
    state,
    descriptors,
    navigation,
}: BottomTabBarProps) => {
    return (
        <View style={tw`flex-row justify-center pt-3 pb-7 bg-black`}>
            {state.routes.map((route, index) => {
                const { options } = descriptors[route.key]

                const isFocused = state.index === index

                const SvgComponent = ICONS[route.name as keyof typeof ICONS].svg

                const onPress = () => {
                    const event = navigation.emit({
                        type: 'tabPress',
                        target: route.key,
                        canPreventDefault: true,
                    })

                    if (route.name === 'Brands')
                        postTrackingEvent({
                            name: 'Tapped Brands tab icon',
                            category: 'Order',
                            onesignal: true,
                        })
                    if (route.name === 'Rewards')
                        postTrackingEvent({
                            name: 'Tapped Rewards tab icon',
                            category: 'Rewards',
                            onesignal: true,
                        })
                    if (route.name === 'Feed')
                        postTrackingEvent({
                            name: 'Tapped Feed tab icon',
                            category: 'General',
                            onesignal: true,
                        })

                    if (!isFocused && !event.defaultPrevented) {
                        // The `merge: true` option makes sure that the params inside the tab screen are preserved
                        navigation.navigate({ key: route.key, merge: true })
                    }
                }

                const SvgColor = isFocused
                    ? ICONS[route.name as keyof typeof ICONS].focusColor ||
                      'white'
                    : tw.color('pc-shade-60')

                return (
                    <TouchableOpacity
                        style={tw`mx-7 flex flex-col items-center justify-between`}
                        key={route.key}
                        accessibilityRole="button"
                        accessibilityState={isFocused ? { selected: true } : {}}
                        accessibilityLabel={options.tabBarAccessibilityLabel}
                        testID={options.tabBarTestID}
                        hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
                        onPress={onPress}
                        onLongPress={() =>
                            ((process.env.VERCEL_ENV &&
                                process.env.VERCEL_ENV !== 'production') ||
                                __DEV__) &&
                            navigation.navigate('Devtools')
                        }
                        activeOpacity={1}
                    >
                        <SvgComponent color={SvgColor} />

                        <MiniTriangleSvg
                            color={SvgColor}
                            style={tw`mt-1.5 ${
                                !isFocused ? 'opacity-0' : 'opacity-100'
                            }`}
                        />
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}
