import { axios } from '@/lib/httpClient'

type postStreetTeamType = {
    first: string
    last: string
    email: string
    phone: string
    zip: string
    social_handle: string | undefined
    brand_id: string
    favorite_part: string
}

export const postStreetTeam = async (data: postStreetTeamType) => {
    return await axios.post('/order/landing-page-out-of-range-signup', data)
}
