import { useQuery } from 'react-query'

import { OrderDetailResponse, OrderStatusResponse } from '@/types/Order'

import { axios } from '@/lib/httpClient'

const getOrderDetails = async (orderId: string) => {
    const response = await axios.get<OrderDetailResponse>('/order/detail', {
        params: { id: orderId },
    })
    return response.data
}

export const useOrderDetailsQueryKey = (orderId: string | undefined) => [
    'order',
    orderId,
]

export const useOrderDetails = (orderId: string | undefined) => {
    return useQuery(
        useOrderDetailsQueryKey(orderId),
        () => {
            if (!orderId) throw new Error('Order id missing')
            return getOrderDetails(orderId)
        },
        { enabled: Boolean(orderId) }
    )
}

export const getOrderStatus = async (orderId: string) => {
    const response = await axios.get<OrderStatusResponse>('/order/status', {
        params: { order_id: orderId },
    })
    return response.data
}

const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000
export const useOrderStatus = (
    orderId: string | undefined,
    orderCreatedOn?: string
) => {
    let is24HrsPassedOrderCreated
    if (orderCreatedOn) {
        is24HrsPassedOrderCreated =
            new Date().getTime() -
                new Date(orderCreatedOn || new Date()).getTime() >
            TWENTY_FOUR_HOURS
    }

    return useQuery(
        ['order_fulfillment', orderId],
        () => {
            if (!orderId) throw new Error('Missing order id')
            return getOrderStatus(orderId)
        },
        {
            enabled: Boolean(orderId),
            staleTime: is24HrsPassedOrderCreated ? Infinity : 1000 * 5, // 5 sec
        }
    )
}
