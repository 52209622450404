import { useQueryClient } from 'react-query'

import { axios } from '@/lib/httpClient'

import { showMessage } from '@/utils/showMessage'

export const useSignOutFlipdish = (brandName = 'all') => {
    const queryClient = useQueryClient()
    return async () => {
        try {
            const response = await axios.post(
                `/order/auth/logout?brand_name=${brandName}`
            )

            showMessage({
                type: 'info',
                message: `successfully signed out of flipdish brand: ${brandName}`,
            })

            return response
        } catch (e) {
            showMessage({
                type: 'warning',
                message: `failed to sign out of flipdish brand: ${brandName}`,
            })
        }
    }
}
