import { useEffect, useState } from 'react'

import { Platform } from 'react-native'

import { useCart } from '@/features/Cart/api/useCart'

import { SplashScreen } from '@/components/SplashScreen'

import { useAllBrands } from '@/hooks/query/useAllBrands'
import { useFeatureFlags } from '@/hooks/query/useFeatureFlags'
import { useProfile } from '@/hooks/query/useProfile'

import { prefetchBrandImages } from '@/utils/prefetchBrandImages'

type PrefetcherProps = {
    setAppIsReady: (value: React.SetStateAction<boolean>) => void
    children: JSX.Element
}

export const Prefetcher = ({ setAppIsReady, children }: PrefetcherProps) => {
    const { isLoading: isLoadingProfile } = useProfile()
    const { isLoading: isLoadingCart } = useCart()
    const { isLoading: isLoadingBrands, data: brands } = useAllBrands()
    useFeatureFlags()

    const [isReady, setIsReady] = useState(false)

    // when all the data is fetched, set the app as ready
    useEffect(() => {
        if (!isLoadingProfile && !isLoadingCart && !isLoadingBrands) {
            setIsReady(true)
            setAppIsReady(true)
        }
    }, [isLoadingProfile, isLoadingCart, isLoadingBrands])

    // only the first time brands are fetched, prefetch the images
    useEffect(() => {
        if (brands) {
            prefetchBrandImages(brands)
        }
    }, [isLoadingBrands])

    // if the app is not ready, show the splash screen
    if (!isReady) {
        if (Platform.OS !== 'web') return null
        return <SplashScreen />
    }

    return <>{children}</>
}
