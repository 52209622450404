import { CartResponse } from '@/types/Cart'
import { Instance } from '@/types/Instance'

import { totalCartPrice } from '@/utils/totalCartPrice'

import { CouponWithDetails } from '../types'
import { calcItemTaxes } from './calcItemTaxes'

export type CheckoutTotals = {
    subtotal: number
    itemTaxes: number
    deliveryFee: number
    deliveryFeeTaxes: number
    serviceFee: number
    serviceFeeTaxes: number
    totalTaxes: number
    totalBeforeTip: number
    couponValue: number
    totalAfterTip: number
    tip: number
    taxesAndFees: number
}

const zeroTotals: CheckoutTotals = {
    subtotal: 0,
    itemTaxes: 0,
    deliveryFee: 0,
    deliveryFeeTaxes: 0,
    serviceFee: 0,
    serviceFeeTaxes: 0,
    totalTaxes: 0,
    totalBeforeTip: 0,
    couponValue: 0,
    totalAfterTip: 0,
    tip: 0,
    taxesAndFees: 0,
}

export const calcCheckoutTotals = (
    cart: CartResponse | undefined,
    instance: Instance | undefined,
    coupon: CouponWithDetails | null,
    tip: number
): CheckoutTotals => {
    if (!cart || cart.items.length === 0 || !instance) return zeroTotals

    const couponValue = coupon?.value || 0

    const serviceFee = instance.service_fee || 0
    const initialServiceFeeTaxes = serviceFee * instance.service_fee_tax_rate

    const deliveryFee =
        cart.fulfillment_type === 'delivery' ? instance.delivery_fee || 0 : 0
    const initialDeliveryFeeTaxes =
        cart.fulfillment_type === 'delivery'
            ? instance.delivery_fee_tax_amount
            : 0

    const subtotal = totalCartPrice(cart.items)

    const initialItemTaxes =
        cart.items.reduce((prev, curr) => {
            return (prev += calcItemTaxes(curr.item) * curr.count)
        }, 0) / 100

    let deliveryFeeTaxes = 0
    let serviceFeeTaxes = 0
    let itemTaxes = 0

    if (couponValue > 0) {
        // weird case with negative coupon
        itemTaxes = initialItemTaxes
        deliveryFeeTaxes = initialDeliveryFeeTaxes
        serviceFeeTaxes = initialServiceFeeTaxes
    } else if (-couponValue >= subtotal + deliveryFee + serviceFee) {
        // coupon covers all items and fees
        itemTaxes = 0
        deliveryFeeTaxes = 0
        serviceFeeTaxes = 0
    } else if (-couponValue >= subtotal + deliveryFee) {
        // coupon covers all items, delivery fee, and part of service fee
        itemTaxes = 0
        deliveryFeeTaxes = 0
        serviceFeeTaxes =
            serviceFee === 0
                ? 0
                : (initialServiceFeeTaxes *
                      (subtotal + deliveryFee + serviceFee + couponValue)) /
                  serviceFee
        serviceFee
    } else if (-couponValue >= subtotal) {
        // coupon covers all items and part of delivery fee
        itemTaxes = 0
        deliveryFeeTaxes =
            deliveryFee === 0
                ? 0
                : (initialDeliveryFeeTaxes *
                      (subtotal + deliveryFee + couponValue)) /
                  deliveryFee
        serviceFeeTaxes = initialServiceFeeTaxes
    } else {
        // coupon is zero or only covers part of items, or have a weird negative coupon or something
        itemTaxes =
            subtotal === 0
                ? 0
                : (initialItemTaxes * (subtotal + couponValue)) / subtotal
        deliveryFeeTaxes = initialDeliveryFeeTaxes
        serviceFeeTaxes = initialServiceFeeTaxes
    }

    const totalTaxes = itemTaxes + deliveryFeeTaxes + serviceFeeTaxes
    const taxesAndFees = totalTaxes + serviceFee
    const totalBeforeTip =
        totalTaxes + deliveryFee + serviceFee + subtotal + couponValue
    const totalAfterTip = totalBeforeTip + tip

    return {
        subtotal,
        itemTaxes,
        serviceFee,
        serviceFeeTaxes,
        deliveryFee,
        deliveryFeeTaxes,
        totalTaxes,
        totalBeforeTip,
        couponValue,
        totalAfterTip,
        tip,
        taxesAndFees,
    }
}
