import { useState } from 'react'

import Ax from 'axios'
import { useQuery, useQueryClient } from 'react-query'

import { CartResponse } from '@/types/Cart'

import { useQueryState } from '@/lib/queryClient'
import { useStore } from '@/lib/store'

import storage from '@/utils/storage'

import { useClearCart, useUpdateCartMenuId } from '../hooks/cartMutations'
import { emptyCart } from '../utils/emptyCart'
import { cartQueryFn } from './getCart'
import { useInstance } from './useInstance'

export const cartQueryKey = 'cart'
export const CartClearingQueryKey = ['cart_clearing']

export const useCart = () => {
    const queryClient = useQueryClient()
    const [instanceId, setInstanceId] = useState<string>()
    const { data: instance } = useInstance(instanceId)

    const { mutate: setCartMenuId } = useUpdateCartMenuId()

    const deliveryType = useStore((state) => state.location.deliveryType)
    const setDeliveryType = useStore((state) => state.setDeliveryType)

    const { mutateAsync: clearCart } = useClearCart()

    const { setData: setIsCartClearing } =
        useQueryState<boolean>(CartClearingQueryKey)

    return useQuery(cartQueryKey, cartQueryFn, {
        placeholderData: emptyCart,
        // staleTime fixes issue with race condition and cart clear not working
        // should be low for checking order_success after checkout
        staleTime: 2 * 1000, // 2 seconds (arbitrary)
        onSuccess: async (cart: CartResponse) => {
            if (cart.instance_id) {
                if (cart.instance_id !== instanceId)
                    setInstanceId(cart.instance_id)
                else if (
                    // If cart's menu id doesn't match instance menu id, set its menu id to instance menu id
                    cart.instance_id === instanceId &&
                    instance?.menu?.menu_id &&
                    cart.menu_id !== instance.menu.menu_id
                )
                    setCartMenuId(instance.menu.menu_id)
            }

            // initially "emptyCart" is returned when a user has no cart
            // but the initial dummy cart has hardcoded value of delivryType as "delivery"
            // and if the user has set the delivery type to "Pickup", this got changed bcz of the
            // condition below
            // should only change the delivery type if the cart has some items, i.e. not the intial cart
            if (
                cart.fulfillment_type &&
                cart.items.length > 0 &&
                cart.fulfillment_type !== deliveryType
            )
                setDeliveryType(cart.fulfillment_type)

            // Must be seperate and not part of useQueryState hook to
            // avoid race condition
            const isCartClearing =
                queryClient.getQueryData(CartClearingQueryKey)

            if (cart.order_success && !isCartClearing) {
                setIsCartClearing(true)
                try {
                    await clearCart()
                } catch {
                } finally {
                    setIsCartClearing(false)
                }
            }
        },
        async onError(err) {
            if (Ax.isAxiosError(err)) {
                if (err.response?.status === 404) {
                    await storage.removeCartId()
                    queryClient.removeQueries(cartQueryKey)
                }
            }
        },
    })
}
