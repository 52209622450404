import { Text } from 'react-native'

import { MenuItemScreenProps } from '@/navigation/types/ScreenProps'
import { useNavigation } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@/components/Button'

import tw from '@/tailwind/tailwind'

export const NoMenuItem = () => {
    const navigation = useNavigation<MenuItemScreenProps['navigation']>()

    return (
        <SafeAreaView style={tw`flex-1 justify-center items-center container`}>
            <Text style={tw`text-center font-ppa-b text-pc-shade-80 mb-8`}>
                This menu or menu item does not exist
            </Text>
            <Button
                variant="primary"
                text={'Go home'}
                full
                onPress={() => navigation.navigate('HomeTabs')}
                trackableName="Tapped Go Home from No Menu Item screen"
                trackableCategory="Order"
            />
        </SafeAreaView>
    )
}
