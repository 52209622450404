import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const SmileySvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            d="M9.172 14.828a4 4 0 0 0 5.656 0M15 10h-.01M9 10h-.01M3 12a9 9 0 1 0 18 0 9 9 0 0 0-18 0Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default SmileySvg
