import { Address } from '@/types/Address'

export const getAddressLines = (address: Address | undefined | null) => {
    if (!address)
        return {
            addressLineOne: '',
            addressLineTwo: '',
        }
    return {
        addressLineOne: `${address?.address1}${
            address?.address2 ? `, ${address.address2}` : ''
        }`,
        addressLineTwo: `${address?.city}, ${address?.state} ${address?.zip}`,
    }
}
