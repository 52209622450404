import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const PlusSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color}
        {...props}
    >
        <Path
            d="M8 2.667v10.666M2.666 8h10.667"
            stroke={color || 'black'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default PlusSvg
