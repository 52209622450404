import { Text, View } from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'

import tw from '@/tailwind/tailwind'

import { BackButton } from '../Titles/BackButton'

export const NoOrders = () => {
    return (
        <SafeAreaView style={tw`flex-1`}>
            <View style={tw`absolute  container`}>
                <BackButton fallback="HomeTabs" title="Back" />
            </View>
            <Text
                style={tw`text-center font-ppa-b text-pc-shade-80 self-center my-auto`}
            >
                This order does not exist
            </Text>
        </SafeAreaView>
    )
}
