import { Image, Text, View } from 'react-native'

import { BrandScreenProps } from '@/navigation/types/ScreenProps'

import { Button } from '@/components/Button'

import { useProfile } from '@/hooks/query/useProfile'

import tw from '@/tailwind/tailwind'

// A component to show whenever no brands are retreived or no address exists
const NoAddress = ({
    navigation,
    closeModal,
}: {
    navigation: BrandScreenProps['navigation']
    closeModal: () => void
}) => {
    const { data: user } = useProfile()
    const action = (user?.addresses.length || 0) > 0 ? 'Select' : 'Add'

    return (
        <View style={tw`items-center justify-center flex-1`}>
            <Image
                style={tw`w-20 h-20`}
                source={require('@/assets/party-popper-emoji.png')}
            />
            <Text style={tw`font-ppa-wbi text-2xl mt-5 text-center`}>
                Welcome
            </Text>
            <Text style={tw`font-ppa-b text-pc-shade-80 mt-5 text-center`}>
                {action} an address to get started!
            </Text>
            <View style={tw`mt-7`}>
                <Button
                    variant="primary"
                    onPress={() => {
                        navigation.navigate('Location Picker')
                        closeModal()
                    }}
                    text={`${action} an Address`}
                    trackableName={`tapped ${action} Address on No Address screen`}
                    trackableCategory="Address"
                    testID="add-address-button"
                />
            </View>
        </View>
    )
}

export { NoAddress }
