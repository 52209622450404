import { Item } from '@/types/Menu'

export const listOfModifiers = (item: Item) => {
    const { modifier_groups } = item
    if (!modifier_groups) return ''

    const list = modifier_groups.reduce(
        (prevG, currG) =>
            prevG +
            currG.modifiers.reduce(
                (prev, curr) => {
                    if (!curr.is_selected) return prev
                    return prev + curr.name + ', '
                },

                ''
            ),
        ''
    )

    // This will remove the last comma and any whitespace after it:
    return list.replace(/,\s*$/, '')
}
