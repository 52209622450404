// Convert cents to readable prices
// 1234 -> $12.34
export const toPrice = (price: number): string =>
    (price === 0 ? price : price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    })

export const dollarInput = (value: string): string => {
    // Use the replace method to filter out non-numeric and non-decimal characters
    const filtered = value.replace(/[^\d.]/g, '')
    // Use another replace method to restrict the number of decimal places to 2
    const decimalLimited = filtered.replace(/(\.\d\d)\d+$/, '$1')
    // If the first character is a decimal point, remove it
    const noLeadingDecimal = decimalLimited.replace(/^(\.)+/, '')
    // Remove all decimals except the first one
    const onlyOneDecimal = noLeadingDecimal.replace(/(\.[^.]*)(\.)/g, '$1')
    return onlyOneDecimal
}

export const normalizeMobileNumber = (value: string): string => {
    if (!value) return value

    // only allow 0-9
    const currentValue = value.replace(/[^\d]/g, '')

    const currentLength = currentValue.length

    if (currentLength === 0) return currentValue

    // return 'x', 'xx', 'xxx'
    if (currentLength < 4) return currentValue

    if (currentLength < 7) {
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
    }

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
    )}-${currentValue.slice(6, 10)}`
}

export function formatCreditCardNumber(value: string) {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    const matches = v.match(/\d{4,16}/g)
    const match = (matches && matches[0]) || ''
    const parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}

// https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
export function hexToRGBA(colorHex: string) {
    let c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(colorHex)) {
        c = colorHex.substring(1).split('')
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        // @ts-ignore
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255, 1]
    }
    throw new Error('Bad Hex')
}
