import { useMemo, useState } from 'react'

import { Platform, Text, View } from 'react-native'

import { TouchableOpacity } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'

import { postTrackingEvent } from '@/components/Analytics'
import { BottomSheetTextInput } from '@/components/BottomSheetModal/BottomSheetTextInput'
import { ModalX } from '@/components/Titles/ModalX'

import { validateEmail, validateName } from '@/utils/validators'

import tw from '@/tailwind/tailwind'

import CheckSvg from '@/svgs/CheckSvg'

export const UserInfoModal = ({
    closeModal,
    setUserInfo,
    currentFirst,
    currentLast,
    currentEmail,
    isLoading,
}: {
    currentFirst: string
    currentLast: string
    currentEmail: string
    closeModal: () => void
    setUserInfo: (first: string, last: string, email: string) => void
    isLoading: boolean
}) => {
    const [first, setFirst] = useState(currentFirst)
    const [last, setLast] = useState(currentLast)
    const [email, setEmail] = useState(currentEmail)
    const [errorMsg, setErrorMsg] = useState('')

    const isFirstValid = useMemo(() => validateName(first), [first])
    const isLastValid = useMemo(() => validateName(last), [last])
    const isEmailValid = useMemo(() => validateEmail(email), [email])
    const isDisabled = useMemo(
        () => !isFirstValid || !isLastValid || !isEmailValid,
        [first, last, email]
    )

    const onXPress = () => {
        closeModal()
    }

    const onCheckPress = async () => {
        postTrackingEvent({
            name: 'Tapped checkmark on user info modal',
            category: 'Checkout',
        })
        if (!isDisabled) {
            setErrorMsg('')
            setUserInfo(first, last, email)
        } else {
            if (!isFirstValid) setErrorMsg('Invalid first name')
            else if (!isLastValid) setErrorMsg('Invalid last name')
            else if (!isEmailValid) setErrorMsg('Invalid email')
        }
    }

    return (
        <SafeAreaView edges={['bottom']} style={tw`flex-1 `}>
            <View style={tw`container flex-1`}>
                <View style={tw`mt-2 mb-3`}>
                    <View style={tw`relative flex-row items-center`}>
                        <ModalX
                            onPress={onXPress}
                            name="Tapped X on user info screen"
                            category="Checkout"
                        />

                        <Text style={tw`ml-4 font-ppa-b text-xl text-center`}>
                            Missing order info
                        </Text>
                    </View>

                    <Text style={tw`flex-row text-2xl font-ppa-wbi mr-20 mt-5`}>
                        Enter your name and email
                    </Text>
                </View>
                <View>
                    <View style={tw`mb-1`}>
                        <Text style={tw`label`}>First</Text>
                        <BottomSheetTextInput
                            style={tw`input border-2 ${
                                errorMsg && !isFirstValid
                                    ? 'border-pc-warning'
                                    : 'border-white'
                            }`}
                            value={first}
                            onChangeText={setFirst}
                            maxLength={30}
                            autoCompleteType="name"
                            textContentType="givenName"
                            onSubmitEditing={onCheckPress}
                            autoFocus={Platform.OS !== 'web'}
                        />
                    </View>
                    <View style={tw`mb-1`}>
                        <Text style={tw`label`}>Last</Text>
                        <BottomSheetTextInput
                            style={tw`input border-2 ${
                                errorMsg && !isLastValid
                                    ? 'border-pc-warning'
                                    : 'border-white'
                            }`}
                            value={last}
                            onChangeText={setLast}
                            maxLength={30}
                            autoCompleteType="name"
                            textContentType="familyName"
                            onSubmitEditing={onCheckPress}
                        />
                    </View>
                    <View style={tw`mb-1`}>
                        <Text style={tw`label`}>Email</Text>
                        <BottomSheetTextInput
                            style={tw`input border-2 ${
                                errorMsg && !isEmailValid
                                    ? 'border-pc-warning'
                                    : 'border-white'
                            }`}
                            value={email}
                            onChangeText={setEmail}
                            autoCompleteType="email"
                            textContentType="emailAddress"
                            onSubmitEditing={onCheckPress}
                            keyboardType={'email-address'}
                        />
                    </View>
                    <Text
                        style={tw`absolute bottom-12 text-pc-warning mt-5 font-ppa-b text-xs`}
                    >
                        {errorMsg}
                    </Text>
                    <View style={tw`items-end`}>
                        <TouchableOpacity
                            style={tw`h-12 w-12 mt-7 flex justify-center
                                 items-center rounded-full border-2 border-black ${
                                     isLoading ? 'bg-pc-shade-80' : 'bg-black'
                                 }`}
                            onPress={onCheckPress}
                            disabled={isLoading}
                        >
                            <CheckSvg />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </SafeAreaView>
    )
}
