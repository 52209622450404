import { View } from 'react-native'

export const asyncMeasure = async (
    el: View
): Promise<{
    fx: number
    fy: number
    width: number
    height: number
    px: number
    py: number
}> => {
    return new Promise((resolve) => {
        el.measure((fx, fy, width, height, px, py) => {
            resolve({ fx, fy, width, height, px, py })
        })
    })
}
