import { Address } from '@/types/Address'

import { useStore } from '@/lib/store'

import { useClearCartConditionally } from './useClearCartConditionally'

export const useSetLocationWithClearCart = () => {
    const selectedLocation = useStore(
        (state) => state.location.selectedLocation
    )
    const setSelectedLocation = useStore((state) => state.setSelectedLocation)

    const clearCartConditionally = useClearCartConditionally()

    return async (newLocation: Address) => {
        await clearCartConditionally(
            !!selectedLocation,
            `You have items in your cart based on current address${
                selectedLocation ? ' ' + selectedLocation.address1 : ''
            }. Do you want to clear your cart and switch to ${
                newLocation.address1
            }?`,
            async () => {
                await setSelectedLocation(newLocation)
            }
        )
    }
}
