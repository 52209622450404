import { axios } from '@/lib/httpClient'

import { PrizesResponse } from '../types'

export const getPrizes = async (brandId: string) => {
    const response = await axios.get<PrizesResponse>('/prize/all', {
        params: { brand_id: brandId },
    })
    return response.data
}
