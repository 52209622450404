import React, { useEffect, useRef, useState } from 'react'

import { AppState, AppStateStatus, Platform } from 'react-native'

import { useBottomSheetModal } from '@gorhom/bottom-sheet'
import {
    DefaultTheme,
    LinkingOptions,
    NavigationContainer,
    NavigationState,
} from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import Constants from 'expo-constants'
import * as Device from 'expo-device'
import * as Linking from 'expo-linking'
import { BranchParams } from 'react-native-branch'

import { AccountSettings } from '@/features/Account'
import { NotAuthed } from '@/features/Account/screens/NotAuthed'
import { Auth } from '@/features/Auth'
import { BrandPage } from '@/features/BrandPage'
import { Cart } from '@/features/Cart'
import { Checkout } from '@/features/Checkout/screens/Checkout'
import { DeveloperTools } from '@/features/DeveloperTools'
import { LocationPicker } from '@/features/LocationPicker'
import { ConfirmAddress } from '@/features/LocationPicker/screens/ConfirmAddress'
import { EditLocation } from '@/features/LocationPicker/screens/EditLocation'
import { LootboxAnimation } from '@/features/LootBox/screens/LootboxAnimation'
import { MenuItemScreen } from '@/features/MenuItem'
import { NoInternet } from '@/features/NoInternet'
import { OrderPlaced } from '@/features/OrderPlaced/screens'
import { OrderProcessing } from '@/features/OrderProcessing'
import { ReviewOrder } from '@/features/ReviewOrder'
import { ReviewOrderBounce } from '@/features/ReviewOrder/screens/ReviewOrderBounce'
import { ReviewSuccess } from '@/features/ReviewOrder/screens/ReviewSuccess'
import { StreetTeam } from '@/features/StreetTeam/screens/Form'
import { StreetTeamFormPartTwo } from '@/features/StreetTeam/screens/FormPartTwo'
import { Welcome } from '@/features/Welcome'

import {
    navigationEventCategoriesAndNames,
    postTrackingEvent,
} from '@/components/Analytics'

import { useProfile } from '@/hooks/query/useProfile'
import { getDLPath, useBranchJourney } from '@/hooks/useBranchJourney'

import { postMixpanelUserProperties } from '@/lib/mixpanel'

import { BottomTabs } from './TabNavigation'
import { navigationRef } from './navigateUsingRef'
import { RootStackParamList } from './types/RootStackParamList'

const Stack = createNativeStackNavigator<RootStackParamList>()

const MyTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: 'white',
    },
}

type handleStateChangeProps = {
    dismissAllModals: () => void
    previousRoute: React.MutableRefObject<string | undefined>
}
const handleStateChange = async ({
    dismissAllModals,
    previousRoute,
}: handleStateChangeProps) => {
    const previousRouteName = previousRoute.current
    const currentRouteName = navigationRef.getCurrentRoute()?.name

    // on navigation change, dismiss all currently open modals
    dismissAllModals()

    if (
        currentRouteName &&
        previousRouteName !== currentRouteName &&
        currentRouteName in navigationEventCategoriesAndNames
    ) {
        const current = navigationEventCategoriesAndNames[currentRouteName]
        const sendToOneSignal = !![
            'account',
            'no user account',
            'orders',
            'checkout',
            'cart',
            'order placed',
            'rewards',
            'welcome',
            'menu item',
            'Brands',
            'Rewards',
        ].includes(current['name'])
        postTrackingEvent({
            name: 'Viewed ' + current['name'] + ' screen',
            category: current['category'],
            properties:
                previousRouteName &&
                previousRouteName in navigationEventCategoriesAndNames
                    ? {
                          from: navigationEventCategoriesAndNames[
                              previousRouteName
                          ]['name'],
                      }
                    : {},
            onesignal: sendToOneSignal,
        })
    }

    previousRoute.current = currentRouteName
}

const isBranchParam = (key: string) =>
    key.startsWith('?') || key.startsWith('~') || key.startsWith('+')

const generateDeeplinkFromBranchParams = (params: BranchParams) => {
    if (!params.$deeplink_path) return ''
    params.$deeplink_path = params.$deeplink_path as string

    let deeplinkString = params.$deeplink_path.split('?')[0]

    const initialDLParamsString = params.$deeplink_path.split('?')[1] || ''

    let queryParams = []
    for (let key in params) {
        // evventually we shouldn't strip out all branch params, but one of them breaks the ability for
        // people to append random query params to the end of branch links that are used for deeplinking
        // ie branchlinkToBrandPage.com + ?brandId='abc'
        if (key != '$deeplink_path' && !isBranchParam(key)) {
            queryParams.push(`${key}=${params[key]}`)
        }
    }

    if (queryParams.length > 0 || initialDLParamsString.length > 0) {
        deeplinkString += '?'
    }

    if (queryParams.length > 0) {
        deeplinkString += `${queryParams.join('&')}`
    }

    if (initialDLParamsString.length > 0) {
        deeplinkString +=
            (queryParams.length > 0 ? '&' : '') + initialDLParamsString
    }

    return deeplinkString
}

const updateUserPropertiesFromBranchParams = (params: BranchParams) => {
    const utm_source = params['~channel'] ?? ''
    const utm_campaign = params['~campaign'] ?? ''
    const utm_medium = params['~feature'] ?? ''
    // const tags: string[] = params['~tags'] ?? []

    postMixpanelUserProperties({
        utm_source,
        utm_campaign,
        utm_medium,
    })
}

const Navigation = () => {
    const { data: profile } = useProfile()
    const { dismissAll: dismissAllModals } = useBottomSheetModal()
    const { setDeeplinkPath } = useBranchJourney()

    const [_isReady, setIsReady] = useState(false) // this is required for deeplinking to work idk why

    // Mixpanel analytics
    useEffect(() => {
        // post mixpanel event when app is opened (first time)
        postTrackingEvent({
            name: 'App opened',
            category: 'General',
            onesignal: true,
        })
        // Because event is only triggered on navigation change,
        // on ios need to trigger an extra viewed screen event
        if (Platform.OS === 'ios') {
            if (navigationRef.current?.isReady())
                postTrackingEvent({
                    name: `Viewed ${
                        navigationRef.getCurrentRoute()?.name
                    } screen`,
                    category: 'General',
                })
        }

        const postAppForegrounded = (appState: AppStateStatus) => {
            if (appState === 'active') {
                postTrackingEvent({
                    name: 'App foregrounded',
                    category: 'General',
                    onesignal: true,
                })
            }
        }

        // post mixpanel event every time app is foregrounded
        AppState.addEventListener('change', postAppForegrounded)

        // remove listner
        return () => {
            AppState.removeEventListener('change', postAppForegrounded)
        }
    }, [])

    // Screen tracking for mixpanel analytics. Inspired by https://reactnavigation.org/docs/screen-tracking
    const previousRoute = useRef<string | undefined>(undefined)

    const prefix = Linking.createURL('/')
    const linking: LinkingOptions<RootStackParamList> = {
        prefixes: [prefix, 'https://order.popchew.com'],
        // overwrite native listener to handle branch events
        /**
         * This getInitialUrl implementation is a solution to the inconsistent behavior seen
         * when the app is cold started from a branch deep link on iOS devices.
         */
        async getInitialURL() {
            // return if simulator as importing branch will throw error
            if (!Device.isDevice) return
            // return if running in expo go app
            if (Constants.appOwnership === 'expo') return
            // Check if the app was opened from a deep link
            // Note: url will be null when the app is NOT installed or cold-started from a deep link.
            const url = await Linking.getInitialURL()
            if (url === null) {
                // Return early as the app was opened normally(NOT with a deep link).
                return
            }
            /**
             * https://github.com/BranchMetrics/react-native-branch-deep-linking-attribution/issues/673
             * There is a race condition that results in the incosistent deep link behavior on cold start
             *  as described in the linked blurb from React-Native-Branch: https://github.com/BranchMetrics/react-native-branch-deep-linking-attribution/blob/5782d434005ade9b41e563c77b72ffa37b27f2f0/src/BranchSubscriber.js#L66
             *
             * To account for the race condition, we can call the branch.openURL() method with the url(deep link) that initially opened the app.
             *
             * Doing so results in the native Branch SDK handling a 'new' deep link when the React-Native-Branch SDK and the javascript bundle are loaded,
             *  which lets our deep-linking implementation perform as expected.
             */
            const branchImport = await import('react-native-branch')
            const branch = branchImport.default
            branch.openURL(url)
            return url
        },
        subscribe(listener) {
            const branchSubscribe = async () => {
                // return if simulator as importing branch will throw error
                if (!Device.isDevice) return

                // return if running in expo go app
                if (Constants.appOwnership === 'expo') return

                const branchImport = await import('react-native-branch')
                const branch = branchImport.default

                return branch.subscribe(async ({ error, params }) => {
                    if (error) {
                        console.error('Error from Branch: ' + error)
                        return
                    }

                    if (!params) return

                    if (params['+non_branch_link']) {
                        // Route non-Branch URL if appropriate.
                        return
                    }

                    if (!params['+clicked_branch_link']) {
                        // Indicates initialization success and some other conditions.
                        // No link was opened.
                        return
                    }

                    // set user properties from branch params
                    try {
                        updateUserPropertiesFromBranchParams(params)
                    } catch {}

                    const deeplinkPath =
                        generateDeeplinkFromBranchParams(params)

                    // ex deeplinkPath = 'Cart' or deeplinkPath = 'HomeTabs/Rewards'
                    listener('popchewapp://' + deeplinkPath)
                })
            }

            return async () => {
                const unsubscribe = await branchSubscribe()
                if (unsubscribe) unsubscribe()
            }
        },
    }

    return (
        <NavigationContainer
            linking={linking}
            documentTitle={{
                formatter: (options, route) =>
                    `Popchew - ${options?.title ?? route?.name}`,
            }}
            ref={navigationRef}
            theme={MyTheme}
            onReady={async () => {
                setIsReady(true)

                const currentRoute = navigationRef.getCurrentRoute()

                previousRoute.current = currentRoute?.name

                // get current path, and remove / from the start
                // and set branch journey deeplink location
                const deeplinkPath =
                    currentRoute?.path && currentRoute.path.length > 0
                        ? currentRoute.path.substring(1)
                        : ''
                setDeeplinkPath(deeplinkPath)
            }}
            onStateChange={(state) => {
                state = state as NavigationState

                // get deeplink path and set branch journey deeplink location
                const deeplinkPath = getDLPath(state)

                setDeeplinkPath(deeplinkPath)

                handleStateChange({ dismissAllModals, previousRoute })
            }}
        >
            <Stack.Navigator
                initialRouteName={
                    // 'Order Processing'
                    Platform.OS !== 'web' && (!profile || profile.is_anon)
                        ? 'Welcome'
                        : 'HomeTabs'
                }
                screenOptions={{
                    headerShown: false,
                }}
            >
                <Stack.Screen
                    name="HomeTabs"
                    component={BottomTabs}
                    options={{ title: 'Home' }}
                />
                <Stack.Screen name="Welcome" component={Welcome} />
                <Stack.Screen name="Login" component={Auth} />

                <Stack.Screen
                    name="Menu Item"
                    component={MenuItemScreen}
                    options={{ title: 'Menu' }}
                />

                {/* Adding an address */}
                <Stack.Group>
                    <Stack.Screen
                        name="Location Picker"
                        component={LocationPicker}
                        options={{ title: 'Select an address' }}
                    />
                    <Stack.Screen
                        name="Edit Location"
                        component={EditLocation}
                    />
                </Stack.Group>

                {/* Review order */}
                <Stack.Group>
                    <Stack.Screen name="Review Order" component={ReviewOrder} />
                    <Stack.Screen
                        name="Review Order Success"
                        component={ReviewSuccess}
                    />
                    <Stack.Screen
                        name="Review Order Start"
                        component={ReviewOrderBounce}
                    />
                </Stack.Group>

                <Stack.Screen
                    name="Confirm Address"
                    component={ConfirmAddress}
                />
                <Stack.Screen
                    name="NoInternet"
                    component={NoInternet}
                    options={{ title: 'No internet' }}
                />
                {/* street team expirament */}
                <Stack.Group>
                    <Stack.Screen
                        name="Street Team Confirm"
                        component={StreetTeamFormPartTwo}
                    />
                    <Stack.Screen name="Street Team" component={StreetTeam} />
                </Stack.Group>
                <Stack.Screen name="BrandPage" component={BrandPage} />
                <Stack.Screen name="Cart" component={Cart} />
                <Stack.Screen name="OrderPlaced" component={OrderPlaced} />
                <Stack.Screen
                    name="Order Processing"
                    component={OrderProcessing}
                />
                <Stack.Screen
                    name="LootboxAnimation"
                    component={LootboxAnimation}
                />
                <Stack.Screen name="Checkout" component={Checkout} />
                <Stack.Screen name="Devtools" component={DeveloperTools} />
            </Stack.Navigator>
            {!profile || profile.is_anon ? <NotAuthed /> : <AccountSettings />}
        </NavigationContainer>
    )
}

export { Navigation }
