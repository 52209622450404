import { useMemo, useState } from 'react'

import { ActivityIndicator, Text, View } from 'react-native'

import { useBottomSheetDynamicSnapPoints } from '@gorhom/bottom-sheet'
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'

import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { ModalX } from '@/components/Titles/ModalX'

import tw from '@/tailwind/tailwind'

export const CouponModal = ({
    closeModal,
    checkCoupon,
    errorMessage,
    setCouponErrorMessage,
    isLoading,

    initialCode,
    bsmRef,
}: {
    closeModal: () => void
    checkCoupon: (code: string) => void
    errorMessage: string
    setCouponErrorMessage: (message: string) => void
    isLoading: boolean
    initialCode?: string
    bsmRef: React.RefObject<BottomSheetModalMethods>
}) => {
    const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], [])
    const {
        animatedHandleHeight,
        animatedSnapPoints,
        animatedContentHeight,
        handleContentLayout,
    } = useBottomSheetDynamicSnapPoints(initialSnapPoints)

    const [code, setCode] = useState<string>(initialCode ?? '')
    const handleCodeChange = (text: string) => {
        setCode(text)
        setCouponErrorMessage('')
    }

    return (
        <BottomSheetModal
            isDark
            ref={bsmRef}
            snapPoints={animatedSnapPoints}
            handleHeight={animatedHandleHeight}
            contentHeight={animatedContentHeight}
            handleComponent={null}
            keyboardBehavior="interactive"
            trackableName="add coupon code"
            trackableCategory="Checkout"
            onDismiss={() => setCouponErrorMessage('')}
        >
            <View style={tw`py-container`} onLayout={handleContentLayout}>
                <View style={tw`px-container`}>
                    <ModalX
                        onPress={closeModal}
                        name="Tapped X on add coupon screen"
                        category="Checkout"
                        title="Coupon"
                        color="white"
                    />
                    <Text style={tw`text-sm text-white font-ppp-m py-4`}>
                        Enter your coupon code
                    </Text>
                </View>
                <View style={tw`relative`}>
                    <Input
                        variant="primary"
                        placeholder="Code"
                        onChangeText={handleCodeChange}
                        value={code}
                        onSubmitEditing={() => checkCoupon(code)}
                        isBottomSheet
                        errorMessage={errorMessage}
                    />
                </View>
                <View
                    style={tw`px-container items-center min-h-[60px] mb-2.5 mt-8`}
                >
                    {isLoading ? (
                        <View style={tw`flex-1 items-center justify-center`}>
                            <ActivityIndicator color="white" />
                        </View>
                    ) : (
                        <View style={tw``}>
                            <Button
                                variant="primary"
                                onPress={() => checkCoupon(code)}
                                full
                                text="Add Coupon"
                                disabled={!code}
                                trackableName="Tapped Add Coupon"
                                trackableCategory="Checkout"
                            />
                        </View>
                    )}
                </View>
            </View>
        </BottomSheetModal>
    )
}
