import prodConf, { UXCamConfiguration } from 'react-native-ux-cam'

// this will crash simulator, and should only be used on real devices
const simulatorConf = {
    optIntoSchematicRecordings: () => {},
    startWithConfiguration: (_api_key: UXCamConfiguration) => {},
    setUserIdentity: (_userIdentity: string) => {},
    setUserProperty: (_propertyName: string, _value: string | number) => {},
    occludeSensitiveView: () => {},
}

const RNUxcam = process.env.APP_VARIANT === undefined ? simulatorConf : prodConf

type updateUxcamUserProps = {
    first?: string | null
    last?: string | null
    email?: string | null
    userId: string
}
export const updateUxcamUser = ({
    userId,
    first,
    last,
    email,
}: updateUxcamUserProps) => {
    RNUxcam.setUserIdentity(userId)

    if (first) RNUxcam.setUserProperty('first', first)
    if (last) RNUxcam.setUserProperty('last', last)
    if (email) RNUxcam.setUserProperty('email', email)
}

export default RNUxcam
