import { Text, View } from 'react-native'

import { ReviewOrderSuccessScreenProps } from '@/navigation/types/ScreenProps'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@/components/Button'

import tw from '@/tailwind/tailwind'

import { ReviewOrderHeader } from '../components/ReviewOrderHeader'

export const ReviewSuccess = ({
    navigation,
    route,
}: ReviewOrderSuccessScreenProps) => {
    const params = route.params
    const { pointsEarned, isPositive } = params || {}

    return (
        <SafeAreaView style={tw`flex-1 justify-between`}>
            <ReviewOrderHeader title="Feedback Recieved" />

            <View style={tw`container`}>
                <Text style={tw`text-pc-secondary font-ppa-wbi text-center`}>
                    +{pointsEarned} Points
                </Text>
                <Text style={tw`font-ppa-wbi text-4xl text-center`}>
                    {isPositive ? 'thank you!' : `we’re sorry`}
                </Text>
                <Text style={tw`text-center text-pc-shade-80 font-ppa-b mt-4`}>
                    {isPositive
                        ? `Your feedback helps us improve the Popchew experience for all users.`
                        : `We greatly appreciate your feedback and apologize for your poor experience. A Popchew team member will reach out via email in the next few days to see how we can make it up to you.`}
                </Text>
            </View>

            <View style={tw`mb-4 mx-4`}>
                <Button
                    variant="primary"
                    text="Home"
                    onPress={() => navigation.navigate('HomeTabs')}
                    trackableCategory="Review"
                    trackableName="Go to Home from review success screen"
                />
            </View>
        </SafeAreaView>
    )
}
