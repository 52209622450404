import * as React from 'react'

import Svg, { Circle, Path, SvgProps } from 'react-native-svg'

const AccountSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Circle
            cx={12}
            cy={7}
            r={4}
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M4 19.714A4.714 4.714 0 0 1 8.714 15h6.572A4.714 4.714 0 0 1 20 19.714v0c0 .71-.576 1.286-1.286 1.286H5.286C4.576 21 4 20.424 4 19.714v0Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default AccountSvg
