import { Text, TouchableOpacity, View } from 'react-native'

import { navigateUsingRef } from '@/navigation/navigateUsingRef'

import { OrderDetailResponse } from '@/types/Order'

import { TrackableClick } from '@/components/Analytics'

import tw from '@/tailwind/tailwind'

import ChevronRightSvg from '@/svgs/ChevronRightSvg'
import StarGroupingSvg from '@/svgs/StarGroupingSvg'

export const PointsEarned = ({ order }: { order: OrderDetailResponse }) => {
    const brandId = order.brand_id
    const pointsEarned = order.points_earned || 0

    return (
        <TrackableClick
            name={'Tapped points earned'}
            category={'Order'}
            properties={{ pointsEarned }}
        >
            <TouchableOpacity
                style={tw`bg-pc-secondary px-container py-2 flex-row rounded-md justify-between items-center`}
                onPress={() => {
                    navigateUsingRef('BrandPage', {
                        brandId,
                        page: 'prizes',
                    })
                }}
            >
                <View style={tw`flex-row items-center`}>
                    <StarGroupingSvg />
                    <View style={tw`ml-4`}>
                        <Text style={tw`text-white font-ppa-wbi`}>
                            Points Earned
                        </Text>
                        <Text style={tw`text-white font-ppa-wbi text-2xl`}>
                            {pointsEarned.toLocaleString()}
                        </Text>
                    </View>
                </View>
                <ChevronRightSvg color={'white'} strokeWidth={4} />
            </TouchableOpacity>
        </TrackableClick>
    )
}
