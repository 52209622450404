import { Text, View } from 'react-native'

import { Order } from '@/types/Order'

import tw from '@/tailwind/tailwind'

export const OrderItems = ({ order }: { order: Order }) => {
    const { cart } = order

    return (
        <View style={tw`mt-4`}>
            <Text style={tw`font-ppa-b text-pc-shade-80 text-base mb-2`}>
                Order Items
            </Text>

            {cart.items.map((item, idx) => {
                return (
                    <View key={idx} style={tw`flex-row items-center mb-2.5`}>
                        <View
                            style={tw`bg-pc-shade-60 rounded-full flex justify-center w-8 h-8 items-center mr-4`}
                        >
                            <Text style={tw`text-white font-ppa-b text-base`}>
                                {item.count}
                            </Text>
                        </View>
                        <View style={tw`flex-shrink`}>
                            <Text style={tw`font-ppa-b text-lg `}>
                                {item.item.name}
                            </Text>
                        </View>
                    </View>
                )
            })}
        </View>
    )
}
