import { useMemo } from 'react'

import { ActivityIndicator, View } from 'react-native'

import { MenuItemScreenProps } from '@/navigation/types/ScreenProps'
import { useNavigation } from '@react-navigation/native'
import uuid from 'react-native-uuid'

import {
    useAddToCart,
    useUpdateItemInCart,
} from '@/features/Cart/hooks/cartMutations'
import { useClearCartConditionally } from '@/features/LocationPicker/utils/useClearCartConditionally'

import { Cart, CartItem } from '@/types/Cart'
import { Instance } from '@/types/Instance'
import { Item, ModifierGroup as ModifierGroupType } from '@/types/Menu'

import { Button } from '@/components/Button'

import { useStore } from '@/lib/store'

import { toPrice } from '@/utils/normalizers'
import { showMessage } from '@/utils/showMessage'
import { totalItemPrice } from '@/utils/totalItemPrice'

import tw from '@/tailwind/tailwind'

type MenuItemBottomProps = {
    quantity: number
    item: Item
    modifierGroups: ModifierGroupType[]
    instance: Instance
    menuId: string
    isUpdate?: boolean
    cartItemId?: string
    cart: Cart
}

export const MenuItemBottom = (props: MenuItemBottomProps) => {
    const navigation = useNavigation<MenuItemScreenProps['navigation']>()

    const {
        quantity,
        item,
        modifierGroups,
        isUpdate,
        instance,
        menuId,
        cartItemId,
        cart,
    } = props

    const currentInstanceId = cart.instance_id
    const currentInstanceName = cart.instance_name

    const {
        instance_id: instanceId,
        instance_name: instanceName,
        brand_id: brandId,
    } = instance
    const { name, price, item_id: itemId } = item

    const deliveryType = useStore((state) => state.location.deliveryType)

    const { mutateAsync: updateItem } = useUpdateItemInCart()
    const clearCartConditionally = useClearCartConditionally()

    const tempItem = useMemo<Item>(
        () => ({ ...item, modifier_groups: modifierGroups }),
        [item, modifierGroups]
    )

    // Get total item price whenever quantity/modifiers are changed
    const totalPrice = useMemo(
        () => quantity * totalItemPrice(tempItem),
        [modifierGroups, quantity]
    )
    const { mutateAsync: addToCart, isLoading: addToCartIsLoading } =
        useAddToCart()

    // Pulled add to cart logic out to deal with auto clear and add from different brand
    const handleAddToCart = async (cartItem: CartItem) => {
        await addToCart({
            cartItem,
            instanceId,
            menuId,
            instanceName,
            fulfillmentType: deliveryType,
        })
        showMessage({
            message: `Added${quantity > 1 ? ' ' + quantity : ''} to cart`,
            type: 'info',
        })
        navigation.navigate('BrandPage', { brandId })
    }

    // Check if item is valid and can be added to cart
    // This is done by checking that the number of selected modifiers is between group's min/max, for each modifier group
    const isValid = useMemo(() => {
        for (const modiferGroup of modifierGroups) {
            const { min_selected, max_selected, modifiers } = modiferGroup
            const numOfSelectedModifiers = modifiers.filter(
                (modifier) => modifier.is_selected
            ).length
            if (
                numOfSelectedModifiers < (min_selected || 0) ||
                (max_selected && numOfSelectedModifiers > max_selected)
            )
                return false
        }
        return true
    }, [modifierGroups])

    // Handle add to cart click
    const onClickAddToCart = async () => {
        // Create cart item
        const newItem: CartItem = {
            item: tempItem,
            count: quantity,
            cart_item_id:
                isUpdate && cartItemId ? cartItemId : uuid.v4().toString(),
        }

        // If updating, update item in cart
        if (isUpdate && cartItemId) {
            await updateItem(newItem)

            showMessage({
                message: `Updated Cart`,
                type: 'info',
            })
            navigation.navigate('Cart')
        }
        // If not, add new item to cart
        else {
            await clearCartConditionally(
                !!currentInstanceId && instanceId !== currentInstanceId,
                `You already have items in your cart from ${currentInstanceName}, would you like to clear your current cart and add items from ${instanceName}?`,
                async () => {
                    await handleAddToCart(newItem)
                }
            )
        }
    }

    return (
        <View style={tw`container border-t border-pc-shade-60`}>
            {addToCartIsLoading ? (
                <ActivityIndicator style={tw`py-5`} />
            ) : (
                <Button
                    variant="primary"
                    onPress={async () => await onClickAddToCart()}
                    text={
                        (isUpdate
                            ? `Update`
                            : `Add ${quantity > 1 ? quantity + ' ' : ''}${
                                  quantity > 1 ? 'Items' : ''
                              } To Cart`) + `  •  ${toPrice(totalPrice)}`
                    }
                    disabled={!isValid}
                    trackableName="Tapped add to cart"
                    trackableCategory="Order"
                    trackableProperties={{
                        'item name': name,
                        'item base price': price,
                        'item id': itemId,
                        quantity,
                        'add to cart price': totalPrice,
                    }}
                    testID="menu-item-add-button"
                    onesignal
                />
            )}
        </View>
    )
}
