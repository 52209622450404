import { useQuery } from 'react-query'

import { axios } from '@/lib/httpClient'

type FeatureFlag = {
    name: string
    active: boolean
}

type getFeatureFlagsResponse = {
    data: FeatureFlag[]
}

const getFeatureFlags = async () => {
    const response = await axios.get<getFeatureFlagsResponse>(
        '/meta/feature-flags'
    )
    return response.data
}

export const useFeatureFlags = () => {
    const query = useQuery(['featureFlags'], getFeatureFlags, {
        staleTime: Infinity,
    })

    // create an object from the array of feature flags
    // where the key is the name of the feature flag
    // and the value is the active state of the feature flag

    // example
    // {
    //     "feature-flag-1": true,
    //     "feature-flag-2": false
    // }

    const featureFlags =
        query.data && query.data.data
            ? query.data.data.reduce(
                  (acc: { [key: string]: boolean }, featureFlag) => {
                      acc[featureFlag.name] = featureFlag.active
                      return acc
                  },
                  {}
              )
            : {}

    return { ...query, featureFlags }
}
