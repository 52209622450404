import * as React from 'react'

import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg'

const TiktokSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <G clipPath="url(#a)">
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.616 3v.015c0 .274.081 4.236 4.238 4.483 0 3.685.002 0 .002 3.06-.313.018-2.737-.157-4.245-1.502l-.005 5.959c.038 2.698-1.464 5.343-4.275 5.878-.788.15-1.499.166-2.688-.094-6.86-2.052-4.58-12.227 2.307-11.138 0 3.285.002 0 .002 3.285-2.846-.419-3.797 1.948-3.041 3.643.688 1.543 3.52 1.878 4.51-.3.111-.425.167-.911.167-1.456V3h3.028Z"
                fill="currentColor"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path
                    fill="currentColor"
                    transform="translate(4.5 3)"
                    d="M0 0h15.357v18H0z"
                />
            </ClipPath>
        </Defs>
    </Svg>
)

export default TiktokSvg
