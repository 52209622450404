import { useEffect } from 'react'

import { ActivityIndicator, FlatList, Platform, Text, View } from 'react-native'

import { BrandScreenProps } from '@/navigation/types/ScreenProps'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'

import { useUpdateFulfillment } from '@/features/Cart/hooks/cartMutations'
import { useClearCartConditionally } from '@/features/LocationPicker/utils/useClearCartConditionally'

import { DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { TrackableClick } from '@/components/Analytics'
import { Button } from '@/components/Button'
import { LoadingView } from '@/components/Loading'
import { ModalX } from '@/components/Titles/ModalX'

import { instancesQueryKey } from '@/hooks/query/useInstances'

import { queryClient } from '@/lib/queryClient'
import { useStore } from '@/lib/store'

import { useInstanceAvailability } from '@/utils/instance/useInstanceAvailability'
import storage from '@/utils/storage'

import tw from '@/tailwind/tailwind'

import BuildingSvg from '@/svgs/BuildingSvg'

import { NoAddress } from './NoAddress'
import { NoBrandsLocations } from './NoBrandsLocations'

type PickupDeliveryModalNavigationType = BrandScreenProps['navigation']

const PickupLocationCard = ({
    instance,
    isSelected,
    setSelectedInstance,
    selectedInstance,
}: {
    instance: Instance
    isSelected: boolean
    setSelectedInstance: () => void
    selectedInstance: Instance | undefined
}) => {
    const deliveryType = useStore((state) => state.location.deliveryType)
    const { isOpen } = useInstanceAvailability(instance, deliveryType)
    const clearCartConditionally = useClearCartConditionally()

    let text = instance.store.address.city
    const distance = instance.distance_from_address.toFixed(1)
    let subText = `${instance.store.address.address1} • ${
        distance.charAt(distance.length - 1) === '0'
            ? distance.substring(0, distance.length - 2)
            : distance
    } mi away`

    const MAX_WEB_TEXT_CHAR = 30
    const MAX_WEB_SUBTEXT_CHAR = 40
    if (Platform.OS === 'web') {
        text =
            text.length > MAX_WEB_TEXT_CHAR
                ? text.substring(0, MAX_WEB_TEXT_CHAR)
                : text
        subText =
            subText.length > MAX_WEB_SUBTEXT_CHAR
                ? subText.substring(0, MAX_WEB_SUBTEXT_CHAR)
                : subText
    }

    return (
        <TrackableClick
            name="Selected an pickup location from brands page pickup locations list"
            category="Address"
        >
            <TouchableOpacity
                style={tw`py-4 px-2 flex-row justify-start items-center ${
                    isSelected ? 'bg-pc-secondary/10' : ''
                }`}
                onPress={() => {
                    if (!isSelected)
                        clearCartConditionally(
                            !!selectedInstance,
                            `Are you sure you want to switch locations? Your cart will be reset`,
                            async () => {
                                setSelectedInstance()
                            }
                        )
                }}
                disabled={!isOpen}
            >
                {isOpen === undefined ? (
                    <View style={tw`flex-row items-center justify-center`}>
                        <ActivityIndicator />
                    </View>
                ) : (
                    <View style={tw`flex-row items-center`}>
                        <BuildingSvg width={20} />

                        <View style={tw`flex-1 ml-4`}>
                            <Text
                                numberOfLines={1}
                                style={tw`font-ppa-b text-lg ${
                                    isOpen ? '' : 'text-pc-shade-60'
                                }`}
                            >
                                {text}
                            </Text>
                            {isOpen ? (
                                <Text
                                    numberOfLines={1}
                                    style={tw`text-pc-shade-80 font-ppa-b`}
                                >
                                    {subText}
                                </Text>
                            ) : (
                                <Text style={tw`text-pc-warning font-ppa-b`}>
                                    Closed
                                </Text>
                            )}
                        </View>
                    </View>
                )}
            </TouchableOpacity>
        </TrackableClick>
    )
}

const SwitchDeliveryType = ({
    navigation,
    instances,
    closeModal,
}: {
    navigation: PickupDeliveryModalNavigationType
    instances: Instance[]
    closeModal: () => void
}) => {
    const deliveryType = useStore((state) => state.location.deliveryType)
    const switchTo =
        deliveryType === DeliveryType.delivery
            ? DeliveryType.pickup
            : DeliveryType.delivery
    const setDeliveryType = useStore((state) => state.setDeliveryType)
    const selectedLocation = useStore(
        (state) => state.location.selectedLocation
    )

    const clearCartConditionally = useClearCartConditionally()
    const { mutateAsync: updateCartFulfillment } = useUpdateFulfillment()
    return (
        <TouchableOpacity
            style={tw`justify-center items-center my-4`}
            onPress={() => {
                clearCartConditionally(
                    true,
                    `Are you sure you want to switch to delivery? Your cart will be reset`,
                    async () => {
                        closeModal()
                        setDeliveryType(switchTo)
                        updateCartFulfillment(switchTo)
                        if (
                            deliveryType === DeliveryType.delivery &&
                            (await storage.getCartId()) &&
                            (!selectedLocation || instances.length === 0)
                        )
                            navigation.navigate('Location Picker')
                    }
                )
            }}
        >
            <Text style={tw`text-center  text-[#03adfc] text-sm font-ppa-b `}>
                {`Switch to ${
                    deliveryType === DeliveryType.delivery
                        ? 'Pickup'
                        : 'Delivery'
                }`}
            </Text>
        </TouchableOpacity>
    )
}

export const PickupDeliveryModal = ({
    instances,
    navigation,
    isLoading,
    setSelectedInstance,
    selectedInstance,
    closeModal,
}: {
    instances: Instance[] | undefined
    navigation: PickupDeliveryModalNavigationType
    isLoading: boolean
    setSelectedInstance: (instance: Instance) => void
    selectedInstance: Instance | undefined
    closeModal: () => void
}) => {
    const deliveryType = useStore((state) => state.location.deliveryType)
    const isDelivery = deliveryType === DeliveryType.delivery

    const selectedLocation = useStore(
        (state) => state.location.selectedLocation
    )

    useEffect(() => {
        queryClient.invalidateQueries(
            instancesQueryKey(selectedLocation?.address_id, deliveryType)
        )
    }, [deliveryType, selectedLocation?.address_id])

    const onDonePress = () => {
        closeModal()
    }

    return (
        <SafeAreaView edges={['bottom']} style={tw`flex-1`}>
            <View style={tw`flex-row items-center mx-5 mb-3 `}>
                <ModalX
                    onPress={() => {
                        queryClient.cancelQueries(
                            instancesQueryKey(
                                selectedLocation?.address_id,
                                deliveryType
                            )
                        )
                        closeModal()
                    }}
                    name="Tapped X on Pickup Locations modal"
                    category="Address"
                />
                <Text style={tw`ml-3 font-ppa-b text-xl `}>
                    {isDelivery ? 'Delivery Address' : 'Choose Pickup Location'}
                </Text>
            </View>
            {!selectedLocation ? (
                <NoAddress navigation={navigation} closeModal={closeModal} />
            ) : isLoading ? (
                <View style={tw`h-30 mt-20`}>
                    <LoadingView />
                </View>
            ) : instances?.length === 0 ? (
                <>
                    <SwitchDeliveryType
                        navigation={navigation}
                        instances={instances}
                        closeModal={closeModal}
                    />

                    <NoBrandsLocations
                        navigation={navigation}
                        closeModal={closeModal}
                    />
                </>
            ) : (
                <>
                    <FlatList
                        keyboardShouldPersistTaps={'handled'}
                        style={tw`flex-1 mx-3`}
                        showsVerticalScrollIndicator={false}
                        testID="address-list-container"
                        data={instances}
                        keyExtractor={(item) =>
                            item.store.address.address_id || ''
                        }
                        renderItem={({ item: instance }) => (
                            <PickupLocationCard
                                instance={instance}
                                isSelected={
                                    instance.instance_id ===
                                    selectedInstance?.instance_id
                                }
                                setSelectedInstance={() =>
                                    setSelectedInstance(instance)
                                }
                                selectedInstance={selectedInstance}
                            />
                        )}
                        ItemSeparatorComponent={() => (
                            <View
                                style={tw`w-full border-t border-pc-shade-20`}
                            />
                        )}
                    />
                    {instances && (
                        <SwitchDeliveryType
                            navigation={navigation}
                            instances={instances}
                            closeModal={closeModal}
                        />
                    )}
                    <View style={tw`mb-4 mx-5`}>
                        <Button
                            variant="primary"
                            onPress={onDonePress}
                            disabled={!selectedInstance}
                            text="Done"
                            trackableName="Click done button on the pickup locations list"
                            trackableCategory="Address"
                        />
                    </View>
                </>
            )}
        </SafeAreaView>
    )
}
