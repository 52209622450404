import { ApplyVoucherToOrderResponse, Order } from '@/types/Order'

import { useAxiosPostMutation } from '@/lib/queryClient'

import { ApiErrors, getApiErrorMessage } from '@/utils/handleApiError'

export const useApplyCouponToOrderMutate = useAxiosPostMutation<
    Order,
    ApplyVoucherToOrderResponse
>('/order/coupon')

export const useApplyCouponToOrder = (
    setCouponErrorMessage: (message: string) => void
) => {
    const { mutateAsync: applyCouponMutate } = useApplyCouponToOrderMutate()

    return async (order: Order) => {
        try {
            return await applyCouponMutate(order)
        } catch (err) {
            const errorMessage = getApiErrorMessage(err, applyCouponApiErrors)
            setCouponErrorMessage(errorMessage)
            throw err
        }
    }
}

export const applyCouponApiErrors: ApiErrors = {
    // Handled with generic error message:
    // 400 Flipdish voucher failure
    // Could handle specifically but should never happen:
    // 400 Invalid order body
    // 400 Order already submitted
    // 400 Missing coupon
    // 400 Missing coupon code
    // 400 Missing flipdish_order_id
    genericMessage: 'Invalid voucher',
    specificErrors: [
        {
            statusCode: 400,
            backendMessage: 'Coupon not valid yet',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon not valid for delivery',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon not valid for pickup',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon minimum order amount not met',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Order in progress for this account',
            frontendMessage: 'Order in progress - try again in a bit',
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon is expired',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon is used up',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon only valid for your first order',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon already applied to this order',
            frontendMessage: 'Already applied to this order',
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon not valid for items on this order',
            frontendMessage: 'Not valid for this order',
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon not valid for this restaurant',
            frontendMessage: 'Not valid for this order',
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon disabled',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon not found',
            frontendMessage: null,
        },
        // Payment ones shouldn't happen but I will handle with a generic payment error so people at least know it's a payment problem
        {
            statusCode: 400,
            backendMessage: 'Payment method error',
            frontendMessage: 'Card validation failed. Try a different card',
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon not valid for cash orders',
            frontendMessage: 'Not valid for cash orders',
        },
        {
            statusCode: 400,
            backendMessage: 'Coupon not valid for card orders',
            frontendMessage: 'Not valid for card orders',
        },
    ],
}
