import { useCallback, useEffect, useMemo } from 'react'

import { Platform } from 'react-native'

import { Gyroscope, ThreeAxisMeasurement } from 'expo-sensors'
import { useSharedValue } from 'react-native-reanimated'

const DEFAULT_UPDATE_INTERVAL = 200 // ms
const MIN_ROTATION_RATE = 0.035 // rad/s - for reducing shaking at small rates
const MAX_ROTATION_RATE = 1.75 // rad/s - for limiting maximum movement speed
const DECAY_RATE = 1 // rad/s - speed at which head returns to frontal position

// Get a value constrained between min and max limits
const constrainedValue = (value: number, min: number, max: number) => {
    if (value < min) return min
    if (value > max) return max
    return value
}

export const useTrackingYGyroAnimation = (
    updateInterval: number = DEFAULT_UPDATE_INTERVAL
) => {
    const yRotation = useSharedValue<number>(0) // radians

    // Memoized constants
    const updateSeconds = useMemo(() => updateInterval / 1000, [updateInterval]) // update intervial in seconds
    const decayRadians = useMemo(
        () => DECAY_RATE * updateSeconds,
        [updateSeconds]
    ) // number of radians animation decays back to zero per update interval
    const rateAdjustment = useMemo(
        () => 1200 / updateInterval,
        [updateInterval]
    ) // adjustment to roughly account for effect of update interval on rate readings
    const rateLimiter = useCallback(
        (rate: number) => {
            const sign = rate < 0 ? -1 : 1
            if (Math.abs(rate) <= MIN_ROTATION_RATE) return 0
            if (Math.abs(rate) >= MAX_ROTATION_RATE)
                return MAX_ROTATION_RATE * sign * rateAdjustment
            return rate * rateAdjustment
        },
        [rateAdjustment]
    )

    useEffect(() => {
        // do nothing on web
        if (Platform.OS === 'web') return

        // set update interval
        Gyroscope.setUpdateInterval(updateInterval)

        // add listener on mount
        const subscription = Gyroscope.addListener(
            (data: ThreeAxisMeasurement) => {
                // if movement is occuring, add rate*time to current tracked position
                if (Math.abs(data.y) > MIN_ROTATION_RATE)
                    yRotation.value = constrainedValue(
                        yRotation.value + rateLimiter(data.y) * updateSeconds,
                        -Math.PI,
                        +Math.PI
                    )
                // otherwise decay back toward 0
                else
                    yRotation.value =
                        Math.abs(yRotation.value) < decayRadians
                            ? 0
                            : yRotation.value +
                              decayRadians * (yRotation.value < 0 ? 1 : -1)
            }
        )
        return () => {
            // remove listener on unmount
            subscription && subscription.remove()
        }
    }, [])

    return yRotation
}
