import React from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'

interface AccountModalProviderProps {
    children: React.ReactNode
}

const AccountModalContext = React.createContext<
    | {
          ref: React.RefObject<BottomSheetModal>
          showChildModal?: string
          setShowChildModal?: React.Dispatch<
              React.SetStateAction<string | undefined>
          >
      }
    | undefined
>(undefined)

function AccountModalProvider({ children }: AccountModalProviderProps) {
    const accountModalRef = React.useRef<BottomSheetModal>(null)
    const [showChildModal, setShowChildModal] = React.useState<
        string | undefined
    >(undefined)

    return (
        <AccountModalContext.Provider
            value={{ ref: accountModalRef, showChildModal, setShowChildModal }}
        >
            {children}
        </AccountModalContext.Provider>
    )
}

function useAccountModal() {
    const context = React.useContext(AccountModalContext)

    if (context === undefined) {
        throw new Error(
            'useAccountModal must be used within a AccountModalProvider'
        )
    }

    const show = () => {
        context.ref?.current?.present()
    }
    const hide = () => {
        context.ref?.current?.close()
    }

    return { show, hide, context }
}

export { AccountModalProvider, useAccountModal }
