import { Text, TouchableOpacity, View } from 'react-native'

import { useNavigateBack } from '@/navigation/hooks/useNavigateBack'
import { getCurrentRoute } from '@/navigation/navigateUsingRef'
import { RootScreenName, ScreenName } from '@/navigation/types/ScreenProps'

import tw from '@/tailwind/tailwind'

import ChevronLeftSvg from '@/svgs/ChevronLeftSvg'
import XSvg from '@/svgs/XSvg'

import { TrackableClick, TrackableProps } from '../Analytics'

export const BackButton = ({
    fallback,
    forceFallbackIfPreviousPageIn,
    isX,
    color = 'black',
    title,
    trackableProperties,
    onesignal,
    legacyTrackableName,
}: {
    color?: 'white' | 'black'
    fallback: RootScreenName | 'PhoneNumberCapture'
    forceFallbackIfPreviousPageIn?: ScreenName[]
    isX?: boolean
    title?: string
    trackableProperties?: TrackableProps['properties']
    onesignal?: TrackableProps['onesignal']
    legacyTrackableName?: string // for old names that are not in the new format used in this file
}) => {
    const currentRoute = getCurrentRoute()
    const navigateBack = useNavigateBack()

    return (
        <View style={tw`flex-row items-center`}>
            <TrackableClick
                onesignal={onesignal}
                properties={{ ...trackableProperties, isX, title }}
                name={
                    legacyTrackableName ??
                    `Tapped back on ${currentRoute?.name ?? 'anonymous screen'}`
                }
                category="General"
            >
                <TouchableOpacity
                    onPress={() =>
                        navigateBack(fallback, forceFallbackIfPreviousPageIn)
                    }
                    hitSlop={{ top: 4, right: 4, bottom: 4, left: 4 }}
                >
                    {isX ? (
                        <XSvg strokeWidth={2} />
                    ) : (
                        <ChevronLeftSvg color={color} />
                    )}
                </TouchableOpacity>
            </TrackableClick>
            {title && (
                <Text
                    style={[
                        tw`ml-2.5 font-ppp-b text-xl ${
                            isX ? 'mt-1' : 'mt-0.5'
                        }`,
                        { color },
                    ]}
                    numberOfLines={1}
                >
                    {title}
                </Text>
            )}
        </View>
    )
}
