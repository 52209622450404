import { useQueryClient } from 'react-query'

import { Order, OrderDetailResponse, SubmitOrderResponse } from '@/types/Order'

import { useOrderDetailsQueryKey } from '@/hooks/query/useOrder'
import { profileQueryKey } from '@/hooks/query/useProfile'

import { sendOneSignalOutcome } from '@/lib/onesignal'
import { useAxiosPostMutation } from '@/lib/queryClient'

import { ApiErrors, getApiErrorMessage } from '@/utils/handleApiError'
import { useCheckReloadInstance } from '@/utils/instance/checkReloadInstance'
import { showErrorMessage } from '@/utils/showMessage'

export const useSubmitOrderMutate = useAxiosPostMutation<
    Order,
    SubmitOrderResponse
>('/order/submit')

export const useSubmitOrder = () => {
    const { mutateAsync: submitOrderMutate } = useSubmitOrderMutate()
    const checkReloadInstance = useCheckReloadInstance()
    const queryClient = useQueryClient()

    return async (order: Order): Promise<SubmitOrderResponse> => {
        try {
            const submittedOrder = await submitOrderMutate(order)

            sendOneSignalOutcome('Submitted order', order.ticket_amount)

            // Proactively set order detail query data to avoid bugs in order processing modal
            queryClient.setQueryData<OrderDetailResponse>(
                useOrderDetailsQueryKey(submittedOrder.popchew_order_id),
                submittedOrder
            )

            queryClient.invalidateQueries(profileQueryKey)

            return submittedOrder
        } catch (err) {
            checkReloadInstance(err, order)
            const errorMessage = getApiErrorMessage(err, submitOrderApiErrors)
            showErrorMessage(errorMessage)
            throw err
        }
    }
}

const submitOrderApiErrors: ApiErrors = {
    // Handled with generic error message:
    // 400 Flipdish failure - submit order
    // Could handle specifically but should never happen:
    // 400 Invalid order body
    // 400 Missing field: flipdish_order_id
    // 400 Missing field: payment_method_id
    genericMessage: 'Unable to submit order',
    specificErrors: [
        {
            statusCode: 400,
            backendMessage:
                'Sorry, the store is not accepting orders right now',
            frontendMessage: 'Store is no longer open',
        },
        {
            statusCode: 400,
            backendMessage: 'Payment account blocked',
            frontendMessage: 'Card not accepted. Try a different card',
        },
        {
            statusCode: 400,
            backendMessage: 'Order in progress for this account', // - try again after a couple minutes',
            frontendMessage: 'Order in progress - try again in a bit',
        },
        {
            statusCode: 400,
            backendMessage: 'Missing name on payment method',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Menu outdated',
            frontendMessage: 'Menu has changed. Try again',
        },
        {
            statusCode: 400,
            backendMessage: 'Restaurant outdated',
            frontendMessage: 'Restaurant has changed. Try again',
        },
        {
            statusCode: 400,
            backendMessage: 'Card declined',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Card has insufficient funds',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Card not supported',
            frontendMessage: null,
        },
    ],
}
