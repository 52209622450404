import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const MiniTriangleSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={8}
        height={6}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M3.614.468a.5.5 0 0 1 .772 0l3.27 3.964a.5.5 0 0 1-.386.818H.73a.5.5 0 0 1-.385-.818L3.615.468Z"
            fill={color}
        />
    </Svg>
)

export default MiniTriangleSvg
