import { useQueryClient } from 'react-query'

import { useMenuQueryKey } from '@/features/BrandPage/screens/Menu/api/useMenu'
import { useInstanceQueryKey } from '@/features/Cart/api/useInstance'

import { Order } from '@/types/Order'

import { parseAxiosError } from '@/lib/httpClient'

export const useCheckReloadInstance = () => {
    const queryClient = useQueryClient()

    const checkReloadInstance = (axiosError: any, order: Order) => {
        const { message } = parseAxiosError(axiosError)
        const msg = message?.toLowerCase() || ''

        if (msg.includes('outdated')) {
            queryClient.invalidateQueries(
                useInstanceQueryKey(order.cart.instance_id)
            )
            queryClient.invalidateQueries(useMenuQueryKey(order.cart.menu_id))
        }
    }

    return checkReloadInstance
}
