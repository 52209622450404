import { useMemo } from 'react'

import { ListRenderItemInfo, Text, View } from 'react-native'

import { useQuery } from 'react-query'

import tw from '@/tailwind/tailwind'

import { HandleContentChangeType, HandleScrollEventType } from '../..'
import { TopFan, getTopFans } from '../../api/getTopFans'
import { SectionIsLoading } from '../../components/SectionIsLoading'
import { AnimatedFlatList } from '../../components/wrappers/AnimatedFlatList'
import { BrandPageSectionWrapper } from '../../components/wrappers/BrandPageSectionWrapper'
import { FullPageView } from '../../components/wrappers/FullPageView'

const NoFans = ({ tabBarHeight }: { tabBarHeight: number }) => {
    return (
        <FullPageView tabBarHeight={tabBarHeight}>
            <View style={tw`flex-1 justify-center items-center`}>
                <Text style={tw`font-ppa-b text-white`}>No fans yet.</Text>
            </View>
        </FullPageView>
    )
}

const FanCard = ({ item: fan, index }: ListRenderItemInfo<TopFan>) => {
    // purposefully does not use fan.rank as to ignore ties
    const { city, state, display_name } = fan
    return (
        <View style={tw`flex-row items-center mb-2`}>
            <View style={tw`w-16 h-16 flex justify-center items-center`}>
                <Text style={tw`text-white font-ppa-wbi text-xl`}>
                    {index + 1}
                </Text>
            </View>
            <View style={tw`px-6 py-2 bg-white/10 flex-1 rounded-lg`}>
                <Text style={tw`font-ppa-b text-white text-lg`}>
                    {display_name}
                </Text>
                <Text style={tw`font-ppa-b text-white`}>
                    {city}, {state}
                </Text>
            </View>
        </View>
    )
}

export const Fans = ({
    brandId,
    pageHeight,
    viewWidth,
    top,
    handleScroll,
    handleContentSizeChange,
    tabBarHeight,
}: {
    brandId: string
    pageHeight: number
    viewWidth: number
    top: number
    handleScroll: HandleScrollEventType
    handleContentSizeChange: HandleContentChangeType
    tabBarHeight: number
}) => {
    const {
        data: TopFansResponse,
        isFetching: isFetchingFans,
        isLoading: isLoadingFans,
    } = useQuery(['top-fans'], getTopFans)

    const topFans = useMemo(
        () =>
            TopFansResponse?.brands.find((brand) => brand.brand_id === brandId)
                ?.top_fans || [],
        [brandId, isFetchingFans]
    )

    let content = (
        <SectionIsLoading
            color={'white'}
            sectionName="fans"
            tabBarHeight={tabBarHeight}
        />
    )

    if (!isLoadingFans) {
        content =
            topFans.length > 0 ? (
                <AnimatedFlatList
                    flatlistProps={{
                        data: topFans,
                        renderItem: FanCard,
                        keyExtractor: (_, idx) => idx.toString(),
                    }}
                    pageHeight={pageHeight}
                    viewWidth={viewWidth}
                    additionalTopSpacing={8}
                    top={top}
                    handleScroll={handleScroll}
                    handleContentSizeChange={handleContentSizeChange}
                    tabBarHeight={tabBarHeight}
                />
            ) : (
                <NoFans tabBarHeight={tabBarHeight} />
            )
    }

    return (
        <BrandPageSectionWrapper pageHeight={pageHeight} viewWidth={viewWidth}>
            {content}
        </BrandPageSectionWrapper>
    )
}
