import { Image, Text, View } from 'react-native'

import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@/components/Button'

import { openLink } from '@/utils/linking'

import tw from '@/tailwind/tailwind'

export const MaintenanceMode = () => {
    return (
        <SafeAreaProvider>
            <View style={tw`flex-1`}>
                <SafeAreaView
                    style={tw`flex-1 flex-col max-w-[400px] px-container self-center`}
                >
                    <View style={tw`flex-col items-center mt-[30%]`}>
                        <View style={tw`w-20 h-20`}>
                            <Image
                                style={tw`h-full w-full`}
                                source={require('@/assets/pensive_face_emoji.png')}
                                resizeMode="cover"
                            />
                        </View>
                        <Text
                            style={tw`font-ppa-wbi text-2xl mt-4 text-center `}
                        >
                            Sorry!
                        </Text>
                        <Text
                            style={tw`text-pc-shade-80 text-base text-center mt-2`}
                        >
                            Our ordering system is down for scheduled
                            maintenance today.
                        </Text>
                    </View>
                    <View style={tw`mt-auto pb-8`}>
                        <Text style={tw`font-ppa-b text-center text-xl`}>
                            Craving Popchew?
                        </Text>
                        <Text
                            style={tw`text-center text-pc-shade-80 text-base mb-8 mt-2`}
                        >
                            You can still order from one of our partners, or on
                            your favorite delivery app.
                        </Text>

                        <View style={tw`mt-3`}>
                            <Button
                                variant="primary"
                                text="Order WingSZN"
                                onPress={() =>
                                    openLink(
                                        'https://wingszn.order-now.menu/order'
                                    )
                                }
                                trackableCategory="Maintenance"
                                trackableName="Clicked Order WingSZN"
                            />
                        </View>

                        <View style={tw`mt-3`}>
                            <Button
                                variant="primary"
                                text="Order SnapBack"
                                onPress={() =>
                                    openLink(
                                        'https://snapback-kitchen.order-now.menu/order'
                                    )
                                }
                                trackableCategory="Maintenance"
                                trackableName="Clicked Order SnapBack"
                            />
                        </View>

                        <View style={tw`mt-3`}>
                            <Button
                                variant="primary"
                                text="Order Topmac"
                                onPress={() =>
                                    openLink(
                                        'https://topmac.order-now.menu/order'
                                    )
                                }
                                trackableCategory="Maintenance"
                                trackableName="Clicked Order Topmac"
                            />
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        </SafeAreaProvider>
    )
}
