import React from 'react'

import { BottomSheetModal as BSM } from '@gorhom/bottom-sheet'
import { WebView } from 'react-native-webview'

import { BottomSheetModal } from '@/components/BottomSheetModal'

import { PRIVACY_POLICY_URL } from '@/utils/constants'

import ModalHeader from '../../components/ModalHeader'

export const PrivacyPolicy = React.forwardRef<BSM>((_, ref) => {
    const onXPress = () => {
        ;(ref as React.MutableRefObject<BSM>)?.current?.dismiss()
    }

    return (
        <BottomSheetModal
            trackableName="privacy policy"
            trackableCategory="Account"
            ref={ref}
            snapPoints={['90%']}
            handleComponent={null}
        >
            <ModalHeader
                trackableCloseButtonProps={{
                    name: 'Tapped X in edit account screen',
                    category: 'Account',
                    testID: 'close-edit-account',
                }}
                closeButtonProps={{
                    onPress: onXPress,
                }}
                title="Privacy Policy"
            />
            <WebView
                source={{
                    uri: PRIVACY_POLICY_URL,
                }}
            />
        </BottomSheetModal>
    )
})
