import { ActivityIndicator } from 'react-native'

import Animated, {
    Extrapolate,
    interpolate,
    useAnimatedStyle,
} from 'react-native-reanimated'

import { Brand } from '@/types/Brand'

import { DORITOS_BRAND_ID } from '@/utils/constants'

import tw from '@/tailwind/tailwind'

import {
    AMOUNT_TO_SCROLL_FOR_MIN_HEADER,
    INITIAL_HEADER_HEIGHT,
    MIN_HEADER_HEIGHT,
} from '../../utils/constants'
import { FoodHead } from './FoodHead'
import { HeaderLogo } from './HeaderLogo'
import { LocationSelector } from './LocationSelector'
import { XButton } from './XButton'

export const BrandHeader = ({
    scroll,
    top,

    showPickupDeliveryModal,
    brand,
    brandIsLoading,
}: {
    scroll: Animated.SharedValue<number>
    top: number

    showPickupDeliveryModal: () => void
    brand?: Brand
    brandIsLoading: boolean
}) => {
    const onlyShowLogo = brand?.id === DORITOS_BRAND_ID

    const animatedStyle = useAnimatedStyle(() => ({
        height: interpolate(
            scroll.value,
            [0, AMOUNT_TO_SCROLL_FOR_MIN_HEADER],

            [INITIAL_HEADER_HEIGHT + top, MIN_HEADER_HEIGHT + top],
            {
                extrapolateRight: Extrapolate.CLAMP,
                extrapolateLeft: Extrapolate.CLAMP,
            }
        ),
    }))
    return (
        <Animated.View
            style={[
                tw`bg-pc-shade-20 flex justify-center items-center relative overflow-hidden`,
                animatedStyle,
            ]}
        >
            <LocationSelector
                brand={brand}
                showPickupDeliveryModal={showPickupDeliveryModal}
            />
            <XButton scroll={scroll} top={top} />

            {brandIsLoading ? (
                <ActivityIndicator />
            ) : (
                <>
                    {!onlyShowLogo && (
                        <FoodHead
                            scroll={scroll}
                            source={{
                                uri:
                                    brand?.main_image_url ||
                                    'https://popchew-web.b-cdn.net/default-main.png',
                            }}
                        />
                    )}
                    {brand && (
                        <HeaderLogo
                            onlyShowLogo={onlyShowLogo}
                            scroll={scroll}
                            brand={brand}
                            amountToScrollForMinHeader={
                                AMOUNT_TO_SCROLL_FOR_MIN_HEADER
                            }
                        />
                    )}

                    <Animated.Image
                        style={tw`h-full w-full absolute z-0`}
                        source={{
                            uri:
                                brand?.background_image_url ||
                                'https://popchew-web.b-cdn.net/default-bg.png',
                        }}
                        resizeMode="cover"
                        // resizeMode="stretch" Makes image shrink on scroll
                    />
                </>
            )}
        </Animated.View>
    )
}
