import Ax from 'axios'
import { useQuery } from 'react-query'

import { Instance } from '@/types/Instance'

import { axios } from '@/lib/httpClient'

import { showMessage } from '@/utils/showMessage'

import { useClearCart } from '../hooks/cartMutations'

const useInstanceQueryFn = async (instanceId: string | undefined | null) => {
    return typeof instanceId === 'undefined' || instanceId === null
        ? Promise.reject(new Error('Invalid instance Id'))
        : (
              await axios.get<Instance>('/order/instance', {
                  params: { instance_id: instanceId },
              })
          ).data
}

export const useInstanceQueryKey = (instanceId: string | undefined | null) => [
    'instance',
    instanceId,
]

export const useInstance = (instanceId: string | null | undefined) => {
    const { mutateAsync: clearCart } = useClearCart()

    return useQuery(
        useInstanceQueryKey(instanceId),
        () => {
            return useInstanceQueryFn(instanceId)
        },
        {
            staleTime: 60 * 1000, // 1 minute
            enabled: Boolean(instanceId),
            onError(err) {
                if (Ax.isAxiosError(err)) {
                    if (err.response?.status === 404) {
                        clearCart()
                        showMessage({
                            type: 'warning',
                            message:
                                'The store you are ordering from is no longer available. Your cart has been emptied.',
                            duration: 5000,
                        })
                    }
                }
            },
        }
    )
}
