import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const ShoppingTagSvg = (props: SvgProps) => (
    <Svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="m.1 8.61 7.595-7.593 5.627-.34-.339 5.628L5.39 13.9.101 8.61Z"
            fill="#9D9FB0"
        />
    </Svg>
)

export default ShoppingTagSvg
