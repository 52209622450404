import { Text, TouchableOpacity, View } from 'react-native'

import { CartNotificationProps } from '@/navigation/types/ScreenProps'
import { useNavigation } from '@react-navigation/native'

import { useCountCartItems } from '@/features/Cart/hooks/useCountCartItems'

import tw from '@/tailwind/tailwind'

import CartSvg from '@/svgs/CartSvg'

import { TrackableClick } from '../Analytics'

export const CartNotification = ({ isDarkMode = false }) => {
    const navigation = useNavigation<CartNotificationProps['navigation']>()

    const onNotificationPress = () => {
        navigation.navigate('Cart')
    }

    const totalCount = useCountCartItems()
    const isCartEmpty = totalCount === 0

    return (
        <TrackableClick
            name="Tapped shopping cart"
            category="Checkout"
            onesignal
        >
            <TouchableOpacity onPress={onNotificationPress}>
                <View
                    style={tw`flex-row justify-center items-center px-3 py-1 rounded-full border ${
                        !isCartEmpty
                            ? 'border-pc-success '
                            : isDarkMode
                            ? 'border-white'
                            : 'border-black'
                    }  ${!isCartEmpty ? 'bg-pc-success' : 'bg-black'}`}
                >
                    <Text
                        style={tw`font-ppa-b text-sm text-white
                         text-center mr-1 w-5`}
                    >
                        {totalCount}
                    </Text>
                    <CartSvg color={'white'} />
                </View>
            </TouchableOpacity>
        </TrackableClick>
    )
}
