import React, { useState } from 'react'

import { ActivityIndicator, Text, View } from 'react-native'

import {
    BottomSheetModal as BSM,
    BottomSheetFlatList,
} from '@gorhom/bottom-sheet'
import * as Device from 'expo-device'

import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'

import * as ExpoPushNotifications from '@/lib/expoNotifications'

import tw from '@/tailwind/tailwind'

import ModalHeader from '../../components/ModalHeader'
import { NotificationCategoryCard } from './NotificationCategoryCard'
import {
    useNotificationPreferences,
    useUpdateNotificationPreferences,
} from './useNotificationPreferences'

export interface NotificationSettingCardProps {
    name: string
    category: string
    title: string
    subtitle: string
    value: boolean
}

export interface NotificationCategoryProps {
    name: string
    title: string
    notifications: NotificationSettingCardProps[]
    value: boolean
}

export const NotificationPreferences = React.forwardRef<BSM>((_, ref) => {
    const [loadingToken, setLoadingToken] = useState<boolean>(false)
    const [token, setToken] = useState<string | null>(null)
    const [modalShown, setModalShown] = useState<boolean>(false)

    const { data, isError, isLoading, refetch } = useNotificationPreferences()
    const preferences = data || []
    const { mutateAsync } = useUpdateNotificationPreferences()

    const onXPress = () => {
        ;(ref as React.MutableRefObject<BSM>)?.current?.dismiss()
    }

    const getToken = async () => {
        setLoadingToken(true)
        setToken(await ExpoPushNotifications.getAndSetToken(true))
        if (token) await refetch()
        setLoadingToken(false)
    }

    // in component
    // useForegroundEvent(getToken)
    // Disabling this - seems to cause the allow setting popup
    // to trigger a lot on app launch
    // useForegroundEvent(getToken)

    // Component
    return (
        <BottomSheetModal
            trackableName={'notification settings'}
            trackableCategory="Account"
            ref={ref}
            snapPoints={['90%']}
            handleComponent={null}
            onShow={async () => {
                setModalShown(true)
                getToken()
            }}
            onHide={() => setModalShown(false)}
        >
            <ModalHeader
                trackableCloseButtonProps={{
                    name: 'Tapped X in notification settings',
                    category: 'Notifications',
                }}
                closeButtonProps={{
                    onPress: onXPress,
                }}
                title="Notifications"
            />
            {isLoading || loadingToken || (!token && !modalShown) ? ( //
                <View>
                    <View style={tw`m-4 w-full items-center justify-center`}>
                        <ActivityIndicator />
                    </View>
                </View>
            ) : isError ? (
                <View>
                    <View style={tw`m-4`}>
                        <Text style={tw`mx-4 font-ppa-b text-lg text-black`}>
                            Error retreiving settings
                        </Text>
                    </View>
                    <View style={tw`mt-6 mx-8`}>
                        <Button
                            variant="primary"
                            onPress={refetch}
                            text="Try again"
                            trackableName="Tapped Try Again in notification settings"
                            trackableCategory="Notifications"
                            onesignal
                        />
                    </View>
                </View>
            ) : !token && Device.isDevice ? (
                <View>
                    <View style={tw`m-4`}>
                        <Text style={tw`ml-4 font-ppa-b text-lg text-black`}>
                            Notification permissions not allowed
                        </Text>
                    </View>

                    <View style={tw`mt-6 mx-8`}>
                        <Button
                            variant="primary"
                            onPress={getToken}
                            text="Allow notifications"
                            trackableName="Tapped Allow Notifications in notifications settings"
                            trackableCategory="Notifications"
                            onesignal
                        />
                    </View>
                </View>
            ) : (
                <BottomSheetFlatList
                    data={preferences}
                    keyExtractor={(category: NotificationCategoryProps) =>
                        category.name
                    }
                    renderItem={(item) => (
                        <NotificationCategoryCard
                            item={item.item}
                            updatePreferences={mutateAsync}
                        />
                    )}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingVertical: 0,
                        marginVertical: 0,
                    }}
                />
            )}
        </BottomSheetModal>
    )
})
