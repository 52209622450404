import { Image, Platform, Text, View } from 'react-native'

import { AccountSettingsProps } from '@/navigation/types/ScreenProps'
import { useNavigation } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'

import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'

import { useAccountModal } from '@/contexts/AccountModalContext'

import tw from '@/tailwind/tailwind'

import ModalHeader from '../../components/ModalHeader'

export const NotAuthed = () => {
    const { hide, context: accountSettingsContext } = useAccountModal()
    const navigation = useNavigation<AccountSettingsProps['navigation']>()

    const navigateToLogin = () => {
        hide()
        navigation.navigate('Login')
    }

    return (
        <BottomSheetModal
            trackableName="no user account"
            trackableCategory="Account"
            ref={accountSettingsContext.ref}
            snapPoints={['65%']}
        >
            <SafeAreaView
                style={tw`flex-1`}
                edges={['bottom', 'left', 'right']}
            >
                <View
                    style={tw`flex-1  px-container ${
                        Platform.OS === 'web' ? 'pb-8' : ''
                    } `}
                >
                    <ModalHeader
                        trackableCloseButtonProps={{
                            name: 'Tapped X in not authed account screen',
                            category: 'Account',
                        }}
                        closeButtonProps={{
                            onPress: () => hide(),
                        }}
                    />
                    <View style={tw`flex flex-col items-center`}>
                        <View style={tw`w-12 h-8 mt-2`}>
                            <Image
                                style={tw`h-full w-full`}
                                source={require('@/assets/mouth.png')}
                                resizeMode="contain"
                            />
                        </View>
                        <Text style={tw`font-ppa-wbi text-lg mt-4`}>
                            the funnest way to eat
                        </Text>
                    </View>
                    <View style={tw`mt-auto`}>
                        <Button
                            variant="primary"
                            text="Sign Up"
                            full
                            onPress={() => navigateToLogin()}
                            trackableName="Tapped Sign Up on Non-Authed modal"
                            trackableCategory="Account"
                        />
                        <View style={tw`mt-2`}>
                            <Button
                                variant="secondary"
                                text="Log In"
                                full
                                onPress={() => navigateToLogin()}
                                trackableName="Tapped Log In on Non-Authed modal"
                                trackableCategory="Account"
                            />
                        </View>
                    </View>
                </View>
            </SafeAreaView>
        </BottomSheetModal>
    )
}
