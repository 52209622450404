import { useQuery } from 'react-query'

import { useMenu } from '@/features/BrandPage/screens/Menu/api/useMenu'

import { MenuResponse } from '@/types/Menu'

type useMenuItemProps = {
    instanceId: string
    itemId: string
}

const MENU_ITEM_QUERY_KEY = (props: useMenuItemProps) => [
    'menu-item',
    props.instanceId,
    props.itemId,
]

const getMenuItem = (menu: MenuResponse | undefined, itemId: string) => {
    if (!menu) throw new Error('Missing menu')

    for (const section of menu.sections) {
        const item = section.items.find((item) => item.item_id == itemId)

        if (item) return item
    }
}

export const useMenuItem = (props: useMenuItemProps) => {
    const { instanceId, itemId } = props
    const { data: menu } = useMenu(instanceId)

    return useQuery(
        MENU_ITEM_QUERY_KEY(props),
        () => getMenuItem(menu, itemId),
        {
            enabled: Boolean(menu),
        }
    )
}
