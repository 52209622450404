import { useEffect, useState } from 'react'

import { ScrollView, View } from 'react-native'

import { MenuItemScreenProps } from '@/navigation/types/ScreenProps'
import { SafeAreaView } from 'react-native-safe-area-context'

import { ModifierGroup as ModifierGroupType } from '@/types/Menu'

import { TrackableRender } from '@/components/Analytics'

import tw from '@/tailwind/tailwind'

import { useMenu } from '../BrandPage/screens/Menu/api/useMenu'
import { useCart } from '../Cart/api/useCart'
import { useInstance } from '../Cart/api/useInstance'
import { useMenuItem } from './api/useMenuItem'
import { MenuItemBottom } from './components/MenuItemBottom'
import { MenuItemCoverPhoto } from './components/MenuItemCoverPhoto'
import { MenuItemInformation } from './components/MenuItemInformation'
import { MenuItemLoading } from './components/MenuItemLoading'
import { ModifierGroup } from './components/ModifierGroup'
import { Navbar } from './components/Navbar'
import { NoMenuItem } from './components/NoMenuItem'
import { QuantityControls } from './components/QuantityControls'

export const MenuItemScreen = ({ route }: MenuItemScreenProps) => {
    const { instanceId, itemId, isUpdate, cartItemId } = route.params || {}

    const { data: cart, isLoading: isCartLoading } = useCart()
    const { data: menu, isLoading: isMenuLoading } = useMenu(instanceId)
    const { data: item, isLoading: isItemLoading } = useMenuItem({
        instanceId,
        itemId,
    })
    const { data: instance, isLoading: isInstanceLoading } =
        useInstance(instanceId)

    const [modifierGroups, setModifierGroups] = useState<ModifierGroupType[]>(
        []
    )

    const [quantity, setQuantity] = useState(1)

    // if not cart update
    useEffect(() => {
        if (isUpdate) return
        if (!item) return

        const { modifier_groups = [] } = item
        modifier_groups.sort((modifierA, modifierB) =>
            (modifierA.min_selected || 0) > 0 &&
            (modifierB.min_selected || 0) < 1
                ? -1
                : 1
        )

        setModifierGroups(modifier_groups)
    }, [item])

    // if is cart update
    useEffect(() => {
        if (cart && isUpdate) {
            const cartItem = cart.items.find(
                (item) => item.cart_item_id === cartItemId
            )
            if (!cartItem) return
            const cartItemModiferGroups = cartItem.item.modifier_groups
            if (!cartItemModiferGroups) return
            setModifierGroups(cartItemModiferGroups)
            setQuantity(cartItem.count)
        }
    }, [cart])

    if (isItemLoading || isInstanceLoading || isMenuLoading || isCartLoading)
        return <MenuItemLoading />
    if (!item || !instance || !menu || !cart) return <NoMenuItem />

    // Extract Attributes
    // const { modifier_groups: modifier_groups = [] } = item
    const { instance_name: instanceName } = instance
    const { menu_id: menuId } = menu

    // Function passed to each modifier that handles toggling and updates modifiers accordingly
    const toggleModifier = (modifierGroupIdx: number, modifierIdx: number) => {
        // Make a deep copy of modifier groups and get current modifier group
        const newModifiers = modifierGroups.map((group) => ({
            ...group,
            modifiers: group.modifiers.map((mod) => ({
                ...mod,
            })),
        }))
        const modifierGroup = newModifiers[modifierGroupIdx]

        // Check if toggled modifier is active or not
        const isActive = modifierGroup.modifiers[modifierIdx].is_selected

        /**
         * Required one or the others have to be handled slightly different
         * we deselect all other modifiers in the specific modifier group
         * when a new one is selected
         * we also don't de-select on click
         */
        const isXOR =
            modifierGroup.min_selected === 1 && modifierGroup.max_selected === 1
        if (isXOR) {
            if (isActive) return

            const currentlySelectedIdx = modifierGroup.modifiers.findIndex(
                (modifier) => modifier.is_selected
            )

            if (currentlySelectedIdx >= 0) {
                newModifiers[modifierGroupIdx].modifiers[
                    currentlySelectedIdx
                ].is_selected = false
            }
        }

        newModifiers[modifierGroupIdx].modifiers[modifierIdx].is_selected =
            !isActive

        setModifierGroups(newModifiers)
    }

    return (
        <TrackableRender
            name="Rendered menu item"
            category="Order"
            properties={{
                'item name': item.name,
                'item id': item.item_id,
                'item price': item.price,
            }}
        >
            <SafeAreaView edges={['right', 'left', 'top']} style={tw`flex-1`}>
                <Navbar brandName={instanceName} />
                <ScrollView
                    contentContainerStyle={tw`flex-grow`}
                    showsVerticalScrollIndicator={false}
                >
                    <MenuItemCoverPhoto item={item} />
                    <View
                        style={tw`  flex-1 flex-col justify-between`}
                        testID="menu-item-form"
                    >
                        <View>
                            <MenuItemInformation item={item} />
                            {modifierGroups?.map((modifierGroup, idx) => {
                                const numOfSelected =
                                    modifierGroup.modifiers.filter(
                                        (modifier) => modifier.is_selected
                                    ).length

                                return (
                                    <ModifierGroup
                                        brandId={instance.brand_id}
                                        key={idx}
                                        modifier_group_idx={idx}
                                        {...modifierGroup}
                                        modifiersState={modifierGroups}
                                        toggleModifier={toggleModifier}
                                        maxItemsReached={
                                            !!(
                                                modifierGroup.max_selected &&
                                                numOfSelected >=
                                                    modifierGroup.max_selected
                                            )
                                        }
                                    />
                                )
                            })}
                        </View>
                        <QuantityControls
                            testID="menu-item-quantity-control"
                            quantity={quantity}
                            setQuantity={setQuantity}
                        />
                    </View>
                </ScrollView>
                <MenuItemBottom
                    quantity={quantity}
                    item={item}
                    modifierGroups={modifierGroups}
                    instance={instance}
                    menuId={menuId}
                    isUpdate={isUpdate}
                    cart={cart}
                    cartItemId={cartItemId}
                />
            </SafeAreaView>
        </TrackableRender>
    )
}
