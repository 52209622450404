import { DeliveryType } from '@/types/Address'
import { CartResponse } from '@/types/Cart'
import { Instance } from '@/types/Instance'

import { toPrice } from '@/utils/normalizers'
import { showMessage } from '@/utils/showMessage'
import { totalCartPrice } from '@/utils/totalCartPrice'

export const isAboveMinimumDeliveryValue = (
    cart: CartResponse | undefined,
    instance: Instance | undefined
) => {
    if (!cart || !instance) return false
    const { minimum_order: minDeliveryPrice } = instance

    const minimumOrder =
        cart?.fulfillment_type === DeliveryType.delivery ? minDeliveryPrice : 0

    if (!(totalCartPrice(cart.items) >= minimumOrder)) {
        showMessage({
            message: `${cart?.instance_name || 'Brand'} has a ${toPrice(
                minDeliveryPrice
            )} minimum for delivery orders`,
            type: 'none',
        })
        return false
    }
    return true
}
