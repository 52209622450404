import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const DollarSvg = (props: SvgProps) => (
    <Svg
        width={8}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M7.434 9.34c0-3.304-4.998-2.576-4.998-4.802 0-.77.658-1.232 1.47-1.232.826 0 1.386.49 1.652 1.204.168.434.462.602.756.602.406 0 .812-.35.812-.84a.883.883 0 0 0-.07-.364C6.65 2.9 5.796 2.186 4.634 1.99v-.616a.696.696 0 0 0-.686-.686.696.696 0 0 0-.686.686v.588C1.778 2.186.756 3.194.756 4.594c0 3.248 4.956 2.786 4.956 4.844 0 .77-.63 1.316-1.694 1.316-.882 0-1.61-.434-1.904-1.414-.112-.392-.406-.574-.714-.574a.856.856 0 0 0-.854.854c0 .14.028.266.084.42.462 1.148 1.414 1.848 2.632 2.044v.602c0 .364.308.686.686.686a.696.696 0 0 0 .686-.686v-.588c1.638-.196 2.8-1.204 2.8-2.758Z"
            fill="#fff"
        />
    </Svg>
)

export default DollarSvg
