import { useRef } from 'react'

import { Image, Text, View } from 'react-native'

import { BottomSheetModal as BottomSheetModalType } from '@gorhom/bottom-sheet'
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'
import { SafeAreaView } from 'react-native-safe-area-context'

import { useUserPoints } from '@/features/Auth/api/useUserPoints'
import { useBuyItemWithPoints } from '@/features/BrandPage/api/useBuyItemWithPoints'

import { Prize } from '@/types/Prize'

import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'
import { ModalX } from '@/components/Titles/ModalX'

import { showMessage } from '@/utils/showMessage'

import tw from '@/tailwind/tailwind'

import { PrizeRedeemedModal } from './PrizeRedeemedModal'

export const PrizeModal = ({
    prizeModalRef,
    selectedPrize,
}: {
    prizeModalRef: React.RefObject<BottomSheetModalMethods>
    selectedPrize: Prize | undefined
}) => {
    const {
        points_cost = 0,
        image_url,
        name,
        copy_text,
        id: prizeId = '',
    } = selectedPrize || {}
    const closeModal = () => prizeModalRef.current?.close()
    const { data: userPointsRes } = useUserPoints()
    const userPoints = userPointsRes?.points || 0
    const canAfford = userPoints >= points_cost
    const { mutate: buyItem } = useBuyItemWithPoints()
    const prizeRedeemedModal = useRef<BottomSheetModalType>(null)
    const openPrizeReedemedModal = () => prizeRedeemedModal.current?.present()

    const attemptBuy = (prizeId: string) => {
        try {
            buyItem(prizeId)
            closeModal()
            openPrizeReedemedModal()
        } catch (e) {
            showMessage({
                message: 'Could not unlock at this time',
                type: 'warning',
            })
        }
    }

    return (
        <>
            <SafeAreaView edges={['bottom']} style={tw`flex-1 container `}>
                <View style={tw`flex-row justify-between `}>
                    <View
                        style={tw`flex justify-center items-center px-4 h-10 rounded-full bg-pc-shade-20`}
                    >
                        <Text style={tw`font-ppa-wbi`}>
                            {points_cost === 0
                                ? 'free'
                                : `${points_cost.toLocaleString()} ${
                                      points_cost === 1 ? 'pt' : 'pts'
                                  }`}
                        </Text>
                    </View>

                    <ModalX
                        onPress={() => closeModal()}
                        category="Rewards"
                        name="Tapped X on prize modal"
                        properties={{ prizeName: name, prizeId: prizeId }}
                    />
                </View>
                <View style={tw`flex-1 py-8`}>
                    <View style={tw`flex-1`}>
                        <Image
                            style={tw`h-full w-full`}
                            source={{ uri: image_url }}
                            resizeMode="contain"
                        />
                    </View>
                </View>

                <Text style={tw`font-ppa-wbi text-xl`}>{name}</Text>
                <Text style={tw`font-ppa-b text-pc-shade-100 text-base mt-2`}>
                    {copy_text}
                </Text>
                <View style={tw`mt-4`}>
                    {canAfford ? (
                        <Button
                            variant="primary"
                            text="Claim"
                            onPress={() => attemptBuy(prizeId)}
                            trackableCategory="Rewards"
                            trackableName="Tapped Claim on Prizes screen"
                        />
                    ) : (
                        <Button
                            variant="link"
                            text="Not enough points"
                            disabled
                            trackableCategory="Rewards"
                            trackableName=""
                        />
                    )}
                </View>
            </SafeAreaView>
            <BottomSheetModal
                snapPoints={['75%']}
                keyboardBehavior="interactive"
                ref={prizeRedeemedModal}
            >
                <PrizeRedeemedModal prizeRedeemedModal={prizeRedeemedModal} />
            </BottomSheetModal>
        </>
    )
}
