import { Image, PixelRatio, View } from 'react-native'

import { Item } from '@/types/Menu'

import { useGetUsableScreenWidth } from '@/hooks/useGetUsableScreenWidth'

import tw from '@/tailwind/tailwind'

export const MenuItemCoverPhoto = ({ item }: { item: Item }) => {
    const { image_url } = item
    const { width } = useGetUsableScreenWidth()

    return (
        <View style={tw`w-full h-64`}>
            {image_url ? (
                <Image
                    style={tw`w-full h-full`}
                    resizeMode="cover"
                    source={{
                        uri: `${image_url}?width=${PixelRatio.getPixelSizeForLayoutSize(
                            width
                        )}`,
                    }}
                />
            ) : (
                <Image
                    style={tw`w-full h-full`}
                    resizeMode="cover"
                    source={require('@/assets/images/menu-item-placeholder.png')}
                />
            )}
        </View>
    )
}
