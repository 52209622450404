import { FC, useEffect, useMemo, useState } from 'react'

import { Animated, Easing, Platform, Text, View } from 'react-native'

import tw from '@/tailwind/tailwind'

export interface LoadingViewProps {
    message?: string
    resizeInTime?: number
    resizeOutTime?: number
    scaleTarget?: number
    scrollTime?: number
    iconSize?: number
    visible?: boolean
}

export interface IconInfo {
    image: string
    tag: number
}

const useNativeDriver = Platform.OS !== 'web'

const baseList = [
    require('@/assets/watermelon-icon.png'),
    require('@/assets/cheese-icon.png'),
    require('@/assets/french-fries-icon.png'),
    require('@/assets/avocado-icon.png'),
    require('@/assets/chicken-icon.png'),
    require('@/assets/pizza-icon.png'),
    require('@/assets/hamburger-icon.png'),
]

export const LoadingView: FC<LoadingViewProps> = ({
    visible = true,
    message,
    resizeInTime = 550,
    resizeOutTime = 350,
    scaleTarget = 1.35,
    scrollTime = 250,
    iconSize = 50,
}) => {
    const [marginIcons, setMarginIcons] = useState(0)
    const [iconsList, setIconsList] = useState<IconInfo[]>(
        baseList.map((image, tag) => ({
            image,
            tag,
        }))
    )

    const [rotateCount, setRotateCount] = useState(0)

    const elementSize = useMemo(
        () => marginIcons * 2 + iconSize,
        [marginIcons, iconSize]
    )
    const [scale] = useState<Animated.Value>(new Animated.Value(1))
    const [opacity] = useState<Animated.Value>(new Animated.Value(0.25))
    const [translateX] = useState<Animated.Value>(new Animated.Value(0))

    const changeImages = () => {
        Animated.sequence([
            Animated.timing(translateX, {
                toValue: -elementSize,
                duration: scrollTime,
                useNativeDriver,
            }),
            Animated.parallel([
                Animated.sequence([
                    Animated.timing(scale, {
                        toValue: scaleTarget,
                        duration: resizeInTime,
                        easing: Easing.in(Easing.bounce),
                        useNativeDriver,
                    }),
                    Animated.timing(scale, {
                        toValue: 1,
                        easing: Easing.in(Easing.bounce),
                        duration: resizeOutTime,
                        useNativeDriver,
                    }),
                ]),
                Animated.sequence([
                    Animated.timing(opacity, {
                        toValue: 1,
                        duration: resizeInTime,
                        useNativeDriver,
                    }),
                    Animated.timing(opacity, {
                        toValue: 0.25,
                        duration: resizeOutTime,
                        useNativeDriver,
                    }),
                ]),
            ]),
        ]).start(() => {
            translateX.setValue(0)
            scale.setValue(1)
            opacity.setValue(0.25)

            setIconsList(
                iconsList.map((_, index) =>
                    index + 1 === iconsList.length
                        ? iconsList[0]
                        : iconsList[index + 1]
                )
            )

            setRotateCount(rotateCount + 1)
        })
    }

    useEffect(() => {
        changeImages()
    }, [rotateCount])

    if (!visible) return null

    return (
        <View
            style={tw`absolute bottom-0 left-0  w-full h-full z-1 items-center justify-center overflow-hidden`}
        >
            <View
                onLayout={(event) => {
                    const { width } = event.nativeEvent.layout
                    setMarginIcons(
                        Math.ceil(
                            (width - iconSize * (iconsList.length - 2)) /
                                (2 * (iconsList.length - 2))
                        )
                    )
                }}
                style={[tw`flex-row w-full h-100px items-center `]}
            >
                <Animated.View
                    style={[
                        tw`flex-row w-full h-100px items-center`,
                        {
                            transform: [
                                {
                                    translateX,
                                },
                            ],
                        },
                    ]}
                >
                    {iconsList.map((item, index) => {
                        let animatedStyle = {}

                        if (index === Math.floor(iconsList.length / 2)) {
                            animatedStyle = {
                                opacity,
                                transform: [
                                    {
                                        scale,
                                    },
                                ],
                            }
                        } else {
                            animatedStyle = {
                                opacity: 0.25,
                                transform: [
                                    {
                                        scale: 1,
                                    },
                                ],
                            }
                        }

                        return (
                            <Animated.Image
                                key={`icon-${item.image}-${item.tag}`}
                                source={item.image as any}
                                style={{
                                    height: iconSize,
                                    width: iconSize,
                                    marginRight: marginIcons,
                                    marginLeft: marginIcons,
                                    ...animatedStyle,
                                }}
                            />
                        )
                    })}
                </Animated.View>
            </View>
            {message && (
                <Text style={tw`m-35px font-ppa-b text-pc-shade-100 text-xl`}>
                    {message}
                </Text>
            )}
        </View>
    )
}
