import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const ChevronLeftSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={11}
        height={18}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            stroke="currentColor"
            strokeWidth={2.667}
            strokeLinecap="round"
            d="M8.781 16.333 2 9.552M9 2.552 2.219 9.333"
        />
    </Svg>
)

export default ChevronLeftSvg
