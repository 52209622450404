import { useEffect, useState } from 'react'

import { ImageBackground, Text, TouchableOpacity, View } from 'react-native'

import Animated, {
    Easing,
    FadeIn,
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withSequence,
    withTiming,
} from 'react-native-reanimated'

import { LootboxInfoResponse } from '@/features/LootBox/types'

import { OrderDetailResponse } from '@/types/Order'
import { Reward } from '@/types/Reward'

import { TrackableClick } from '@/components/Analytics'

import tw from '@/tailwind/tailwind'

import { OrderPlacedViewNavigationType } from './OrderPlacedView'

type EligibleForLootboxProps = {
    order: OrderDetailResponse
    navigation: Exclude<OrderPlacedViewNavigationType, null>
    lootboxInfo: LootboxInfoResponse
}

const HEIGHT = 95 // px
const LOOT_BOX_SIZE = 25 // rem
const BOUNCE_HEIGHT = 10 // px

export const EligibleForLootbox = ({
    order,
    navigation,
    lootboxInfo,
}: EligibleForLootboxProps) => {
    const [rewardData, setRewardData] = useState<Reward['custom_data']>(null)

    useEffect(() => {
        if (lootboxInfo?.reward?.custom_data)
            setRewardData(lootboxInfo.reward.custom_data)
    }, [lootboxInfo])

    // BOUNCE ANIMATION
    const bounceAnim = useSharedValue<number>(0)

    const boxBounceStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateY: -bounceAnim.value * BOUNCE_HEIGHT }],
        }
    }, [bounceAnim])

    useEffect(() => {
        bounceAnim.value = withRepeat(
            withSequence(
                withTiming(1, {
                    duration: 1000,
                    easing: Easing.linear,
                }),
                withTiming(0, {
                    duration: 1000,
                    easing: Easing.bounce,
                })
            ),
            100
        )
    }, [])

    const lootboxReward = lootboxInfo.reward

    if (!rewardData || !lootboxReward) return null
    const {
        font_color = '#000000',
        claim_tile_background_image_url,
        claim_tile_lootbox_image_url,
    } = rewardData

    if (!claim_tile_background_image_url || !claim_tile_lootbox_image_url) {
        console.error('Missing lootbox custom data', rewardData)
        return null
    }

    return (
        <Animated.View style={tw`mt-4`} entering={FadeIn.duration(2000)}>
            <TrackableClick
                name="Clicked on go to lootbox from order detail"
                category="Lootbox"
            >
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate('LootboxAnimation', {
                            orderId: order.popchew_order_id,
                            rewardId: lootboxReward.id,
                        })
                    }}
                >
                    <ImageBackground
                        // source={require('@/assets/images/lootbox-wavy-background.png')}
                        source={{
                            uri: claim_tile_background_image_url,
                        }}
                        resizeMethod="scale"
                        resizeMode="cover"
                        imageStyle={tw`rounded-md`}
                        style={[
                            tw`flex-row justify-center px-5 max-h-[${HEIGHT}px]`,
                        ]}
                    >
                        <View style={tw`w-[70%] p-4`}>
                            <Text
                                style={tw`text-xl font-ppa-b text-[${font_color}]`}
                            >
                                Claim loot box!
                            </Text>
                            <Text
                                style={tw`font-ppa-reg text-xs text-[${font_color}]`}
                                numberOfLines={2}
                            >{`Open the box to see which prize you won`}</Text>
                        </View>
                        <Animated.Image
                            style={[
                                tw`w-${LOOT_BOX_SIZE} h-${LOOT_BOX_SIZE} self-end mt-2`,
                                boxBounceStyle,
                            ]}
                            // source={require('@/assets/images/loot-box-standalone.png')}
                            source={{
                                uri: claim_tile_lootbox_image_url,
                            }}
                        />
                    </ImageBackground>
                </TouchableOpacity>
            </TrackableClick>
        </Animated.View>
    )
}
