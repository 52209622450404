import { useClearCart } from '@/features/Cart/hooks/cartMutations'
import { useCountCartItems } from '@/features/Cart/hooks/useCountCartItems'

import { showAlert } from '@/utils/showAlert'

export const useClearCartConditionally = () => {
    const { mutateAsync: clearCart } = useClearCart()
    const cartItemsCount = useCountCartItems()

    return async (
        askIf: boolean,
        message: string,
        yesCallback: () => Promise<void> | void,
        noCallback: () => Promise<void> | void = () => {}
    ) => {
        if (askIf && cartItemsCount > 0) {
            showAlert({
                title: 'Your cart is not empty',
                description: message,
                buttonTexts: ['Clear Cart', 'No'],
                buttonHandlers: {
                    'Clear Cart': async () => {
                        try {
                            await clearCart()
                            await yesCallback()
                        } catch (err) {
                            console.error(err)
                        }
                    },
                    No: async () => {
                        await noCallback()
                    },
                },
            })
        } else {
            await yesCallback()
        }
    }
}
