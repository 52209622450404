import { Text, View } from 'react-native'

import { ModifierGroup as ModifierGroupType } from '@/types/Menu'

import tw from '@/tailwind/tailwind'

import { Modifier } from './Modifier'

interface ModifierGroupProps extends ModifierGroupType {
    modifier_group_idx: number
    modifiersState: ModifierGroupType[]
    toggleModifier: (modifier_group_idx: number, modifier_idx: number) => void
    maxItemsReached: boolean
    brandId: string
}
export const ModifierGroup = (modiferGroup: ModifierGroupProps) => {
    const {
        name,
        min_selected: min,
        max_selected: max,
        modifiers,
        modifier_group_idx,
        modifiersState,
        toggleModifier,
        maxItemsReached,
        brandId,
    } = modiferGroup

    const isXOR = min === 1 && max === 1

    let subtext =
        min && !max
            ? `Choose at least ${min}`
            : min && max && min !== max
            ? `Choose ${min} - ${max} options`
            : min && max && min === max
            ? `Choose ${min}`
            : max && !min
            ? `Choose up to ${max}`
            : // !max && !min
              ``

    if (isXOR) subtext = 'Required'

    return (
        <View style={tw`mb-6`} testID="modifier-group">
            <View style={tw`bg-pc-shade-20 py-4 px-container`}>
                <Text style={tw`text-xl font-ppa-b `}>{name}</Text>
                {subtext ? (
                    <Text
                        testID="modifier-subtext"
                        style={tw`font-ppa-b text-sm text-pc-shade-80`}
                    >
                        {subtext}
                    </Text>
                ) : null}
            </View>
            <View style={tw`px-container mt-2`}>
                {modifiers.map((modifier, idx) => {
                    return (
                        <Modifier
                            modifier_idx={idx}
                            modifier_group_idx={modifier_group_idx}
                            isXOR={isXOR}
                            {...modifier}
                            key={idx}
                            modifiersState={modifiersState}
                            toggleModifier={toggleModifier}
                            maxItemsReached={maxItemsReached}
                            brandId={brandId}
                        />
                    )
                })}
            </View>
        </View>
    )
}
