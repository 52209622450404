import storage from './storage'

let deviceId: string | null = null

const fruitImages = [
    require('@/assets/fruit-icon-avocado.jpg'),
    require('@/assets/fruit-icon-cherry.jpg'),
    require('@/assets/fruit-icon-eggplant.jpg'),
    require('@/assets/fruit-icon-icecream.jpg'),
    require('@/assets/fruit-icon-watermelon.jpg'),
]

const readDeviceId = async () => {
    const idStore = await storage.getDeviceId()
    if (idStore) deviceId = idStore
}
readDeviceId()

const hashDeviceId = () => {
    if (deviceId === null) return 0

    const idDigits = parseInt(deviceId.replace(/\D/g, ''))

    return isNaN(idDigits) ? 0 : idDigits % fruitImages.length
}

export const getRandomProfileImage = () => {
    const position = deviceId === null ? 0 : hashDeviceId()

    return fruitImages[position]
}
