import { useEffect, useMemo, useState } from 'react'

import { Text, TouchableOpacity, View } from 'react-native'

import {
    getCurrentRoute,
    navigateUsingRef,
    navigationRef,
} from '@/navigation/navigateUsingRef'
import { RootStackParamList } from '@/navigation/types/RootStackParamList'
import { SafeAreaView } from 'react-native-safe-area-context'

import { OrderDetailResponse } from '@/types/Order'

import { useOrderStatus } from '@/hooks/query/useOrder'
import { useOrderRefetchInterval } from '@/hooks/query/useOrderRefetchInterval'
import { useProfile } from '@/hooks/query/useProfile'

import { getOrderStatusText } from '@/utils/getOrderStatusText'
import { sortOrders } from '@/utils/sortOrders'

import tw from '@/tailwind/tailwind'

import ChevronRightSvg from '@/svgs/ChevronRightSvg'

import { shouldShowTopBar, shouldTopbarShowOnCurrentRoute } from './utils'

interface OrderStatusTopbarProps {
    children: JSX.Element
}

const ROUTES_TO_HIDE_STATUS_BAR: Array<keyof RootStackParamList> = [
    'OrderPlaced',
]

export const OrderStatusTopbar = ({ children }: OrderStatusTopbarProps) => {
    const [showTopbar, setShowTopbar] = useState(false)
    const [shouldShowTopBarAtCurrentRoute, setShouldShowTopBarAtCurrentRoute] =
        useState(false)
    const currentRoute = getCurrentRoute()

    const { data: profile } = useProfile()

    const { orders = [] } = profile || {}

    const sortedOrders = sortOrders(orders)

    const selectedOrder = sortedOrders[0] as OrderDetailResponse | undefined

    const {
        data: orderStatus,
        refetch: refetchOrderFulfillmentDetails,
        dataUpdatedAt: orderDataUpdatedAt,
    } = useOrderStatus(selectedOrder?.order_id)

    const statusObj = useMemo(
        () => getOrderStatusText(selectedOrder, orderStatus),
        [selectedOrder, orderStatus]
    )

    // When order changes or a new order is added
    // or when we regularaly fetch order updates
    // compute if we should show or hide sidebar
    useEffect(() => {
        setShowTopbar(
            Boolean(
                orderStatus &&
                    selectedOrder &&
                    shouldShowTopBar(selectedOrder, orderStatus)
            )
        )
    }, [selectedOrder, orderDataUpdatedAt])

    useOrderRefetchInterval({
        intervalTimeInSec: 5,
        onInterval: refetchOrderFulfillmentDetails,
        enabled: Boolean(selectedOrder && showTopbar),
    })

    useEffect(() => {
        if (!navigationRef.current) return

        // initial primer for status bar
        setShouldShowTopBarAtCurrentRoute(
            shouldTopbarShowOnCurrentRoute(
                ROUTES_TO_HIDE_STATUS_BAR,
                currentRoute
            )
        )

        const unsubscribe = navigationRef.current.addListener('state', () => {
            const currentRoute = getCurrentRoute()

            setShouldShowTopBarAtCurrentRoute(
                shouldTopbarShowOnCurrentRoute(
                    ROUTES_TO_HIDE_STATUS_BAR,
                    currentRoute
                )
            )
        })

        return unsubscribe
    }, [navigationRef.current])

    return (
        <View style={tw`flex-1`}>
            {shouldShowTopBarAtCurrentRoute && (
                <TouchableOpacity
                    onPress={() =>
                        selectedOrder?.order_id
                            ? navigateUsingRef('OrderPlaced', {
                                  orderId: selectedOrder?.order_id,
                              })
                            : {}
                    }
                    style={tw`${
                        statusObj?.isFailed ? 'bg-pc-warning' : 'bg-pc-success'
                    } `}
                >
                    {showTopbar && selectedOrder && (
                        <SafeAreaView
                            edges={['top']}
                            style={tw`flex-row justify-between py-4 px-7 `}
                        >
                            <View style={tw`flex-row items-center `}>
                                <View
                                    style={tw`w-[6px] h-[6px] bg-white rounded-full mr-8`}
                                />
                                <View>
                                    <Text
                                        style={tw`text-white font-ppa-b text-xs`}
                                    >
                                        {statusObj?.longText}
                                    </Text>
                                    <Text
                                        style={tw`text-white font-ppa-b text-xs`}
                                    >
                                        {selectedOrder?.brand_name}
                                    </Text>
                                </View>
                            </View>

                            <View style={tw`justify-center items-center`}>
                                <ChevronRightSvg
                                    width={15}
                                    height={20}
                                    viewBox="0 0 20 25"
                                    color="#fff"
                                    strokeWidth={3}
                                />
                            </View>
                        </SafeAreaView>
                    )}
                </TouchableOpacity>
            )}
            <>{children}</>
        </View>
    )
}
