import { Address } from '@/types/Address'

export const sortAddresses = (
    addresses: Address[],
    selectedAddress: Address | null
) => {
    if (selectedAddress?.address_id) {
        // make a copy of the array
        const sortedAddresses = addresses.map((address) => {
            return { ...address }
        })

        // find the index of the selected address
        const selectedIndex = sortedAddresses.findIndex(
            (address) => address.address_id === selectedAddress.address_id
        )

        // if selected address is not in the array, return the original array
        // this can happen from race conditions
        if (selectedIndex < 0) return addresses

        // move selected address to the front of the array
        sortedAddresses.unshift(sortedAddresses.splice(selectedIndex, 1)[0])

        return sortedAddresses
    }

    return addresses
}
