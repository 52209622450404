import { useEffect, useRef, useState } from 'react'

import { ActivityIndicator, Text, View } from 'react-native'

import { TEST_CC_CVV, TEST_CC_MO, TEST_CC_NUMBER, TEST_CC_YEAR } from '@env'
import {
    BottomSheetModal as BottomSheetModalType,
    BottomSheetScrollView,
} from '@gorhom/bottom-sheet'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { useQueryClient } from 'react-query'

import { User } from '@/types/User'

import { postTrackingEvent } from '@/components/Analytics'
import { BottomSheetModal } from '@/components/BottomSheetModal'
import { BottomSheetTextInput } from '@/components/BottomSheetModal/BottomSheetTextInput'
import { Button } from '@/components/Button'
import { ModalX } from '@/components/Titles/ModalX'

import { useProfile } from '@/hooks/query/useProfile'
import { useUpdateUser } from '@/hooks/query/useUpdateUser'
import { useKeyboard } from '@/hooks/useKeyboard'

import { getApiErrorMessage } from '@/utils/handleApiError'
import { formatCreditCardNumber } from '@/utils/normalizers'
import { showMessage } from '@/utils/showMessage'
import RNUxcam from '@/utils/uxcam'

import tw from '@/tailwind/tailwind'

import {
    createPaymentApiErrors,
    createPaymentMethod,
} from '../api/createPaymentMethod'
import { PaymentMethod, PaymentMethodResponse } from '../types'
import { isCCExpired } from '../utils/isCCExpired'
import { UserInfoModal } from './UserInfoModal'

interface PaymentMethodForm {
    name?: string
    ccNumber: string
    month: string
    year: string
    cvv: string
}

export const CreatePaymentMethodModal = ({
    closeModal,
    closeParentModal,
    setPaymentMethod,
}: {
    closeModal: () => void
    closeParentModal: () => void
    setPaymentMethod: (paymentMethod: PaymentMethodResponse) => void
}) => {
    const { isKeyboardOpen, isKeyboardOpening } = useKeyboard()
    const { top } = useSafeAreaInsets()

    const { data: profile } = useProfile()
    const { mutateAsync: updateUser, isLoading: updateUserIsLoading } =
        useUpdateUser()

    const [first, setFirst] = useState<string>('')
    const [last, setLast] = useState<string>('')
    const [email, setEmail] = useState<string>('')

    useEffect(() => {
        if (profile?.first) setFirst(profile.first)
        if (profile?.last) setLast(profile.last)
        if (profile?.email) setEmail(profile.email)
    }, [profile])

    const queryClient = useQueryClient()
    const [updatedUserInfo, setUpdatedUserInfo] = useState<boolean>(false)
    useEffect(() => {
        if (updatedUserInfo) checkCardInfo()
    }, [updatedUserInfo])

    const [isLoading, setIsLoading] = useState(false)

    const initialPaymentMethod = {
        name: '',
        ccNumber: TEST_CC_NUMBER || '',
        month: TEST_CC_MO || '',
        year: TEST_CC_YEAR || '',
        cvv: TEST_CC_CVV || '',
    }

    const [pMethod, setPMethod] =
        useState<PaymentMethodForm>(initialPaymentMethod)

    const userInfoBottomSheetRef = useRef<BottomSheetModalType>(null)
    const hideUserInfoScreen = () => userInfoBottomSheetRef.current?.close()
    const showUserInfoScreen = () => userInfoBottomSheetRef.current?.present()
    const today = new Date()

    const initialErrorState = {
        ccNumber: '',
        month: '',
        year: '',
        cvv: '',
    }
    const [pMethodErrors, setPMethodErrors] =
        useState<PaymentMethodForm>(initialErrorState)

    const handleChange = (key: keyof PaymentMethodForm, val: string) => {
        setPMethod({
            ...pMethod,
            [key]: val,
        })
        setPMethodErrors(initialErrorState)
    }

    const handleErrorChange = (key: keyof PaymentMethodForm, val: string) => {
        setPMethodErrors({
            ...pMethodErrors,
            [key]: val,
        })
        postTrackingEvent({
            name: 'Tapped Save payment method with invalid entries',
            category: 'Checkout',
            properties: {
                error: key,
            },
        })
    }

    const updateUserInfo = async (
        _first: string,
        _last: string,
        _email: string
    ) => {
        setIsLoading(true)

        const data: Partial<User> = {
            first: _first,
            last: _last,
            email,
        }
        if (_email !== email) data.email = _email
        await updateUser(data)
        setFirst(_first)
        setLast(_last)
        setEmail(_email)
        setUpdatedUserInfo(true)
        hideUserInfoScreen()

        setIsLoading(false)
    }

    const checkCardInfo = () => {
        const { ccNumber, month, year, cvv } = pMethod

        //  check for validity and set errors
        if (!ccNumber || ccNumber.length < 15) {
            return handleErrorChange(
                'ccNumber',
                'Please enter a valid card number'
            )
        }

        if (!month || Number(month) > 12 || Number(month) <= 0) {
            return handleErrorChange(
                'month',
                'Expiration month must be between 1-12.'
            )
        }

        if (!year || year.length !== 4) {
            return handleErrorChange(
                'year',
                'Expiration year is invalid. Ex. 2022.'
            )
        }

        // Check if expired
        if (isCCExpired(Number(month), Number(year))) {
            return handleErrorChange(
                'ccNumber',
                `Card is expired. ${month}/${year} is in the past.`
            )
        }

        if (!cvv) {
            return handleErrorChange('cvv', 'Please enter a valid cvv')
        }

        if (updatedUserInfo || (first && last && email)) handleSubmit()
        else showUserInfoScreen()
    }

    const handleSubmit = async () => {
        const { name, ccNumber, month, year, cvv } = pMethod

        const paymentMethod: PaymentMethod = {
            ...(name && { name }),
            card_number: ccNumber.replace(/\s+/g, ''),
            month: Number(month),
            year: Number(year),
            cvv,
            first,
            last,
        }

        setIsLoading(true)

        try {
            const paymentMethodRes = await createPaymentMethod(paymentMethod)
            await queryClient.invalidateQueries('profile')
            setPMethod(initialPaymentMethod)
            setPaymentMethod(paymentMethodRes)
            postTrackingEvent({
                name: 'Tapped save and added new payment method',
                category: 'Checkout',
            })
            closeModal()
            closeParentModal()
        } catch (err) {
            const errorMessage = getApiErrorMessage(err, createPaymentApiErrors)
            showMessage({
                message: errorMessage,
                type: 'warning',
            })
            if (errorMessage.includes('name')) {
                showUserInfoScreen()
            }
        }

        setIsLoading(false)
    }

    return (
        <>
            <SafeAreaView edges={['bottom']} style={[tw`flex-1`]}>
                <View
                    style={[
                        tw`flex-1 container`,
                        {
                            marginTop:
                                isKeyboardOpen || isKeyboardOpening ? top : 0,
                        },
                    ]}
                    ref={(view) => RNUxcam.occludeSensitiveView(view)}
                >
                    <View style={tw`flex-row items-center mb-12 `}>
                        <ModalX
                            onPress={closeModal}
                            name="Tapped X on create payment screen"
                            category="Checkout"
                        />
                        <Text style={tw`ml-3 font-ppa-b text-xl `}>
                            Payment Method
                        </Text>
                    </View>

                    <BottomSheetScrollView
                        contentContainerStyle={tw`flex-grow`}
                        showsVerticalScrollIndicator={false}
                    >
                        <Text style={tw`label`}>Card Nickname (optional)</Text>
                        <BottomSheetTextInput
                            onChangeText={(text: string) =>
                                handleChange('name', text)
                            }
                            style={tw`input`}
                            value={pMethod.name}
                            onSubmitEditing={checkCardInfo}
                        />

                        <Text style={tw`label mt-4`}>Card Number</Text>
                        <BottomSheetTextInput
                            keyboardType="number-pad"
                            onChangeText={(text: string) =>
                                handleChange(
                                    'ccNumber',
                                    formatCreditCardNumber(text)
                                )
                            }
                            autoCompleteType="cc-number"
                            style={tw`input mb-1 border-2 ${
                                !!pMethodErrors['ccNumber']
                                    ? 'border-pc-warning'
                                    : 'border-transparent'
                            }`}
                            value={pMethod.ccNumber}
                            onSubmitEditing={checkCardInfo}
                            placeholder="XXXX XXXX XXXX XXXX"
                        />
                        {!!pMethodErrors['ccNumber'] && (
                            <Text style={tw`text-pc-warning text-xs`}>
                                {pMethodErrors['ccNumber']}
                            </Text>
                        )}

                        <View style={tw`flex-row mt-4`}>
                            <View style={tw`flex-1 mr-4`}>
                                <Text style={tw`label`}>Expiry Month</Text>
                                <BottomSheetTextInput
                                    keyboardType="number-pad"
                                    onChangeText={(text: string) =>
                                        handleChange('month', text)
                                    }
                                    autoCompleteType="cc-exp-month"
                                    maxLength={2}
                                    style={tw`input border-2 ${
                                        !!pMethodErrors['month']
                                            ? 'border-pc-warning'
                                            : 'border-transparent'
                                    }`}
                                    value={pMethod.month}
                                    onSubmitEditing={checkCardInfo}
                                    placeholder={String(
                                        today.getMonth() + 1
                                    ).padStart(2, '0')}
                                />
                                {!!pMethodErrors['month'] && (
                                    <Text style={tw`text-pc-warning text-xs`}>
                                        {pMethodErrors['month']}
                                    </Text>
                                )}
                            </View>

                            <View style={tw`flex-1 mr-4`}>
                                <Text style={tw`label`}>Expiry Year</Text>
                                <BottomSheetTextInput
                                    keyboardType="number-pad"
                                    onChangeText={(text: string) =>
                                        handleChange('year', text)
                                    }
                                    autoCompleteType="cc-exp-year"
                                    maxLength={4}
                                    style={tw`input mb-1 border-2 ${
                                        !!pMethodErrors['year']
                                            ? 'border-pc-warning'
                                            : 'border-transparent'
                                    }`}
                                    value={pMethod.year}
                                    onSubmitEditing={checkCardInfo}
                                    placeholder={String(today.getFullYear())}
                                />
                                {!!pMethodErrors['year'] && (
                                    <Text style={tw`text-pc-warning text-xs`}>
                                        {pMethodErrors['year']}
                                    </Text>
                                )}
                            </View>

                            <View style={tw`flex-1 `}>
                                <Text style={tw`label`}>CVV</Text>
                                <BottomSheetTextInput
                                    keyboardType="number-pad"
                                    onChangeText={(text: string) =>
                                        handleChange('cvv', text)
                                    }
                                    maxLength={4}
                                    style={tw`input mb-1 border-2 ${
                                        !!pMethodErrors['cvv']
                                            ? 'border-pc-warning'
                                            : 'border-transparent'
                                    }`}
                                    value={pMethod.cvv}
                                    onSubmitEditing={checkCardInfo}
                                    placeholder="XXX"
                                />
                                {!!pMethodErrors['cvv'] && (
                                    <Text style={tw`text-pc-warning text-xs`}>
                                        {pMethodErrors['cvv']}
                                    </Text>
                                )}
                            </View>
                        </View>
                    </BottomSheetScrollView>

                    {isLoading ? (
                        <View
                            style={tw`h-14 flex-row items-center justify-center `}
                        >
                            <ActivityIndicator />
                        </View>
                    ) : (
                        <View style={tw`mt-4`}>
                            <Button
                                variant="primary"
                                onPress={checkCardInfo}
                                text="Save"
                                trackableCategory="Checkout"
                                trackableName="Tapped Save"
                            />
                        </View>
                    )}
                </View>
            </SafeAreaView>
            <BottomSheetModal
                ref={userInfoBottomSheetRef}
                snapPoints={['90%']}
                handleComponent={null}
                keyboardBehavior="interactive"
                trackableName="user name and email"
                trackableCategory="Checkout"
            >
                <UserInfoModal
                    closeModal={hideUserInfoScreen}
                    setUserInfo={updateUserInfo}
                    currentFirst={first}
                    currentLast={last}
                    currentEmail={email}
                    isLoading={updateUserIsLoading}
                />
            </BottomSheetModal>
        </>
    )
}
