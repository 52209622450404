import { useCallback, useMemo, useState } from 'react'

import { Platform, Text, View } from 'react-native'

import { useBottomSheetDynamicSnapPoints } from '@gorhom/bottom-sheet'
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'
import SwitchSelector from 'react-native-switch-selector'

import { BottomSheetModal } from '@/components/BottomSheetModal'
import { BottomSheetTextInput } from '@/components/BottomSheetModal/BottomSheetTextInput'
import { Button } from '@/components/Button'
import { ModalX } from '@/components/Titles/ModalX'

import { dollarInput, toPrice } from '@/utils/normalizers'

import tw from '@/tailwind/tailwind'

import DollarSvg from '@/svgs/DollarSvg'

type CustomTipModalProps = {
    bsmRef: React.RefObject<BottomSheetModalMethods>
    setCustomTip: React.Dispatch<React.SetStateAction<number | null>>
    previousTipSelectedIdx: number
    toggleRef: React.RefObject<SwitchSelector>
}

export const CustomTipModal = ({
    bsmRef,
    setCustomTip,
    previousTipSelectedIdx,
    toggleRef,
}: CustomTipModalProps) => {
    const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], [])

    const {
        animatedHandleHeight,
        animatedSnapPoints,
        animatedContentHeight,
        handleContentLayout,
    } = useBottomSheetDynamicSnapPoints(initialSnapPoints)

    const [customTipTemp, setCustomTipTemp] = useState('')

    const hide = useCallback(() => {
        bsmRef.current?.close()
    }, [bsmRef])

    const handleSubmit = () => {
        const converted = Number(customTipTemp || 0)

        setCustomTip(converted * 100)
        hide()
    }

    return (
        <BottomSheetModal
            isDark
            ref={bsmRef}
            snapPoints={animatedSnapPoints}
            handleHeight={animatedHandleHeight}
            contentHeight={animatedContentHeight}
            handleComponent={null}
            keyboardBehavior="interactive"
            trackableName="custom tip"
            trackableCategory="Checkout"
            onDismiss={() => {
                // if there is no custom tip set, revert switch to previous tip selected
                if (!customTipTemp) {
                    toggleRef.current?.toggleItem(previousTipSelectedIdx)
                }
            }}
        >
            <View
                style={tw`flex-1 py-container`}
                onLayout={handleContentLayout}
            >
                <View style={tw`px-container`}>
                    <ModalX
                        onPress={hide}
                        name="Tapped X on custom tip screen"
                        category="Checkout"
                        color="white"
                        title="Custom Tip"
                    />

                    <Text style={tw`text-sm text-white font-ppp-m py-4`}>
                        100% of your tip goes to the driver
                    </Text>
                </View>
                <View style={tw`flex-row`}>
                    <View
                        style={tw`bg-grey-700  rounded-l-xl flex-row items-center pl-4 pr-2.5`}
                    >
                        <DollarSvg />
                    </View>
                    <BottomSheetTextInput
                        onChangeText={(text) => {
                            const filteredText = dollarInput(text)

                            setCustomTipTemp(filteredText)
                        }}
                        keyboardType="decimal-pad"
                        autoFocus={Platform.OS !== 'web'}
                        style={tw`input flex-grow rounded-r-xl bg-grey-700 font-ppp-sb text-white text-base pl-0 leading-[1.1rem]`}
                        value={customTipTemp}
                        onSubmitEditing={handleSubmit}
                    />
                </View>
                <View style={tw`self-center mt-8 mb-2.5`}>
                    <Button
                        variant="action"
                        onPress={handleSubmit}
                        text={`Confirm ${
                            customTipTemp
                                ? toPrice(Number(customTipTemp) * 100) + ' '
                                : ''
                        }Tip`}
                        trackableCategory="Checkout"
                        trackableName="Tapped Confirm Tip"
                        disabled={!customTipTemp}
                    />
                </View>
            </View>
        </BottomSheetModal>
    )
}
