import { TouchableOpacity } from 'react-native'

import { navigateUsingRef } from '@/navigation/navigateUsingRef'

import { OrderDetailResponse } from '@/types/Order'

import { TrackableClick } from '@/components/Analytics'
import { Thumbnail } from '@/components/Thumbnail'

import tw from '@/tailwind/tailwind'

import { OrderMainInfo } from './OrderMainInfo'

export const OrderPlacedInstanceCard = ({
    order,
}: {
    order: OrderDetailResponse
}) => {
    const brandId = order.brand_id || ''

    return (
        <TrackableClick category="Order" name="Tapped instance/brand card">
            <TouchableOpacity
                style={tw`mt-4`}
                onPress={() => {
                    navigateUsingRef('BrandPage', {
                        brandId,
                    })
                }}
            >
                <Thumbnail variant="small" brandId={brandId} />

                <OrderMainInfo order={order} />
            </TouchableOpacity>
        </TrackableClick>
    )
}
