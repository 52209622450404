import { View } from 'react-native'

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import { BrandsScreen } from '@/features/Brands/screens/BrandsScreen'
import { Rewards } from '@/features/Rewards'
import { SocialFeed } from '@/features/SocialFeed'

import { TabBar } from '@/components/TabBar'

import tw from '@/tailwind/tailwind'

import { TabsParamList } from './types/TabsParamList'

const Tab = createBottomTabNavigator<TabsParamList>()

const BottomTabs = () => {
    return (
        <View style={tw`flex-1 bg-black`}>
            <Tab.Navigator
                screenOptions={{
                    headerShown: false,
                }}
                tabBar={(props) => <TabBar {...props} />}
                initialRouteName="Brands"
                sceneContainerStyle={tw`bg-white rounded-b-3xl overflow-hidden`}
            >
                <Tab.Screen
                    name="Brands"
                    component={BrandsScreen}
                    options={{ title: 'Eat' }}
                />
                <Tab.Screen name="Rewards" component={Rewards} />
                <Tab.Screen name="Feed" component={SocialFeed} />
            </Tab.Navigator>
        </View>
    )
}

export { BottomTabs }
