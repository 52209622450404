import { View, ViewProps } from 'react-native'

import tw from '@/tailwind/tailwind'

import { INITIAL_HEADER_HEIGHT } from '../../utils/constants'

export const FullPageView = ({
    viewProps,
    children = <></>,
    tabBarHeight,
}: {
    viewProps?: ViewProps
    children?: JSX.Element
    tabBarHeight: number
}) => {
    return (
        <View
            {...viewProps}
            style={[
                viewProps ? viewProps.style : null,
                tw`flex-1 pt-[${INITIAL_HEADER_HEIGHT + tabBarHeight}px]`,
            ]}
        >
            {children}
        </View>
    )
}
