import { Text } from 'react-native'

import {
    DeliveryTrackingStatus,
    Order,
    OrderDetailResponse,
    OrderStatusResponse,
    badOrderStates,
    goodOrderStates,
} from '@/types/Order'

import tw from '@/tailwind/tailwind'

const textWithTwColor = (text: string, color: string) => (
    <Text style={tw`font-ppa-b text-${color}`}>{text}</Text>
)

interface OrderStatusText {
    shortText: string
    shortTextEl: JSX.Element
    longText: string
    longTextEl: JSX.Element
    isFailed: boolean
}

export const orderStatusMap: {
    [key in Exclude<DeliveryTrackingStatus, null>]: string
} = {
    Unassigned: 'Placed',
    Unaccepted: 'Placed',
    Accepted: 'Order Accepted',
    OnTheWay: 'Order Accepted',
    Carrying: 'On the Way',
    ArrivedAtLocation: 'Arriving Soon',
    Delivered: 'Order Delivered',
    CannotDeliver: 'Delivery Failed',
}

export function getOrderStatusText(
    order: Order | OrderDetailResponse | undefined,
    orderStatus: OrderStatusResponse | undefined
): OrderStatusText {
    // Validate inputs
    if (!order || !orderStatus || !order.created_on || !orderStatus.order_state)
        return {
            shortText: '',
            get shortTextEl() {
                return <>{this.shortText}</>
            },
            longText: '',
            get longTextEl() {
                return <>{this.longText}</>
            },
            isFailed: false,
        }

    // Get relevant data
    const deliveryStatus = orderStatus.status
    const orderState = orderStatus.order_state
    const isDelivery = order.fulfillment_type === 'delivery'

    // Get times
    const timeSinceOrderCreated =
        new Date().getTime() - new Date(order.created_on).getTime()
    const has2MinutesPassed = timeSinceOrderCreated > 2 * 60 * 1000 // 2 minutes
    const has10MinutesPassed = timeSinceOrderCreated > 10 * 60 * 1000 // 10 minutes

    // Case: Failed
    // Criteria:
    // - order is in a known bad state
    // - order is not in a known good state after 2 minutes
    if (
        (has2MinutesPassed && !goodOrderStates.includes(orderState)) ||
        (isDelivery && deliveryStatus === 'CannotDeliver') ||
        badOrderStates.includes(orderState)
    ) {
        const refundText =
            orderStatus?.payment_status === 'Refunded' ? ' and Refunded' : ''
        return {
            shortText: 'Cancelled',
            get shortTextEl() {
                return textWithTwColor(this.shortText, 'pc-warning')
            },
            longText: `There was a problem with your order${refundText}`,
            get longTextEl() {
                return textWithTwColor(this.longText, 'pc-warning')
            },
            isFailed: true,
        }
    }

    // Case: Successful delivery
    // Criteria:
    // - pickup order dispatched after 10 min
    // - delivery order delivered
    if (isDelivery && deliveryStatus === 'Delivered') {
        return {
            shortText: orderStatusMap[deliveryStatus],
            get shortTextEl() {
                return textWithTwColor(this.shortText, 'pc-success')
            },
            longText: orderStatusMap[deliveryStatus],
            get longTextEl() {
                return textWithTwColor(this.longText, 'pc-success')
            },
            isFailed: false,
        }
    }
    // Case: Successful pickup
    if (!isDelivery && has10MinutesPassed && orderState === 'Dispatched')
        return {
            shortText: 'Completed',
            get shortTextEl() {
                return textWithTwColor(this.shortText, 'pc-success')
            },
            longText: 'Order completed',
            get longTextEl() {
                return textWithTwColor(this.longText, 'pc-success')
            },
            isFailed: false,
        }

    // Case: Placed - anything else

    const placedText = deliveryStatus
        ? orderStatusMap[deliveryStatus]
        : 'Placed'
    const placedTextEl = textWithTwColor(placedText, 'pc-shade-80')

    return {
        shortText: placedText,
        get shortTextEl() {
            return placedTextEl
        },
        longText: placedText,
        get longTextEl() {
            return placedTextEl
        },
        isFailed: false,
    }
}
