import { Platform, Text, View } from 'react-native'

import { Order } from '@/types/Order'

import { Button } from '@/components/Button'
import { ProfilePic } from '@/components/ProfilePic'
import { Thumbnail } from '@/components/Thumbnail'

import { useBrand } from '@/hooks/query/useBrand'
import { useOrderStatus } from '@/hooks/query/useOrder'

import { getOrderStatusText } from '@/utils/getOrderStatusText'
import { toPrice } from '@/utils/normalizers'
import { totalCartPrice } from '@/utils/totalCartPrice'

import tw from '@/tailwind/tailwind'

const isWeb = Platform.OS === 'web'

interface OrderCardProps {
    item: Order
    onViewOrderPress: (order: Order) => void
}

export const OrderCard = ({
    item: order,
    onViewOrderPress,
}: OrderCardProps) => {
    const itemsCount = order.cart.items.length
    const totalPrice = totalCartPrice(order.cart.items)
    const onViewMorePress = () => {
        onViewOrderPress(order)
    }

    const { data: brand } = useBrand(order.brand_id)
    const { data: orderStatus } = useOrderStatus(
        order.order_id,
        order.created_on
    )
    const orderStatusText = getOrderStatusText(order, orderStatus)

    return (
        <View style={tw`pb-4 border-b border-pc-shade-40 mb-4`}>
            <Thumbnail brandId={order.brand_id} />

            <View style={tw`flex-row justify-between items-center mt-2`}>
                <View style={tw`flex-row items-center flex-shrink`}>
                    <ProfilePic brandId={order.brand_id} variant="small" />
                    <View style={tw`mx-3 flex-shrink`}>
                        <Text style={tw`font-ppa-b text-base`}>{`${
                            order.brand_name
                        }${
                            brand?.creator_name
                                ? ' - ' + brand?.creator_name
                                : ''
                        }`}</Text>

                        <Text
                            style={tw`font-ppa-b ${
                                isWeb ? 'text-[13px]' : 'text-sm'
                            } text-pc-shade-80`}
                        >
                            {`${itemsCount} ${
                                itemsCount > 1 ? 'items' : 'item'
                            } ● ${toPrice(
                                order.ticket_amount
                                    ? order.ticket_amount
                                    : totalPrice
                            )}`}
                            <Text>{' ● '}</Text>
                            {orderStatusText.shortTextEl}
                        </Text>
                        {order.created_on && (
                            <Text
                                style={tw`font-ppa-b text-sm text-pc-shade-80`}
                            >
                                {new Date(order.created_on).toLocaleString(
                                    undefined,
                                    {
                                        weekday: 'short',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: '2-digit',
                                    }
                                )}
                            </Text>
                        )}
                    </View>
                </View>

                <Button
                    variant="secondary"
                    size="sm"
                    onPress={onViewMorePress}
                    text="View"
                    trackableName="Tapped view past order"
                    trackableCategory="Account"
                    trackableProperties={{
                        'order id': order.popchew_order_id,
                        'order qty': itemsCount,
                        'order date': order.created_on,
                        'order price': totalPrice,
                        'order brand': order.brand_name,
                    }}
                />
            </View>
        </View>
    )
}
