import { useCallback, useEffect, useState } from 'react'

import { SafeAreaView, Text, View } from 'react-native'

import { RootStackScreenProps } from '@/navigation/types/ScreenProps'

import { badOrderStates } from '@/types/Order'

import { useOrderDetails, useOrderStatus } from '@/hooks/query/useOrder'
import { useOrderRefetchInterval } from '@/hooks/query/useOrderRefetchInterval'

import { queryClient } from '@/lib/queryClient'

import { showMessage } from '@/utils/showMessage'

import tw from '@/tailwind/tailwind'

import { cartQueryKey } from '../Cart/api/useCart'
import { isOrderProcessingComplete } from '../Checkout/utils/isOrderProcessingComplete'
import { MouthAnimation } from './components/MouthAnimation'

const additionalSteps = [
    'Checking with restaurant',
    'Kissing the chef 😙👨🏻‍🍳',
    'Heating the fryer',
    'Chasing a chicken',
    'Making the sauces',
    'Hamster on wheel',
]

export const OrderProcessing = ({
    navigation,
    route,
}: RootStackScreenProps<'Order Processing'>) => {
    const { orderId } = route.params ?? {}

    const { data: orderDetail } = useOrderDetails(orderId)
    const { data: orderStatus, refetch: refetchOrderStatus } =
        useOrderStatus(orderId)

    const [finishUpAnimation, setFinishUpAnimation] = useState(false)
    const [statusText, setStatusText] = useState<string>('Submitting order')

    // called when animation runs out of time
    // which is also triggered when order is done processing and successful
    const onAnimationDone = useCallback(() => {
        // confirm order was successful and if so navigate to order placed screen
        const isError =
            !orderStatus || badOrderStates.includes(orderStatus.order_state)
        if (!isError) navigation.navigate('OrderPlaced', { orderId })
    }, [orderId, navigation, orderStatus])

    // if refetch interval reached max tries, show error message
    const onTimeout = useCallback(() => {
        showMessage({
            type: 'warning',
            message:
                'We are unable to process your order at this time. Please try again later',
            duration: 6000,
        })

        navigation.navigate('Checkout')
    }, [navigation])

    /** Check if modal should close because order has been processed */
    useOrderRefetchInterval({
        // if within 10 seconds of order places make interval 1 sec
        // else make it 3 sec
        // or if order response is not available, don't setup interval
        intervalTimeInSec: orderDetail
            ? new Date().getTime() -
                  new Date(orderDetail.created_on).getTime() <
              10 * 1000
                ? 1
                : 3
            : undefined,
        maxTries: 30, // ~ 60 seconds
        onInterval: refetchOrderStatus,
        onTimeout,
    })

    // update status text
    useEffect(() => {
        // generate random time between 5 and 7 seconds
        const randomTime = Math.floor(Math.random() * 2000) + 5000

        // every random time, update status text from list of steps
        const interval = setInterval(() => {
            const step = additionalSteps.shift()
            if (step) setStatusText(step)
        }, randomTime)

        return () => clearInterval(interval)
    }, [])

    // Every time order status changes, check if order is confirmed or error
    useEffect(() => {
        if (!orderStatus) return

        const orderState = orderStatus.order_state
        if (isOrderProcessingComplete(orderState)) {
            // notify user if error
            const rejectionReason = orderStatus.rejection_reason
            const isError = badOrderStates.includes(orderState)
            if (isError) {
                if (rejectionReason) {
                    showMessage({
                        type: 'warning',
                        message: `Unable to place order: ${rejectionReason}`,
                        duration: 5000,
                    })
                } else {
                    showMessage({
                        type: 'warning',
                        message: `Unable to place order. Please try again later.`,
                        duration: 5000,
                    })
                }
                navigation.navigate('Checkout')
            } else {
                queryClient.invalidateQueries(cartQueryKey, {
                    exact: true,
                    refetchActive: true,
                    refetchInactive: true,
                })
                // set animation to be done, this will handle navigation after animation is done and cleaned up
                if (!finishUpAnimation) setFinishUpAnimation(true)
                // in the off chance that the animation is done before the order is processed, navigate to order placed screen
                // when it is processed
                else navigation.navigate('OrderPlaced', { orderId })
            }
        }
    }, [orderStatus, navigation, orderId])

    return (
        <SafeAreaView style={tw`bg-black flex-1 items-center justify-center`}>
            <View style={tw`w-full relative`}>
                <View
                    style={tw`absolute bottom-full left-0 right-7 w-full pb-8 flex items-center`}
                >
                    <MouthAnimation
                        onAnimationDone={onAnimationDone}
                        finishUpAnimation={finishUpAnimation}
                    />
                </View>

                <View style={tw`items-center`}>
                    <View style={tw`w-full px-container`}>
                        <Text
                            style={tw`text-xl text-white font-ppp-b text-center`}
                        >
                            {statusText}
                        </Text>
                        <Text
                            style={tw`text-sm text-white font-ppp-m text-center mt-4`}
                            onPress={() => setFinishUpAnimation(true)}
                        >
                            This usually takes about 30 seconds.
                        </Text>
                    </View>
                </View>
            </View>
        </SafeAreaView>
    )
}
