import {
    Text,
    TouchableOpacity,
    TouchableOpacityProps,
    View,
    ViewProps,
} from 'react-native'

import openMap from 'react-native-open-maps'

import { Address } from '@/types/Address'
import { Instance } from '@/types/Instance'

import tw from '@/tailwind/tailwind'

import ChevronRightSvg from '@/svgs/ChevronRightSvg'
import MapPinSvg from '@/svgs/MapPinSvg'

type LocationBlockProps = {
    isDelivery: boolean
    selectedLocation: Address
    instance: Instance
}

type ContainerProps = TouchableOpacityProps | ViewProps

export const LocationBlock = ({
    isDelivery,
    selectedLocation,
    instance,
}: LocationBlockProps) => {
    const address = isDelivery ? selectedLocation : instance.store.address
    const { address1, address2, city, state } = address
    const topText = instance.brand_name
    const lowerText = `${address1}${
        address2 ? `, ${address2}` : ''
    }, ${city}, ${state}`

    const Container: React.ComponentType<ContainerProps> = isDelivery
        ? View
        : TouchableOpacity

    const addressUrl = lowerText + ' ' + instance.store.address.zip

    return (
        <View style={tw`pb-4 border-b border-grey-700`}>
            <Container
                style={tw`flex-row px-container items-center mt-4`}
                onPress={() => (isDelivery ? {} : openMap({ end: addressUrl }))}
            >
                <View style={tw`self-start mt-2.5`}>
                    <MapPinSvg color="white" />
                </View>
                <View style={tw`flex-1 ml-3 shrink`}>
                    <Text style={tw`font-ppp-b text-base text-white`}>
                        {topText}
                    </Text>
                    <Text style={tw`font-ppp-m text-sm text-white`}>
                        {lowerText}
                    </Text>
                </View>
                {!isDelivery && (
                    <View style={tw`flex items-center ml-3`}>
                        <ChevronRightSvg color="white" />
                    </View>
                )}
            </Container>
        </View>
    )
}
