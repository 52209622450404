import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { PhoneNumberCapture } from './screens/PhoneNumberCapture'
import { VerifyCode } from './screens/VerifyCode'
import { AuthStackParamList } from './types/AuthStackParamList'

const Stack = createNativeStackNavigator<AuthStackParamList>()

const Auth = () => {
    return (
        <Stack.Navigator
            initialRouteName={'PhoneNumberCapture'}
            screenOptions={{
                headerShown: false,
                contentStyle: {
                    backgroundColor: '#ffffff',
                },
            }}
        >
            <Stack.Screen
                name="PhoneNumberCapture"
                component={PhoneNumberCapture}
                options={{ title: 'Join' }}
            />
            <Stack.Screen
                name="VerifyCode"
                component={VerifyCode}
                options={{ title: 'Verify' }}
            />
        </Stack.Navigator>
    )
}

export { Auth }
