import { Text, View } from 'react-native'

import { OrderDetailResponse } from '@/types/Order'

import { ProfilePic } from '@/components/ProfilePic'

import tw from '@/tailwind/tailwind'

export const OrderMainInfo = ({ order }: { order: OrderDetailResponse }) => {
    const {
        cart,
        brand_id: brandId,
        created_on: orderCreatedOn,
        fulfillment_type: fulfillmentType,
    } = order
    const { instance_name } = cart

    return (
        <View
            style={tw`flex-row py-container border-b items-center border-pc-shade-40`}
        >
            <ProfilePic brandId={brandId} variant="medium" />
            <View style={tw`ml-4 flex-shrink`}>
                <Text style={tw`text-xl font-ppa-b`}>{instance_name}</Text>
                <Text style={tw`text-base font-ppa-b text-pc-shade-80`}>
                    {new Date(orderCreatedOn).toLocaleString(undefined, {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                    })}
                </Text>
                <Text style={tw`text-base font-ppa-b text-pc-shade-80`}>
                    {fulfillmentType === 'delivery' ? 'Delivery' : 'Pickup'}
                </Text>
            </View>
        </View>
    )
}
