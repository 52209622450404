import { ActivityIndicator } from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'

import tw from '@/tailwind/tailwind'

export const FullScreenLoader = () => {
    return (
        <SafeAreaView style={tw`flex-1 items-center justify-center`}>
            <ActivityIndicator />
        </SafeAreaView>
    )
}
