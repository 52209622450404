import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const ChevronRightSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            d="m9 5 7 7-7 7"
            stroke="currentColor"
            strokeWidth={props.strokeWidth || 2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default ChevronRightSvg
