import { useAxiosPostMutation } from '@/lib/queryClient'

interface SignupForEmailData {
    first?: string
    last?: string
    phone?: string | null
    email?: string
    address1?: string
    address2?: string
    city?: string
    state?: string
    zip?: string
    lat?: string
    lon?: string
    country?: string
    user_id?: string
}

export function useSignupForEmail() {
    return useAxiosPostMutation<SignupForEmailData, unknown>(
        '/ingest/doritos/optin'
    )
}
