import { Text, View } from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@/components/Button'

import tw from '@/tailwind/tailwind'

export const ApplyCouponTopbar = ({
    applyCoupon,
}: {
    applyCoupon: () => void
}) => {
    return (
        <SafeAreaView
            style={tw`bg-pc-success w-full flex-row justify-between p-container items-center `}
        >
            <Text style={tw`text-white text-base mr-4`}>
                You unlocked $10 off your first order
            </Text>

            <Button
                variant="primary"
                size="sm"
                text="Apply"
                trackableCategory="Checkout"
                trackableName="Tapped apply on coupon top bar"
                onPress={() => applyCoupon()}
            />
        </SafeAreaView>
    )
}
