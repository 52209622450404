import { useQueryClient } from 'react-query'

import { CreateOrderRequest, CreateOrderResponse, Order } from '@/types/Order'

import { profileQueryKey } from '@/hooks/query/useProfile'

import { useAxiosPostMutation } from '@/lib/queryClient'
import { useStore } from '@/lib/store'

import { ApiErrors, getApiErrorMessage } from '@/utils/handleApiError'
import { useCheckReloadInstance } from '@/utils/instance/checkReloadInstance'
import { showErrorMessage } from '@/utils/showMessage'

import { removeNonSelectedItemsFromOrder } from '../utils/removeNonSelectedItemsFromOrder'

export const useCreateOrderMutate = useAxiosPostMutation<
    CreateOrderRequest,
    CreateOrderResponse
>('/order/create')

export const useCreateOrder = (useCash: boolean = false) => {
    const queryClient = useQueryClient()
    const { mutateAsync: createOrderMutate } = useCreateOrderMutate()
    const checkReloadInstance = useCheckReloadInstance()
    const devUseCash = useStore((state) => state.devTools.useCash)

    return async (order: Order) => {
        const parsedOrder = removeNonSelectedItemsFromOrder(
            order
        ) as CreateOrderRequest
        if (useCash || devUseCash) parsedOrder.cash_payment = true
        try {
            return await createOrderMutate(parsedOrder)
        } catch (err) {
            checkReloadInstance(err, order)
            const errorMessage = getApiErrorMessage(err, createOrderApiErrors)
            showErrorMessage(errorMessage)
            throw err
        } finally {
            // profile info is updated when order is created, even in case of most errors
            await queryClient.invalidateQueries(profileQueryKey)
        }
    }
}

export const createOrderApiErrors: ApiErrors = {
    // Handled with generic error message:
    // 500 Flipdish failure - create order
    // Could handle specifically but should never happen:
    // 400 Invalid order body
    // 400 Invalid email
    // 400 Invalid address
    // 400 Invalid fulfillment_type
    // 400 Required modifiers not selected
    // 400 Too many modifiers selected
    genericMessage: 'Unable to place order',
    specificErrors: [
        {
            statusCode: 400,
            backendMessage: 'Invalid name',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Email already in use',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage:
                'Order outside Flipdish delivery zone for this restaurant',
            frontendMessage: 'Outside delivery range',
        },
        {
            statusCode: 500,
            backendMessage: 'Order in progress for this account', // - try again after a couple minutes",
            frontendMessage: 'Order in progress - wait a bit and try again',
        },
        {
            statusCode: 400,
            backendMessage: 'Tip must be smaller than $120.00',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage:
                'Sorry, the store is not accepting orders right now',
            frontendMessage: 'Restaurant is no longer open',
        },
        {
            statusCode: 400,
            backendMessage: 'Restaurant unavailable',
            frontendMessage: 'Restaurant is no longer open',
        },
        {
            statusCode: 400,
            backendMessage: 'Restaurant outdated',
            frontendMessage: 'The store changed. Try again',
        },
        {
            statusCode: 400,
            backendMessage: 'Menu outdated',
            frontendMessage: 'The menu changed. Try again',
        },
        {
            statusCode: 400,
            backendMessage: 'Order below minimum order value',
            frontendMessage: 'Order is below minimum order value',
        },
        {
            statusCode: 400,
            backendMessage: 'Invalid coupon',
            frontendMessage: null,
        },
        {
            statusCode: 400,
            backendMessage: 'Instance unavailable for this order',
            frontendMessage: 'Restaurant is no longer open',
        },
        // Payment ones shouldn't happen but I will handle with a generic payment error so people at least know it's a payment problem
        {
            statusCode: 400,
            backendMessage: 'Missing payment_method_id',
            frontendMessage: 'Payment method error',
        },
        {
            statusCode: 404,
            backendMessage: 'Popchew payment method not found',
            frontendMessage: 'Payment method error',
        },
    ],
}
