import { navigateUsingRef, navigationRef } from '@/navigation/navigateUsingRef'
import { RootStackParamList } from '@/navigation/types/RootStackParamList'
import { REACT_APP_API_URL } from '@env'
import Ax, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { catchAndReportError } from '@/lib/appMonitoring'

import { isConnectedToNetwork } from '@/utils/network'
import { showMessage } from '@/utils/showMessage'

import storage from '../utils/storage'

export const axios = Ax.create({
    baseURL: REACT_APP_API_URL,
})

// We should never call this from the axios interceptor in response to a mixpanel call failing or
// it will create an infinite loop
const showWarningMessage = (message: string, url?: string) => {
    const isMixpanel = !!url?.includes('mixpanel')
    if (!isMixpanel)
        showMessage({
            message,
            type: 'warning',
        })
}

// RESPONSE INTERCEPTOR
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    _retry?: boolean
}

let isRefreshing = false
let failedQueue: {
    resolve: (value: unknown) => void
    reject: (reason?: any) => void
}[] = []

const processQueue = (error: null | any, token: null | string = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })

    failedQueue = []
}

const goToPhoneNumberCapture = () => {
    // Prevents duplicate navigation if there are multiple simultaneous calls

    const currentRoute = navigationRef.isReady()
        ? navigationRef.getCurrentRoute()?.name
        : 'PhoneNumberCapture'

    const currentParams = navigationRef.isReady()
        ? navigationRef.getCurrentRoute()?.params
        : []

    if (
        currentRoute === 'PhoneNumberCapture' ||
        currentRoute === 'VerifyCode' ||
        !currentRoute
    )
        return

    navigateUsingRef('Login', {
        screen: 'PhoneNumberCapture',
        params: {
            returnToOnFinish: currentRoute as keyof RootStackParamList,
            returnToOnFinishParams: currentParams as
                | Record<string, string | Record<string, string>>
                | undefined,
        },
    })
}

// Before sending request
const authRequestInterceptor = async (config: AxiosRequestConfig) => {
    // Ensure connected to the internet
    const isConnected = await isConnectedToNetwork()
    if (!isConnected) {
        navigateUsingRef('NoInternet')
        return config
    }

    // Add token to headers if it exists
    const token = await storage.getAccessToken()

    if (token) {
        config.headers!['Authorization'] = `Bearer ${token}`
    } else {
        goToPhoneNumberCapture()
    }

    config.headers!['Content-Type'] = 'application/json'
    return config
}

const requestErrInterceptor = (error: AxiosRequestConfig) => {
    return Promise.reject(error)
}

// Return responses with no changes
const responseInterceptor = (response: AxiosResponse) => {
    return response
}

// Handle response errors
const responseErrInterceptor = async (error: AxiosError) => {
    const originalRequest = error?.config as CustomAxiosRequestConfig
    const statusCode = error?.response?.status
    const errorMessage = error?.response?.data.message

    if (statusCode === 401 && errorMessage === 'Flipdish unauthenticated') {
        goToPhoneNumberCapture()
        Promise.reject(error)
    }

    if (!originalRequest?._retry && statusCode === 401) {
        originalRequest._retry = true

        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject })
            })
                .then((token) => {
                    axios.defaults.headers.common[
                        'Authorization'
                    ] = `Bearer ${token}`
                    return axios(originalRequest)
                })
                .catch((err) => {
                    return Promise.reject(err)
                })
        }

        isRefreshing = true

        let refreshToken = await storage.getRefreshToken()

        if (!refreshToken) {
            isRefreshing = false
            await storage.removeAccessToken()
            await storage.removeRefreshToken() // in case of empty string?
            // await anonAuthorize()

            refreshToken = await storage.getRefreshToken()
            if (!refreshToken) return Promise.reject(error)
        }

        return new Promise(function (resolve, reject) {
            axios
                .post<{ access_token: string }>(`/auth/refresh`, {
                    refresh_token: refreshToken,
                })
                .then(async ({ data }) => {
                    await storage.setAccessToken(data.access_token)

                    axios.defaults.headers.common[
                        'Authorization'
                    ] = `Bearer ${data.access_token}`

                    processQueue(null, data.access_token)
                    resolve(axios(originalRequest))
                })
                .catch((err) => {
                    processQueue(err, null)
                    reject(err)
                })
                .finally(() => {
                    isRefreshing = false
                })
        })
    }

    // All of the below interceptions will return Promise.reject(error)
    if (statusCode === 400 && errorMessage === '400 Bad token') {
        await storage.removeAccessToken()
        await storage.removeRefreshToken()
        goToPhoneNumberCapture()
    }

    if (
        originalRequest?.url === '/auth/refresh' &&
        (statusCode === 401 ||
            statusCode === 502 ||
            (statusCode === 400 &&
                errorMessage === '400 Missing fields or bad access token') ||
            errorMessage === 'Invalid user id')
    ) {
        await storage.removeAccessToken()
        await storage.removeRefreshToken()
        goToPhoneNumberCapture()
    }

    if (statusCode === 403) {
        // Flipdish
        showWarningMessage('Must be signed in', originalRequest.url)

        goToPhoneNumberCapture()
    }

    if (statusCode === 429)
        showWarningMessage(
            'Too many attempts. Try again later',
            originalRequest.url
        )

    if (statusCode === 500)
        showWarningMessage('Something went wrong', originalRequest.url)

    catchAndReportError({ error })
    return Promise.reject(error)
}

axios.interceptors.request.use(authRequestInterceptor, requestErrInterceptor)
axios.interceptors.response.use(responseInterceptor, responseErrInterceptor)

export const axiosPost =
    <Input, Output>(url: string) =>
    async (data: Input) => {
        const response = await axios.post<Input, AxiosResponse<Output>>(
            url,
            data
        )
        return response.data
    }

export const parseAxiosError = (error: any) => {
    if (!Ax.isAxiosError(error)) return {}

    return {
        errorMessage: error.message || '',
        message: error.response?.data?.message || '',
        status: error.response?.status,
    }
}
