import { QueryKey, useQuery } from 'react-query'

import { Brand } from '@/types/Brand'

import { axios } from '@/lib/httpClient'
import { queryClient } from '@/lib/queryClient'

import { allBrandsQueryKey } from './useAllBrands'

const getBrand = async (brandId: string | undefined) => {
    return typeof brandId === 'undefined'
        ? Promise.reject(new Error('Invalid brand id'))
        : (await axios.get<Brand>(`/brand?id=${brandId}`)).data
}

export const useBrandQueryKey = (
    brandId: string | undefined | null
): QueryKey => ['brand', brandId]

export const useBrandQueryFn = (brandId: string | undefined) => {
    return getBrand(brandId)
}

export const useBrand = (brandId: string | undefined) => {
    return useQuery(
        useBrandQueryKey(brandId),
        () => {
            // if (!brandId) throw new Error('Brand ID is required in useBrand')
            return useBrandQueryFn(brandId)
        },
        {
            enabled: Boolean(brandId),
            staleTime: 60 * 1000, // 60 seconds
            initialData: () => {
                // this is essentially the same as the prefetch on the BrandCard, but in case that ever gets removed
                return (
                    queryClient.getQueryData<Brand[]>(allBrandsQueryKey) || []
                ).find((brand) => brand.id === brandId)
            },
        }
    )
}
