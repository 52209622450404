import { ListRenderItemInfo, Text, View } from 'react-native'
import { Switch } from 'react-native'

import { BottomSheetFlatList } from '@gorhom/bottom-sheet'

import tw from '@/tailwind/tailwind'

import { NotificationCategoryProps, NotificationSettingCardProps } from '.'
import { NotificationSettingCard } from './NotificationSettingCard'
import { PrefsToChange } from './useNotificationPreferences'

export const NotificationCategoryCard = ({
    item,
    updatePreferences,
}: {
    item: ListRenderItemInfo<NotificationCategoryProps>['item']
    updatePreferences: (prefsToChange: PrefsToChange) => void
}) => {
    return (
        <View>
            <View
                style={tw`flex-row justify-between items-center py-4 border-b border-pc-shade-40`}
            >
                <View style={tw`ml-4`}>
                    <Text style={tw`font-ppa-b text-lg text-black`}>
                        {item.title}
                    </Text>
                </View>
                <Switch
                    trackColor={{
                        false: tw.color('bg-pc-shade-80'),
                        true: tw.color('bg-pc-success'),
                    }}
                    thumbColor="white"
                    ios_backgroundColor={tw.color('bg-pc-shade-80')}
                    onValueChange={() => {
                        updatePreferences({
                            category: item.name,
                            value: !item.value,
                            name: '',
                        })
                    }}
                    value={item.value}
                />
            </View>
            {item.value && (
                <BottomSheetFlatList
                    data={item.notifications}
                    keyExtractor={(setting: NotificationSettingCardProps) =>
                        setting.name
                    }
                    renderItem={(item) => (
                        <NotificationSettingCard
                            item={item.item}
                            updatePreferences={updatePreferences}
                        />
                    )}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{
                        paddingRight: 15,
                        paddingLeft: 30,
                        paddingVertical: 5,
                    }}
                />
            )}
        </View>
    )
}
