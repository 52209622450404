export function validateEmail(email: string): boolean {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export function validateZip(zip: string): boolean {
    const re = /^\d{5}$/
    return re.test(zip)
}

export function validateName(name: string): boolean {
    // The string cannot contain any of the following characters: ~, `, @, #, $, %, ^, &, *, +, =, ?, ;, [, {, ], }
    // The string must be between 1 and 50 characters in length
    const re = /^[^~`@#$%\^&\*\+=\?;\[\{\]\}]{1,50}$/
    return re.test(name)
}
