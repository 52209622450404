import { useEffect, useRef, useState } from 'react'

import {
    Image,
    KeyboardAvoidingView,
    ScrollView,
    Text,
    View,
} from 'react-native'

import { RootStackScreenProps } from '@/navigation/types/ScreenProps'
import { SafeAreaView } from 'react-native-safe-area-context'

import { TrackableRender } from '@/components/Analytics'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'

import { useBrand } from '@/hooks/query/useBrand'
import { useProfile } from '@/hooks/query/useProfile'

import { WING_SZN_BRAND_ID } from '@/utils/constants'

import tw from '@/tailwind/tailwind'

const emptyForm = {
    first: '',
    last: '',
    email: '',
    phone: '',
    zip: '',
    handle: '',
}

export const StreetTeam = ({
    navigation,
    route,
}: RootStackScreenProps<'Street Team'>) => {
    const { data: profile } = useProfile()
    const { brandId } = route.params ?? {}
    const { data: brand } = useBrand(brandId)
    const isWingSzn = brand?.id === WING_SZN_BRAND_ID
    const mainImage = isWingSzn
        ? require('@/assets/images/street-team/wing-szn-cover.webp')
        : require('@/assets/images/street-team/snapback-cover.webp')

    const formImage = isWingSzn
        ? require('@/assets/images/street-team/wingszn-form-header.webp')
        : require('@/assets/images/street-team/jack-form-header.webp')

    const creatorImage = brand?.main_image_url

    const color = isWingSzn ? '#F6401B' : '#FCE53E'
    const textColor = isWingSzn ? 'white' : 'black'

    const scrollViewRef = useRef<ScrollView>(null)

    const [value, setValue] = useState<typeof emptyForm>({
        first: profile?.first ?? '',
        last: profile?.last ?? '',
        email: profile?.email ?? '',
        phone: profile?.phone ?? '',
        zip: '',
        handle: '',
    })

    const [errors, setErrors] = useState<typeof emptyForm>(emptyForm)

    const handleChange = (key: keyof typeof value) => (text: string) => {
        setValue((prev) => ({ ...prev, [key]: text }))
        setErrors((prev) => ({ ...prev, [key]: '' }))
    }

    const handleSubmit = () => {
        if (
            !value.first ||
            !value.last ||
            !value.email ||
            !value.phone ||
            !value.zip
        ) {
            setErrors((prev) => ({
                ...prev,
                first: !value.first ? 'Required' : '',
                last: !value.last ? 'Required' : '',
                email: !value.email ? 'Required' : '',
                phone: !value.phone ? 'Required' : '',
                zip: !value.zip ? 'Required' : '',
            }))

            return
        }
        navigation.navigate('Street Team Confirm', {
            ...value,
            brandId,
        })
    }

    useEffect(() => {
        if (!brandId) navigation.navigate('HomeTabs')
    }, [brandId, navigation])

    if (!brandId) return null
    return (
        <TrackableRender
            name="Viewed street team landing page"
            category="General"
        >
            <KeyboardAvoidingView behavior="padding" style={tw`flex-1`}>
                <ScrollView
                    ref={scrollViewRef}
                    style={tw`bg-black flex-1 pb-16`}
                    showsVerticalScrollIndicator={false}
                >
                    <SafeAreaView
                        style={tw`flex-1 bg-[${color}] rounded-b-4xl pb-16`}
                    >
                        <Image style={tw`w-full h-72`} source={mainImage} />
                        <Text
                            style={tw`text-${textColor} text-2xl font-ppp-m text-center`}
                        >
                            Join the Street Team
                        </Text>
                        <Text
                            style={tw`font-ppp-m text-base text-${textColor} text-center px-container leading-tight mt-3`}
                        >
                            Start as a member of the squad and level-up your
                            street cred by completing challenges, promoting{' '}
                            {brand?.name}, and eating {brand?.food_type}.
                        </Text>

                        <View style={tw`mt-8 px-container self-center`}>
                            <Button
                                size="sm"
                                trackableCategory="General"
                                trackableName="Press join on street team (scroll down)"
                                variant={isWingSzn ? 'action' : 'primary'}
                                text="Join"
                                onPress={() => {
                                    scrollViewRef.current?.scrollToEnd()
                                }}
                            />
                        </View>

                        <View style={tw`px-container`}>
                            <View style={tw`mt-16`}>
                                <Image
                                    source={require('@/assets/images/street-team/cash-bottle.webp')}
                                    style={tw`h-28 w-full`}
                                    resizeMode="contain"
                                />
                            </View>
                            <Text
                                style={tw`text-${textColor} font-ppp-m text-3xl text-center mt-6`}
                            >
                                Earn Cash
                            </Text>
                            <Text
                                style={tw`text-${textColor} font-ppp-m text-sm text-center`}
                            >
                                by getting your friends to order {brand?.name}
                            </Text>
                            <View style={tw`mt-16`}>
                                <Image
                                    source={require('@/assets/images/street-team/prizes.webp')}
                                    style={tw`h-28 w-full`}
                                    resizeMode="contain"
                                />
                            </View>
                            <Text
                                style={tw`text-${textColor} font-ppp-m text-3xl text-center mt-6`}
                            >
                                Win Prizes
                            </Text>
                            <Text
                                style={tw`text-${textColor} font-ppp-m text-sm text-center`}
                            >
                                compete head to head with other street team
                                members to win sweet rewards
                            </Text>
                            <View style={tw`mt-16`}>
                                <Image
                                    source={{ uri: creatorImage }}
                                    style={tw`h-28 w-full`}
                                    resizeMode="contain"
                                />
                            </View>
                            <Text
                                style={tw`text-${textColor} font-ppp-m text-3xl text-center mt-6`}
                            >
                                Connect with {brand?.creator_name}
                            </Text>
                            <Text
                                style={tw`text-${textColor} font-ppp-m text-sm text-center`}
                            >
                                get early access to exclusive content and
                                receive personalized messages straight from{' '}
                                {brand?.creator_name}
                            </Text>
                        </View>
                    </SafeAreaView>
                    <View style={tw`w-full`}>
                        <Image
                            source={formImage}
                            style={tw`h-72 w-full mt-4`}
                            resizeMode={isWingSzn ? 'cover' : 'contain'}
                        />
                    </View>
                    <View style={tw`px-container`}>
                        <Text
                            style={tw`text-white font-ppp-m text-3xl text-center mt-6`}
                        >
                            Join the Waitlist
                        </Text>
                        <Text
                            style={tw`text-white font-ppp-m text-sm text-center`}
                        >
                            Apply to be notified when the {brand?.name} Street
                            Team launches in your area
                        </Text>
                    </View>
                    <View style={tw`flex flex-row w-full mt-4`}>
                        <View style={tw`mr-4 flex-1`}>
                            <Input
                                variant="primary"
                                label="First"
                                value={value.first}
                                onChangeText={handleChange('first')}
                                errorMessage={errors.first}
                            />
                        </View>
                        <View style={tw`flex-1`}>
                            <Input
                                variant="primary"
                                label="last"
                                value={value.last}
                                onChangeText={handleChange('last')}
                                errorMessage={errors.last}
                            />
                        </View>
                    </View>
                    <View style={tw`mt-4`}>
                        <Input
                            variant="primary"
                            label="Email"
                            value={value.email}
                            onChangeText={handleChange('email')}
                            errorMessage={errors.email}
                            keyboardType="email-address"
                        />
                    </View>
                    <View style={tw`mt-4`}>
                        <Input
                            variant="primary"
                            label="Phone"
                            value={value.phone}
                            onChangeText={handleChange('phone')}
                            errorMessage={errors.phone}
                            keyboardType="phone-pad"
                        />
                    </View>
                    <View style={tw`mt-4`}>
                        <Input
                            variant="primary"
                            label="Zip"
                            value={value.zip}
                            onChangeText={handleChange('zip')}
                            errorMessage={errors.zip}
                            keyboardType="numeric"
                        />
                    </View>
                    <View style={tw`mt-4`}>
                        <Input
                            variant="primary"
                            label="Twitter or Instagram handle"
                            value={value.handle}
                            onChangeText={handleChange('handle')}
                            errorMessage={errors.handle}
                            keyboardType="twitter"
                        />
                    </View>
                    <SafeAreaView
                        style={tw`self-center mt-8`}
                        edges={['bottom']}
                    >
                        <Button
                            variant="action"
                            text="Get Notified"
                            trackableCategory="General"
                            trackableName="tapped get notified on street team"
                            onPress={handleSubmit}
                        />
                    </SafeAreaView>
                </ScrollView>
            </KeyboardAvoidingView>
        </TrackableRender>
    )
}
