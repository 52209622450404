import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const ChevronDownSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={12}
        height={7}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        {...props}
    >
        <Path
            d="M10.666 1 6 5.667 1.333 1"
            stroke={color || '#606060'}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default ChevronDownSvg
