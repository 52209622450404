import { OrderStatusResponse } from '@/types/Order'

export const isOrderProcessingComplete = (
    orderState: OrderStatusResponse['order_state']
) => {
    const shouldCloseStates: OrderStatusResponse['order_state'][] = [
        'Dispatched',
        'Delivered',
        'RejectedAfterBeingAccepted',
        'RejectedAutomatically',
        'RejectedByFlipdish',
        'RejectedByStore',
    ]

    return shouldCloseStates.includes(orderState)
}
