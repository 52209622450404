import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const MapPinSvg = (props: SvgProps) => (
    <Svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M14 6.667c0 4.666-6 8.666-6 8.666s-6-4-6-8.666a6 6 0 1 1 12 0Z"
            fill="#fff"
        />
        <Path d="M8 8.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="#000" />
    </Svg>
)

export default MapPinSvg
