import { Image, ImageSourcePropType, Platform } from 'react-native'

import Animated, {
    Extrapolate,
    interpolate,
    useAnimatedStyle,
} from 'react-native-reanimated'

import tw from '@/tailwind/tailwind'

export const FoodHead = ({
    scroll,
    source,
}: {
    scroll: Animated.SharedValue<number>
    source: ImageSourcePropType
}) => {
    const animatedStyle = useAnimatedStyle(() => ({
        opacity: interpolate(scroll.value, [0, 180], [1, 0], {
            extrapolateRight: Extrapolate.CLAMP,
        }),
    }))
    return (
        <Animated.View
            style={[
                tw`w-full h-36 z-10 ${Platform.OS !== 'web' ? 'mt-16' : ''} `,
                animatedStyle,
            ]}
        >
            <Image
                style={tw`h-full w-full`}
                source={source}
                resizeMode="contain"
            />
        </Animated.View>
    )
}
