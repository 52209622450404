import { View } from 'react-native'

import { BackButton } from '@/components/Titles/BackButton'

import tw from '@/tailwind/tailwind'

export const CheckoutTitle = () => {
    return (
        <View style={tw`px-container`}>
            <BackButton
                color="white"
                fallback="Cart"
                forceFallbackIfPreviousPageIn={['Login']}
                title="Checkout"
                legacyTrackableName="Tapped back arrow on checkout screen"
            />
        </View>
    )
}
