import { Image, View } from 'react-native'

import tw from '@/tailwind/tailwind'

export const SplashScreen = () => {
    return (
        <View style={tw`flex-1 bg-pc-primary`}>
            <Image
                source={require('@/assets/splash.png')}
                resizeMode="contain"
                style={tw`h-full w-full`}
            />
        </View>
    )
}
