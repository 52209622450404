import { Image, View } from 'react-native'

import { useBrand } from '@/hooks/query/useBrand'

import tw from '@/tailwind/tailwind'

const variants = {
    small: { main: 'p-1.5 w-10 h-10', check: 'w-3.5 h-3.5' },
    medium: { main: 'p-1.5 w-12 h-12', check: 'w-5 h-5' },
}

interface ProfilePicProps {
    brandId?: string | undefined
    variant: keyof typeof variants
}

export const ProfilePic = ({ brandId, variant }: ProfilePicProps) => {
    const { data: brand } = useBrand(brandId)

    const color = brand?.color || tw.color('bg-pc-primary') || 'white'
    const image = brand?.logo_image_url
        ? { uri: brand.logo_image_url }
        : require('@/assets/popchew-mouth.png')

    return (
        <View
            style={tw`relative justify-center items-center ${variants[variant].main} rounded-full bg-[${color}]`}
        >
            <Image
                source={image}
                style={tw`w-full h-full`}
                resizeMode="contain"
            />
            <Image
                source={require('@/assets/checkmark.png')}
                style={tw`absolute -top-0.5 -right-0.5 ${variants[variant].check}`}
                resizeMode="cover"
            />
        </View>
    )
}
