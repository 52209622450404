import { View } from 'react-native'

import { OrderDetailResponse } from '@/types/Order'

import { BackButton } from '@/components/Titles/BackButton'
import { ModalX } from '@/components/Titles/ModalX'

import tw from '@/tailwind/tailwind'

export const OrderPlacedHeader = ({
    order,
    isModal,
    closeModal,
}: {
    order: OrderDetailResponse
    isModal: boolean
    closeModal?: () => void
}) => {
    const { flipdish_order_id, popchew_order_id: orderId } = order

    return (
        <View style={tw` ${isModal ? 'pb-2 bg-white' : ''} `}>
            {isModal ? (
                <ModalX
                    onPress={() => closeModal?.()}
                    name="Tapped x on specific order modal"
                    properties={{ orderId }}
                    category="Account"
                />
            ) : (
                <BackButton
                    forceFallbackIfPreviousPageIn={[
                        'Checkout',
                        'Order Processing',
                    ]}
                    fallback="BrandPage"
                    title={`Order #${flipdish_order_id}`}
                />
            )}
        </View>
    )
}
