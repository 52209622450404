import { Item } from '@/types/Menu'

export const calcItemTaxes = (item: Item) => {
    const { modifier_groups } = item
    if (!modifier_groups) return item.price

    // Check for any selected price-overriding modifiers
    let foundOverride = false
    let highestOverride = 0
    for (const modGroup of modifier_groups) {
        for (const mod of modGroup.modifiers) {
            if (mod.is_selected && mod.override_item_price) {
                foundOverride = true
                if (mod.price > highestOverride) highestOverride = mod.price
                // Favor the most expensive override modifier
            }
        }
    }
    const basePrice = foundOverride ? highestOverride : item.price

    const baseTax = basePrice * item.tax_rate
    const totalModiferTax = modifier_groups.reduce((mGprev, mGcurr) => {
        return (
            mGprev +
            mGcurr.modifiers.reduce((prev, curr) => {
                if (curr.override_item_price) return prev
                if (!curr.is_selected) return prev
                return curr.price * curr.tax_rate + prev
            }, 0)
        )
    }, 0)
    return baseTax + totalModiferTax
}
