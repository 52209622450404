import { useQuery } from 'react-query'

import { axios } from '@/lib/httpClient'

import { CommentsResponse } from '../types'

export const getComments = async (
    brandId: string,
    count: number | undefined = undefined
) => {
    const response = await axios.get<CommentsResponse>(
        `/brand/comments?brand_id=${brandId}${count ? `&count=${count}` : ''}`
    )
    return response.data?.comments
}

export const useComments = (
    brandId: string,
    count: number | undefined = undefined
) =>
    useQuery(['comments', brandId, count], () => getComments(brandId, count), {
        staleTime: Infinity,
    })
