import { useEffect, useRef, useState } from 'react'

import {
    ActivityIndicator,
    KeyboardAvoidingView,
    SafeAreaView,
    Text,
    TextInput,
    View,
} from 'react-native'

import { useNavigateBack } from '@/navigation/hooks/useNavigateBack'
import parsePhoneNumber from 'libphonenumber-js'
import { MaskedTextInput } from 'react-native-mask-text'

import { postTrackingEvent } from '@/components/Analytics'
import { ChevronButton } from '@/components/ChevronButton'
import { Triangle } from '@/components/Elements/Triangle'
import { BackButton } from '@/components/Titles/BackButton'
import { ModalX } from '@/components/Titles/ModalX'

import { useProfile } from '@/hooks/query/useProfile'

import tw from '@/tailwind/tailwind'

import { sendSMS } from '../../api/sendSMSVerification'
import { PhoneNumberCaptureProps } from '../../types/AuthScreenProps'

const PhoneNumberCapture = ({ route, navigation }: PhoneNumberCaptureProps) => {
    const { returnToOnFinish, returnToOnFinishParams } = route.params || {}

    const navigateBack = useNavigateBack()

    const phoneInputRef = useRef<TextInput>(null)

    const [phone, setPhone] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    // Profile
    const { data: profile } = useProfile()
    useEffect(() => {
        if (profile?.phone) setPhone(profile.phone)
    }, [profile])

    const submit = async () => {
        setError('')

        // Validate phone number
        const phoneNumber = parsePhoneNumber(phone, 'US')
        if (!phoneNumber?.isValid()) {
            setError('Please enter a valid US phone number')
            await postTrackingEvent({
                name: 'Entered invalid mobile number',
                category: 'Registration',
                onesignal: true,
            })
            phoneInputRef.current?.focus()
        } else {
            setLoading(true)
            try {
                await sendSMS(phone)
                await postTrackingEvent({
                    name: 'Entered mobile number',
                    category: 'Registration',
                    onesignal: true,
                })
                setLoading(false)

                navigation.navigate('VerifyCode', {
                    phone: phoneNumber.formatNational(),
                    returnToOnFinish,
                    returnToOnFinishParams,
                })
            } catch (e) {
                setLoading(false)
                setError('Failed to send SMS')
            }
        }
    }

    const changePhone = (num: string) => {
        setError('')
        setPhone(num)
    }

    return (
        <SafeAreaView style={tw` flex-1`}>
            <KeyboardAvoidingView style={tw`flex-1 `} behavior="padding">
                <View style={tw`container flex-1`}>
                    <BackButton
                        title="Join"
                        fallback="HomeTabs"
                        legacyTrackableName="Tapped X on mobile number entry screen"
                    />

                    <Text style={tw`text-2xl font-ppa-wbi mt-10  text-center`}>
                        What’s your number?
                    </Text>
                    <Text
                        style={tw`text-pc-shade-80 text-base font-ppa-b text-center mt-1.5 mb-6`}
                    >
                        We will send you a confirmation code
                    </Text>

                    <MaskedTextInput
                        mask="+1 999-999-9999"
                        testID="auth-mobile-number-input"
                        style={tw`input text-center bg-transparent text-2xl py-2`}
                        value={phone}
                        onChangeText={(_text, rawText) => {
                            changePhone(rawText)
                        }}
                        autoCompleteType={'tel'}
                        textContentType={'telephoneNumber'}
                        onSubmitEditing={submit}
                        ref={phoneInputRef}
                        textAlign={'center'}
                        placeholder="(555) 767-2439"
                        placeholderTextColor={tw.color('pc-shade-60')}
                        keyboardType="phone-pad"
                        autoFocus
                    />
                    {!!error && (
                        <>
                            <View style={tw`self-center`}>
                                <Triangle
                                    color={tw.color('pc-warning') || 'black'}
                                />
                            </View>
                            <View
                                style={tw`bg-pc-warning rounded-full py-2 px-4 self-center`}
                            >
                                <Text style={tw`text-white  `}>{error}</Text>
                            </View>
                        </>
                    )}
                    <View style={tw`flex-1 justify-end pb-4`}>
                        {loading ? (
                            <ActivityIndicator />
                        ) : (
                            <ChevronButton
                                onPress={submit}
                                testID={'auth-mobile-number-next-button'}
                            />
                        )}
                    </View>
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    )
}

export { PhoneNumberCapture }
