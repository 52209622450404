import { useEffect } from 'react'

import { Text, TouchableOpacity, View } from 'react-native'

import tw from '@/tailwind/tailwind'

import { VoteButtonStatusType } from '../types'
import { getHighlightColorFromVote } from '../utils/getHighlightColorFromVote'
import { NegativeTags, PositiveTags } from '../utils/tags'

type TagsProps = {
    selectedVote: VoteButtonStatusType
    selectedTags: {
        [key: string]: boolean
    }
    setSelectedTags: React.Dispatch<
        React.SetStateAction<{
            [key: string]: boolean
        }>
    >
}

export const Tags = (props: TagsProps) => {
    const { selectedVote, selectedTags, setSelectedTags } = props

    const tags = selectedVote === 'like' ? PositiveTags : NegativeTags

    useEffect(() => {
        const initialState = tags.reduce(
            (o, key) => ({ ...o, [key]: false }),
            {}
        )
        setSelectedTags(initialState)
    }, [selectedVote])

    const changeSelectedTag = (tag: string) => {
        setSelectedTags({ ...selectedTags, [tag]: !selectedTags[tag] })
    }

    const highlightColor = getHighlightColorFromVote(selectedVote)

    return (
        <View style={tw`flex-row flex-wrap justify-center px-container mt-12`}>
            {Object.entries(selectedTags).map(([tag, isSelected]) => {
                return (
                    <TouchableOpacity
                        hitSlop={{ top: 4, right: 4, bottom: 4, left: 4 }}
                        onPress={() => changeSelectedTag(tag)}
                        key={tag}
                        style={tw`bg-pc-shade-20 rounded-full self-center px-4 py-1.5 mx-1 mt-2 ${
                            isSelected ? `bg-[${highlightColor}]` : ''
                        }`}
                    >
                        <Text
                            style={tw`font-ppa-b text-sm ${
                                isSelected ? 'text-white' : ''
                            }`}
                        >
                            {tag}
                        </Text>
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}
