import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const XSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={color || '#000'}
        strokeWidth={1.5}
        {...props}
    >
        <Path
            d="M1 13 13 1M1 1l12 12"
            stroke="currentColor"
            strokeLinecap="round"
        />
    </Svg>
)

export default XSvg
