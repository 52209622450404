import { createNavigationContainerRef } from '@react-navigation/native'
import { ValueOf } from 'react-native-gesture-handler/lib/typescript/typeUtils'

import { RootStackParamList } from './types/RootStackParamList'
import { RootScreenName } from './types/ScreenProps'

// Can be used to navigate when navigation prop or useNavigation hook not reachable. Only use when those options can't be
// For future improvement see https://reactnavigation.org/docs/typescript/ for using custom RootStackParamsList type for passing props into pages when using navigationRef
export const navigationRef = createNavigationContainerRef<RootStackParamList>()

export const getCurrentRoute = () => navigationRef.current?.getCurrentRoute()

export const navigateUsingRef = (
    name: RootScreenName,
    params: ValueOf<RootStackParamList> = undefined
) => {
    if (navigationRef.isReady()) {
        if (params) navigationRef.navigate(name, params)
        else navigationRef.navigate(name)
    }
}
