import {
    Text,
    TouchableOpacity,
    TouchableOpacityProps,
    View,
    ViewProps,
} from 'react-native'

import { Instance } from '@/types/Instance'

import { TextWithEmailOrPhone } from '@/components/TextWithEmailOrPhone'

import tw from '@/tailwind/tailwind'

import BookmarkSvg from '@/svgs/BookmarkSvg'
import ChevronRightSvg from '@/svgs/ChevronRightSvg'

type InstructionBlockProps = {
    isDelivery: boolean
    deliveryNote: string
    setDeliveryNote: React.Dispatch<React.SetStateAction<string>>
    showDeliveryNoteScreen: () => void | undefined
    instance: Instance
}
type ContainerProps = TouchableOpacityProps | ViewProps

export const InstructionBlock = ({
    isDelivery,
    deliveryNote,
    instance,
    showDeliveryNoteScreen,
}: InstructionBlockProps) => {
    const topText = isDelivery ? 'Delivery Instructions' : 'Pickup Instructions'
    const lowerText = deliveryNote || 'Add delivery instructions'

    const Container: React.ComponentType<ContainerProps> = isDelivery
        ? TouchableOpacity
        : View

    if (!isDelivery && !instance.customer_pickup_instructions) return null

    return (
        <View style={tw`pb-4 border-b border-grey-700`}>
            <Container
                style={tw`flex-row px-container items-center mt-4`}
                onPress={() => (isDelivery ? showDeliveryNoteScreen() : {})}
            >
                <View style={tw`self-start mt-2.5`}>
                    <BookmarkSvg color="white" />
                </View>
                <View style={tw`flex-1 ml-3 shrink`}>
                    <Text style={tw`font-ppp-b text-base text-white`}>
                        {topText}
                    </Text>
                    {isDelivery ? (
                        <Text
                            style={tw`font-ppp-m text-sm text-white self-start ${
                                !deliveryNote ? 'underline' : ''
                            }`}
                        >
                            {lowerText}
                        </Text>
                    ) : (
                        <TextWithEmailOrPhone
                            instructions={
                                instance.customer_pickup_instructions ?? ''
                                // `Thanks for ordering! Your food will be ready for pickup at Kennedy's Chicken 6 - Grand Concourse. If you have any questions, you can reach our customer support team at (888)-711-1774 or by emailed hey@popchew.com. Enjoy your meal!`
                            }
                            textStyle={tw`font-ppp-m text-sm text-white`}
                            clickableTextStyle={tw`font-ppp-m text-sm text-pc-primary`}
                        />
                    )}
                </View>
                {isDelivery && (
                    <View style={tw`flex items-center ml-3`}>
                        <ChevronRightSvg color="white" />
                    </View>
                )}
            </Container>
        </View>
    )
}
