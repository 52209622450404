import { ImageBackground, View } from 'react-native'

import tw from '@/tailwind/tailwind'

import { useIsMobile } from './hooks/useIsMobile'

const minHeight = 768
const minWidth = 940

export const MAX_HEIGHT_PERCENTAGE = 0.919
export const BORDER_ASPECT_RATIO = 0.462

export const WebBorder = ({ children }: { children: JSX.Element }) => {
    const useBorder = !useIsMobile()

    const appContent = (
        <View
            style={
                useBorder
                    ? [
                          tw`flex-1 bg-white mr-[3px] rounded-tr-[13px] rounded-tl-[13px] rounded-br-[24px] rounded-bl-[24px] overflow-hidden max-h-[${
                              MAX_HEIGHT_PERCENTAGE * 100
                          }%]`,
                          {
                              aspectRatio: BORDER_ASPECT_RATIO,
                          },
                      ]
                    : tw`flex-1 bg-white max-w-[428px]`
            }
        >
            {children}
        </View>
    )

    return (
        <View style={tw`flex-1 flex-row justify-center bg-black`}>
            {useBorder ? (
                <ImageBackground
                    source={require('@/assets/blue-sky-with-clouds-background.webp')}
                    style={tw`flex-1 justify-center flex-row min-w-[${minWidth}px] p-0`}
                    resizeMode="cover"
                >
                    <ImageBackground
                        source={require('@/assets/phone-frame-min.webp')}
                        style={tw`flex-1 flex-col min-h-[${minHeight}px] min-w-[${minWidth}px] items-center p-0`}
                        resizeMode="contain"
                    >
                        <View style={tw`grow-0 h-[6.1%]`}></View>
                        {appContent}
                        {/* <Image
                            source={require('@/assets/download-app-qr-code.png')}
                            resizeMode="center"
                            style={tw`h-[250px] w-[125px] absolute bottom-[20px] right-[50px] z-0`}
                        /> */}
                    </ImageBackground>
                </ImageBackground>
            ) : (
                appContent
            )}
        </View>
    )
}
