import { useEffect, useRef, useState } from 'react'

import { Platform, Text, View } from 'react-native'

import Animated, {
    SharedValue,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated'

import { getAddressLines } from '@/features/Checkout/utils/getAddressLines'

import { DeliveryTrackingStatus, OrderDetailResponse } from '@/types/Order'

import { BrandLogoThumbnail } from '@/components/BrandLogoThumbnail'

import { orderStatusMap } from '@/utils/getOrderStatusText'

import tw from '@/tailwind/tailwind'

// Gets the fractional distance along the line for a given node - from figma
const getNodeXProportion = (node: number) => {
    if (node === 1) return 0.1221
    if (node === 2) return 0.3634
    if (node === 3) return 0.6047
    if (node === 4) return 0.8459
    return 0
}

const BOX_HEIGHT = 150
const PX_FROM_TOP = 50
const LINE_SIZE = 6
const NODE_SIZE = 20
const IMAGE_SIZE = 48
const TEXT_TOP_PADDING = 23
const ANIMATION_DURATION = 500

// for each delivery type we care about in order
// build array, remove dupes
const nodesToWatch: Partial<Exclude<DeliveryTrackingStatus, null>>[] = [
    'Accepted',
    'OnTheWay',
    'Carrying',
    'ArrivedAtLocation',
    'Delivered',
]
const nodesToWatchMappedAndDeDuped = [
    ...new Set(nodesToWatch.map((status) => orderStatusMap[status])),
]

const blankColor = tw.color('pc-shade-60')
const fillColor = '#000000' // tw.color('pc-success')

const DeliveryStatusNode = ({
    currentNode,
    nodeLabel,
    nodeX,
    nodeNumber,
    brandId,
}: {
    currentNode: SharedValue<number>
    nodeLabel: string
    nodeX: number
    nodeNumber: number
    brandId: string
}) => {
    const nodeStyle = useAnimatedStyle(
        () => ({
            backgroundColor:
                currentNode.value >= nodeNumber ? fillColor : blankColor,
        }),
        [currentNode]
    )

    const textStyle = useAnimatedStyle(
        () => ({
            color: currentNode.value >= nodeNumber ? 'black' : blankColor,
        }),
        [currentNode]
    )

    const imageAnim = useSharedValue<number>(0)
    useAnimatedStyle(() => {
        const show = currentNode.value === nodeNumber
        imageAnim.value = withTiming(show ? 1 : 0, {
            duration: ANIMATION_DURATION,
        })
        return {}
    }, [currentNode])
    const imageStyle = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    scale: imageAnim.value,
                },
            ],
        }
    }, [imageAnim])

    const nodeSectionWidth = nodeX / (nodeNumber - 0.5)
    const textXOffset = nodeSectionWidth * 0.05
    const textWidth = nodeSectionWidth * 0.9
    const fontSize = Platform.OS === 'web' ? 'sm' : 'base'

    return (
        <>
            <Animated.View
                style={[
                    tw`absolute  w-[${NODE_SIZE}px] h-[${NODE_SIZE}px] m-0 p-0 rounded-full top-[${
                        PX_FROM_TOP + LINE_SIZE / 2 - NODE_SIZE / 2
                    }px] left-[${nodeX - NODE_SIZE / 2}px]`,
                    nodeStyle,
                ]}
            />
            <Animated.Text
                style={[
                    tw`absolute text-center font-ppa-b text-${fontSize} w-[${textWidth}px] m-10 h-20 m-0 p-0 top-[${
                        PX_FROM_TOP + LINE_SIZE + TEXT_TOP_PADDING
                    }px] left-[${
                        nodeX - nodeSectionWidth / 2 + textXOffset
                    }px]`,
                    textStyle,
                ]}
            >
                {nodeLabel}
            </Animated.Text>
            <Animated.View
                style={[
                    tw`absolute  w-[${IMAGE_SIZE}px] h-[${IMAGE_SIZE}px] m-0 p-0 top-[${
                        PX_FROM_TOP + LINE_SIZE / 2 - IMAGE_SIZE / 2
                    }px] left-[${nodeX - IMAGE_SIZE / 2}px]`,
                    imageStyle,
                ]}
            >
                <BrandLogoThumbnail brandId={brandId} />
            </Animated.View>
        </>
    )
}

export const OrderPlacedDeliveryStatus = ({
    deliveryStatus,
    order,
}: {
    deliveryStatus: DeliveryTrackingStatus
    order: OrderDetailResponse
}) => {
    const { brand_id: brandId } = order

    const currentNode = useSharedValue<number>(0)
    const movingNode = useSharedValue<number>(0)
    const targetNode = useRef<number>(0)
    const [viewWidth, setViewWidth] = useState<number>(0)

    // Get the x pixel position of the center of a node
    const getNodeX = (node: number) => {
        const nodeXProportion = getNodeXProportion(node)
        return nodeXProportion * viewWidth
    }

    const stepToNode = () => {
        let n = currentNode.value
        const takeAStep = () => {
            if (n === targetNode.current) return
            if (n > targetNode.current) n--
            else n++
            const up = targetNode.current > currentNode.value
            if (!up) currentNode.value = n
            movingNode.value = withTiming(getNodeXProportion(n), {
                duration: ANIMATION_DURATION,
            })
            setTimeout(() => {
                if (up) currentNode.value = n
                takeAStep()
            }, ANIMATION_DURATION)
        }
        takeAStep()
    }

    // Trigger animations on changes to delivery status or on initial render
    useEffect(() => {
        const nodeToGoTo = deliveryStatus
            ? nodesToWatchMappedAndDeDuped.findIndex(
                  (status) => status === orderStatusMap[deliveryStatus]
              ) +
              //   cannot delivery should be -1
              (deliveryStatus === 'CannotDeliver' ? 0 : 1)
            : -1

        targetNode.current = nodeToGoTo
        stepToNode()
    }, [deliveryStatus, viewWidth])

    const fillBarStyle = useAnimatedStyle(() => {
        const percent =
            currentNode.value === nodesToWatchMappedAndDeDuped.length
                ? '100%'
                : Math.round(100 * movingNode.value).toString() + '%'
        return {
            width: percent,
        }
    }, [movingNode, currentNode])

    const { addressLineOne } = getAddressLines(order.address)

    return (
        <>
            <Text
                style={tw`font-ppa-reg text-xs mb-2`}
            >{`Delivering to ${addressLineOne}`}</Text>
            <View
                style={tw`flex border rounded-md border-[${
                    blankColor || ''
                }] w-full h-[${BOX_HEIGHT}px] bg-white`}
                onLayout={(event) => {
                    setViewWidth(event.nativeEvent.layout.width)
                }}
            >
                {/* HORIZONTAL LINE - GRAY */}
                <View
                    style={tw`absolute w-full h-0 top-[${PX_FROM_TOP}px] border-b-[${LINE_SIZE}px] border-[${
                        blankColor || ''
                    }] bg-white`}
                />
                {/* HORIZONTAL LINE - YELLOW */}
                <Animated.View
                    style={[
                        tw`absolute left-0 h-0 top-[${PX_FROM_TOP}px] border-b-[${LINE_SIZE}px] border-[${
                            fillColor || ''
                        }]`,
                        fillBarStyle,
                    ]}
                />
                {/* NODES */}
                {nodesToWatchMappedAndDeDuped.map((label, index) => (
                    <DeliveryStatusNode
                        key={index}
                        currentNode={currentNode}
                        nodeLabel={label}
                        nodeX={getNodeX(index + 1)}
                        nodeNumber={index + 1}
                        brandId={brandId}
                    />
                ))}
            </View>
        </>
    )
}
