import { View } from 'react-native'

import tw from '@/tailwind/tailwind'

export const Triangle = ({ color }: { color: string }) => {
    return (
        <View
            style={tw`w-0 h-0 bg-transparent border-solid border-t-0 border-r-4 border-b-8 border-l-4 border-t-transparent border-r-transparent border-b-[${color}] border-l-transparent`}
        ></View>
    )
}
