import { useQuery } from 'react-query'

import { Reward } from '@/types/Reward'

import { axios } from '@/lib/httpClient'

type GetEligibleRewardsType = {
    message: string | null
    rewards: Reward[]
}

const getEligibleRewards = async () => {
    const response = await axios.get<GetEligibleRewardsType>('/reward/eligible')
    return response.data.rewards
}

export const useEligibleRewardsQueryKey = ['eligibleRewards']

export const useEligibleRewards = () => {
    return useQuery(useEligibleRewardsQueryKey, getEligibleRewards, {
        staleTime: 5 * 1000, // 5 seconds
    })
}
