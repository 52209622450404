import { Text, View } from 'react-native'

import { Instance } from '@/types/Instance'

import tw from '@/tailwind/tailwind'

import LightningSvg from '@/svgs/LightningSvg'

type CheckoutLocationInfoProps = {
    isDelivery: boolean
    instance: Instance
}

export const CheckoutLocationInfo = ({
    isDelivery,
    instance,
}: CheckoutLocationInfoProps) => {
    const lowMinRange =
        instance.make_minutes_min +
        (isDelivery ? instance.delivery_minutes_min : 0)
    const highMinRange =
        instance.make_minutes_max +
        (isDelivery ? instance.delivery_minutes_max : 0)

    return (
        <View style={tw`mt-5 pb-4 border-b border-grey-700`}>
            <View style={tw`px-container flex-row items-center`}>
                <Text style={tw`font-ppp-b text-xl text-pc-primary`}>
                    {isDelivery ? 'Delivery' : 'Pickup'}
                </Text>
                <View
                    style={tw`flex-row items-center rounded-full bg-pc-primary px-3 py-1.5 ml-4`}
                >
                    <LightningSvg />
                    <Text style={tw`font-ppp-m text-sm ml-1.5`}>
                        {lowMinRange}-{highMinRange} mins
                    </Text>
                </View>
            </View>
        </View>
    )
}
