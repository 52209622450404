import {
    createRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import { NativeScrollEvent, NativeSyntheticEvent, View } from 'react-native'

import { BrandScreenProps } from '@/navigation/types/ScreenProps'
import { BottomSheetModal as BottomSheetModalType } from '@gorhom/bottom-sheet'
import { useNavigationState } from '@react-navigation/native'
import { AxiosError } from 'axios'
import { FlatList } from 'react-native-gesture-handler'
import { useSharedValue, withTiming } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { DeliveryType } from '@/types/Address'
import { Instance } from '@/types/Instance'

import { BottomSheetModal } from '@/components/BottomSheetModal'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { Missing404 } from '@/components/Missing404'

import { useBrand } from '@/hooks/query/useBrand'
import { useGetUsableScreenWidth } from '@/hooks/useGetUsableScreenWidth'

import { useStore } from '@/lib/store'

import { DORITOS_BRAND_ID } from '@/utils/constants'
import { removeClosedInstances } from '@/utils/instance/removeClosedInstances'

import tw from '@/tailwind/tailwind'

import { useInstancesByBrandId } from '../../hooks/query/useInstances'
import { useQueryState } from '../../lib/queryClient'
import { BrandHeader } from './components/BrandHeader'
import { BrandPageSections } from './components/BrandPageSections'
import { BrandTabBar } from './components/BrandTabBar'
import { PickupDeliveryModal } from './components/PickupDeliveryModal'
import { SectionIsLoading } from './components/SectionIsLoading'
import { Comments } from './screens/Comments'
import { Fans } from './screens/Fans'
import { Menu } from './screens/Menu'
import { useDefaultMenu } from './screens/Menu/api/useMenu'
import { Prizes } from './screens/Prizes'
import { AMOUNT_TO_SCROLL_FOR_MIN_HEADER } from './utils/constants'

export type possibleBrandPage = 'menu' | 'prizes' | 'fans' | 'comments'
export type BrandPageType = { title: possibleBrandPage; component: JSX.Element }
export type HandleScrollEventType = (
    event: NativeSyntheticEvent<NativeScrollEvent>
) => void
export type HandleContentChangeType = (x: number, y: number) => void

const constrainValue = (value: number, min: number, max: number) => {
    if (value < min) return min
    if (value > max) return max
    return value
}

export const BrandPage = ({ navigation, route }: BrandScreenProps) => {
    const { brandId, page, showModal, showPrizeId } = route.params || {}

    // hide tabbar for doritos
    let tabBarHeight = 60
    const isTabBarVisible = brandId !== DORITOS_BRAND_ID

    if (!isTabBarVisible) tabBarHeight = 0

    const pickupDeliveryModalRef = createRef<BottomSheetModalType>()
    const showPickupDeliveryModal = () =>
        pickupDeliveryModalRef.current?.present()

    const hidePickupDeliveryModal = () =>
        pickupDeliveryModalRef.current?.close()

    useEffect(() => {
        if (showPrizeId) return
        if (showModal === 'selectLocation') showPickupDeliveryModal()
    }, [showModal])

    const deliveryType = useStore((state) => state.location.deliveryType)

    const {
        data: brand,
        isLoading: brandIsLoading,
        isError: brandIsError,
        error: brandError,
    } = useBrand(brandId)

    const isDelivery = deliveryType === DeliveryType.delivery

    const brandSectionListRef = useRef<FlatList<BrandPageType>>(null)
    const tabBarRef = useRef<FlatList<BrandPageType>>(null)

    const { top, bottom } = useSafeAreaInsets()

    const { width } = useGetUsableScreenWidth()
    const [viewWidth, setViewWidth] = useState(width)

    const [pageHeight, setPageHeight] = useState(0)

    const scroll = useSharedValue(0)
    const scrollOffsets = useRef<number[]>([0, 0, 0, 0])
    const contentSizes = useRef<number[]>([0, 0, 0, 0])
    const [selectedPageIdx, setSelectedPageIdx] = useState(0)

    const handleContentSizeChange = (index: number, _: number, y: number) => {
        contentSizes.current[index] = y
    }

    const handleBrandTabContentChangeIndex =
        (index: number) => (x: number, y: number) => {
            handleContentSizeChange(index, x, y)
        }

    const handleBrandTabScroll = (
        index: number,
        event: NativeSyntheticEvent<NativeScrollEvent>
    ) => {
        // Calculate change in scroll
        const offset = event.nativeEvent.contentOffset.y
        const scrollChange = offset - scrollOffsets.current[index]

        // Update offsets
        scrollOffsets.current[index] = offset

        // Prevents momentum moving header after switching tab
        if (index !== selectedPageIdx) return
        // If hit the top of the container, animate scroll down
        if (offset === 0) scroll.value = withTiming(0)
        // If hit the bottom of the container, animate scroll up
        // TODO - decide how to handle when scrolled to bottom of section and header is only partially collapsed
        // else if (
        //     offset + 1 >
        //     contentSizes.current[index] - pageHeight
        //     // sometimes scroll ends at 0.5 for some reason
        // )
        //     scroll.value = withTiming(AMOUNT_TO_SCROLL_FOR_MIN_HEADER)
        // Else map scroll value to position - on the way down wait until a certain distance away
        else if (
            (scrollChange < 0 && offset < AMOUNT_TO_SCROLL_FOR_MIN_HEADER) ||
            scrollChange > 0
        )
            scroll.value = constrainValue(
                scroll.value + scrollChange,
                0,
                AMOUNT_TO_SCROLL_FOR_MIN_HEADER
            )
    }
    const handleBrandTabScrollIndex =
        (index: number) => (event: NativeSyntheticEvent<NativeScrollEvent>) => {
            handleBrandTabScroll(index, event)
        }

    const scrollTabs = (idx: number) => {
        // scroll to correct section in tabs
        if (tabBarRef.current && (idx || idx === 0)) {
            tabBarRef.current.scrollToIndex({
                index: idx,
            })
        }

        // On tab change scroll to position
        scroll.value = withTiming(
            constrainValue(
                scrollOffsets.current[idx],
                0,
                AMOUNT_TO_SCROLL_FOR_MIN_HEADER
            )
        )
    }

    const resetSection = useCallback(() => {
        scrollOffsets.current[0] = 0
        if (selectedPageIdx === 0) scroll.value = withTiming(0)
    }, [scroll, selectedPageIdx])

    // don't ever call this from BrandPageSections, can lead to bad snapping
    const changePage = (idx: number) => {
        setSelectedPageIdx(idx)
        // scroll to correct section in brands
        if (brandSectionListRef.current && (idx || idx === 0)) {
            brandSectionListRef.current.scrollToIndex({
                index: idx,
                animated: false,
            })
        }
        scrollTabs(idx)
    }

    const { data: instances, isLoading: isInstancesLoading } =
        useInstancesByBrandId(brandId)

    const openInstances = useMemo(
        () => (instances ? removeClosedInstances(instances, deliveryType) : []),
        [instances, deliveryType]
    )

    const { data: selectedInstance, setData: setSelectedInstance } =
        useQueryState<Instance>(['selectedInstance', brandId, deliveryType])

    const { data: defaultMenu, isLoading: isLoadingDefaultMenu } =
        useDefaultMenu(brandId)

    // WHEN THE BRAND PAGE IS OPENED
    const navigationState = useNavigationState((state) => state.routes)
    useEffect(() => {
        // Only when page is shown
        if (navigationState.slice(-1)[0].name !== 'BrandPage') return

        // If no brand id or delivery type, clear selected instance and return
        if (!brandId || !deliveryType || !instances || !openInstances)
            return setSelectedInstance(undefined)

        // For delivery, show the modal if there's no locations in range (will show no brands error on modal),
        // Otherwise set the location to the closest delivery instance

        if (isDelivery) {
            if (openInstances.length === 0) showPickupDeliveryModal()
            else setSelectedInstance(openInstances[0])
        }
        // For pickup, always show the modal on change if coming from brands screen
        else {
            if (!selectedInstance || openInstances.length === 0)
                showPickupDeliveryModal()
        }
    }, [instances, openInstances, deliveryType, brandId, navigationState])

    // Set navigation title upon brand info received
    useEffect(() => {
        if (brand) navigation.setOptions({ title: brand.name })
    }, [brand, navigation])

    useEffect(() => {
        if (!brandId) navigation.navigate('HomeTabs')
    }, [brandId, navigation])

    const tabs: BrandPageType[] = [
        {
            title: 'menu',
            component: (
                <Menu
                    tabBarHeight={tabBarHeight}
                    resetSection={resetSection}
                    navigation={navigation}
                    route={route}
                    pageHeight={pageHeight}
                    viewWidth={viewWidth}
                    scroll={scroll}
                    top={top}
                    bottom={bottom}
                    handleScroll={handleBrandTabScrollIndex(0)}
                    handleContentSizeChange={handleBrandTabContentChangeIndex(
                        0
                    )}
                    showPickupDeliveryModal={showPickupDeliveryModal}
                    isInstancesLoading={isInstancesLoading}
                />
            ),
        },
    ]

    // doritos hardcode
    if (brandId !== DORITOS_BRAND_ID) {
        const otherTabs: BrandPageType[] = [
            {
                title: 'prizes',
                component: (
                    <Prizes
                        tabBarHeight={tabBarHeight}
                        showPrizeId={showPrizeId}
                        brandId={brandId}
                        scroll={scroll}
                        pageHeight={pageHeight}
                        viewWidth={viewWidth}
                        top={top}
                        handleScroll={handleBrandTabScrollIndex(1)}
                        handleContentSizeChange={handleBrandTabContentChangeIndex(
                            1
                        )}
                    />
                ),
            },
            {
                title: 'fans',
                component: (
                    <Fans
                        tabBarHeight={tabBarHeight}
                        brandId={brandId}
                        top={top}
                        pageHeight={pageHeight}
                        viewWidth={viewWidth}
                        handleScroll={handleBrandTabScrollIndex(2)}
                        handleContentSizeChange={handleBrandTabContentChangeIndex(
                            2
                        )}
                    />
                ),
            },
            {
                title: 'comments',
                component: (
                    <Comments
                        tabBarHeight={tabBarHeight}
                        brandId={brandId}
                        pageHeight={pageHeight}
                        viewWidth={viewWidth}
                        handleScroll={handleBrandTabScrollIndex(3)}
                        handleContentSizeChange={handleBrandTabContentChangeIndex(
                            3
                        )}
                        top={top}
                    />
                ),
            },
        ]

        tabs.push(...otherTabs)
    }

    useEffect(() => {
        // allow direct linking to pages
        if (!page) return
        for (let i = 0; i < tabs.length; i++) {
            if (page === tabs[i].title) changePage(i)
        }
    }, [page])

    if (!brandId) return null

    if (brandIsLoading || isLoadingDefaultMenu) return <FullScreenLoader />
    if (
        !brand ||
        (brandIsError && (brandError as AxiosError).response?.status === 404) ||
        !defaultMenu
    )
        return (
            <Missing404
                mainText="The brand you’re looking for doesn’t exist"
                buttonText="View all brands"
                trackableCategory="Brands"
                renderTrackableName="Viewed 404 screen on BrandPage"
                buttonTrackableName="Tapped view all brands on BrandPage 404"
                buttonOnPress={() =>
                    navigation.navigate('HomeTabs', { screen: 'Brands' })
                }
            />
        )

    return (
        <View
            style={tw`flex-1 bg-white relative`}
            onLayout={(event) => {
                setPageHeight(event.nativeEvent.layout.height)
                const width = event.nativeEvent.layout.width
                if (width) setViewWidth(width)
            }}
        >
            <View style={tw`absolute top-0 z-50 w-full`}>
                <BrandHeader
                    scroll={scroll}
                    top={top}
                    showPickupDeliveryModal={showPickupDeliveryModal}
                    brand={brand}
                    brandIsLoading={brandIsLoading}
                />
                {isTabBarVisible && (
                    <BrandTabBar
                        tabBarRef={tabBarRef}
                        data={tabs}
                        selectedPageIdx={selectedPageIdx}
                        changePage={changePage}
                        tabBarHeight={tabBarHeight}
                    />
                )}
            </View>

            {isInstancesLoading ? (
                <SectionIsLoading
                    sectionName="menu"
                    color="black"
                    tabBarHeight={tabBarHeight}
                />
            ) : (
                <>
                    <BrandPageSections
                        data={tabs}
                        brandSectionListRef={brandSectionListRef}
                        setSelectedPageIdx={setSelectedPageIdx}
                        scrollTabs={scrollTabs}
                        viewWidth={viewWidth}
                    />
                    <BottomSheetModal
                        ref={pickupDeliveryModalRef}
                        snapPoints={['70%']}
                        keyboardBehavior="interactive"
                        trackableName="pickup locations"
                        trackableCategory="Address"
                    >
                        <PickupDeliveryModal
                            navigation={navigation}
                            instances={instances}
                            isLoading={isInstancesLoading}
                            setSelectedInstance={setSelectedInstance}
                            selectedInstance={selectedInstance}
                            closeModal={hidePickupDeliveryModal}
                        />
                    </BottomSheetModal>
                </>
            )}
        </View>
    )
}
