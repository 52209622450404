import { StateAbbreviation } from '@/types/Address'

export const SUPPORT_EMAIL = 'hey@popchew.com'
export const SUPPORT_PHONE = '888-711-1774'
export const PRIVACY_POLICY_URL =
    'https://popchew.com/privacy-policy?hideMenus=true'
export const POPCHEW_HQ_TEST_INSTANCE_ID = '45846'

export const LOOTBOX_TERMS_URL = 'https://popchew.com/images/lootbox-terms.pdf'
export const LOOTBOX_CLAIM_FORM = 'https://oybjt1g8c68.typeform.com/to/s3xTKe97'

export const STATES: StateAbbreviation[] = [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
]

export const DORITOS_BRAND_ID = 'e672a892467747d2879374f3ff351b33'
export const WELCOME_LOOTBOX_ID = '93ce992ce1974dd69c3633e4486463a8'
export const WELCOME_COUPON_CODE = 'FIRSTBITE10'
export const WELCOME_FEATURE_FLAG = 'give_welcome_lootbox'
export const SNAPBACK_BRAND_ID = 'cea52552bdb311ecadb3acde48001122'
export const WING_SZN_BRAND_ID = '2a38da38521d11ecbabeaa665a5617a2'
