import { useEffect, useRef, useState } from 'react'

import { Image, Text, View } from 'react-native'

import { WelcomeProps } from '@/navigation/types/ScreenProps'
import { vw } from 'react-native-expo-viewport-units'
import Onboarding from 'react-native-onboarding-swiper'
import { SafeAreaView } from 'react-native-safe-area-context'

import { TrackableClick, postTrackingEvent } from '@/components/Analytics'
import { Button } from '@/components/Button'

import tw from '@/tailwind/tailwind'

const Welcome = ({ navigation }: WelcomeProps) => {
    const [index, setIndex] = useState(0)
    const onboardingRef = useRef<Onboarding>(null)

    // Analytics for carousel
    useEffect(() => {
        carouselMixpanelEvent(0)
    }, [])

    const pages = [
        {
            backgroundColor: '#fff',
            image: (
                <View style={tw`h-full w-full`}>
                    <Image
                        style={{
                            flex: 1,
                            height: undefined,
                            width: undefined,
                        }}
                        resizeMode="contain"
                        source={require('@/assets/onboarding-food-min.jpg')}
                    />
                </View>
            ),
            title: 'Food',
            subtitle: 'Delicious food delivered straight to your doorstep',
        },
        {
            backgroundColor: '#fff',
            image: (
                <View style={tw`h-full w-full`}>
                    <Image
                        style={{
                            flex: 1,
                            height: undefined,
                            width: undefined,
                        }}
                        resizeMode="contain"
                        source={require('@/assets/onboarding-creators-min.jpg')}
                    />
                </View>
            ),
            title: 'Creators',
            subtitle: 'Engage with your favorite creators through food',
        },
        {
            backgroundColor: '#fff',
            image: (
                <View style={tw`h-full w-full`}>
                    <Image
                        style={{
                            flex: 1,
                            height: undefined,
                            width: undefined,
                        }}
                        resizeMode="contain"
                        source={require('@/assets/onboarding-rewards-min.jpg')}
                    />
                </View>
            ),
            title: 'Rewards',
            subtitle: 'Prizes and merch from your favorite creators',
        },
    ]

    const swipeCarousel = (index: number) => {
        setIndex(index)
        carouselMixpanelEvent(index)
    }

    const carouselMixpanelEvent = (index: number) => {
        postTrackingEvent({
            name: 'Viewed intro carousel',
            category: 'Registration',
            onesignal: true,
            properties: {
                'carousel number': index + 1,
            },
        })
    }

    return (
        <SafeAreaView style={tw`flex min-h-full`}>
            <Onboarding
                ref={onboardingRef}
                containerStyles={tw`justify-start `}
                imageContainerStyles={{
                    height: vw(112),
                    ...tw.style('pb-16 m-0 w-full container'),
                }}
                titleStyles={tw`font-ppa-wbi text-4xl`}
                subTitleStyles={tw`font-ppa-b text-pc-shade-100 text-base px-8`}
                bottomBarHighlight={false}
                bottomBarHeight={0}
                pageIndexCallback={swipeCarousel}
                showPagination={false}
                pages={pages}
            />

            <View style={tw`flex flex-row justify-center   `}>
                {pages.map((_page, i) => {
                    return (
                        <View
                            key={i}
                            style={tw`h-2 w-2 rounded-full mx-0.5 ${
                                i === index ? 'bg-black' : 'bg-pc-shade-60'
                            } `}
                        ></View>
                    )
                })}
            </View>

            <View style={tw`container mt-12`}>
                <Button
                    variant="primary"
                    onPress={() => navigation.navigate('Login')}
                    text="Enter"
                    trackableName="Tapped Enter on welcome screen"
                    trackableCategory="Registration"
                    onesignal
                />
                <TrackableClick
                    name="Skipped registration"
                    category="Registration"
                    onesignal
                >
                    <Text
                        onPress={() => navigation.navigate('HomeTabs')}
                        style={tw`mt-6 text-pc-secondary text-center font-ppa-b`}
                    >
                        I’m just browsing
                    </Text>
                </TrackableClick>
            </View>
        </SafeAreaView>
    )
}

export { Welcome }
