import { Text, View } from 'react-native'

import { toPrice } from '@/utils/normalizers'

import tw from '@/tailwind/tailwind'

import { CheckoutTotals } from '../utils/calcCheckoutTotals'

type OrderTotalProps = {
    checkoutTotals: CheckoutTotals
}

export const OrderTotal = ({ checkoutTotals }: OrderTotalProps) => {
    return (
        <View
            style={tw`pt-4 pb-8 px-container flex-row justify-between border-b border-grey-700`}
        >
            <Text style={tw`font-ppp-b text-base text-white`}>Total</Text>
            <Text style={tw`font-ppp-b text-base text-white`}>
                {toPrice(checkoutTotals.totalAfterTip)}
            </Text>
        </View>
    )
}
