import React, { useEffect, useState } from 'react'

import { Image, Text, View } from 'react-native'

import { navigateUsingRef } from '@/navigation/navigateUsingRef'
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'
import { LinearGradient } from 'expo-linear-gradient'
import { SafeAreaView } from 'react-native-safe-area-context'

import ModalHeader from '@/features/Account/components/ModalHeader'
import LootboxTerms from '@/features/LootBox/components/LootboxTerms'
import { LootboxInfoResponse } from '@/features/LootBox/types'

import { Reward } from '@/types/Reward'

import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'

import { useIsMobile } from '@/hooks/useIsMobile'

import { WELCOME_LOOTBOX_ID } from '@/utils/constants'

import tw from '@/tailwind/tailwind'

export const LIGHTER_YELLOW = '#FFFC00'
const DARKER_YELLOW = '#FFC93D'

interface ClaimLootboxModalProps {
    orderId?: string
    lootboxInfo: LootboxInfoResponse
    bsmRef: React.RefObject<BottomSheetModalMethods>
}

export const ClaimLootboxModal =
    // ClaimLootboxModalProps
    ({ orderId, lootboxInfo, bsmRef }: ClaimLootboxModalProps) => {
        const [rewardData, setRewardData] =
            useState<Reward['custom_data']>(null)
        const isWelcomeLootbox = lootboxInfo.reward?.id === WELCOME_LOOTBOX_ID

        const closeModal = () => {
            if (bsmRef.current) bsmRef.current.close()
        }

        useEffect(() => {
            if (lootboxInfo?.reward?.custom_data)
                setRewardData(lootboxInfo.reward.custom_data)
        }, [lootboxInfo])

        const isMobile = useIsMobile()

        const lootboxReward = lootboxInfo.reward

        if (!rewardData || !lootboxReward) return null

        const {
            modal_splash_image_url,
            // fontColor = '#000000',
        } = rewardData

        const headline = isWelcomeLootbox
            ? 'WELCOME TO POPCHEW'
            : 'GIFTS JUST FOR YOU'

        const claimButtonText = isWelcomeLootbox
            ? 'Open A Free Prize'
            : 'Claim Your Lootbox'

        return (
            <BottomSheetModal
                trackableName="claim lootbox"
                trackableCategory="Lootbox"
                trackableProperties={{
                    'lootbox name': lootboxReward.name,
                    'lootbox id': lootboxReward.id,
                }}
                ref={bsmRef}
                snapPoints={isMobile ? ['95%'] : ['75%']}
                backgroundStyle={tw`bg-[${LIGHTER_YELLOW}]`}
            >
                <SafeAreaView edges={['bottom']} style={tw`flex-1`}>
                    <ModalHeader
                        trackableCloseButtonProps={{
                            name: 'Tapped X in lootbox modal',
                            category: 'Lootbox',
                            properties: {
                                isWelcomeLootbox,
                            },
                        }}
                        closeButtonProps={{
                            onPress: closeModal,
                        }}
                        title=" "
                        containerProps={{
                            style: tw`py-0 my-0`,
                        }}
                    />
                    <LinearGradient
                        colors={[LIGHTER_YELLOW, DARKER_YELLOW]}
                        style={tw`w-full h-full`}
                        start={[1.0, 0.0]}
                        end={[1.0, 1.0]}
                    >
                        <Text style={tw`text-center font-ppa-b text-xl pb-2`}>
                            {headline}
                        </Text>
                        <Image
                            source={{
                                uri: modal_splash_image_url,
                            }}
                            style={tw`h-80 z-10`}
                            resizeMode="contain"
                        />
                        <Image
                            source={require('@/assets/images/lootbox/lootbox-wavy-background.png')}
                            style={tw`absolute bottom-0 z-0 w-full h-60`}
                            resizeMode="cover"
                        />
                        <View style={tw`mx-4 pt-8`}>
                            <Button
                                text={claimButtonText}
                                trackableName="Tapped claim your lootbox on modal"
                                trackableCategory="Lootbox"
                                trackableProperties={{
                                    'lootbox name': lootboxReward.name,
                                    'lootbox id': lootboxReward.id,
                                }}
                                variant="primaryStrong"
                                size="default"
                                onPress={() => {
                                    navigateUsingRef('LootboxAnimation', {
                                        ...(orderId && { orderId }),
                                        rewardId: lootboxReward.id,
                                    })
                                }}
                            />
                        </View>
                        {!isWelcomeLootbox && <LootboxTerms />}
                    </LinearGradient>
                </SafeAreaView>
            </BottomSheetModal>
        )
    }
