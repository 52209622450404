import { useCallback } from 'react'

import { navigateUsingRef } from '@/navigation/navigateUsingRef'

import { queryClient } from '@/lib/queryClient'
import { useStore } from '@/lib/store'

import storage from '@/utils/storage'

import { anonAuthorize } from '../api/anonAuthorize'

// doesn't ship in our RN version
Promise.allSettled =
    Promise.allSettled ||
    ((promises: Promise<void>[]) =>
        Promise.all(
            promises.map((p) =>
                p
                    .then((value) => ({
                        status: 'fulfilled',
                        value,
                    }))
                    .catch((reason) => ({
                        status: 'rejected',
                        reason,
                    }))
            )
        ))

export const useLogout = () => {
    const removeSelectedLocation = useStore(
        (state) => state.removeSelectedLocation
    )

    const logout = useCallback(async () => {
        await Promise.allSettled([
            storage.clearNotificationToken(),
            storage.removeAddressId(),
            storage.removeCartId(),
        ])

        removeSelectedLocation()

        await anonAuthorize()

        // clear all queries, ideally we just clear the ones that are related to the user
        // use resetQuries instead of clear so it triggers a rerender
        queryClient.resetQueries()

        navigateUsingRef('HomeTabs')
    }, [])

    return [logout]
}
