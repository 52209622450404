import { Text, TouchableOpacity, View } from 'react-native'

import tw from '@/tailwind/tailwind'

import { VoteButtonStatusType } from '../types'
import { getHighlightColorFromVote } from '../utils/getHighlightColorFromVote'

type VoteButtonProps = {
    text: string
    isSelected: boolean
    buttonType: VoteButtonStatusType
    setSelectedVote: React.Dispatch<
        React.SetStateAction<VoteButtonStatusType | undefined>
    >
}

const VoteButton = ({
    text,
    isSelected,
    setSelectedVote,
    buttonType,
}: VoteButtonProps) => {
    const strokeColor = getHighlightColorFromVote(buttonType)

    return (
        <TouchableOpacity
            onPress={() => setSelectedVote(buttonType)}
            style={tw`bg-pc-shade-20 rounded-full w-20 h-20 justify-center items-center ${
                isSelected ? `border-[${strokeColor}] border-4` : ''
            }`}
        >
            <Text style={tw`text-2xl`}>{text}</Text>
        </TouchableOpacity>
    )
}

type VoteButtonsProps = {
    selectedVote: VoteButtonStatusType | undefined
    setSelectedVote: React.Dispatch<
        React.SetStateAction<VoteButtonStatusType | undefined>
    >
}
export const VoteButtons = ({
    selectedVote,
    setSelectedVote,
}: VoteButtonsProps) => {
    const isSelected = (buttonType: VoteButtonStatusType) =>
        buttonType === selectedVote

    return (
        <View style={tw`flex-row justify-center items-center`}>
            <VoteButton
                text="😭💔"
                buttonType="dislike"
                setSelectedVote={setSelectedVote}
                isSelected={isSelected('dislike')}
            />
            <Text style={tw`mx-4 font-ppa-b text-pc-shade-80`}>or</Text>

            <VoteButton
                text="🔥🤌"
                buttonType="like"
                setSelectedVote={setSelectedVote}
                isSelected={isSelected('like')}
            />
        </View>
    )
}
