import { StoreSlice, StoreState } from '@/lib/store'

export interface DevToolsState {
    devTools: {
        useCash: boolean | undefined
        setUseCash: (useCash: boolean | undefined) => void
    }
}

export const createDevToolsSlice: StoreSlice<DevToolsState> = (set) => ({
    devTools: {
        useCash: false,
        setUseCash: async (useCash: boolean | undefined) => {
            set((state: StoreState) => {
                state.devTools.useCash = useCash
                return state
            })
        },
    },
})
