import { GooglePlaceDetail } from 'react-native-google-places-autocomplete'

import { Address, StateAbbreviation } from '@/types/Address'

export const convertGooglePlaces = (details: GooglePlaceDetail) => {
    const { address_components, geometry } = details

    const address: Partial<Address> = {}

    // Add address components
    let address1 = ''
    for (const component of address_components) {
        if (component.types.includes('street_number')) {
            address1 = component.short_name + ' ' + address1
            continue
        }
        if (component.types.includes('route')) {
            address1 = address1 + component.short_name
            continue
        }
        if (component.types.includes('locality')) {
            address.city = component.short_name
            continue
        }
        if (component.types.includes('sublocality_level_1')) {
            address.city = component.short_name
            continue
        }
        if (component.types.includes('postal_code')) {
            address.zip = component.short_name
            continue
        }
        if (component.types.includes('administrative_area_level_1')) {
            address.state = component.short_name as StateAbbreviation
            continue
        }
    }

    // Lattitude and Longitude
    address.lat = geometry.location.lat.toString()
    address.lon = geometry.location.lng.toString()
    address.address1 = address1.trim()

    return address as Address
}
