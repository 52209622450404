import { useMutation, useQueryClient } from 'react-query'

import { Address } from '@/types/Address'
import { GetProfileResponse } from '@/types/User'

import { axios } from '@/lib/httpClient'
import { sendOneSignalOutcome } from '@/lib/onesignal'

export const useUpdateAddress = () => {
    const queryClient = useQueryClient()
    return useMutation(
        async (updatedAddress: Address) => {
            const response = await axios.patch<Address>(
                '/order/address',
                updatedAddress
            )
            return response.data
        },
        {
            onMutate: (updatedAddress) => {
                const profile =
                    queryClient.getQueryData<GetProfileResponse>('profile')

                queryClient.setQueryData('profile', {
                    ...(profile || {}),
                    addresses: (profile?.addresses || []).map((address) => {
                        if (address.address_id === updatedAddress.address_id) {
                            return {
                                ...address,
                                ...updatedAddress,
                            }
                        }

                        return address
                    }),
                })

                return () => queryClient.setQueryData('profile', profile)
            },
            onError: (_, __, rollback: any) => {
                if (rollback) {
                    rollback()
                }
            },

            onSuccess: () => {
                sendOneSignalOutcome('Updated user address')
                return queryClient.invalidateQueries('profile')
            },
        }
    )
}
