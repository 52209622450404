import React from 'react'

import { navigationRef } from '@/navigation/navigateUsingRef'
import { BottomSheetModal as BSM } from '@gorhom/bottom-sheet'

import { OrderPlacedView } from '@/features/OrderPlaced/components/OrderPlacedView'

import { BottomSheetModal } from '@/components/BottomSheetModal'

interface OrderProps {
    orderId: string | undefined
}

export const OrderScreen = React.forwardRef<BSM, OrderProps>(
    ({ orderId }, ref) => {
        const onXPress = () => {
            ;(ref as React.MutableRefObject<BSM>)?.current?.dismiss()
        }

        const content = orderId ? (
            <OrderPlacedView
                orderId={orderId}
                isModal
                closeModal={onXPress}
                navigation={navigationRef.current}
            />
        ) : (
            <></>
        )

        return (
            <BottomSheetModal
                trackableName="past order"
                trackableCategory="Account"
                ref={ref}
                snapPoints={['90%']}
                handleComponent={null}
            >
                {content}
            </BottomSheetModal>
        )
    }
)
