export const fonts = {
    'PPAgrandir-Regular': require('assets/fonts/PPAgrandir-Regular.otf'),
    'PPAgrandir-TextBold': require('assets/fonts/PPAgrandir-TextBold.otf'),
    'PPAgrandir-WideBlackItalic': require('assets/fonts/PPAgrandir-WideBlackItalic.otf'),
    'PPAgrandir-GrandHeavy': require('assets/fonts/PPAgrandir-GrandHeavy.otf'),
    'PPPangramSansRounded-SemiBold': require('assets/fonts/PPPangramSansRounded-SemiBold.otf'),
    'PPPangramSansRounded-Bold': require('assets/fonts/PPPangramSansRounded-Bold.otf'),
    'PPPangramSansRounded-Regular': require('assets/fonts/PPPangramSansRounded-Regular.otf'),
    'PPPangramSansRounded-Medium': require('assets/fonts/PPPangramSansRounded-Medium.otf'),
    'MonumentExtended-Black': require('assets/fonts/MonumentExtended-Black.otf'),
}
