import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const TabHomeSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={26}
        height={26}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M.103 11.596a3 3 0 0 1 1.165-2.571L11.78.938a2 2 0 0 1 2.438 0l10.513 8.087a3 3 0 0 1 1.165 2.57l-.656 10.162A4 4 0 0 1 21.25 25.5H17.5a1 1 0 0 1-1-1V17a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v7.5a1 1 0 0 1-1 1H4.75A4 4 0 0 1 .76 21.758L.103 11.595Z"
            fill={color}
        />
    </Svg>
)

export default TabHomeSvg
