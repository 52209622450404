import { useEffect, useState } from 'react'

import { MAINTENANCE_MODE } from '@env'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import FlashMessage from 'react-native-flash-message'
import {
    GestureHandlerRootView,
    enableExperimentalWebImplementation,
} from 'react-native-gesture-handler'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { QueryClientProvider } from 'react-query'

import tw from '@/tailwind/tailwind'

import { Navigation } from './Navigation'
import { WebBorder } from './WebBorder'
import { Notification } from './components/Notification'
import { OrderStatusTopbar } from './components/OrderStatusTopbar'
import { AccountModalProvider } from './contexts/AccountModalContext'
import { MaintenanceMode } from './features/MaintenanceMode'
import { Prefetcher } from './lib/Prefetcher'
import { enableAppMonitoring } from './lib/appMonitoring'
import { enableOneSignal } from './lib/onesignal'
import { queryClient } from './lib/queryClient'
import { clearStorageIfEnvChange } from './utils/clearStorageIfEnvChange'
import { enableHotJar } from './utils/enableHotJot'
import { enableSessionReplay } from './utils/enableSessionReplay'
import { fonts } from './utils/fonts'

String(MAINTENANCE_MODE) // required to fix runtime issue with env var
// https://github.com/goatandsheep/react-native-dotenv/issues/199
const isInMaintenanceMode = MAINTENANCE_MODE === 'true'

SplashScreen.preventAutoHideAsync()

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (process.browser) {
    // @ts-ignore
    window._frameTimestamp = null
}

function App() {
    const [appIsReady, setAppIsReady] = useState(false)
    const [fontsLoaded] = useFonts(fonts)

    useEffect(() => {
        enableAppMonitoring()
        enableSessionReplay()
        enableOneSignal()
        enableHotJar()

        // stops touchable clicks from bubbling up
        // https://github.com/software-mansion/react-native-gesture-handler/issues/2032#issuecomment-1253659658
        enableExperimentalWebImplementation()
    }, [])

    // Clear storage on mobile if changing between dev enviornemnts
    // because some storage is retained when app is deleted
    useEffect(() => {
        const load = async () => {
            await clearStorageIfEnvChange()
        }

        load()
    }, [])

    useEffect(() => {
        const hide = async () => {
            if (appIsReady && fontsLoaded) {
                await SplashScreen.hideAsync()
            }
        }

        hide()
    }, [appIsReady, fontsLoaded])

    let content = (
        <QueryClientProvider client={queryClient}>
            <SafeAreaProvider>
                <FlashMessage
                    position="top"
                    MessageComponent={Notification}
                    autoHide
                    duration={3000}
                />
                <GestureHandlerRootView style={tw`flex-1`}>
                    <BottomSheetModalProvider>
                        <AccountModalProvider>
                            <Prefetcher setAppIsReady={setAppIsReady}>
                                <OrderStatusTopbar>
                                    <Navigation />
                                </OrderStatusTopbar>
                            </Prefetcher>
                        </AccountModalProvider>
                    </BottomSheetModalProvider>
                </GestureHandlerRootView>
            </SafeAreaProvider>
        </QueryClientProvider>
    )

    if (isInMaintenanceMode) {
        content = <MaintenanceMode />
    }

    return <WebBorder>{content}</WebBorder>
}

export default App
