import { Image, Text, View } from 'react-native'

import { CartProps } from '@/navigation/types/ScreenProps'

import { TrackableRender } from '@/components/Analytics'
import { Button } from '@/components/Button'

import tw from '@/tailwind/tailwind'

type NoCartItemsProps = {
    navigation: CartProps['navigation']
}

// A component to show whenever cart is empty
const NoCartItems = ({ navigation }: NoCartItemsProps) => {
    return (
        <TrackableRender name="Viewed No Cart Items screen" category="Checkout">
            <View style={tw`flex min-h-full`}>
                <View style={tw`items-center justify-center flex-1 mb-30`}>
                    <Image
                        style={tw`w-33 h-35`}
                        source={require('@/assets/empty-cart-error-min.jpg')}
                    />
                    <Text style={tw`font-ppa-wbi text-2xl mt-5 text-center`}>
                        Your cart is empty
                    </Text>
                    <Text
                        style={tw`font-ppa-b text-pc-shade-80 mt-5 text-center`}
                    >
                        No items in your cart.
                    </Text>
                    <View style={tw`mt-7`}>
                        <Button
                            variant="primary"
                            onPress={() =>
                                navigation.navigate('HomeTabs', {
                                    screen: 'Brands',
                                })
                            }
                            text="Browse Food"
                            trackableName="Tapped browse food with no cart items"
                            trackableCategory="Checkout"
                        />
                    </View>
                </View>
            </View>
        </TrackableRender>
    )
}

export { NoCartItems }
