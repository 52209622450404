import { useEffect, useMemo, useRef, useState } from 'react'

import { ScrollView, View } from 'react-native'

import { BrandScreenProps } from '@/navigation/types/ScreenProps'
import Animated from 'react-native-reanimated'

import {
    useDefaultMenu,
    useMenu,
} from '@/features/BrandPage/screens/Menu/api/useMenu'

import { Instance } from '@/types/Instance'
import { ExtendedMenuSection } from '@/types/Menu'

import { useQueryState } from '@/lib/queryClient'
import { useStore } from '@/lib/store'

import tw from '@/tailwind/tailwind'

import { HandleContentChangeType, HandleScrollEventType } from '../..'
import { SectionIsLoading } from '../../components/SectionIsLoading'
import { StickyHeader } from '../../components/wrappers/StickyHeader'
import { MenuSectionGrid } from './components/MenuSectionGrid'
import { TabBar } from './components/TabBar'
import { ViewCartButton } from './components/ViewCartButton'

export const Menu = ({
    navigation,
    route,
    pageHeight,
    viewWidth,
    scroll,
    top,
    bottom,
    handleScroll,
    handleContentSizeChange,
    showPickupDeliveryModal,
    resetSection,
    isInstancesLoading,
    tabBarHeight,
}: {
    navigation: BrandScreenProps['navigation']
    route: BrandScreenProps['route']
    pageHeight: number
    viewWidth: number
    scroll: Animated.SharedValue<number>
    handleScroll: HandleScrollEventType
    handleContentSizeChange: HandleContentChangeType
    showPickupDeliveryModal: () => void
    top: number
    bottom: number
    resetSection: () => void
    isInstancesLoading: boolean
    tabBarHeight: number
}) => {
    const { brandId } = route.params || {}
    const deliveryType = useStore((state) => state.location.deliveryType)

    const { data: selectedInstance } = useQueryState<Instance>([
        'selectedInstance',
        brandId,
        deliveryType,
    ])

    const sectionGridRef = useRef<any>(null)
    const scrollHeader = useRef<ScrollView>(null)
    const scrollHeaderParent = useRef<View>(null)
    const scrollHeaderItems = useRef<(View | null)[]>([])
    const [focusedSectionIndex, setFocusedSectionIndex] = useState(0)
    const [blockUpdateIndex, setBlockUpdateIndex] = useState(false)

    const { data: defaultMenu, isLoading: isLoadingDefaultMenu } =
        useDefaultMenu(brandId)

    const { data: realMenu, isLoading: isLoadingRealMenu } = useMenu(
        selectedInstance?.instance_id
    )

    const menu = realMenu ?? defaultMenu

    const isExampleMenu = !realMenu

    const menuSections: ExtendedMenuSection[] =
        (isExampleMenu ? defaultMenu : realMenu)?.sections
            .map((section, idx) => ({
                ...section,
                index: idx,
                data: section.items,
                title: section.name,
            }))
            .sort((section) => (section.is_bdot_featured ? -1 : 1)) ?? []

    const isLoading = useMemo(
        () => isLoadingRealMenu || isLoadingDefaultMenu || isInstancesLoading,
        [isLoadingDefaultMenu, isLoadingRealMenu, isInstancesLoading]
    )
    useEffect(() => {
        if (isLoading) resetSection()
    }, [isLoading, resetSection])

    const menuContent = (
        <>
            {/* menu navbar */}
            <StickyHeader
                scroll={scroll}
                top={top}
                styles={tw`z-10`}
                tabBarHeight={tabBarHeight}
            >
                <TabBar
                    menuSections={menuSections}
                    sectionGridRef={sectionGridRef}
                    scrollHeader={scrollHeader}
                    scrollHeaderItems={scrollHeaderItems}
                    focusedSectionIndex={focusedSectionIndex}
                    setBlockUpdateIndex={setBlockUpdateIndex}
                    setFocusedSectionIndex={setFocusedSectionIndex}
                    top={top}
                    scrollHeaderParent={scrollHeaderParent}
                />
            </StickyHeader>

            {/* menu content / grid */}
            <MenuSectionGrid
                navigation={navigation}
                handleScroll={handleScroll}
                handleContentSizeChange={handleContentSizeChange}
                top={top}
                setBlockUpdateIndex={setBlockUpdateIndex}
                blockUpdateIndex={blockUpdateIndex}
                focusedSectionIndex={focusedSectionIndex}
                setFocusedSectionIndex={setFocusedSectionIndex}
                scrollHeader={scrollHeader}
                scrollHeaderItems={scrollHeaderItems}
                sectionGridRef={sectionGridRef}
                menuSections={menuSections}
                instanceId={selectedInstance?.instance_id ?? ''}
                instanceName={selectedInstance?.instance_name ?? ''}
                menuId={menu?.menu_id ?? ''}
                additionalTopSpacing={64}
                pageHeight={pageHeight}
                viewWidth={viewWidth}
                scrollHeaderParent={scrollHeaderParent}
                bottom={bottom}
                isExampleMenu={isExampleMenu}
                showPickupDeliveryModal={showPickupDeliveryModal}
                tabBarHeight={tabBarHeight}
            />

            {/* View Cart button */}
            <ViewCartButton navigation={navigation} />
        </>
    )

    const loadingContent = (
        <SectionIsLoading
            sectionName="menu"
            color="black"
            tabBarHeight={tabBarHeight}
        />
    )

    if (isLoadingRealMenu) return loadingContent
    else if (menuSections?.length === 0 || !selectedInstance) {
        if (isLoadingDefaultMenu) return loadingContent
        else return menuContent
    } else return menuContent
}
