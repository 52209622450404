import { useCallback, useState } from 'react'

import {
    ActivityIndicator,
    KeyboardAvoidingView,
    Platform,
    SafeAreaView,
    ScrollView,
    Text,
    TextInput,
    View,
} from 'react-native'

import { useNavigateBack } from '@/navigation/hooks/useNavigateBack'
import { EditLocationProps } from '@/navigation/types/ScreenProps'
import { useFocusEffect } from '@react-navigation/native'

import { Address } from '@/types/Address'

import { TrackableClick } from '@/components/Analytics'
import { Button } from '@/components/Button'
import { BackButton } from '@/components/Titles/BackButton'

import { useStore } from '@/lib/store'

import tw from '@/tailwind/tailwind'

import { useCreateAddress } from '../../api/useCreateAddress'
import { useUpdateAddress } from '../../api/useUpdateAddress'
import { useSetLocationWithClearCart } from '../../utils/useSetLocationWithClearCart'

export const EditLocation = ({ navigation, route }: EditLocationProps) => {
    const { isEdit } = route.params || {}
    const navigateBack = useNavigateBack()

    const deliveryType = useStore((state) => state.location.deliveryType)
    const tempLocation = useStore((state) => state.location.tempLocation)
    const setTempLocation = useStore((state) => state.setTempLocation)
    const [location, setLocation] = useState<Address>(
        tempLocation || ({} as Address)
    )
    const { address1, address2 = '', city, state, zip } = location

    const { mutateAsync: createAddress, isLoading: isCreateLoading } =
        useCreateAddress()
    const { mutateAsync: mutateUpdateAddress, isLoading: isUpdateLoading } =
        useUpdateAddress()

    const setLocationWithClearCart = useSetLocationWithClearCart()

    useFocusEffect(
        useCallback(() => {
            if (isEdit === undefined) navigation.navigate('Location Picker')
        }, [isEdit])
    )

    useFocusEffect(
        useCallback(() => {
            if (!tempLocation) return navigation.navigate('Location Picker')
            setLocation(tempLocation)
        }, [tempLocation])
    )

    const onDonePress = async () => {
        if (!isEdit) {
            const data = await createAddress({
                ...location,
                fulfillment_type: deliveryType,
            })
            await setLocationWithClearCart({
                ...location,
                address_id: data.user_address_id,
            })

            navigation.navigate('HomeTabs', {
                screen: 'Brands',
            })
        } else {
            await mutateUpdateAddress(location)
            navigateBack('Location Picker')
        }

        setTempLocation(null)
    }

    const handleInputChange = (inputName: string) => (inputValue: string) => {
        setLocation((currentLocation) => ({
            ...currentLocation,
            [inputName]: inputValue,
        }))
    }

    if (!tempLocation || isEdit === undefined) return null

    return (
        <SafeAreaView style={tw`flex-1`}>
            <KeyboardAvoidingView
                style={tw`flex-1`}
                {...(Platform.OS === 'ios' ? { behavior: 'padding' } : {})}
            >
                <ScrollView>
                    <View style={tw`container`}>
                        <BackButton
                            fallback="Location Picker"
                            title="Address Info"
                            legacyTrackableName="Tapped back arrow on address info screen"
                        />
                    </View>
                    <View style={tw`container`}>
                        <Text style={tw`text-lg font-ppa-b`}>
                            {address1}
                            {address2 ? ' ' + address2 : ''} {city}, {state}{' '}
                            {zip}
                        </Text>
                        <TextInput
                            testID="address-input-apt-suite-floor"
                            style={tw`input mt-4`}
                            placeholder="Apt/Suite/Floor"
                            value={location?.address2 || ''}
                            onChangeText={handleInputChange('address2')}
                            onSubmitEditing={onDonePress}
                        />
                        <TextInput
                            testID="address-input-business-building-name"
                            style={tw`input mt-2.5`}
                            placeholder="Business or building name"
                            value={location?.building_name || ''}
                            onChangeText={handleInputChange('building_name')}
                            onSubmitEditing={onDonePress}
                        />
                        <TextInput
                            testID="address-input-nickname"
                            style={tw`input mt-2.5`}
                            placeholder="Address nickname"
                            value={location?.name || ''}
                            onChangeText={handleInputChange('name')}
                            onSubmitEditing={onDonePress}
                        />
                        <View
                            style={tw`border-b border-pc-shade-40 mt-4`}
                        ></View>
                        <Text style={tw`label mt-2.5`}>
                            Delivery instructions
                        </Text>
                        <TextInput
                            testID="address-input-delivery-instructions"
                            style={tw`input `}
                            placeholder="e.g leave at door"
                            value={location?.delivery_instructions || ''}
                            onChangeText={handleInputChange(
                                'delivery_instructions'
                            )}
                            onSubmitEditing={onDonePress}
                        />
                    </View>
                </ScrollView>
                <View
                    style={tw`px-container py-4 bg-white border-t border-pc-shade-60`}
                >
                    {isCreateLoading || isUpdateLoading ? (
                        <ActivityIndicator style={tw`py-5`} />
                    ) : (
                        <Button
                            variant="primary"
                            onPress={onDonePress}
                            text="Done"
                            trackableName="Tapped Done on Edit Location screen"
                            trackableCategory="Address"
                            testID="address-done-btn"
                        />
                    )}
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    )
}
