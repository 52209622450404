import { useEffect, useRef, useState } from 'react'

import { TouchableOpacity, View } from 'react-native'

import { BottomSheetScrollView } from '@gorhom/bottom-sheet'
import bottomSheetModal from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetModal'
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types'

import { TrackableClick } from '@/components/Analytics'
import { BottomSheetModal } from '@/components/BottomSheetModal'
import { Button } from '@/components/Button'
import { ModalX } from '@/components/Titles/ModalX'

import { useProfile } from '@/hooks/query/useProfile'

import tw from '@/tailwind/tailwind'

import CheckSvg from '@/svgs/CheckSvg'

import { HiddenPaymentMethod, PaymentMethodResponse } from '../types'
import { CardDetails } from './CardDetails'
import { CreatePaymentMethodModal } from './CreatePaymentMethodModal'

export const SelectPaymentMethod = ({
    closeModal,
    setPaymentMethod,
    selectedPaymentMethod,
    bsmRef,
}: {
    closeModal: () => void
    setPaymentMethod: (
        paymentMethod: PaymentMethodResponse | HiddenPaymentMethod
    ) => void
    selectedPaymentMethod: PaymentMethodResponse | HiddenPaymentMethod | null
    bsmRef: React.RefObject<BottomSheetModalMethods>
}) => {
    const { refetch: refetchProfile, data: profile } = useProfile()
    const [paymentMethods, setPaymentMethods] = useState<HiddenPaymentMethod[]>(
        profile?.payment_methods ?? []
    )
    useEffect(() => {
        setPaymentMethods(profile?.payment_methods ?? [])
    }, [profile])

    useEffect(() => {
        if (paymentMethods.length === 0) showCreatePaymentMethodScreen()
    }, [paymentMethods])

    const paymentMethodBottomSheetRef = useRef<bottomSheetModal>(null)
    const showCreatePaymentMethodScreen = () =>
        paymentMethodBottomSheetRef.current?.present()
    const hidePaymentMethodScreen = () => {
        paymentMethodBottomSheetRef.current?.close()
        refetchProfile()
    }

    return (
        <BottomSheetModal
            ref={bsmRef}
            snapPoints={['80%']}
            handleComponent={null}
            keyboardBehavior="interactive"
            trackableName="select payment method"
            trackableCategory="Checkout"
            isDark
        >
            <View style={tw`flex-1 container`}>
                {/* <View style={tw`flex-row items-center mb-7 `}>
                    <Text style={tw`ml-3 font-ppa-b text-xl `}>
                    Select Payment Method
                    </Text>
                </View> */}

                <ModalX
                    onPress={closeModal}
                    name="Tapped X on select payment method screen"
                    category="Checkout"
                    title="Select Payment Method"
                    color="white"
                />

                <BottomSheetScrollView showsVerticalScrollIndicator={false}>
                    {paymentMethods.map((pMethod, idx: number) => {
                        const isLast = idx === paymentMethods.length - 1
                        const isSelected =
                            selectedPaymentMethod &&
                            selectedPaymentMethod.payment_method_id ===
                                pMethod.payment_method_id

                        return (
                            <View
                                key={pMethod.payment_method_id}
                                style={tw`border-pc-shade-40 ${
                                    !isLast ? 'border-b' : ''
                                }`}
                            >
                                <TrackableClick
                                    name="Tapped a payment method on select payment screen"
                                    category="Checkout"
                                >
                                    <TouchableOpacity
                                        onPress={() =>
                                            setPaymentMethod(pMethod)
                                        }
                                        style={tw`flex-row justify-between items-center py-6`}
                                    >
                                        <CardDetails paymentMethod={pMethod} />

                                        {isSelected ? (
                                            <CheckSvg color="white" />
                                        ) : null}
                                    </TouchableOpacity>
                                </TrackableClick>
                            </View>
                        )
                    })}
                    <View style={tw`mt-4`}>
                        <Button
                            variant="action"
                            onPress={() => showCreatePaymentMethodScreen()}
                            text="Add New"
                            trackableName="Tapped add payment method on select payment screen"
                            trackableCategory="Checkout"
                        />
                    </View>
                </BottomSheetScrollView>

                <BottomSheetModal
                    ref={paymentMethodBottomSheetRef}
                    snapPoints={['80%']}
                    handleComponent={null}
                    keyboardBehavior="interactive"
                    trackableName="account payments"
                    trackableCategory="Account"
                >
                    <CreatePaymentMethodModal
                        closeModal={hidePaymentMethodScreen}
                        closeParentModal={closeModal}
                        setPaymentMethod={setPaymentMethod}
                    />
                </BottomSheetModal>
            </View>
        </BottomSheetModal>
    )
}
