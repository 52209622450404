import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const LargeCheckSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={135}
        height={104}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="m12 59.571 31.714 31.715L123 12"
            stroke="#3BCA8F"
            strokeWidth={23.786}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default LargeCheckSvg
