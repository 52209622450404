import { useState } from 'react'

import { Order, SubmitOrderResponse } from '@/types/Order'

import { showErrorMessage } from '@/utils/showMessage'

import { useApplyCouponToOrder } from './useApplyCouponToOrder'
import { useCreateOrder } from './useCreateOrder'
import { useSubmitOrder } from './useSubmitOrder'

export const usePlaceOrder = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const createOrder = useCreateOrder()
    const applyCoupon = useApplyCouponToOrder((message: string) => {
        if (message) showErrorMessage(message)
    })
    const submitOrder = useSubmitOrder()

    const placeOrder = async (order: Order): Promise<SubmitOrderResponse> => {
        setIsLoading(true)
        try {
            // Create order
            let workingOrder = await createOrder(order)

            // Apply voucher if there's a coupon code
            if (order.coupon?.code)
                workingOrder = await applyCoupon(workingOrder)

            // Submit order
            return await submitOrder(workingOrder)
        } finally {
            setIsLoading(false)
        }
    }

    return {
        placeOrder,
        isLoading,
    }
}
