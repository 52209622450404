import { useState } from 'react'

import { Switch, Text, View } from 'react-native'

import { GeneralProps } from '@/navigation/types/ScreenProps'
import { useNavigation } from '@react-navigation/native'

import { Brand } from '@/types/Brand'
import { Order } from '@/types/Order'

import { Button } from '@/components/Button'

import { useAllBrands } from '@/hooks/query/useAllBrands'
import { useProfile } from '@/hooks/query/useProfile'

import tw from '@/tailwind/tailwind'

const SCREENS = ['OrderPlaced', 'LootboxAnimation', 'Review Order']

// const dallinDoritosOrderId = '6e32fb37b45f4be3a8fd5bf7e719abf3'
// const ryanOrderId = 'fc7eb5f1402011ed8d6c3b54bc8a7cc9'

export const DeveloperOrderPages = () => {
    const navigation = useNavigation<GeneralProps['navigation']>()

    const [flipdishOrdersOnly, setFlipdishOrdersOnly] = useState<boolean>(false)
    const [selectedScreen, setSelectedScreen] = useState<string>(SCREENS[0])
    const { data: brands } = useAllBrands()
    const { data: profile } = useProfile()
    const { orders = [] } = profile || {}

    const getOrderIdFromOrdersAndBrand = (brand: Brand, orders: Order[]) => {
        const order = orders.find(
            (order) =>
                order.brand_id === brand.id &&
                (!flipdishOrdersOnly || order.flipdish_order_id)
        )

        return order?.popchew_order_id || null
    }

    return (
        <View style={tw`flex-col items-center text-center border p-2`}>
            <Text style={tw`font-ppa-b mb-2 text-lg`}>Go to screen</Text>
            <View style={tw`flex-row w-full justify-between`}>
                {SCREENS.map((screen, index) => (
                    <Button
                        key={index}
                        variant={
                            selectedScreen === screen ? 'info' : 'secondary'
                        }
                        size="xs"
                        text={screen}
                        onPress={() => setSelectedScreen(screen)}
                        trackableCategory="Developer Tools"
                        trackableName="Selected screen in developer order pages"
                    />
                ))}
            </View>
            <Text style={tw`font-ppa-b text-lg pt-2`}>
                With order from brand
            </Text>
            <View style={tw`flex-row w-full pt-2 justify-between`}>
                {brands?.map((brand) => {
                    const orderId = getOrderIdFromOrdersAndBrand(brand, orders)
                    return (
                        <Button
                            key={brand.id}
                            variant="primary"
                            size="xs"
                            text={brand.name.substring(0, 3)}
                            onPress={() =>
                                navigation.navigate(selectedScreen, {
                                    orderId: orderId || '', // 'ce8173076db94ca99535480110bf62c8'
                                })
                            }
                            disabled={!orderId}
                            trackableCategory="Developer Tools"
                            trackableName="Tapped "
                        />
                    )
                })}
            </View>
            <Text style={tw`font-ppa-reg pt-2 text-xs`}>
                If button is gray you don't have orders from that brand
            </Text>
            <Text style={tw`mr-3`}>Only orders in flipdish</Text>
            <Switch
                value={flipdishOrdersOnly}
                onValueChange={setFlipdishOrdersOnly}
            />
        </View>
    )
}
