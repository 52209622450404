import { useEffect, useMemo, useState } from 'react'

import {
    ActivityIndicator,
    Image,
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'

import { RootStackScreenProps } from '@/navigation/types/ScreenProps'
import * as Clipboard from 'expo-clipboard'
import { SafeAreaView } from 'react-native-safe-area-context'

import { TrackableClick, TrackableRender } from '@/components/Analytics'
import { Button } from '@/components/Button'

import { useBrand } from '@/hooks/query/useBrand'

import { WING_SZN_BRAND_ID } from '@/utils/constants'
import { showMessage } from '@/utils/showMessage'

import tw from '@/tailwind/tailwind'

import { postStreetTeam } from '../../api'

type OptionProps = {
    favoritePart: string
    onSubmit: (favoritePart: string) => () => void
}

const Option = ({ onSubmit, favoritePart }: OptionProps) => {
    return (
        <TrackableClick
            category="General"
            name="tapped option on street team form"
            properties={{ favoritePart }}
        >
            <TouchableOpacity
                onPress={onSubmit(favoritePart)}
                style={tw`bg-grey-700 rounded-md mt-3`}
            >
                <Text style={tw`text-white text-center text-xs p-3`}>
                    {favoritePart}
                </Text>
            </TouchableOpacity>
        </TrackableClick>
    )
}

export const StreetTeamFormPartTwo = ({
    navigation,
    route,
}: RootStackScreenProps<'Street Team Confirm'>) => {
    const { first, last, email, zip, phone, brandId, socialHandle } =
        route.params
    const { data: brand } = useBrand(brandId)
    const favoriteParts = useMemo(
        () => [
            `Earn cash for promoting ${brand?.name}`,
            `Getting access to exclusive content from ${brand?.creator_name}`,
            `Competing with other ${brand?.creator_name} fans for rewards`,
            `Being able to chat with ${brand?.creator_name}`,
            `Getting access to free ${brand?.name} and discounts`,
            `None of the above - I just want to support ${brand?.creator_name}`,
        ],

        [brand]
    )
    const isWingSzn = brand?.id === WING_SZN_BRAND_ID
    const color = isWingSzn ? '#F6401B' : '#FCE53E'
    const textColor = isWingSzn ? 'white' : 'black'
    const mainImage = isWingSzn
        ? require('@/assets/images/street-team/wing-szn-cover.webp')
        : require('@/assets/images/street-team/snapback-cover.webp')
    const required = [first, last, email, zip, phone, brandId]
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = (favoritePart: string) => async () => {
        const data = {
            first,
            last,
            email,
            phone,
            zip,
            social_handle: socialHandle,
            brand_id: brandId,
            favorite_part: favoritePart,
        }

        setIsLoading(true)
        try {
            await postStreetTeam(data)
        } catch {}
        setHasSubmitted(true)
        setIsLoading(false)
    }

    const copyToClipboard = async () => {
        await Clipboard.setStringAsync('STREET15')
        showMessage({ message: 'Code copied to clipboard', type: 'success' })
    }

    useEffect(() => {
        required.some((item) => !item) && navigation.goBack()
    }, [])
    if (required.some((item) => !item)) return null

    return (
        <TrackableRender
            category="General"
            name="Viewed 2nd step of street team form"
        >
            <View style={tw`flex-1 bg-black`}>
                <ScrollView style={tw`pb-16`}>
                    <SafeAreaView
                        style={tw`bg-[${color}] rounded-b-4xl pb-16`}
                        edges={['top']}
                    >
                        <Image style={tw`w-full h-72`} source={mainImage} />

                        {!hasSubmitted && (
                            <>
                                <Text
                                    style={tw`text-${textColor} text-2xl font-ppp-m text-center`}
                                >
                                    Thanks for Applying
                                </Text>
                                <Text
                                    style={tw`font-ppp-m text-base text-${textColor} text-center px-container leading-tight mt-3`}
                                >
                                    We’ll notify you when the {brand?.name}{' '}
                                    Street Team launches in your area
                                </Text>
                            </>
                        )}
                    </SafeAreaView>

                    {!hasSubmitted ? (
                        <SafeAreaView edges={['bottom']}>
                            <Text
                                style={tw`text-white text-2xl font-ppp-m text-center mt-8`}
                            >
                                One last step
                            </Text>
                            <Text
                                style={tw`font-ppp-m text-base text-white text-center px-container leading-tight mt-3`}
                            >
                                What part of the Street Team is most appealing
                                to you?
                            </Text>
                            <View style={tw`mt-8 relative`}>
                                {isLoading && (
                                    <View
                                        style={tw`bg-black absolute w-full h-full left-0 right-0 z-10 flex justify-center items-center`}
                                    >
                                        <ActivityIndicator color="white" />
                                    </View>
                                )}
                                <View style={tw`px-container`}>
                                    {favoriteParts.map((part) => (
                                        <Option
                                            key={part}
                                            onSubmit={handleSubmit}
                                            favoritePart={part}
                                        />
                                    ))}
                                </View>
                            </View>
                        </SafeAreaView>
                    ) : (
                        <View style={tw`flex-1 h-full px-container`}>
                            <Text
                                style={tw`text-white font-ppp-m text-2xl text-center mt-8`}
                            >
                                Thank you!
                            </Text>
                            <Text
                                style={tw`text-white font-ppp-m text-2xl text-center mt-4`}
                            >
                                Here's a $15 OFF code to try {brand?.name}
                            </Text>
                            <Text
                                style={tw`text-white font-ppp-m text-2xl text-center mt-8`}
                            >
                                use code:
                            </Text>
                            <TouchableOpacity
                                onPress={copyToClipboard}
                                style={tw`relative self-center`}
                            >
                                <Text
                                    style={[
                                        tw`text-white font-ppp-m text-2xl text-center border-dashed border-4 border-spacing-[10px] border-white py-3 px-8 mt-2 self-center`,
                                    ]}
                                >
                                    STREET15
                                </Text>
                                <Text
                                    style={tw`text-white text-center text-xs absolute bottom-2 self-center`}
                                >
                                    click to copy
                                </Text>
                            </TouchableOpacity>
                        </View>
                    )}
                </ScrollView>
                {hasSubmitted && (
                    <SafeAreaView
                        style={tw`mt-auto px-container mb-4`}
                        edges={['bottom']}
                    >
                        <Button
                            text="View Menu"
                            onPress={() =>
                                navigation.navigate('BrandPage', { brandId })
                            }
                            variant="action"
                            trackableCategory="General"
                            trackableName="tapped view menu on street team success"
                        />
                    </SafeAreaView>
                )}
            </View>
        </TrackableRender>
    )
}
