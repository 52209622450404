import AsyncStorage from '@react-native-async-storage/async-storage'

import { queryClient } from '@/lib/queryClient'

import { isomorphicStorage } from '../lib/isomorphicStorage'

const storagePrefix = 'popapp_'

const getToken = async (key: string) => {
    return isomorphicStorage.getItem(`${storagePrefix}${key}`)
}

const removeToken = async (key: string) => {
    await isomorphicStorage.removeItem(`${storagePrefix}${key}`)
}

const setToken = async (key: string, value: string) => {
    await isomorphicStorage.setItem(`${storagePrefix}${key}`, value)
}

const getDeviceId = async () => {
    return AsyncStorage.getItem(`${storagePrefix}device_id`)
}

const removeDeviceId = async () => {
    return AsyncStorage.removeItem(`${storagePrefix}device_id`)
}

const setDeviceId = async (deviceId: string) => {
    return AsyncStorage.setItem(`${storagePrefix}device_id`, deviceId)
}

const getCartId = async () => {
    return AsyncStorage.getItem(`${storagePrefix}cart_id`)
}
const setCartId = async (cartId: string) => {
    return AsyncStorage.setItem(`${storagePrefix}cart_id`, cartId)
}
const removeCartId = async () => {
    return AsyncStorage.removeItem(`${storagePrefix}cart_id`)
}

const getPreviousAPI = async () => {
    return AsyncStorage.getItem(`${storagePrefix}previous_api`)
}

const setPreviousAPI = async (prevApi: string) => {
    return AsyncStorage.setItem(`${storagePrefix}previous_api`, prevApi)
}

const storage = {
    getAccessToken: () => getToken('access'),
    removeAccessToken: () => removeToken('access'),
    setAccessToken: (token: string) => setToken('access', token),
    getRefreshToken: () => getToken('refresh'),
    removeRefreshToken: () => removeToken('refresh'),
    setRefreshToken: (token: string) => setToken('refresh', token),
    getAddressId: () => getToken('address_id'),
    setAddressId: (token: string) => setToken('address_id', token),
    removeAddressId: () => removeToken('address_id'),
    getDeviceId,
    setDeviceId,
    removeDeviceId,
    getNotificationPermissionChecked: () =>
        getToken('notification_permissions_checked'),
    setNotificationPermissionChecked: () =>
        setToken('notification_permissions_checked', 'yes'),
    clearNotificationPermissionChecked: () =>
        removeToken('notification_permissions_checked'),
    getNotificationToken: () => getToken('notification_token'),
    setNotificationToken: (token: string) =>
        setToken('notification_token', token),
    clearNotificationToken: () => removeToken('notification_token'),
    getCartId: () => getCartId(),
    setCartId: (cartId: string) => setCartId(cartId),
    removeCartId: () => removeCartId(),
    getPreviousAPI: () => getPreviousAPI(),
    setPreviousAPI: (prevApi: string) => setPreviousAPI(prevApi),
}

export const clearAllStorage = async () => {
    await Promise.allSettled([
        AsyncStorage.clear(),
        storage.removeAccessToken(),
        storage.removeRefreshToken(),
    ])
    queryClient.clear()
}

export default storage
