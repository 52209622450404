import React from 'react'

import {
    Image,
    Platform,
    Text,
    TouchableHighlight,
    TouchableOpacity,
    View,
} from 'react-native'

import {
    BottomSheetModal as BSM,
    BottomSheetScrollView,
} from '@gorhom/bottom-sheet'
import { SvgProps } from 'react-native-svg'

import { useLogout } from '@/features/Auth/utils/logout'

import { TrackableClick } from '@/components/Analytics'
import { BottomSheetModal } from '@/components/BottomSheetModal'

import { useProfile } from '@/hooks/query/useProfile'

import { useAccountModal } from '@/contexts/AccountModalContext'

import { PRIVACY_POLICY_URL } from '@/utils/constants'
import { openLink } from '@/utils/linking'
import { getRandomProfileImage } from '@/utils/randomImage'

import tw from '@/tailwind/tailwind'

import AccountSvg from '@/svgs/AccountSvg'
import BellSvg from '@/svgs/BellSvg'
import BouySvg from '@/svgs/BouySvg'
import ChevronRightSvg from '@/svgs/ChevronRightSvg'
// import CreditCardSvg from '@/svgs/CreditCardSvg'
import InstagramSvg from '@/svgs/InstagramSvg'
import JuiceCupSvg from '@/svgs/JuiceCupSvg'
// import PinSvg from '@/svgs/PinSvg'
import SmileySvg from '@/svgs/SmileySvg'
import TiktokSvg from '@/svgs/TiktokSvg'
import YoutubeSvg from '@/svgs/YoutubeSvg'

import ModalHeader from '../../components/ModalHeader'
// import { Addresses } from '../Addresses'
import { EditAccount } from '../EditAccount'
import { NotificationPreferences } from '../NotificationPreferences'
import { OrderList } from '../OrderList'
import { PrivacyPolicy } from '../PrivacyPolicy'
import { Support } from '../Support'

interface Route {
    title: string
    IconComponent: (props: SvgProps) => JSX.Element
    iconColor?: string
    modalName: string
    testID: string
    action?: () => any
}

let settingRoutes: Route[] = [
    {
        title: 'Edit Account',
        IconComponent: AccountSvg,
        iconColor: tw.color('pc-shade-80'),
        modalName: 'EditAccount',
        testID: 'account-settings-edit',
    },
    {
        title: 'Orders',
        IconComponent: JuiceCupSvg,
        iconColor: tw.color('pc-secondary'),
        modalName: 'Orders',
        testID: 'account-settings-orders',
    },
    // Re-enable eventually,
    // https://linear.app/popchew/issue/BUILD-567/create-payment-methods-bottom-sheet-modal-in-account-settings
    // {
    //     title: 'Payments',
    //     IconComponent: CreditCardSvg,
    //     iconColor: tw.color('pc-success'),
    //     modalName: 'Payments',
    //     testID: 'account-settings-payments',
    // },
    {
        title: 'Notifications',
        IconComponent: BellSvg,
        iconColor: tw.color('pc-shade-80'),
        modalName: 'Notifications',
        testID: 'account-settings-notifications',
    },
    // Re-enable eventually,
    // still needs ability to edit address
    // https://linear.app/popchew/issue/BUILD-529/stop-there-being-default-addresses-in-the-profile
    // {
    //     title: 'Addresses',
    //     IconComponent: PinSvg,
    //     iconColor: tw.color('pc-success'),
    //     modalName: 'Addresses',
    //     testID: 'account-settings-address',
    // },
    {
        title: 'About Popchew',
        IconComponent: SmileySvg,
        iconColor: tw.color('pc-shade-80'),
        action: () => openLink('https://popchew.com/'),
        modalName: 'AboutPopchew',
        testID: 'account-settings-about',
    },
    {
        title: 'Support',
        IconComponent: BouySvg,
        iconColor: tw.color('pc-shade-80'),
        modalName: 'Support',
        testID: 'account-settings-support',
    },
]

// Remove notifications on web - best way I could find to maintain list order and avoid type issues
if (Platform.OS === 'web') {
    settingRoutes = settingRoutes.filter(
        (route) => route.title !== 'Notifications'
    )
}

export const AccountSettings = () => {
    const { context: accountSettingsContext, hide } = useAccountModal()
    const { data: profile } = useProfile()
    const [logout] = useLogout()

    const ordersModalRef = React.useRef<BSM>(null)
    const editAccountModalRef = React.useRef<BSM>(null)
    const addressesModalRef = React.useRef<BSM>(null)
    const notificationsModalRef = React.useRef<BSM>(null)
    const supportModalRef = React.useRef<BSM>(null)
    const privacyPolicyRef = React.useRef<BSM>(null)

    const refDict = React.useMemo<
        Record<
            typeof settingRoutes[0]['modalName'],
            React.RefObject<BSM> | null
        >
    >(
        () => ({
            EditAccount: editAccountModalRef,
            Orders: ordersModalRef,
            Payments: null,
            Notifications: notificationsModalRef,
            Addresses: addressesModalRef,
            AboutPopchew: null,
            Support: supportModalRef,
            PrivacyPolicy: privacyPolicyRef,
        }),
        []
    )

    const showChildModal = (modalName: string) => () => {
        if (refDict[modalName]) {
            // Present modal
            refDict[modalName]?.current?.present()
        }
    }

    // When the modal is shown, check the context for if there is a child modal to show
    // If there is, show it and reset the context
    const onShow = () => {
        if (
            accountSettingsContext?.showChildModal &&
            accountSettingsContext?.setShowChildModal
        ) {
            showChildModal(accountSettingsContext.showChildModal)()
            accountSettingsContext.setShowChildModal(undefined)
        }
    }

    return (
        <BottomSheetModal
            trackableName="account"
            trackableCategory="Account"
            ref={accountSettingsContext.ref}
            snapPoints={['100%']}
            onShow={onShow}
        >
            <ModalHeader
                trackableCloseButtonProps={{
                    name: 'Tapped X in main account screen',
                    category: 'Account',
                }}
                closeButtonProps={{
                    onPress: () => hide(),
                }}
            />
            <BottomSheetScrollView>
                <View style={tw`mb-6`}>
                    <View style={tw`justify-center items-center mb-1`}>
                        <View
                            style={[
                                tw`bg-white rounded-full justify-center items-center h-[115px] w-[115px]`,
                                ,
                                {
                                    shadowColor: 'rgba(0,0,0,0.15)',
                                    shadowOpacity: 1,
                                    shadowOffset: {
                                        height: 1,
                                        width: 0,
                                    },
                                },
                            ]}
                        >
                            <Image
                                style={tw`rounded-full h-full w-full`}
                                source={
                                    profile?.profile_image_url
                                        ? profile?.profile_image_url
                                        : getRandomProfileImage()
                                }
                                width={110}
                                height={110}
                            />
                        </View>

                        {profile?.first ? (
                            <Text style={tw`font-ppa-b text-xl mt-4`}>
                                {profile.first +
                                    (profile.last ? ' ' + profile.last : '')}
                            </Text>
                        ) : null}
                    </View>
                    {settingRoutes.map(({ IconComponent, ...setting }, idx) => (
                        <TrackableClick
                            name="Tapped a settings option from account menu"
                            category="Account"
                            properties={{
                                setting: setting.modalName,
                            }}
                            key={idx}
                            testID={setting.testID}
                        >
                            <TouchableHighlight
                                style={tw`flex-row items-center px-4`}
                                underlayColor={tw.color('pc-shade-20')}
                                onPress={
                                    setting.action
                                        ? setting.action
                                        : showChildModal(setting.modalName)
                                }
                            >
                                <>
                                    <View style={tw`mr-4`}>
                                        <IconComponent
                                            color={setting.iconColor}
                                        />
                                    </View>
                                    <View
                                        style={tw`flex-row flex-1 justify-between items-center py-[18px] border-b border-pc-shade-60`}
                                    >
                                        <Text style={tw`font-ppa-b text-lg`}>
                                            {setting.title}
                                        </Text>
                                        <ChevronRightSvg
                                            color={tw.color('pc-shade-60')}
                                        />
                                    </View>
                                </>
                            </TouchableHighlight>
                        </TrackableClick>
                    ))}

                    <View style={tw`flex-row items-center ml-10 mb-10 mt-4`}>
                        {[
                            {
                                comp: InstagramSvg,
                                name: 'instagram',
                                url: 'https://www.instagram.com/eatpopchew/',
                            },
                            // nick is too embarrassed to show these for now :/
                            // {
                            //     comp: TwitterSvg,
                            //     name: 'twitter',
                            //     url: 'https://twitter.com/eatpopchew',
                            // },
                            {
                                comp: TiktokSvg,
                                name: 'tiktok',
                                url: 'https://www.tiktok.com/@eatpopchew',
                            },
                            {
                                comp: YoutubeSvg,
                                name: 'youtube',
                                url: 'https://www.youtube.com/c/Popchew',
                            },
                        ].map((Icon, idx) => (
                            <View key={idx} style={tw`mr-4 `}>
                                <TrackableClick
                                    name="Tapped social icon"
                                    category="Account"
                                    properties={{
                                        channel: Icon.name,
                                    }}
                                    testID={`social-media-button-${Icon.name}`}
                                    onesignal
                                    onesignalValue={Icon.name}
                                >
                                    <TouchableOpacity
                                        onPress={() => openLink(Icon.url)}
                                        style={tw`p-2`}
                                    >
                                        <Icon.comp />
                                    </TouchableOpacity>
                                </TrackableClick>
                            </View>
                        ))}
                    </View>

                    <View style={tw`px-container flex-row`}>
                        <TouchableOpacity
                            style={tw`self-start`}
                            onPress={
                                Platform.OS !== 'web'
                                    ? showChildModal('PrivacyPolicy')
                                    : () => openLink(PRIVACY_POLICY_URL)
                            }
                        >
                            <Text style={tw` text-pc-shade-100`}>
                                Privacy Policy
                            </Text>
                        </TouchableOpacity>
                        <Text style={tw`self-start text-pc-shade-100`}>
                            {' '}
                            •{' '}
                        </Text>
                        <TouchableOpacity
                            style={tw`self-start`}
                            onPress={() => logout()}
                        >
                            <Text style={tw`text-pc-shade-100`}>Sign Out</Text>
                        </TouchableOpacity>
                    </View>
                </View>

                <PrivacyPolicy ref={privacyPolicyRef} />
                <Support ref={supportModalRef} />
                <OrderList ref={ordersModalRef} />
                <EditAccount ref={editAccountModalRef} />
                {/* <Addresses ref={addressesModalRef} /> */}
                {Platform.OS !== 'web' && (
                    <NotificationPreferences ref={notificationsModalRef} />
                )}
            </BottomSheetScrollView>
        </BottomSheetModal>
    )
}
