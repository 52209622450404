import React from 'react'

import { Text, View } from 'react-native'

import { BottomSheetModal as BSM } from '@gorhom/bottom-sheet'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { TrackableClick } from '@/components/Analytics'
import { BottomSheetModal } from '@/components/BottomSheetModal'

import { SUPPORT_EMAIL, SUPPORT_PHONE } from '@/utils/constants'
import { openLink } from '@/utils/linking'

import tw from '@/tailwind/tailwind'

import ModalHeader from '../../components/ModalHeader'

export const Support = React.forwardRef<BSM>((_, ref) => {
    const onXPress = () => {
        ;(ref as React.MutableRefObject<BSM>)?.current?.dismiss()
    }

    return (
        <BottomSheetModal
            ref={ref}
            snapPoints={['90%']}
            handleComponent={null}
            trackableName="support"
            trackableCategory="Account"
        >
            <ModalHeader
                trackableCloseButtonProps={{
                    name: 'Tapped X in support settings',
                    category: 'Account',
                    testID: 'close-support-account',
                }}
                closeButtonProps={{
                    onPress: onXPress,
                }}
                title="Support"
            />
            <View style={tw`px-container`}>
                <Text style={tw`font-ppa-b text-2xl text-center mt-7 mb-7`}>
                    Need help?
                </Text>

                <Text
                    style={tw`font-ppa-b text-center text-lg text-pc-shade-80 `}
                >
                    Email our support team at
                </Text>
                <TrackableClick name="Tapped support email" category="Account">
                    <TouchableOpacity
                        hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
                        style={tw`mt-1`}
                        onPress={() => openLink(`mailto:${SUPPORT_EMAIL}`)}
                    >
                        <Text
                            style={tw`font-ppa-b text-pc-secondary text-lg text-center`}
                        >
                            {SUPPORT_EMAIL}
                        </Text>
                    </TouchableOpacity>
                </TrackableClick>

                <Text style={tw`font-ppa-b my-4 text-center text-base`}>
                    or
                </Text>

                <Text
                    style={tw`font-ppa-b text-center text-lg text-pc-shade-80 `}
                >
                    Give us a ring between 4PM - 2AM EST
                </Text>
                <TrackableClick name="Tapped support phone" category="Account">
                    <TouchableOpacity
                        hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
                        style={tw`mt-1`}
                        onPress={() => openLink(`tel:${SUPPORT_PHONE}`)}
                    >
                        <Text
                            style={tw`font-ppa-b text-pc-secondary text-lg text-center `}
                        >
                            {SUPPORT_PHONE}
                        </Text>
                    </TouchableOpacity>
                </TrackableClick>
            </View>
        </BottomSheetModal>
    )
})
