import { Platform } from 'react-native'

import { navigationRef } from '@/navigation/navigateUsingRef'
import Constants from 'expo-constants'

import { postTrackingEvent } from '@/components/Analytics/postTrackingEvent'

export const enableOneSignal = async () => {
    if (Platform.OS === 'web') return
    if (Constants.appOwnership === 'expo') return
    if (!Constants.manifest?.extra?.oneSignalAppId) return

    const OneSignalImport = await import('react-native-onesignal')
    const OneSignal = OneSignalImport.default

    OneSignal.setAppId(Constants.manifest.extra.oneSignalAppId)

    //Method for handling notifications received while app in foreground
    OneSignal.setNotificationWillShowInForegroundHandler(
        (notificationReceivedEvent) => {
            const notification = notificationReceivedEvent.getNotification()

            // Complete with null means don't show a notification.
            notificationReceivedEvent.complete(notification)
        }
    )

    //Method for handling notifications opened
    OneSignal.setNotificationOpenedHandler((notification) => {
        // TOO MANY EVENTS? + require cycle
        postTrackingEvent({
            name: `OneSignal Notification Opened: ${notification.notification.title}`,
            category: 'Notifications',
        })
    })

    OneSignal.setInAppMessageClickHandler((action) => {
        const name = action.click_name
        if (name && name.includes('deeplink-')) {
            const link = name.replace('deeplink-', '')
            const pageName = link.substring(0, link.indexOf('?'))
            const paramsString = link.substring(link.indexOf('?') + 1)
            const paramsArray = paramsString.split('&')
            const params: Record<string, string> = {}
            for (const param of paramsArray) {
                const paramKeyVal = param.split('=')
                params[paramKeyVal[0]] = paramKeyVal[1]
            }
            navigationRef.navigate(pageName, params)
        }
    })
    if (__DEV__) OneSignal.sendTag('test', 'true')
}

export const updateOneSignalUser = async ({
    id,
    email,
    phone,
}: {
    id?: string
    email?: string | null
    phone?: string | null
}) => {
    if (Platform.OS === 'web') return
    if (Constants.appOwnership === 'expo') return

    try {
        const OneSignalImport = await import('react-native-onesignal')
        const OneSignal = OneSignalImport.default

        if (id) OneSignal.setExternalUserId(id)

        if (email) OneSignal.setEmail(email)

        if (phone) OneSignal.setSMSNumber(phone)
    } catch (_e) {
        // NEVER THROW AN ERROR IN HERE, as it will cause functions that call this to fail
    }
}

export const sendOneSignalOutcome = async (
    name: string,
    value?: string | number
) => {
    if (Platform.OS === 'web') return
    if (Constants.appOwnership === 'expo') return

    try {
        const OneSignalImport = await import('react-native-onesignal')
        const OneSignal = OneSignalImport.default

        if (value) OneSignal.sendOutcomeWithValue(name, value)
        else OneSignal.sendOutcome(name)
    } catch (_e) {
        // NEVER THROW AN ERROR IN HERE, as it will cause functions that call this to fail
    }
}
