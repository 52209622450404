export const timeAgo = (date: number) => {
    const seconds = Math.floor((new Date().getTime() - date) / 1000)

    let interval = seconds / 31536000

    if (interval > 1) {
        return Math.floor(interval) + 'yr'
    }
    interval = seconds / 2592000
    if (interval > 1) {
        return Math.floor(interval) + 'mo'
    }
    interval = seconds / 86400
    if (interval > 1) {
        return Math.floor(interval) + 'd'
    }
    interval = seconds / 3600
    if (interval > 1) {
        return Math.floor(interval) + 'hr'
    }
    interval = seconds / 60
    if (interval > 1) {
        return Math.floor(interval) + 'm'
    }
    return Math.floor(seconds) + 's'
}
