import * as React from 'react'

import Svg, { Path, SvgProps } from 'react-native-svg'

const BubbleStarSvg = ({ color, ...props }: SvgProps) => (
    <Svg
        width={27}
        height={26}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M11.707 1.115c.733-1.487 2.853-1.487 3.586 0l2.59 5.249a2 2 0 0 0 1.507 1.094l5.792.841c1.64.239 2.296 2.255 1.109 3.412l-4.192 4.085a2 2 0 0 0-.575 1.77l.99 5.77c.28 1.633-1.435 2.88-2.902 2.108L14.43 22.72a2 2 0 0 0-1.862 0l-5.18 2.724c-1.468.771-3.183-.475-2.902-2.108l.989-5.77a2 2 0 0 0-.575-1.77L.709 11.711C-.478 10.554.177 8.538 1.818 8.299l5.792-.841a2 2 0 0 0 1.506-1.094l2.59-5.25Z"
            fill={color}
        />
    </Svg>
)

export default BubbleStarSvg
