import { useNavigation, useNavigationState } from '@react-navigation/native'

import { BackProps, RootScreenName, ScreenName } from '../types/ScreenProps'

const useNavigateBack = () => {
    const navigation = useNavigation<BackProps['navigation']>()
    const routeStack = useNavigationState((state) => state.routes)

    return (
        fallback: RootScreenName | 'PhoneNumberCapture',
        forceFallbackIfPreviousPageIn?: Array<ScreenName>
    ) => {
        // Used to stop infinite loops, if a back button shouldn't
        // go a certain way force fallback
        const previousIndex = routeStack.length - 2

        if (
            previousIndex >= 0 &&
            ((routeStack[previousIndex].name as ScreenName) === 'VerifyCode' ||
                forceFallbackIfPreviousPageIn?.includes(
                    routeStack[previousIndex].name as ScreenName
                ))
        )
            return navigation.navigate(fallback)

        navigation.canGoBack()
            ? navigation.goBack()
            : navigation.navigate(fallback)
    }
}

export { useNavigateBack }
