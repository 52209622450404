import React, { FC } from 'react'

import {
    Text,
    TextProps,
    TouchableOpacityProps,
    View,
    ViewProps,
} from 'react-native'

import { TrackableElementProps } from '@/components/Analytics'
import { ModalX } from '@/components/Titles/ModalX'

import tw from '@/tailwind/tailwind'

export interface ModalHeaderProps {
    trackableCloseButtonProps: TrackableElementProps
    closeButtonProps?: TouchableOpacityProps
    title?: string
    titleProps?: TextProps
    containerProps?: ViewProps
}

const ModalHeader: FC<ModalHeaderProps> = ({
    trackableCloseButtonProps,
    containerProps,
    closeButtonProps,
    title,
    titleProps,
}) => {
    const { name, category, testID } = trackableCloseButtonProps

    return (
        <View
            {...containerProps}
            style={[
                tw`container`,
                containerProps?.style ? containerProps.style : {},
            ]}
        >
            <View style={tw`relative flex-row items-center pb-2`}>
                <ModalX
                    onPress={closeButtonProps?.onPress}
                    name={name}
                    category={category}
                    testID={testID}
                />

                {title && (
                    <Text
                        {...titleProps}
                        style={[
                            tw`ml-4 font-ppa-b text-xl text-center`,
                            titleProps?.style ? titleProps.style : {},
                        ]}
                    >
                        {title}
                    </Text>
                )}
            </View>
        </View>
    )
}

export default ModalHeader
