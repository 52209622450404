import { Item } from '@/types/Menu'

export const totalItemPrice = (item: Item) => {
    const { modifier_groups } = item
    if (!modifier_groups) return item.price

    // Check for any selected price-overriding modifiers
    let foundOverride = false
    let highestOverride = 0
    for (const modGroup of modifier_groups) {
        for (const mod of modGroup.modifiers) {
            if (mod.is_selected && mod.override_item_price) {
                foundOverride = true
                if (mod.price > highestOverride) highestOverride = mod.price
                // Favor the most expensive override modifier
            }
        }
    }
    const basePrice = foundOverride ? highestOverride : item.price

    // Add up total cost of selected non-override modifiers
    const modifierCosts = modifier_groups.reduce(
        (mGprev, mGcurr) =>
            mGprev +
            mGcurr.modifiers.reduce((prev, curr) => {
                if (curr.override_item_price) return prev
                if (!curr.is_selected) return prev
                return curr.price + prev
            }, 0),
        0
    )

    return basePrice + modifierCosts
}
