import { useEffect, useRef } from 'react'

interface useOrderRefetchIntervalProps {
    intervalTimeInSec: number | undefined
    onTimeout?: () => void
    onInterval: () => void
    maxTries?: number
    enabled?: boolean
}

export const useOrderRefetchInterval = ({
    intervalTimeInSec,
    onTimeout,
    onInterval,
    maxTries,
    enabled = true,
}: useOrderRefetchIntervalProps) => {
    const timeoutFnRef = useRef(onTimeout)
    const intervalFnRef = useRef(onInterval)
    const intervalRef = useRef<NodeJS.Timer | null>(null)

    useEffect(() => {
        timeoutFnRef.current = onTimeout
    }, [onTimeout])

    useEffect(() => {
        intervalFnRef.current = onInterval
    }, [onInterval])

    useEffect(() => {
        const onTimeoutFn = () => timeoutFnRef.current?.()
        const onIntervalFn = () => intervalFnRef.current()

        if (!intervalTimeInSec) return
        if (!enabled) return

        let maxTriesLeft = maxTries

        const timer = setInterval(() => {
            try {
                if (maxTriesLeft === 0) {
                    onTimeoutFn()
                    clearInterval(timer)
                } else {
                    onIntervalFn()
                }
                maxTriesLeft && maxTriesLeft--
            } catch (err) {}
        }, intervalTimeInSec * 1000)

        intervalRef.current = timer

        return () => clearInterval(timer)
    }, [intervalTimeInSec, maxTries, enabled])

    return intervalRef
}
