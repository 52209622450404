import React, { useEffect, useState } from 'react'

import { Platform, View, useWindowDimensions } from 'react-native'

import {
    BottomSheetModal as BSM,
    BottomSheetBackdrop,
    BottomSheetModalProps,
} from '@gorhom/bottom-sheet'
import { SharedValue } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { useIsMobile } from '@/hooks/useIsMobile'

import tw from '@/tailwind/tailwind'

import {
    TrackableProps,
    TrackingCategory,
    postTrackingEvent,
} from '../Analytics'

interface BSMProps extends BottomSheetModalProps {
    children: React.ReactNode
    trackableName?: string
    trackableCategory?: TrackingCategory
    trackableProperties?: TrackableProps['properties']
    enableTouchOutsideToClose?: boolean
    onShow?: () => void
    onHide?: () => void
    isDark?: boolean
}

export const BottomSheetModal = React.forwardRef<BSM, BSMProps>(
    (
        {
            children,
            snapPoints,
            trackableName,
            trackableCategory,
            trackableProperties,
            enableTouchOutsideToClose = true,
            onShow,
            onHide,
            isDark = false,
            backgroundStyle,
            ...props
        },
        ref
    ) => {
        // Dimensional considerations: since snap points are always based on the TOTAL screen height, they are different on web vs ios/android for two reasons:
        // 1. Fancy border on web changes the proportion of usable screen
        // 2. SafeAreaView on ios/android changes the proportion of usable screen
        // Note that currently modals still pop up from the bottom so the convertSnapNumber formula will need to be adjusted
        // detaching the bottom leads to other problems because of inherent problems with the gorlom package, probably not a good idea for now
        type SnapPoints = (string | number)[] | SharedValue<(string | number)[]>
        const snapPointsMemo: SnapPoints = React.useMemo(
            () => snapPoints,
            [snapPoints]
        )

        const { height, width } = useWindowDimensions()

        const insets = useSafeAreaInsets()
        const aspectRatio = 0.423
        const minHeight = 768

        const [leftScroll, setLeftScroll] = useState(0)
        const handleScroll = () => {
            setLeftScroll(window.scrollX)
        }

        const isMobile = useIsMobile()
        useEffect(() => {
            if (!isMobile) {
                handleScroll()
                window.addEventListener('scroll', handleScroll)
            }
            return () => {
                if (!isMobile)
                    window.removeEventListener('scroll', handleScroll)
            }
        }, [isMobile])

        const modalWidth =
            (height > minHeight ? height : minHeight) * aspectRatio
        const modalLeftMargin = (width - modalWidth) / 2 - 2 - leftScroll // offset 2 px left because frame image isn't exactly centered

        const convertSnapPoints = (snapPoints: SnapPoints) => {
            if ('value' in snapPoints) return snapPoints // ignore SharedValues type

            const convertSnapNumber = (value: number) =>
                Platform.OS === 'web'
                    ? value * 0.919 + 0.02 // 91.9% is usable screen height with fancy border, 2% of screen is bottom of fancy border,
                    : (value * (height - insets.top)) / height

            return snapPoints.map((snapPoint: string | number) => {
                if (typeof snapPoint === 'string')
                    return (
                        convertSnapNumber(
                            parseInt(snapPoint.replace('%', ''))
                        ).toString() + '%'
                    )
                if (typeof snapPoint === 'number')
                    return convertSnapNumber(snapPoint)
                else return snapPoint
            })
        }

        const renderBackdrop = React.useCallback(
            (props) => {
                return (
                    <BottomSheetBackdrop
                        {...props}
                        opacity={1}
                        appearsOnIndex={1}
                        disappearsOnIndex={-1}
                        pressBehavior={
                            enableTouchOutsideToClose ? 'close' : 'none'
                        }
                    />
                )
            },
            [enableTouchOutsideToClose]
        )

        const renderBackground = React.useCallback((props) => {
            return (
                <View
                    {...props}
                    style={[
                        { ...props.style },
                        tw` ${
                            isDark ? 'bg-grey-800' : 'bg-white'
                        }  rounded-tl-[30px] rounded-tr-[30px]`,
                        backgroundStyle,
                    ]}
                />
            )
        }, [])

        // Analytics: -1 is down position (dismiss), 0 is up (present)
        const trackOnMove = (index: number) => {
            if (trackableName && trackableCategory) {
                const sendToOneSignal = ['notify brands'].includes(
                    trackableName
                )
                if (index === -1) {
                    postTrackingEvent({
                        name: 'Dismissed ' + trackableName + ' screen',
                        category: trackableCategory,
                        onesignal: sendToOneSignal,
                        properties: trackableProperties,
                    })
                } else if (index === 0) {
                    postTrackingEvent({
                        name: 'Viewed ' + trackableName + ' screen',
                        category: trackableCategory,
                        onesignal: sendToOneSignal,
                        properties: trackableProperties,
                    })
                }
            }
        }

        return (
            <BSM
                {...props}
                ref={ref}
                snapPoints={convertSnapPoints(snapPointsMemo)}
                backdropComponent={renderBackdrop}
                backgroundComponent={renderBackground}
                stackBehavior="push"
                enableDismissOnClose
                enablePanDownToClose
                handleIndicatorStyle={tw`${
                    isDark ? 'bg-white' : 'bg-pc-shade-40'
                } `}
                onChange={(index) => {
                    // call props on change
                    if (props.onChange) props.onChange(index)

                    // call onShow and onHide based on index
                    if (index === -1 && onHide) onHide()
                    else if (index === 0 && onShow) onShow()

                    // call tracking on change
                    trackOnMove(index)
                }}
                style={
                    !isMobile && {
                        marginLeft: modalLeftMargin,
                        width: modalWidth,
                    }
                }
            >
                {children}
            </BSM>
        )
    }
)
