import { axios } from '@/lib/httpClient'

export type TopFan = {
    profile_picture: string
    display_name: string
    city: string
    state: string
    rank: number
}

type TopFansResponse = {
    brands: Array<{
        brand_id: string
        brand_name: string
        top_fans: Array<TopFan>
    }>
}

export const getTopFans = async () => {
    const response = await axios.get<TopFansResponse>('/brand/top-fans')
    return response.data
}
