import { Text, View } from 'react-native'

import { TouchableOpacity } from 'react-native-gesture-handler'

import { Item } from '@/types/Menu'

import { TrackableClick } from '@/components/Analytics'

import { toPrice } from '@/utils/normalizers'

import tw from '@/tailwind/tailwind'

type AddButtonProps = {
    count: number
    quickAddCartItem: () => void
    price: number
    item: Item
    disabled?: boolean
    isDark?: boolean
}

export const AddButton = (props: AddButtonProps) => {
    const { count, quickAddCartItem, price, item, disabled, isDark } = props

    const isItemInCart = count > 0
    const medButtonText = 'font-ppp-m text-sm '
    const boldButtonText = 'font-ppp-b text-sm '

    const buttonClass =
        'rounded-full items-center flex-row px-3 pb-1 pt-1.5 mt-4 border self-start'
    if (disabled)
        return (
            <View style={tw`${buttonClass} bg-grey-700 border-grey-700`}>
                <Text style={tw`${boldButtonText} text-white`}>
                    Unavailable
                </Text>
            </View>
        )

    const buttonContent = isItemInCart ? (
        <>
            <Text
                style={tw`${boldButtonText} ${isDark ? '' : 'text-white'}

`}
            >
                Added
            </Text>
            <Text
                style={tw`${medButtonText} ml-2.5 ${
                    isDark ? '' : 'text-white'
                }`}
            >
                {count}
            </Text>
        </>
    ) : (
        <>
            <Text style={tw`${medButtonText} ${isDark ? 'text-white' : ''} `}>
                {toPrice(price)}
            </Text>
            <Text
                style={tw`${boldButtonText} ${
                    isDark ? 'text-white' : ''
                } ml-2.5`}
            >
                Add
            </Text>
        </>
    )

    return (
        <TrackableClick
            name="Tapped quick add menu item"
            category="Order"
            properties={{
                'item name': item.name,
                'item price': item.price,
                'item id': item.item_id,
            }}
            onesignal
        >
            <TouchableOpacity
                hitSlop={{ top: 6, bottom: 6, left: 6, right: 6 }}
                style={[
                    tw`${buttonClass} ${
                        isItemInCart
                            ? isDark
                                ? 'border-black'
                                : 'border-white'
                            : isDark
                            ? 'bg-black border-black'
                            : 'bg-pc-primary border-pc-primary'
                    }`,
                    { cursor: 'pointer', boxSizing: 'border-box' },
                ]}
                onPress={quickAddCartItem}
            >
                {buttonContent}
            </TouchableOpacity>
        </TrackableClick>
    )
}
